import React, { useRef } from 'react'
import { useResizeObserver } from 'usehooks-ts'

interface AnimateHeightProps {
  children: React.ReactNode
}

export default function AnimateHeight({ children }: AnimateHeightProps) {
  const observedDivRef = useRef<HTMLDivElement>(null)
  const targetDivRef = useRef<HTMLDivElement>(null)

  useResizeObserver({
    ref: observedDivRef,
    onResize: ({ height }) => {
      if (targetDivRef.current && height) {
        targetDivRef.current.style.height = `${height}px`
      }
    },
  })

  return (
    <div
      ref={targetDivRef}
      style={{ transition: 'height 0.3s ease-in-out', overflow: 'hidden' }}
    >
      <div ref={observedDivRef}>
        {children}
      </div>
    </div>
  )
}
