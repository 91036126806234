import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, FormControl, MenuItem, Select, Switch, TextField } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ColorPicker from 'material-ui-color-picker';
import DeleteIcon from '@material-ui/icons/Delete';
import { FONT_FAMILY } from '../../constant/bannerItems';
import { _useToasts } from '../../common/_useToasts'
import Loader from '../../common/Loader';
import { updateGeneralSettings } from "../../util/settings/settingsAPIUtil";

const BreadcrumbEditSection = ({ getId, open, handleClose, retrieveSettings, generalSettings }) => {

    const [loading, setLoading] = useState(false)
    const { _addToast } = _useToasts();

    const [formStates, setFormStates] = useState(generalSettings?.values || {
        showBreadCrumbs: true,
        breadCrumbFontSize: "",
        breadCrumbFont: "",
        breadCrumbFontColor: "",
        breadCrumbBackgroundColor: "",
    });

    useEffect(() => {
        if (generalSettings) {
            setFormStates(generalSettings?.values)
        }
    }, [generalSettings]);

    const updateFormValue = (type, key) => (event) => {
        const { target } = event;
        setFormStates({
            ...formStates,
            [type]: target[key],
        });
    }
    const updateFormValueColor = (type, key) => {
        setFormStates({
            ...formStates,
            [type]: key,
        });
    };
    const handleSubmit = async () => {
        setLoading(true)
        let insertData = {
            "values": formStates,
            "settingsName": generalSettings?.settingsName,
            "page": generalSettings?.page,
        }

        if (getId) {
            await updateGeneralSettings(getId, insertData).then(res => {
                    setLoading(false)
                    if (res.data.error) {
                        _addToast(res.data.message, {
                            appearance: "error",
                            autoDismiss: true
                        });
                    } else {
                        _addToast("Settings updated successfully", {
                            appearance: "success",
                            autoDismiss: true
                        });
                        retrieveSettings()
                        handleClose()

                    }
                }).catch((error, config, code, request, response) => {
                    setLoading(false)
                    if (error.response && error.response.data && error.response.data.message) {
                        _addToast(error.response.data.message, {
                            appearance: "error",
                            autoDismiss: true
                        });
                    } else {
                        _addToast('Issues while update settings', {
                            appearance: "error",
                            autoDismiss: true
                        });
                    }

                });
        }
    }

    return (
        <div>
            {loading && (
                <Loader />
            )}
            <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
                <DialogTitle className='form-dialog-title' id="form-dialog-title">
                    <span>Breadcrumb settings</span>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className='banner-form'>
                        {/* <form className="banner-form-style" onSubmit={handleSubmit}> */}
                        <form className="banner-form-style">
                            <div >
                                <div className="row col-md-12 input-box align-items-center mb-15">
                                    <label className="col-md-5">Show Breadcrumbs</label>
                                    <div className="col-md-4">
                                        <Switch
                                            edge="end"
                                            color="primary"
                                            onChange={updateFormValue('showBreadCrumbs', 'checked')}
                                            checked={formStates?.showBreadCrumbs}
                                            inputProps={{ 'aria-labelledby': 'switch-list-label-bluetooth' }}
                                        />
                                    </div>
                                </div>
                                <div className="row col-md-12 input-box align-items-center mb-15">
                                    <label className="col-md-5">Breadcrumb Font</label>
                                    <div className="col-md-4">
                                        <FormControl variant="outlined" className="w-100">
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={formStates?.breadCrumbFont}
                                                onChange={updateFormValue('breadCrumbFont', 'value')}
                                                name="breadCrumbFont"
                                            >
                                                {FONT_FAMILY && FONT_FAMILY.length && Object.keys(FONT_FAMILY).map((data, index) => {
                                                    return <MenuItem key={index} style={{ fontFamily: FONT_FAMILY[data].label }} value={FONT_FAMILY[data].value}> {FONT_FAMILY[data].label}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row col-md-12 input-box align-items-center mb-15">
                                    <label className="col-md-5">Breadcrumb font Size</label>
                                    <div className="col-md-4">
                                        <TextField
                                            className="w-100"
                                            id="outlined-basic"
                                            label=""
                                            size="medium"
                                            type="number"
                                            value={formStates?.breadCrumbFontSize}
                                            variant="outlined"
                                            onChange={updateFormValue('breadCrumbFontSize', 'value')}
                                        />
                                    </div>
                                </div>
                                <div className="row col-md-12 input-box align-items-center mb-15">
                                    <label className="col-md-5">Breadcrumb Font Color</label>
                                    <div className="col-md-6">
                                        <div>
                                            <span className="ml-2 mr-2">{formStates?.breadCrumbFontColor}</span>
                                            <ColorPicker
                                                name="breadCrumbFontColor"
                                                defaultValue={"Select Color"}
                                                value={formStates?.breadCrumbFontColor}
                                                onChange={(color) => { updateFormValueColor('breadCrumbFontColor', color) }}
                                            />
                                            {formStates?.breadCrumbFontColor != "" && (
                                                <DeleteIcon
                                                    className="text-error"
                                                    title={"Delete"}
                                                    onClick={() => {
                                                        updateFormValueColor('breadCrumbFontColor', "")
                                                    }}
                                                    aria-label=""
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row col-md-12 input-box align-items-center mb-15">
                                    <label className="col-md-5">Breadcrumb Background Color</label>
                                    <div className="col-md-6">
                                        <div>
                                            <span className="ml-2 mr-2">{formStates?.breadCrumbBackgroundColor}</span>
                                            <ColorPicker
                                                name="breadCrumbBackgroundColor"
                                                defaultValue={"Select Color"}
                                                value={formStates?.breadCrumbBackgroundColor}
                                                onChange={(color) => { updateFormValueColor('breadCrumbBackgroundColor', color) }}
                                            />
                                            {formStates?.breadCrumbBackgroundColor != "" && (
                                                <DeleteIcon
                                                    className="text-error"
                                                    title={"Delete"}
                                                    onClick={() => {
                                                        updateFormValueColor('breadCrumbBackgroundColor', "")
                                                    }}
                                                    aria-label=""
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Button type="button" className="cancelButton" onClick={() => { handleClose() }}>Cancel</Button>&nbsp;&nbsp;&nbsp;
                                    <Button variant="contained" color="primary" className="bannerbutton" onClick={handleSubmit}> {getId ? "Update" : "Save"}</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
        </div >
    )
}

export default BreadcrumbEditSection
