import React, { useState } from 'react'
import {
  Box,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Pagination,
  Radio,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { SavedAddress } from '../../../models/Address'
import { getAddressString } from '../../../helpers/checkout'
import { Search, StarOutline } from '@mui/icons-material'

export interface SavedAddressListProps {
  addresses: SavedAddress[]
  searchEnabled: boolean
  selectedAddressId: string | null
  onSelectAddress: (address: SavedAddress) => void
}

const PAGE_MAX = 5

const getDefaultPage = (
  addresses: SavedAddress[],
  selectedAddressId: string | null,
) => {
  const page = addresses.findIndex(
    (address) => address.id === selectedAddressId,
  )
  return page === -1 ? 1 : Math.floor(page / PAGE_MAX) + 1
}

export default function SavedAddressList({
  addresses,
  searchEnabled,
  selectedAddressId,
  onSelectAddress,
}: SavedAddressListProps) {
  const [searchText, setSearchText] = React.useState('')

  const [page, setPage] = useState(getDefaultPage(addresses, selectedAddressId))

  const filteredAddresses = addresses.filter((address) => {
    return (
      `${address.firstName?.toLowerCase()} ${address.lastName?.toLowerCase()}`.includes(
        searchText.toLowerCase(),
      ) ||
      address.email?.toLowerCase().includes(searchText.toLowerCase()) ||
      address.locationName?.toLowerCase().includes(searchText.toLowerCase()) ||
      address.company?.toLowerCase().includes(searchText.toLowerCase()) ||
      getAddressString(address).toLowerCase().includes(searchText.toLowerCase())
    )
  })

  const pageCount = Math.ceil(filteredAddresses.length / PAGE_MAX)

  const handleSelect = (address: SavedAddress) => () => {
    onSelectAddress(address)
  }

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const handleInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSearchText(e.target.value)
    setPage(1)
  }

  return (
    <Box>
      {searchEnabled && (
        <Stack mx={2} mb={1}>
          <TextField
            variant="outlined"
            value={searchText}
            margin="dense"
            size="small"
            onChange={handleInput}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              },
            }}
            fullWidth
          />
        </Stack>
      )}
      {filteredAddresses.length === 0 && (
        <Typography variant="body1" textAlign={'center'} my={2}>
          No addresses found
        </Typography>
      )}
      <List disablePadding>
        {filteredAddresses
          .slice((page - 1) * PAGE_MAX, (page - 1) * PAGE_MAX + PAGE_MAX)
          .map((address, idx) => (
            <React.Fragment key={idx}>
              <ListItem
                key={address.id}
                data-testid={`address-${address.id}`}
                disablePadding
                secondaryAction={
                  address.isDefault ? (
                    <Tooltip title="Default">
                      <StarOutline />
                    </Tooltip>
                  ) : undefined
                }
              >
                <ListItemButton onClick={handleSelect(address)} dense>
                  <ListItemIcon>
                    <Radio
                      checked={selectedAddressId === address.id}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': address.locationName }}
                    />
                  </ListItemIcon>
                  <Stack direction={'column'}>
                    <ListItemText
                      primary={address.locationName}
                      secondary={getAddressString(address)}
                    />
                    {selectedAddressId === address.id &&
                      (address.firstName ||
                        address.lastName ||
                        address.email) && (
                        <ListItemText
                          secondary={`${address.firstName} ${address.lastName}${
                            address.email ? ` (${address.email})` : ''
                          }`}
                        />
                      )}
                  </Stack>
                </ListItemButton>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
      </List>
      <Stack direction={'row'} justifyContent={'center'} m={2}>
        <Pagination
          page={page}
          onChange={handlePageChange}
          count={pageCount}
          color="primary"
        />
      </Stack>
      <Divider />
    </Box>
  )
}
