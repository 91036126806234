import { ListDataRequest, ListDataResponse } from '../../models/List'
import {
  BudgetHistory,
  BudgetHistoryFilters,
  UserBudget,
} from '../../models/NewBudget'
import { aetherApi } from './aetherApi'

export const budgetApi = aetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getActiveBudgets: builder.query<UserBudget[], void>({
      query: () => `api/v2/budget/user-active`,
      providesTags: ['Budgets'],
    }),
    getEnabled: builder.query<boolean, void>({
      query: () => `api/v2/budget/enabled`,
    }),
    getBudgetHistory: builder.query<
      ListDataResponse<BudgetHistory, BudgetHistoryFilters>,
      { request: ListDataRequest<BudgetHistoryFilters>; id: string }
    >({
      query: ({ request, id }) => ({
        url: `api/v2/budget/budgets/${id}/user-history`,
        method: 'POST',
        body: request,
      }),
      providesTags: ['BudgetHistory']
    }),
  }),
})

export const {
  useGetActiveBudgetsQuery,
  useGetEnabledQuery,
  useGetBudgetHistoryQuery,
} = budgetApi
