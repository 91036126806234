import React from 'react';
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { FileMetadata } from '../../../models/File';
import { Delete, Folder } from '@mui/icons-material';
import { formatBytes } from '../../../helpers/formattingHelpers';

interface DocumentRowProps {
  file: FileMetadata;
  onDelete: () => void;
}

export default function DocumentRow({ file, onDelete }: DocumentRowProps) {
  return (
    <ListItem
      secondaryAction={
        <IconButton onClick={onDelete}>
          <Delete />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar>
          <Folder />
        </Avatar>
      </ListItemAvatar>
      <ListItemText 
        primary={file.name}
        secondary={formatBytes(file.size)}
      />
    </ListItem>
  );
}
