import React from 'react'
import {
  Box,
  Card,
  CardContent,
  Grid2,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import useCurrencyDisplay from '../useCurrencyDisplay'
import { TextFieldElement } from 'react-hook-form-mui'
import { AgcFullDetail } from '../../../models/AgcRequest'

const ItemImage = styled('img')({
  maxWidth: '100%',
})

export default function GiftCardRow({
  item,
  index,
}: {
  item: AgcFullDetail
  index: number
}) {
  const { format } = useCurrencyDisplay()

  return (
    <Card>
      <CardContent>
        <Grid2 container alignItems={'center'} spacing={4}>
          <Grid2 size={3}>
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <Box maxWidth={84} maxHeight={84} overflow="hidden">
                {item.imageUrl && (
                  <ItemImage src={item.imageUrl} alt={item.productName} />
                )}
              </Box>
              <Typography variant="body1">{item.productName}</Typography>
            </Stack>
          </Grid2>
          <Grid2 size={1}>
            <Typography variant="body1" align="right">
              #{item.quantityIndex + 1}
            </Typography>
          </Grid2>
          <Grid2 size={2}>
            <Typography variant="body1" align="right">
              {format(item.amount)}
            </Typography>
          </Grid2>
          <Grid2 size={2}>
            <Typography variant="body1">{item.code}</Typography>
          </Grid2>
          <Grid2 size={4}>
            <TextFieldElement
              name={`notificationDetails.${index}.email`}
              label="Email"
              type="email"
              fullWidth
              size="small"
            />
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  )
}
