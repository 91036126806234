import { getDefaultColor, getDefaultSize } from "../redux/actions/cartActions";
import Decimal from 'decimal.js'
import { formatPriceFixedDecimalPlaces } from "../common/PriceFormat";
// get products
export const getProducts = (products, category, type, limit) => {
  const finalProducts = category
    ? products && products.filter(
      product => product.category && product.category.filter(single => single === category)[0]
    )
    : products;

  if (type && type === "new") {
    const newProducts = finalProducts.filter(single => single.new);
    return newProducts.slice(0, limit ? limit : newProducts.length);
  }
  if (type && type === "bestSeller") {
    return finalProducts
      .sort((a, b) => {
        return b.saleCount - a.saleCount;
      })
      .slice(0, limit ? limit : finalProducts.length);
  }
  if (type && type === "saleItems") {
    const saleItems = finalProducts.filter(
      single => single.discount && single.discount > 0
    );
    return saleItems.slice(0, limit ? limit : saleItems.length);
  }
  return finalProducts.slice(0, limit ? limit : finalProducts.length);
};

// get product discount price
export const getDiscountPrice = (price, discount) => {
  return discount && discount > 0 ? price - price * (discount / 100) : null;
};

export const getMoqNote = (general, product, minQty) => {
  let value = 'Minimum order quantity for this item is [MOQ]';
  if(!!product?.moqNote && (product?.showMoqNote === true || product?.showMoqNote === false)) {
    value = product.moqNote;
  } else {
    if(!!general.moqNote){
      value = general.moqNote;
    }
  }
  return value.replace('[MOQ]', minQty ?? '1');
}

export const getShowMoqNote = (general, product) => {
  let value = false;
  if(product?.showMoqNote === true || product?.showMoqNote === false) {
    value = product.showMoqNote;
  } else {
    if(general?.showMoqNote === true || general?.showMoqNote === false) {
      value = general.showMoqNote;
    }
  }
  return value;
}

// get product cart quantity
export const getProductCartQuantity = (cartItems = [], product = {}, color = '', size = '') => {
  if (!Array.isArray(cartItems) || !product.id) {
    return 0;
  }
  const productInCart = cartItems?.filter((single) => {
    return (
      single.id === product.id &&
      (single.selectedProductColor ? single.selectedProductColor === color : true) &&
      (single.selectedProductSize ? single.selectedProductSize === size : true)
    );
  })[0];

  if (cartItems.length >= 1 && productInCart) {
    if (product.variation) {
      const matchedItem = cartItems?.filter(
        (single) =>
          single.id === product.id &&
          single.selectedProductColor === color &&
          single.selectedProductSize === size,
      )[0];
      return matchedItem?.quantity ?? 0;
    } else {
      const matchedItem = cartItems?.filter((single) => single.id === product.id)[0];
      return matchedItem?.quantity ?? 0;
    }
  } else {
    return 0;
  }
};

//get products based on category
export const getSortedProducts = (products, sortType, sortValue) => {
  if (products && sortType && sortValue) {
    if (sortType === "category") {
      return products.filter(
        product => product.category.filter(single => single === sortValue)[0]
      );
    }
    if (sortType === "tag") {
      return products.filter(
        product => product.tag.filter(single => single === sortValue)[0]
      );
    }
    if (sortType === "color") {
      return products.filter(
        product =>
          product.variation &&
          product.variation.filter(single => single.color === sortValue)[0]
      );
    }
    if (sortType === "size") {
      return products.filter(
        product =>
          product.variation &&
          product.variation.filter(
            single => single.size.filter(single => single.name === sortValue)[0]
          )[0]
      );
    }
    if (sortType === "filterSort") {
      let sortProducts = [...products];
      if (sortValue === "default") {
        return sortProducts;
      }
      if (sortValue === "priceHighToLow") {
        return sortProducts.sort((a, b) => {
          return b.price - a.price;
        });
      }
      if (sortValue === "priceLowToHigh") {
        return sortProducts.sort((a, b) => {
          return a.price - b.price;
        });
      }
    }
  }
  return products;
};

// get individual element
const getIndividualItemArray = array => {
  let individualItemArray = array.filter(function (v, i, self) {
    return i === self.indexOf(v);
  });
  return individualItemArray;
};

// get individual categories
export const getIndividualCategories = products => {
  let productCategories = [];
  products &&
    products.map(product => {
      return (
        product.category &&
        product.category.map(single => {
          return productCategories.push(single);
        })
      );
    });
  const individualProductCategories = getIndividualItemArray(productCategories);
  return individualProductCategories;
};

// get individual tags
export const getIndividualTags = products => {
  let productTags = [];
  products &&
    products.map(product => {
      return (
        product.tag &&
        product.tag.map(single => {
          return productTags.push(single);
        })
      );
    });
  const individualProductTags = getIndividualItemArray(productTags);
  return individualProductTags;
};

// get individual colors
export const getIndividualColors = products => {
  let productColors = [];
  products &&
    products.map(product => {
      return (
        product.variation &&
        product.variation.map(single => {
          return productColors.push(single.color);
        })
      );
    });
  const individualProductColors = getIndividualItemArray(productColors);
  return individualProductColors;
};

// get individual sizes
export const getProductsIndividualSizes = products => {
  let productSizes = [];
  products &&
    products.map(product => {
      return (
        product.variation &&
        product.variation.map(single => {
          return single.size.map(single => {
            return productSizes.push(single.name);
          });
        })
      );
    });
  const individualProductSizes = getIndividualItemArray(productSizes);
  return individualProductSizes;
};

// get product individual sizes
export const getIndividualSizes = product => {
  let productSizes = [];
  product.variation &&
    product.variation.map(singleVariation => {
      return (
        singleVariation.size &&
        singleVariation.size.map(singleSize => {
          return productSizes.push(singleSize.name);
        })
      );
    });
  const individualSizes = getIndividualItemArray(productSizes);
  return individualSizes;
};

export const setActiveSort = e => {
  const filterButtons = document.querySelectorAll(
    ".sidebar-widget-list-left button, .sidebar-widget-tag button, .product-filter button"
  );
  filterButtons.forEach(item => {
    item.classList.remove("active");
  });
  e.currentTarget.classList.add("active");
};

export const setActiveLayout = e => {
  const gridSwitchBtn = document.querySelectorAll(".shop-tab button");
  gridSwitchBtn.forEach(item => {
    item.classList.remove("active");
  });
  e.currentTarget.classList.add("active");
};

export const toggleShopTopFilter = e => {
  const shopTopFilterWrapper = document.querySelector(
    "#product-filter-wrapper"
  );
  shopTopFilterWrapper.classList.toggle("active");
  if (shopTopFilterWrapper.style.height) {
    shopTopFilterWrapper.style.height = null;
  } else {
    shopTopFilterWrapper.style.height =
      shopTopFilterWrapper.scrollHeight + "px";
  }
  e.currentTarget.classList.toggle("active");
};

export const getFirstPrice = (product, decimalsToShow = 2) => {
  let productPrice = 0;
  let partArray = []
  if (product.WebstoreProductPartArray && product.WebstoreProductPartArray[0] && product.WebstoreProductPartArray[0].partPrice) {
    partArray = product.WebstoreProductPartArray
  } else if (product && product.ProductPartArray) {
    if (product.ProductPartArray.ProductPart && product.ProductPartArray.ProductPart.length > 0) {
      partArray = product.ProductPartArray.ProductPart
    }
  }
  if (partArray && partArray.length > 0) {
    if (partArray[0].partPrice) {
      if (partArray[0].partPrice.PartPriceArray) {
        if (partArray[0].partPrice.PartPriceArray.PartPrice &&
          partArray[0].partPrice.PartPriceArray.PartPrice.length > 0) {
          if (partArray[0].partPrice.PartPriceArray.PartPrice[0]) {
            const price = partArray[0].partPrice.PartPriceArray.PartPrice[0].salePrice
            productPrice = isNaN(price) ? 0 : price
          }
        }
      }
    }
  }
  return Number(productPrice).toFixed(decimalsToShow)
}
export const getPriceBycolorSize = cartItem => {
  let product = cartItem.product
  let productPrice = 0;
  let partArray = []
  if (product?.WebstoreProductPartArray && product?.WebstoreProductPartArray?.[0] && product.WebstoreProductPartArray[0].partPrice) {
    partArray = product.WebstoreProductPartArray
  } else if (product && product.ProductPartArray) {
    if (product.ProductPartArray.ProductPart && product.ProductPartArray.ProductPart.length > 0) {
      partArray = product.ProductPartArray.ProductPart
    }
  }
  if (partArray && partArray.length > 0) {
    partArray.map(partPriceArray => {
      if (partPriceArray.ApparelSize && partPriceArray.ApparelSize.labelSize === product.selectedProductSize) {
        if (partPriceArray.ColorArray && partPriceArray.ColorArray.Color && partPriceArray.ColorArray.Color.colorName && partPriceArray.ColorArray.Color.colorName === product.selectedProductColor) {
          if (partPriceArray.partPrice && partPriceArray.partPrice.PartPriceArray && partPriceArray.partPrice.PartPriceArray.PartPrice) {

            let priceArray = partPriceArray.partPrice.PartPriceArray.PartPrice
            priceArray.map((price, pKey) => {
              if (Number(price.minQuantity) <= Number(product.quantity)) {
                productPrice = price.salePrice
              } else if (pKey === 0) {
                productPrice = (price.salePrice);
              }
            })
          }
        }
      }
    })
  }

  return Number(productPrice).toFixed(2)
}

export const getPriceByColorSizeQty = async (
  item,
  quantityCount,
  selectedProductColor,
  selectedProductSize,
  userGroupDiscount
) => {
  let temprowData = {
    quantity: Number(quantityCount.toString().replace(/,/g, '')),
    price: 0,
    totalPrice: 0,
    basePrice: 0,
    setupCharges: item.ChargeArray.Charge,
    size: selectedProductSize
    ? selectedProductSize
    : item.selectedProductSize
    ? item.selectedProductSize
    : getDefaultSize(item),
    color: selectedProductColor
    ? selectedProductColor
    : item.selectedProductColor
    ? item.selectedProductColor
    : getDefaultColor(item),
  }
  let groups = []
  let priceTable = []

  if (item?.WebstoreProductPartArray?.length) {
    item.WebstoreProductPartArray.forEach(part => {
      let priceOption = part
      const size = part.ApparelSize.labelSize
      if (!groups[size]) {
        groups[size] = []
      }
      groups[size].push(priceOption)
    })
  } else if (item?.ProductPartArray?.ProductPart?.length) {
    item.ProductPartArray.ProductPart.forEach(part => {
      let priceOption = part
      const size = part.ApparelSize.labelSize
      if (!groups[size]) {
        groups[size] = []
      }
      groups[size].push(priceOption)
    })
  }

  for (let size in groups) {
    priceTable.push({ size: size, priceOptions: groups[size] })
  }

  if(priceTable.length > 0 )
  {
    priceTable.map((priceTableRow) => {
      if (priceTableRow.size === temprowData.size) {
        let priceOptionsArray = priceTableRow.priceOptions
        priceOptionsArray.map((priceOption) => {
          if (priceOption.ColorArray.Color.colorName == temprowData.color) {
            if (priceOption.partPrice.PartPriceArray.PartPrice.length == 1) {
              temprowData.price = priceOption.partPrice.PartPriceArray.PartPrice[0].salePrice
            } else {
              priceOption.partPrice.PartPriceArray.PartPrice.map((p, pKey) => {
                if (Number(p.minQuantity) <= Number(temprowData.quantity)) {
                  temprowData.price = p.salePrice
                } else if (pKey == 0) {
                  temprowData.price = p.salePrice
                }
              })
            }
            
            let showSell = false
            let offerPrice = 0
            let discountType = ''

            let todayDate = new Date().toISOString()

            if (todayDate >= item?.startDate && todayDate <= item?.endDate) {
              if (item?.makeProductSale) {
                showSell = true
              }
            } else if (item?.startDate == '' || item?.startDate == undefined) {
              if (item?.makeProductSale) {
                showSell = true
              }
            }

            if (item?.sellingPrice) {
              offerPrice = item.sellingPrice
            }
            if (item?.discountType == '0' || item?.discountType == '1') {
              discountType = item?.discountType
            }

            if (Number(userGroupDiscount > 0) && temprowData.price > 0) {
              temprowData.price =
                Number(temprowData.price) -
                (Number(temprowData.price) * Number(userGroupDiscount)) / 100
              temprowData.price = Number(temprowData.price).toFixed(2)
              if (temprowData.price < 0) {
                temprowData.price = 0
              }
            }
            temprowData.basePrice = temprowData.price

            if (discountType == '1' && showSell && temprowData.price > 0) {
              if (offerPrice > 0) {
                temprowData.price = Number(temprowData.price) - Number(offerPrice)
                if (temprowData.price < 0) {
                  temprowData.price = 0
                }
              }
            } else if (discountType == '0' && showSell && temprowData.price > 0) {
              temprowData.price =
                Number(temprowData.price) -
                (Number(temprowData.price) * Number(offerPrice)) / 100

              if (temprowData.price < 0) {
                temprowData.price = 0
              }
            } else {
            }
            temprowData.totalPrice = temprowData.price * temprowData.quantity
          }
        })
      }
    })
  }
  return temprowData
}

export const showCurrencycodeSymbol = currency => {
  if (currency.currencyName && currency.currencyName !== "") {
    return currency.currencySymbol
  } else {
    return "$ "
  }
}
export const numberWithCommas = (x) => {
  var parts = Number(x).toFixed(2).toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}
export const numberWithCommas2 = (x, fixedNumber = 2) => {
  const numValue = Number(x);
  const decimals = Number(fixedNumber);

  if (Number.isNaN(numValue)) {
    return Number(0).toFixed(decimals).toString();
  }

  if (numValue === 0) {
    return Number(numValue).toFixed(decimals).toString();
  }

  if (numValue % 1 !== 0) {
    const numValue1 = (new Decimal(numValue).toDP(decimals)).toFixed(decimals);
    return numValue1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    const parts = new Decimal(numValue).toDP(decimals).toFixed(decimals).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

}

export const roundedPrice = (num, decimalsToShow = 2) => {
  const numValue = Number(num);
  const decimals = Number(decimalsToShow);

  if (Number.isNaN(numValue) || numValue === 0) {
    return Number(0).toFixed(decimals);
  }

  return formatPriceFixedDecimalPlaces(numValue, decimalsToShow);
}

export const WithCommas = (x) => {
	var parts = Number(x).toString();
	parts = parts.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return parts;
}
export const getClassBycartItem = (cartItemData) => {
  if (!cartItemData) {
    return ""
  }
  let cartItem = cartItemData.product
  let order = cartItemData.currentOrder
  return (cartItem !== undefined && order.length > 0)
    ? "active"
    : ""
}
export const getStock = (sizeDefault, sizcolorDefault, productInventory, product, productDstInventory, isStock, partIds = [{}]) => {
  let inventoryArray = null

  if (productInventory) {
      inventoryArray = productInventory?.PartInventoryArray?.PartInventory
  }
  let stock = 0
  if ((product?.poType === 'DropShip' || product?.poType === "Stock") && (isStock === "supplierStock" || isStock === "both")) {
    inventoryArray && inventoryArray.length > 0 && inventoryArray.map((stockArray) => {
      let check = false;
      let localPartId;

      for(const pp of partIds){
        if(sizeDefault?.toLowerCase()?.trim() === pp?.size?.toLowerCase()?.trim() &&
        sizcolorDefault?.toLowerCase()?.trim() === pp?.color?.toLowerCase()?.trim()){
          localPartId = pp.partId;
        }
      }
      if(localPartId !== undefined && localPartId !== null && localPartId.length){
        if(stockArray.partId === localPartId) check = true
      }

      if(check === false){
        if(sizcolorDefault?.trim()?.toLowerCase() === stockArray.partColor?.trim()?.toLowerCase() &&
          stockArray.labelSize?.trim()?.toLowerCase() === sizeDefault?.trim()?.toLowerCase())
          check = true;
      }

      if (check === true) {
          if (stockArray?.InventoryLocationArray?.InventoryLocation?.length) {
          stockArray.InventoryLocationArray.InventoryLocation.map(inventoryArray => {
            if (inventoryArray?.inventoryLocationQuantity?.Quantity?.value) {
              stock = Number(stock) + Number(inventoryArray.inventoryLocationQuantity.Quantity.value)
            }
          })
        }
      }
    })
  }
  let dstStock = 0;
  //distributorStock or both
  //distributor inventory
  if (product?.poType === "Stock" && (isStock === "distributorStock" || isStock === "both")) {
    productDstInventory && productDstInventory.map(dstStockArray => {
      if (dstStockArray.productId === product.productId) {
        let check = false;
        let localPartId;

        for (const pp of partIds) {
          if (sizeDefault?.toLowerCase()?.trim() === pp?.size?.toLowerCase()?.trim() &&
            sizcolorDefault?.toLowerCase()?.trim() === pp?.color?.toLowerCase()?.trim()) {
            localPartId = pp.partId;
          }
        }
        if (localPartId !== undefined && localPartId !== null && localPartId.length) {
          if (dstStockArray.partId === localPartId) check = true
        }

        if (check === false) {
          if (sizcolorDefault?.trim()?.toLowerCase() === dstStockArray.color?.trim()?.toLowerCase() &&
          dstStockArray.size?.trim()?.toLowerCase() === sizeDefault?.trim()?.toLowerCase())
            check = true;
        }

        if(check === true){
          dstStock = Number(dstStockArray.quantity)
        }
      }
    })
  }
  return Number(stock) + Number(dstStock)
}
export const getPersColor = (product, productSettings) => {
  let color = ""
  if (product?.productPersColor && product.productPersColor !== "" && product.productPersColor !== " ") {
    color = product.productPersColor
  } else if (productSettings?.defaultPerscolor && productSettings?.defaultPerscolor !== "" && productSettings?.defaultPerscolor !== " ") {
    color = productSettings?.defaultPerscolor
  }
  return color;
}
export const getPersFont = (product, productSettings) => {
  let font = ""
  if (product?.productPersFont && product.productPersFont !== "" && product.productPersFont !== " ") {
    font = product.productPersFont
  } else if (productSettings?.defaultPersfont && productSettings?.defaultPersfont !== "" && productSettings?.defaultPersfont !== " ") {
    font = productSettings?.defaultPersfont
  }
  return font;
}
export const getPersLocation = (product, productSettings) => {
  let location = ""
  if (product?.productPersLocation && product.productPersLocation !== "" && product.productPersLocation !== " ") {
    location = product.productPersLocation
  } else if (productSettings?.defaultPerslocation && productSettings?.defaultPerslocation !== "" && productSettings?.defaultPerslocation !== " ") {
    location = productSettings?.defaultPerslocation
  }
  return location
}
export const getPersNote = (product, productSettings) => {
  let note = ""
  if (product?.productPersNote && product.productPersNote !== "" && product.productPersNote !== " ") {
    note = product.productPersNote
  } else if (productSettings?.defaultPersNote && productSettings?.defaultPersNote !== "" && productSettings?.defaultPersNote !== " ") {
    note = productSettings?.defaultPersNote
  }
  return note;
}
export const getminQuantity = (partPrice) => {
	if (partPrice && partPrice.length > 0) {
		let minObj = partPrice.reduce(function (prev, curr) {
			return Number(prev.minQuantity) < Number(curr.minQuantity) ? prev : curr;
		});
		return minObj.minQuantity
	}
	return null
}
export const getAllQty = (partPrice) => {
	let allQty = [];
	if (partPrice && partPrice.length > 0) {
		allQty = partPrice.map((item) => {
			return Number(item.minQuantity)
		})
	}
	return allQty
}

export const getGlobalShowProductPrice = (product, globalShowPriceForUsers = false, globalShowPriceForGuests = false, user =null) => {
  let showPrice = false;
  if (user) {
    showPrice = getShowProductPrice(product, false) ?? globalShowPriceForUsers ;
  } else {
    showPrice = getShowProductPrice(product, true) ??  globalShowPriceForGuests;
  }
  return showPrice;
}

export const getShowProductPrice = (product, isGuestUser = false) => {
  let showValue;
  if (product) {
    if(isGuestUser && product?.showProductPriceForGuests!==undefined && product.showProductPriceForGuests !== null) {
      showValue = product?.showProductPriceForGuests;
    } else if (!isGuestUser && product?.showProductPrice!==undefined && product.showProductPrice !== null){
      showValue = product?.showProductPrice;
    }else{
      showValue = null;
    }
    return showValue;
  } else {
    return null
  }
}

export const getSectionSetting = (globalSetting, productSetting, sectionSetting) => {
  let sectionEnabled = true;
  if (typeof sectionSetting === 'boolean') {
    sectionEnabled = sectionSetting;
  }
  if (typeof productSetting === 'boolean') {
    return productSetting && sectionEnabled;
  } 
  return globalSetting && sectionEnabled;
}

export const exactValue = (value, decimalsToShow) => {
  const decimals = Math.pow(10, decimalsToShow);
  return Math.round(value * decimals) / decimals;
}