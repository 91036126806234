import { OtpRequest } from '../../models/Otp'
import { AutocompleteRequest, RadarAddress } from '../../models/Radar'
import { aetherApi } from './aetherApi'

const radarApi = aetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getAutocomplete: builder.query<RadarAddress[], AutocompleteRequest>({
      query: (params) => ({
        url: `api/v2/radar/autocomplete`,
        params,
        method: 'GET',
      })
    }),
  }),
})

export const { useGetAutocompleteQuery } = radarApi
