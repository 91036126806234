import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GetOrCreateSessionParams } from '../api/checkoutApi'
import { set } from 'lodash'
import { AetherPaymentRequest } from '../../models/Payment'
import { PaymentMethodType } from '../../models/PaymentMethodType'

export interface CheckoutState {
  sessionParams: GetOrCreateSessionParams | null
  editingStep: string | null
  debug: boolean
  headerOffset: number
  paymentRequests: AetherPaymentRequest[]
  selectedPaymentMethodType: PaymentMethodType | null
}

const initialState: CheckoutState = {
  sessionParams: null,
  editingStep: null,
  debug: false,
  headerOffset: 0,
  paymentRequests: [],
  selectedPaymentMethodType: null,
}

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    updateSessionParams: (state, action) => {
      state.sessionParams = action.payload
    },
    editStep: (state, action) => {
      state.editingStep = action.payload
    },
    completeEditStep: (state) => {
      state.editingStep = null
    },
    setDebug: (state, action: PayloadAction<boolean>) => {
      state.debug = action.payload
    },
    setHeaderOffset: (state, action: PayloadAction<number>) => {
      state.headerOffset = action.payload
    },
    addPaymentRequest: (state, action: PayloadAction<AetherPaymentRequest>) => {
      state.paymentRequests.push(action.payload)
    },
    updatePaymentRequest: (
      state,
      action: PayloadAction<{ index: number; request: AetherPaymentRequest }>,
    ) => {
      set(state.paymentRequests, action.payload.index, action.payload.request)
    },
    editPaymentRequest: (
      state,
      action: PayloadAction<{ index: number; }>,
    ) => {
      state.paymentRequests = state.paymentRequests.slice(0, action.payload.index + 1)
    },
    updatePaymentRequests: (
      state,
      action: PayloadAction<AetherPaymentRequest[]>,
    ) => {
      state.paymentRequests = action.payload
    },
    clearPaymentRequests: (state) => {
      state.paymentRequests = []
      state.selectedPaymentMethodType = null;
    },
    setSelectedPaymentMethodType: (
      state,
      action: PayloadAction<PaymentMethodType | null>,
    ) => {
      state.selectedPaymentMethodType = action.payload
    },
  },
})

export const {
  updateSessionParams,
  editStep,
  completeEditStep,
  setDebug,
  setHeaderOffset,
  addPaymentRequest,
  updatePaymentRequest,
  editPaymentRequest,
  updatePaymentRequests,
  clearPaymentRequests,
  setSelectedPaymentMethodType,
} = checkoutSlice.actions

export default checkoutSlice.reducer
