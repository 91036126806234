import React, { useContext } from 'react';
import { CheckboxElement, useFormContext, useWatch } from 'react-hook-form-mui';
import { useEffect, useMemo } from 'react';
import { Grid2, Stack, Typography } from '@mui/material';
import CheckoutTextField from './CheckoutTextField';
import { useGetCountriesQuery, useGetStatesQuery } from '../../redux/api/advanceApi';
import CheckoutDropdown from './CheckoutDropdown';
import GridSkeleton from '../../common/GridSkeleton';
import _ from 'lodash';
import AddressAutocompleteField from '../../components/AddressAutocompleteField';
import { RadarAddress } from '../../models/Radar';
import { Address } from '../../models/Address';
import { RegionDefinition } from '../../models/CheckoutSession';
import useFormValue from '../../common/useFormValue';
import { AuthContext } from '../../common/Auth';

interface AddressFormProps {
  label: string;
  formPrefix?: string;
  requirePhone?: boolean;
  requireCompany?: boolean;
  companyRename?: string;
  restrictAddress?: boolean;
  restrictPhone?: boolean;
  restrictCompany?: boolean;
  restrictName?: boolean;
  restrictEmail?: boolean;
  triggerContact?: boolean;
  restrictCountries?: string[];
  regionDefinitions?: RegionDefinition[];
}

export default function AddressForm({
  label,
  formPrefix,
  requirePhone = false,
  requireCompany = false,
  companyRename,
  restrictAddress = false,
  restrictPhone = false,
  restrictCompany = false,
  restrictName = false,
  restrictEmail = false,
  triggerContact,
  restrictCountries,
  regionDefinitions,
}: AddressFormProps) {
  const { user } = useContext(AuthContext);

  const effectivePrefix = useMemo(() => {
    if (formPrefix !== undefined) {
      return `${formPrefix}.`;
    } else {
      return '';
    }
  }, [formPrefix]);

  const {
    data: countries,
    isLoading: loadingCountries,
    isSuccess: countriesSuccess,
  } = useGetCountriesQuery();

  const formContext = useFormContext();

  const { setValue, trigger, getValues } = formContext;

  const countryValue: string = useWatch({
    name: `${effectivePrefix}address.country`,
  });

  const { data: states, isFetching: loadingStates } = useGetStatesQuery(countryValue, {
    skip: !countryValue,
  });

  const saveAddress = useFormValue({ name: 'saveAddress', formContext });

  useEffect(() => {
    if (countriesSuccess && !getValues(`${effectivePrefix}address.country`) && !restrictCountries) {
      setValue(`${effectivePrefix}address.country`, 'US');
    }
  }, [countriesSuccess, effectivePrefix, getValues, setValue, restrictCountries]);

  useEffect(() => {
    if (triggerContact) {
      trigger([`${effectivePrefix}contact`]);
    }
  }, [effectivePrefix, trigger, triggerContact]);

  const handleAutocomplete = (ra: RadarAddress) => {
    const addr: Address = {
      street1: ra.addressLabel,
      street2: '',
      city: ra.city,
      postalCode: ra.postalCode,
      country: ra.countryCode,
      state: ra.stateCode,
      residential: true,
    };
    setValue(`${effectivePrefix}address`, addr, { shouldDirty: true });
  };

  const handleAutocompleteClear = () => {
    setValue(
      `${effectivePrefix}address`,
      {
        street1: '',
        street2: '',
        city: '',
        postalCode: '',
        country: '',
        state: '',
        residential: true,
      },
      { shouldDirty: true },
    );
  };

  const filteredCountries = useMemo(() => {
    if (restrictCountries === undefined) {
      return countries;
    }
    return countries?.filter((c) => restrictCountries.includes(c.code));
  }, [restrictCountries, countries]);

  const filteredStates = useMemo(() => {
    const rd = regionDefinitions?.find((r) => r.countryCode === countryValue);
    if (rd?.regions === undefined) {
      return states;
    }
    return states?.filter((s) => rd?.regions?.includes(s.code));
  }, [regionDefinitions, states, countryValue]);

  useEffect(() => {
    if (filteredCountries?.length === 1) {
      setValue(`${effectivePrefix}address.country`, filteredCountries[0].code);
    }
  }, [effectivePrefix, filteredCountries, setValue]);

  useEffect(() => {
    if (filteredStates?.length === 1) {
      setValue(`${effectivePrefix}address.state`, filteredStates[0].code);
    }
  }, [effectivePrefix, filteredStates, setValue]);

  return (
    <Stack direction={'column'} spacing={2}>
      <Typography variant="h6">{label} Address</Typography>
      {loadingCountries && <GridSkeleton size={6} quantity={12} />}
      {!loadingCountries && (
        <>
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}contact.firstName`}
                label="First Name"
                rules={{ required: true }}
                readOnly={restrictName}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}contact.lastName`}
                label="Last Name"
                rules={{ required: true }}
                readOnly={restrictName}
              />
            </Grid2>
            <Grid2 size={12}>
              <CheckoutTextField
                name={`${effectivePrefix}contact.email`}
                label="Email"
                rules={{ required: true }}
                readOnly={restrictEmail}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}contact.phone`}
                label="Phone"
                rules={{ required: requirePhone }}
                readOnly={restrictPhone}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}contact.company`}
                label={companyRename ?? 'Company'}
                rules={{ required: requireCompany }}
                readOnly={restrictCompany}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <AddressAutocompleteField
                name={`${effectivePrefix}address.street1`}
                restrictedCountries={restrictCountries}
                regionDefinitions={regionDefinitions}
                label="Address Line 1"
                onSelect={handleAutocomplete}
                onClear={handleAutocompleteClear}
                readOnly={restrictAddress}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}address.street2`}
                label="Address Line 2"
                readOnly={restrictAddress}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}address.city`}
                label="City"
                rules={{ required: true }}
                readOnly={restrictAddress}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}address.postalCode`}
                label="Postal Code"
                rules={{ required: true }}
                readOnly={restrictAddress}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <CheckoutDropdown
                name={`${effectivePrefix}address.country`}
                label="Country"
                options={filteredCountries?.map((c) => ({ id: c.code, label: c.name })) ?? []}
                rules={{ required: true }}
                readOnly={restrictAddress}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <CheckoutDropdown
                name={`${effectivePrefix}address.state`}
                label="State"
                options={filteredStates?.map((s) => ({ id: s.code, label: s.name })) ?? []}
                rules={{ required: true }}
                loading={loadingStates}
                readOnly={restrictAddress}
              />
            </Grid2>
            {user && (
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <CheckboxElement name="saveAddress" label="Save address" />
              </Grid2>
            )}
            {saveAddress && (
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <CheckoutTextField
                  name={'saveAddressName'}
                  label="Location Name"
                  rules={{ required: true }}
                />
              </Grid2>
            )}
          </Grid2>
        </>
      )}
    </Stack>
  );
}
