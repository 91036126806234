import React, { useContext, useState } from 'react'
import useForm from '../../pages/banner/customHooks'
import Validate from './Validate'
import ReactStars from 'react-rating-stars-component'
import { AuthContext } from '../../common/Auth'
import { REVIEW, X_TENANT_ID } from '../../common/ActionUrl'
import { _useToasts } from '../../common/_useToasts'
import axios from 'axios'
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import NotLoggedInModal from './NotLoggedInModal'

function AddProductReviewModel(props) {
  const { productId, itemId, afterAdd, cancel, show, setOpenReview } = props
  const { user } = useContext(AuthContext)

  let createdBy = user?.userId
  let updatedBy = user?.userId
  let userId = user?.userId

  const [openModel, setOpenModel] = useState(false)
  const { _addToast } = _useToasts()
  const {
    inputs,
    handleCustomeInput,
    handleInputChange,
    handleSubmit,
    resetForm,
    errors,
  } = useForm(
    {
      productStart: 0,
      userId,
      replyToId: '',
      productId,
      itemId,
      review: '',
      status: 'pending',
      updatedAt: '',
      createdAt: new Date(),
      createdBy,
      updatedBy,
    },
    Validate,
    reviewCallback,
  )

  async function reviewCallback() {
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        'X-TENANT-ID': X_TENANT_ID,
      },
    }
    if (!userId) {
      setOpenModel(true)
    } else {
      axios
        .post(REVIEW, inputs, requestOptions)
        .then((res) => {
          if (res.data.error) {
            _addToast(res.data.message, {
              appearance: 'error',
              autoDismiss: true,
            })
          } else {
            _addToast(
              'Thank you for your review. It will be published shortly.',
              {
                appearance: 'success',
                autoDismiss: true,
              },
            )
            setOpenReview(false)
            afterAdd()
            resetForm()
          }
        })
        .catch((err) => {
          _addToast(err.response.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
          console.log('err', err)
        })
    }
  }
  const productStarView = {
    size: 14,
    count: 5,
    isHalf: false,
    value: inputs.productStart,
    color: 'black',
    activeColor: 'orange',
    emptyIcon: <i className="fa fa-star-o" />,
    filledIcon: <i className="fa fa-star" />,
    onChange: (newValue) => {
      handleCustomeInput('productStart', newValue)
    },
  }
  const handleCancel = () => {
    setOpenModel(false)
  }

  return (
    <>
      <style>{`
                .MuiDialog-paperWidthXs {
                max-width : 525px !important;
                }
            `}</style>
      <Dialog
        fullWidth={true}
        open={show}
        onClose={cancel}
        className="dialog"
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
      >
        <DialogTitle>
          <>Add a Review</>
        </DialogTitle>
        <div className="notlogin-form">
          <div
            className="notlogin-form-style only-banner"
            style={{ maxWidth: '' }}
          >
            <DialogContent>
              <form>
                <p>
                  <div className="star-box">
                    <span>Your rating:</span>
                    <div className="ratting-star">
                      <ReactStars {...productStarView} />
                    </div>
                  </div>
                </p>
                <p className="error">
                  {errors && errors.productStart ? errors.productStart : ''}
                </p>
                <p>
                  <textarea
                    name="review"
                    placeholder="Your Review"
                    maxLength="250"
                    onChange={handleInputChange}
                    value={inputs.review}
                  />
                </p>
                <p className="error">
                  {errors && errors.review ? errors.review : ''}
                </p>
                <div className="buttons-container">
                  <Button onClick={cancel} className="cancelButton">
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      handleSubmit()
                    }}
                    className="bannerbutton ml-3"
                  >
                    Submit
                  </Button>
                </div>

                {openModel && (
                  <NotLoggedInModal
                    openM={openModel}
                    cancel={() => handleCancel()}
                  />
                )}
              </form>
            </DialogContent>
          </div>
        </div>
      </Dialog>
    </>
  )
}
export default AddProductReviewModel
