import React from 'react';
import { Input, List, Stack, Typography } from '@mui/material';
import { useUploadDocumentsMutation } from '../../../redux/api/checkoutApi';
import { useAppSelector } from '../../../redux/hooks';
import { selectParams, selectSessionId } from '../../../redux/selectors/checkoutSelectors';
import { LoadingButton } from '@mui/lab';
import { useFieldArray, useFormContext } from 'react-hook-form-mui';
import { CustomerDetailsForm } from '../../../models/CustomerDetailsForm';
import { _useToasts } from '../../../common/_useToasts';
import DocumentRow from './DocumentRow';
import { Upload } from '@mui/icons-material';
import { DocumentUploadRelabel } from '../../../models/CheckoutSession';

const MAX_FILES = 3;

export default function DocumentUploadSection({ documentUploadRelabel }: DocumentUploadRelabel) {
  const params = useAppSelector(selectParams);
  const sessionId = useAppSelector((state) => selectSessionId(state, params));
  const [uploadDocuments, { isLoading }] = useUploadDocumentsMutation();

  const { control } = useFormContext<CustomerDetailsForm>();

  const { _addToast } = _useToasts();

  const { append, fields, remove, update } = useFieldArray({
    control,
    name: 'files',
  });

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files ?? []);
    if (files && sessionId) {
      try {
        const result = await uploadDocuments({ sessionId, files }).unwrap();
        for (const file of result) {
          const index = fields.findIndex((f) => f.key === file.key);
          if (index >= 0) {
            update(index, file);
          } else {
            append(file);
          }
        }
      } catch (error) {
        _addToast('Error while uploading documents', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  };

  const handleDelete = (index: number) => () => {
    remove(index);
  };

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" alignItems={'end'}>
        <Typography variant="h6" pt={2}>
          {
            documentUploadRelabel !== undefined
            && documentUploadRelabel !== null
            && documentUploadRelabel.length > 0 &&
            documentUploadRelabel
          }
        </Typography>
        {fields.length < MAX_FILES && (
          <LoadingButton
            component="label"
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            color="primary"
            loading={isLoading}
            endIcon={<Upload />}
          >
            Upload
            <Input
              type="file"
              hidden
              onChange={handleFileUpload}
              value={''}
              slotProps={{ input: { multiple: true } }}
            />
          </LoadingButton>
        )}
      </Stack>
      <List dense>
        {fields.map((file, idx) => (
          <DocumentRow file={file} onDelete={handleDelete(idx)} />
        ))}
      </List>
      {fields.length === 0 && (
        <Typography variant="body2">
          No documents uploaded
        </Typography>
      )}
    </Stack>
  );
}
