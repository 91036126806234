import PropTypes from "prop-types";
import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
} from "react";
import MetaTags from "react-meta-tags";
import Paginator from "react-hooks-paginator";
import ShopSidebar from "../../wrappers/product/ShopSidebar";
import ShopTopbar from "../../wrappers/product/ShopTopbar";
import ShopProducts from "../../wrappers/product/ShopProducts";
import Loader from "../../common/Loader";
import ShopGridStandardStyle from "./views/ShopGridStandard";
import ShopGridTwoColumn from "./views/ShopGridTwoColumn";
import ShopGridFullWidth from "./views/ShopGridFullWidth";
import ShopGridFilter from "./views/ShopGridFilter";
import ShopGridNoSidebar from "./views/ShopGridNoSidebar";
import ShopGridRightSidebar from "./views/ShopGridRightSidebar";
import ShopListStandardStyle from "./views/ShopListStandard";
import ShopListTwoColumn from "./views/ShopListTwoColumns";
import { ShopViewsList, ShopViewsObject } from "../../util/shop.views";
import MainBreadCrumb from '../../wrappers/breadcrumb/MainBreadCrumb';
import { defaultTo, get } from "lodash";
import { UseShopMainProductsState } from "./state/useShopMainProductsState";
import { useLocation } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import ShopListFullWidth from "./views/ShopListFullWidth";
import { useSelector } from "react-redux";
import { AuthContext } from "../../common/Auth";
import { useThemeSettingsData } from "../../layouts/state/useThemeSettingsData";

const ShopMainProductsView = (props) => {
  const [showSideBar, setShowSidebar] = useState(false)

  const {
    breadCrumbProps,
    currentPage,
    products,
    pageLimit,
    total_count,
    layout,
    loading,
    page,
    selectedColors,
    deleteSelectedColor,
    onSelectColor,
    showAllProduct,
    setOffset,
    setCurrentPage,
    getLayout,
    getFilterSortParams,
    pageLimit1,
    sideBarSettings,
    tabName,
    sortSizeSetting,
    getSortParams,
    onHandleInput,
    searchQuery,
    onSelectCategory,
    structuredData,
    newColor,
    viewSizesOptions,
    selectedCategories,
    onSelectTag,
    tags,
    selectedTags,
    selectedSizes,
    onSelectSize,
    deleteSelectedCat,
    deleteSelectedTag,
    deleteSelectedSize,
    generalDescription,
    themeLayout,
    rawStructure,
    sortingOrderFilter,
    filterSortType,
  } = UseShopMainProductsState({ ...props, showSideBar});

  const { selectedSorting, setSelectedSorting } = props

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  const [colorsParams, setColorParams] = useState('')
  const [tagsParams, setTagParams] = useState('')
  const [categoriesParams, setCategoriesParams] = useState('')
  const [sizeParams, setSizeParams] = useState('')
  const [searchParams, setSearchParams] = useState('')
  const [catLayout, setCatLayout] = useState(layout)

  const [themeLayoutChange, setthemeLayoutChange] = useState(themeLayout)
  const { themeSettingsData } = useContext(AuthContext);
  const { productSorting } = useThemeSettingsData({
    themeSettingsData
})
  const {
    SHOP_GRID_STANDARD,
    SHOP_GRID_TWO_COLUMN,
    SHOP_GRID_FULL_WIDTH,
    SHOP_GRID_FILTER,
    SHOP_GRID_NO_SIDEBAR,
    SHOP_LIST_STANDARD,
    SHOP_GRID_RIGHT_SIDEBAR,
    SHOP_LIST_FULL_WIDTH,
    SHOP_LIST_TWO_COLUMN,
  } = ShopViewsObject;



  const [pageCount, setPageCount] = useState(9);
  const loadingCategories = useSelector((state) => state.shopData.fetchingCategories);

  useEffect(() => {
    setPageCount(Math.ceil(total_count / pageLimit))
  }, [total_count, pageLimit])

  useEffect(() => {
    let getCat = selectedCategories && selectedCategories[0]?.category?.categoryLayout
    if(getCat){
      setthemeLayoutChange(getCat)
      if (!!layout) {
        setCatLayout(layout)
      } else {
      if (getCat === "shop-grid-two-column") {
        setCatLayout("grid two-column")
      }
      if (getCat === "shop-list-standard" || getCat === "shop-list-full-width") {
        setCatLayout("list")
      }
      if (getCat === "shop-list-two-column") {
        setCatLayout("list two-column")
      }
      if (getCat === "shop-grid-filter" || getCat === "shop-grid-standard" || getCat === "shop-grid-no-sidebar" || getCat === "shop-grid-full-width" || getCat === 'shop-grid-right-sidebar') {
        setCatLayout("grid three-column")
      }
    }
    } else {
      setCatLayout(layout)
      setthemeLayoutChange(themeLayout)
    }
  }, [selectedCategories, layout, themeLayout])

  useEffect(() => {
    if(themeLayoutChange) {
      if(![SHOP_GRID_TWO_COLUMN, SHOP_LIST_TWO_COLUMN, SHOP_GRID_FILTER, SHOP_GRID_NO_SIDEBAR].includes(themeLayoutChange)) {
        setShowSidebar(true)
      }
    }
  }, [themeLayoutChange])

  useEffect(() => {
    if(productSorting) {
      if(selectedCategories.length === 1) {
        let id = selectedCategories[0].category._id;
        let settingsCategory = productSorting?.[id]
        if(settingsCategory?.mainSort) {
          setSelectedSorting({
            value: 'productCategorySorting.productSorting_asc',
            label: 'Default Sorting Order',
          });
        } else if (settingsCategory?.secondarySort) {
          setSelectedSorting(settingsCategory.selectedSorting);
        } else {
          setSelectedSorting(productSorting.selectedSorting);
        }
      } else {
        setSelectedSorting(productSorting.selectedSorting);
      }
    }
  }, [selectedCategories, productSorting])

  useEffect(() => {
    setColorParams(query.get('colors') || '')
    setTagParams(query.get('tags') || '')
    setCategoriesParams(query.get('cat') || '')
    setSizeParams(query.get('sizes') || '')
    if(query.get('q') === '') {
      setSearchParams(query.get('q') || '')
    }
  }, [query])

  const resetSearchParams = () => {
    onHandleInput("")
    setSearchParams("")
  }

  const handleChange = (e, value) => {
    setCurrentPage(value)
  }
  const determineViewStyle = (themeLayout) => {
    const PaginatorComponent = () =>{
      return loading ? <></> :
        <Pagination count={ pageCount } page={ currentPage } onChange={ handleChange } hidePrevButton hideNextButton/>
    };
    const ShopTopbarComponent = () => (
        <ShopTopbar
            getLayout={getLayout}
            getFilterSortParams={getFilterSortParams}
            productCount={Number(total_count)||0}
            sortedProductCount={defaultTo(get(products, "length", 0),0)}
            pageLimit1={pageLimit1}
            pageLimit={pageLimit}
            selectedSorting={selectedSorting}
            setSelectedSorting={setSelectedSorting}
            sortingOrderFilter={sortingOrderFilter}
            filterSortType={filterSortType}
        />
    );
    const ShopProductsComponent = () => (
      <ShopProducts layout={catLayout} products={products} loading={loading} />
    );
    const ShopSidebarComponent = () => (
        <ShopSidebar
            products={products}
            sideBarSettings = {sideBarSettings}
            sortSizeSetting={sortSizeSetting}
            getSortParams={getSortParams}
            sideSpaceClass="mr-30"
            onHandleInput={onHandleInput}
            resetSearchParams={resetSearchParams}
            searchQuery={searchQuery}
            onSelectCategory={onSelectCategory}
            categories={structuredData}
            colors={newColor}
            sizes={viewSizesOptions}
            selectedCategories={selectedCategories}
            onSelectTag={onSelectTag}
            tags={tags}
            selectedTags={selectedTags}
            onSelectColor={onSelectColor}
            selectedSizes={selectedSizes}
            selectedColors={selectedColors}
            onSelectSize={onSelectSize}
        />
    );

    const props = {
      showAllProduct,
      selectedCategories,
      selectedTags,
      selectedColors,
      selectedSizes,
      deleteSelectedCat,
      deleteSelectedColor,
      deleteSelectedTag,
      deleteSelectedSize,
      ShopTopbarComponent,
      ShopSidebarComponent,
      PaginatorComponent,
      ShopProductsComponent,
      generalDescription,
      rawStructure,
      colors: newColor,
      tags,
      sizes: viewSizesOptions,
      colorsParams,
      tagsParams,
      sizeParams,
      setSearchParams,
      categoriesParams,
      searchParams,
      resetSearchParams,
      showSideBar
    };

     switch (themeLayoutChange) {
      case SHOP_GRID_TWO_COLUMN:
        return <ShopGridTwoColumn {...props} />;
      case SHOP_GRID_STANDARD:
        return <ShopGridStandardStyle {...props} />;
      case SHOP_GRID_FULL_WIDTH:
        return <ShopGridFullWidth {...props} />;
      case SHOP_GRID_FILTER:
        return <ShopGridFilter {...props} />;
      case SHOP_GRID_NO_SIDEBAR:
        return (
            <ShopGridNoSidebar
                ShopTopbarComponent={ShopTopbarComponent}
                PaginatorComponent={PaginatorComponent}
                ShopProductsComponent={ShopProductsComponent}
                {...props}
            />
        );
      case SHOP_LIST_STANDARD:
        return <ShopListStandardStyle {...props} />;
      case SHOP_GRID_RIGHT_SIDEBAR:
        return <ShopGridRightSidebar {...props} />;
      case SHOP_LIST_FULL_WIDTH:
        return <ShopListFullWidth {...props} />;
      case SHOP_LIST_TWO_COLUMN:
        return <ShopListTwoColumn {...props} />;
      default:
        throw new Error("Something went wrong!");
    }

  };
  return (
      <Fragment>
        <MetaTags>
          <title>{tabName}</title>
          <meta
              name="description"
              content="Shop page"
          />
        </MetaTags>

        {/* breadcrumb */}
        <MainBreadCrumb/>

        {/* <LayoutOne headerTop="visible"> */}
        {(loading || loadingCategories) && <Loader />}

        {determineViewStyle(themeLayoutChange)}
      </Fragment>
  );
};

ShopMainProductsView.propTypes = {
  location: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object,
  products: PropTypes.array,
  layout: PropTypes.string,
};

export default ShopMainProductsView
