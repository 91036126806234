import React from 'react';
import { Alert, Box, Stack, Typography } from '@mui/material';
import { useApplyCouponMutation, useRemoveCouponMutation } from '../../redux/api/checkoutApi';
import CheckoutTextField from './CheckoutTextField';
import { FormContainer, useForm, useFormState } from 'react-hook-form-mui';
import { LoadingButton } from '@mui/lab';
import { useAppSelector } from '../../redux/hooks';
import {
  selectCoupon,
  selectParams,
  selectSessionId,
  selectSettings,
} from '../../redux/selectors/checkoutSelectors';
import { getError } from '../../helpers/checkout';
import { Discount } from '@mui/icons-material';

interface ApplyCouponForm {
  couponCode: string;
}

export default function CouponRow() {
  const settings = useAppSelector(selectSettings);
  const params = useAppSelector(selectParams);
  const coupon = useAppSelector((state) => selectCoupon(state, params));
  const sessionId = useAppSelector((state) => selectSessionId(state, params));
  const [applyCoupon, { isLoading: applyLoading, error: applyError }] = useApplyCouponMutation();
  const [removeCoupon, { isLoading: removeLoading, error: removeError }] =
    useRemoveCouponMutation();

  const formContext = useForm<ApplyCouponForm>();
  const { handleSubmit } = formContext;
  const { isValid } = useFormState(formContext);

  const handleApplyCoupon = async (data: ApplyCouponForm) => {
    if (!params || !sessionId) return;
    try {
      await applyCoupon({
        sessionId,
        userId: params.userId,
        couponCode: data.couponCode,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleRemoveCoupon = async () => {
    if (!params || !sessionId) return;
    try {
      await removeCoupon({ sessionId, userId: params.userId });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <FormContainer formContext={formContext}>
      <Stack direction={'column'} spacing={2} p={2}>
        {coupon === null && (
          <Stack direction={'row'} spacing={2}>
            <Box flexGrow={1}>
              <CheckoutTextField
                name={'couponCode'}
                label={`${settings.couponLabelText} Code`}
                rules={{ required: true }}
                hideRequired
              />
            </Box>
            <LoadingButton
              variant={'contained'}
              disabled={!isValid}
              loading={applyLoading}
              onClick={handleSubmit(handleApplyCoupon)}
            >
              Apply
            </LoadingButton>
          </Stack>
        )}
        {coupon !== null && (
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Discount />
            <Box flexGrow={1}>
              {coupon.amountType ? (
                <Typography>{coupon.couponCode}</Typography>
              ) : (
                <Typography>
                  {coupon.couponCode} (-{coupon.discount}%)
                </Typography>
              )}
            </Box>
            <LoadingButton variant={'text'} loading={removeLoading} onClick={handleRemoveCoupon}>
              Remove
            </LoadingButton>
          </Stack>
        )}
        {applyError && <Alert severity={'error'}>{getError(applyError)}</Alert>}
        {removeError && <Alert severity={'error'}>{getError(removeError)}</Alert>}
      </Stack>
    </FormContainer>
  );
}
