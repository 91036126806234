import { Stack } from '@mui/material'
import React from 'react'
import { FieldValues, RegisterOptions, TextFieldElement } from 'react-hook-form-mui'

interface CheckoutTextFieldProps {
  name: string
  label: string
  helperText?: string
  rules?: Omit<RegisterOptions<FieldValues, string>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  disabled?: boolean
  readOnly?: boolean
  hideRequired?: boolean
}

export default function CheckoutTextField({
  name,
  label,
  helperText,
  rules,
  disabled,
  readOnly,
  hideRequired,
}: CheckoutTextFieldProps) {
  return (
    <Stack direction={'column'}>
      <TextFieldElement
        name={name}
        size="small"
        label={label}
        helperText={helperText}
        rules={rules}
        required={rules?.required !== undefined && rules?.required !== false && !hideRequired}
        disabled={disabled}
        slotProps={{
          htmlInput: {
            'data-testid': name,
          },
          input: {
            readOnly,
          },
        }}
      />
    </Stack>
  )
}
