import { Button, Card, CardContent, Stack, Typography } from '@mui/material'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
  selectActiveStep,
  selectLockChanges,
  selectParams,
  selectSkipCustomerDetails,
  selectStatus,
} from '../../../redux/selectors/checkoutSelectors'
import { CheckoutStatus, CheckoutStep } from '../../../models/CheckoutSession'
import CustomerDetailsActiveContent from './CustomerDetailsActiveContent'
import CustomerDetailsPassiveContent from './CustomerDetailsPassiveContent'
import { editStep } from '../../../redux/reducers/checkoutReducer'

export default function CustomerDetailsCard() {
  const dispatch = useAppDispatch()
  const params = useAppSelector(selectParams)
  const activeStep = useAppSelector((state) => selectActiveStep(state, params))
  const lockChanges = useAppSelector((state) =>
    selectLockChanges(state, params),
  )
  const skipCustomerDetails = useAppSelector((state) =>
    selectSkipCustomerDetails(state, params),
  )

  const handleEditStep = () => {
    dispatch(editStep(CheckoutStep.CUSTOMER_DETAILS))
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant="h5" gutterBottom>
            Customer Details
          </Typography>
          {!skipCustomerDetails &&
            activeStep !== CheckoutStep.CUSTOMER_DETAILS &&
            !lockChanges && (
              <Button variant="outlined" size="small" onClick={handleEditStep}>
                Edit
              </Button>
            )}
        </Stack>
        {activeStep === CheckoutStep.CUSTOMER_DETAILS && (
          <CustomerDetailsActiveContent />
        )}
        {activeStep !== CheckoutStep.CUSTOMER_DETAILS && (
          <CustomerDetailsPassiveContent />
        )}
      </CardContent>
    </Card>
  )
}
