import PropTypes from "prop-types";
import React, {Fragment, useState, useEffect, useContext} from "react";
import Swiper from "react-id-swiper";
import SectionTitle from "../../components/section-title/SectionTitle";
import RelatedProductGrid from "./RelatedProductGrid";
import {X_TENANT_ID, RELATED_PRODUCTS} from '../../common/ActionUrl'
import axios from "axios"

const RelatedProductSliderPromotional = ({spaceBottomClass, category, product}) => {
    const [relatedProductsList, setRelatedProductsList] = useState([])
    let loopslide = 4;
    if (relatedProductsList?.length) {
        if (relatedProductsList.length <= 4) {
            loopslide = relatedProductsList.length
        }
    }
    const settings = {
      loop: loopslide >= 4 ? true : false,
      slidesPerView: loopslide,
      grabCursor: true,
      observer: true,
      observeParents: true,
      initialSlide: 0,
      speed: 500,
    }
    const getRelatedProducts = async () => {
        let ids = []
        let productIds = []
        if (product?.RelatedProductArray) {
            let relatedProd = product?.RelatedProductArray?.[0]
            if (relatedProd?.RelatedProduct?.length > 0) {
                relatedProd.RelatedProduct.map(productData => {
                    ids.push(productData.pId)
                    productIds.push(productData.productId)
                })
            }
            const requestOptions = {
                headers: {'Content-Type': 'application/json', 'X-TENANT-ID': X_TENANT_ID},
            }
            await axios.post(RELATED_PRODUCTS, {ids: ids, productIds: productIds}, requestOptions)
                .then((res) => {
                    if (res?.data) {
                        const response = res.data;
                        setRelatedProductsList(response)
                    }
                }).catch(err => {
                    console.log('err', err);
                });
        }
    }

    useEffect(() => {
        getRelatedProducts()
    }, [product])

    if (relatedProductsList?.length == 0) {
        return ""
    }
    return (
        <div
            className={`related-product-area ${spaceBottomClass ? spaceBottomClass : ""
            }`}
        >
            <div className="container mt-5">
              <div className="row" style={{
                borderBottom: "2px solid #a1a1a1"
              }}>
                <h5 style={{
                  margin: "auto",
                  fontStyle: "italic",
                  fontWeight: "bold",
                  color: "#a1a1a1"
                }}>
                  {"Related Items"}
                </h5>
              </div>

                <div className="row">
                    <Swiper {...settings}>
                        <RelatedProductGrid
                            category={category}
                            isProductSlider={true}
                            centeredSlides={true}
                            relatedProductsList={relatedProductsList}
                            sliderClassName={relatedProductsList?.length > 4 ? "swiper-slide" : ""}
                        />
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

RelatedProductSliderPromotional.propTypes = {
    category: PropTypes.string,
    spaceBottomClass: PropTypes.string
};

export default RelatedProductSliderPromotional;
