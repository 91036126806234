import PropTypes from "prop-types";
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import Swiper from "react-id-swiper";
import useElementSize from "../../util/custom-hooks/useElementSize";
import { productImageExt, productImageUrl } from "../../util/helper";
import {AuthContext} from '../../common/Auth'
import { useProductConfigState } from "../../pages/shop-product/state/useProductConfigState";
import { fetchImageSize, getImageJustification } from "../../wrappers/product/util/getDimensions";
const ProductImageGallery = ({ product,
  selectedImage,
  selectedColor,
  setSelectedColor,
  selectedDecorations,
  singleSelectedDecorations,
  artworkListById,
  artworkList,
  temp,
  setSelectedImage,
  showSell, setSelectedThumbImgColor, selectedThumbImgColor,isReplace, isCatalogue=false }) => {

    const [imgW, imgH] = useElementSize(
      '.swiper-slide-active .containerimg img',
    )
	const [imageW, setImageW] = useState(imgW)
	const [imageH, setImageH] = useState(imgH) 
    const [gallerySwiper, getGallerySwiper] = useState(null)
    const [thumbnailSwiper, getThumbnailSwiper] = useState(null)
	const [DefaultProductBackgroundColor, setDefaultProductBackgroundColor] = useState()
	const [imageChanged, setImageChanged] = useState(0)
	const [overlayImgWidth, setOverlayImgWidth] = useState(0);
	const [overlayImgHeight, setOverlayImgHeight] = useState(0);
	const [overlayImgTop, setOverlayImgTop] = useState(0);
	const [overlayImgLeft, setOverlayImgLeft] = useState(0);
	const {
		settingData
	  } = useContext(AuthContext)

	  const {
		getSettingStatus,
	  } = useProductConfigState({product})
	
    // effect for swiper slider synchronize
	useEffect(() => {
		if (settingData?.length > 0) {
		  settingData.map((i) => {
			if (i.page === 'General') {
			  if (!!i?.values?.DefaultProductBackgroundColor) {
				setDefaultProductBackgroundColor(i?.values?.DefaultProductBackgroundColor);
			  }
			}
		  })
		}
	}, [settingData])
	let getSingleImage = {}
	const photoZoom = async (zmImg='', singlelogoDetails = {}) => {
		const node = document.querySelector(".swiper-slide-active .single-image.lightGal");
		let zoomPictureNode = document.getElementsByClassName("lg-img-wrap")
		if(!!zoomPictureNode && !!zoomPictureNode?.[0] && !!DefaultProductBackgroundColor && zmImg !== '')
			zoomPictureNode[0].classList.add(zmImg)
		if (node) {
			const clone = node.cloneNode(true);
			zoomPictureNode[0].classList.add('lg-img-zoom')
			if (zoomPictureNode) {
				zoomPictureNode[0].appendChild(clone);
			}
			
			var fourChildNode = zoomPictureNode[0].querySelector('.containerimg');
			if(!!fourChildNode) {				
				fourChildNode.style.maxWidth = 'unset'
				fourChildNode.style.width = 'unset'
				fourChildNode.style.margin = 'unset'
			}
			let fourChildNode0 = zoomPictureNode[0].querySelector('.containerimg .react_lightgallery_item');
			let fourChildNode1 = zoomPictureNode[0].querySelector('.containerimg .react_lightgallery_item .img-fluid');
			if(!!fourChildNode1 && fourChildNode1?.width > 2000) {
			fourChildNode1.style.height = 'auto'
			fourChildNode0.style.display = 'flex'
			fourChildNode0.style.alignItems = 'center'
			fourChildNode0.style.justifyContent = 'center'
			}
		}
	
		//get outerbox
		getSingleImage = singlelogoDetails
		let getOuterBOx = document.querySelector('.lg-img-wrap .react_lightgallery_item')
		if(!!getOuterBOx && !!getSingleImage)
		{
			let imgWidthZoom = parseFloat(getSingleImage?.iw) || 0;
			let imgHeightZoom = parseFloat(getSingleImage?.ih) || 0;
			let singleZoomX = parseFloat(getSingleImage?.x) || 0;
			let singleZoomY = parseFloat(getSingleImage?.y) || 0;
			let singleZoomW = parseFloat(getSingleImage?.w) || 0;
			let singleZoomH = parseFloat(getSingleImage?.h) || 0;

			if(getSingleImage?.isNewLogo === "1" && !!getSingleImage?.processedImage) {
				imgWidthZoom = getSingleImage?.processedImageWidth
				imgHeightZoom = getSingleImage?.processedImageHeight
				singleZoomX = getSingleImage?.newX
				singleZoomY = getSingleImage?.newY
				singleZoomW = getSingleImage?.newW
				singleZoomH = getSingleImage?.newH
			}
			
			const { width, height } = await fetchImageSize(getSingleImage.image);
			const imageJustify = getImageJustification(getSingleImage);
			let maxValue = Number.MIN_VALUE;
			let ratio = 1;
			let newX = getSingleImage.x;
			let newY= getSingleImage.y;
			let newW = getSingleImage.w;
			let newH = getSingleImage.h;
	  
			let newIw = width, newIh = height;
			if(height > 800 || width > 800) {
	  
			  maxValue = Math.max(width, height);
			  if(maxValue === height) {
				ratio = 800/height
				newIh = 800;
				newIw = ratio*width;
			  }else{
				ratio = 800/width;
				newIw = 800;
				newIh = height*ratio;
			  }
			}
			if(ratio !== 1){
			  newW = (getSingleImage.w*100)/newIw;
			  newX = (getSingleImage.x*100)/newIw;
			  newY = (getSingleImage.y*100)/newIh;
			  newH = (getSingleImage.h*100)/newIh;
			} else{
			  newW = (getSingleImage.w*100)/width;
			  newX = (getSingleImage.x*100)/width;
			  newY = (getSingleImage.y*100)/height;
			  newH = (getSingleImage.h*100)/height;
			}

			let overlayImag = document.querySelectorAll(".lg-img-wrap .overlayimg")
			const css = '.overlayimg:hover{  transform: scale(1.5); }';
			const style = document.createElement('style');
			if (style.sheet) {
				style.sheet.cssText = css;
			} else {
				style.appendChild(document.createTextNode(css));
			}
			for (let i = 0; i < overlayImag.length; i++) {
				overlayImag[i].style.left = newX + "%"
				overlayImag[i].style.top = newY + "%"
				overlayImag[i].style.width = newW + "%"
				overlayImag[i].style.height = newH + "%"
				overlayImag[i].style.transition = 'transform 0.3s';
				setOverlayImgTop(newX)
				setOverlayImgLeft(newX)
				setOverlayImgWidth(newW );
				setOverlayImgHeight(newH);
				overlayImag[i].appendChild(style)
			}
		
			//add width height to artwork
		
			let overlayImag2 = document.querySelectorAll(".lg-img-wrap .overlayimg .img-fluid")
			for (let i = 0; i < overlayImag2.length; i++) {
				overlayImag2[i].style.removeProperty('max-width');
				overlayImag2[i].style.removeProperty('max-height');
				overlayImag2[i].style.left = overlayImgLeft;
				overlayImag2[i].style.top = overlayImgTop;
				overlayImag2[i].style.width = '100%';
				overlayImag2[i].style.height = '100%';
				overlayImag2[i].style.objectFit = 'contain';
				overlayImag2[i].style.objectPosition = imageJustify
			}
		}
		let getOuterBOxCheck2 = document.querySelector('.lg-img-wrap .lightGal')
		if(!getOuterBOxCheck2) {
			return
		}
		let zoomInBtn = document.getElementById('lg-zoom-in');
		let zoomOutBtn = document.getElementById('lg-zoom-out');

		let scaleItem = 1

		zoomInBtn.addEventListener('click', function (event) {
			getOuterBOxCheck2.style.transform = `scale(${scaleItem + 0.5}) translateX(-50%)`
			scaleItem = scaleItem + 0.5
		});
		zoomOutBtn.addEventListener('click', function (event) {
			getOuterBOxCheck2.style.transform = `scale(${scaleItem - 0.5}) translateX(-50%)`
			scaleItem = scaleItem - 0.5
		});
	}

    useEffect(() => {
      if (
        gallerySwiper !== null &&
        gallerySwiper.controller &&
        thumbnailSwiper !== null &&
        thumbnailSwiper.controller
      ) {
        gallerySwiper.controller.control = thumbnailSwiper
        thumbnailSwiper.controller.control = gallerySwiper
      }
    }, [gallerySwiper, thumbnailSwiper])

    // swiper slider settings
    const gallerySwiperParams = {
      getSwiper: getGallerySwiper,
      spaceBetween: 10,
      loopedSlides: 4,
      loop: product?.MediaContent?.length <= 1 ? false : true,
      effect: 'fade',
    }
    useEffect(() => {
		let key = 0
      if (selectedImage) {
		let temp = product?.WebstoreProductPartArray.filter((val) => val.image===selectedImage && val?.isSwapImage,)
		if (temp.length > 0) {
		  if (product?.WebstoreProductPartArray) {
			product.WebstoreProductPartArray.filter(
				(ele, ind) => ind ===  product?.WebstoreProductPartArray.findIndex( elem => elem.color === ele.color)).map((single, index) => {
					if (single.image === selectedImage) {
						return key = index
						
					}
				})  
		  }
		}else{
			if (product.MediaContent) {
				product.MediaContent.map((single, index) => {
				  if (single.url === selectedImage) {
					key = index
				  }
				})
			  }
		}
        if (document.querySelectorAll('.swiper-pagination-bullet')) {
          let color_current = ''
          if (document.querySelector('#sw1')) {
            if (
              document
                .querySelector('#sw1')
                .querySelector('.swiper-slide-active')
            ) {
              if (
                document
                  .querySelector('#sw1')
                  .querySelector('.swiper-slide-active')
                  .querySelector('span')
              ) {
                color_current = document
                  .querySelector('#sw1')
                  .querySelector('.swiper-slide-active')
                  .querySelector('span').innerText
              }
            }
          }
          if (color_current !== selectedImage) {
            const frameZones = Array.from(
              document.querySelectorAll('.swiper-pagination-bullet'),
            )
            let flag = true
            frameZones &&
              frameZones.map(async (el, k) => {
                if (k === key && flag) {
                  flag = false
                  let opts = {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    buttons: 1,
                  }
                  el.dispatchEvent(new MouseEvent('mousedown', opts))
                  await new Promise((r) => setTimeout(r, 50))
                  el.dispatchEvent(new MouseEvent('mouseup', opts))
                  el.dispatchEvent(new MouseEvent('click', opts))
                }
              })
          }
        }
      }
    }, [selectedImage])

	useEffect(() => {
		setTimeout(() => {
		  const element = document.querySelectorAll('.swiper-slide-active .containerimg img');
		  setImageW(element[0]?.offsetWidth)
		  setImageH(element[0]?.offsetHeight)
		}, 1000);
	  },[selectedDecorations]);

	useEffect(() => {
		setTimeout(() => {
			const element = document.querySelectorAll('.swiper-slide-active .containerimg img');
			setImageW(element[0]?.offsetWidth)
			setImageH(element[0]?.offsetHeight)
		}, 500);
	},[imgW, imgH]);

	useEffect(() => {
		if (singleSelectedDecorations) {
		  let getIndex = product.LogoInfo.filter(
			(val) =>
			  val.color === selectedColor &&
			  val.location === singleSelectedDecorations.location
		  );
	  
		  if (getIndex.length > 0) {
			setSelectedImage(getIndex[0]?.image);
			setImageChanged(imageChanged+1)
		  } else {
			let getIndexOfExtra_logo = [];
			product.LogoInfo.map((val) => {
			  if (val.color === selectedColor) {
				if (
				  val.extra_logo.filter(
					(e) => e.location === singleSelectedDecorations.location
				  ).length > 0
				) {
				  getIndexOfExtra_logo.push(val);
				}
			  }
			});
			if (getIndexOfExtra_logo.length) {
			  setSelectedImage(getIndexOfExtra_logo[0]?.image);
			  setImageChanged(imageChanged+1)
			} else {
			  let getImgColorIndex = product.LogoInfo.filter(
				(val) => val.color === selectedColor
			  );
	  
			  if (getImgColorIndex.length > 0) {
				setSelectedImage(getImgColorIndex[0]?.image);
				setImageChanged(imageChanged+1)
			  }
			}
		  }
		}
	  }, [singleSelectedDecorations]);

    const thumbnailSwiperParams = {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      on: {
        init: () => {
          let color_current = document
            ?.querySelector('#sw1')
            ?.querySelector('.swiper-slide-active')
            ?.querySelector('span')?.innerText
          setSelectedThumbImgColor(color_current)
        },

        slideChangeTransitionEnd: (el) => {
          if (document.querySelector('#sw1')) {
            if (
              document
                .querySelector('#sw1')
                .querySelector('.swiper-slide-active')
            ) {
              if (
                document
                  .querySelector('#sw1')
                  .querySelector('.swiper-slide-active')
                  .querySelector('span')
              ) {
                let color_current = document
                  .querySelector('#sw1')
                  .querySelector('.swiper-slide-active')
                  .querySelector('span').innerText
                setSelectedThumbImgColor(color_current)
              }
            }
          }
        },
      },
      getSwiper: getThumbnailSwiper,
      spaceBetween: isCatalogue ? 10 : 20,
      slidesPerView:
        product?.MediaContent?.length >= 4
          ? 4
          : product?.MediaContent?.length >= 3
          ? 3
          : product?.MediaContent?.length >= 2
          ? 2
          : 1,
      loopedSlides: 4,
      observer: true,
      observeParents: true,
      initialSlide: 0,
      touchRatio: 1,
      touchAngle: 90,
      threshold: 20,
      speed: 100,
      freeMode: true,
      loop: product?.MediaContent?.length <= 1 ? false : true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      renderPrevButton: () => (
        <>
          {product?.MediaContent?.length > 4 && (
            <button className="antera-swiper-button swiper-button-prev ht-swiper-button-nav">
              <i className="pe-7s-angle-left" />
            </button>
          )}
        </>
      ),
      renderNextButton: () => (
        <>
          {product?.MediaContent?.length > 4 && (
            <button className="antera-swiper-button swiper-button-next ht-swiper-button-nav">
              <i className="pe-7s-angle-right" />
            </button>
          )}
        </>
      ),
    }
    const getReducePer = (width, param) => {
      let reducePer = 100
      while ((Number(width) * reducePer) / 100 > param) {
        reducePer = reducePer - 1
      }
      return reducePer
    }

    const saleStyles = {
      color: product?.bannerFontColor ? product?.bannerFontColor : 'black',
      'backgroundColor': product?.bannerBgColor
        ? product?.bannerBgColor
        : '#FF7F00',
    }

	useEffect(() => {
		setTimeout(() => {
			const element = document.querySelectorAll('.swiper-slide-active .containerimg img');
			if(element.length > 0) {
				setImageW(element[0]?.offsetWidth)
				if(element[0]?.offsetWidth <= 451 && element[0]?.offsetWidth > 0) {
					const element1 = document.querySelectorAll('.swiper-slide-active .containerimg');
					if(element1.length > 0) {
						element1[0].style.width = `${element[0]?.offsetWidth}px`
					}
				}
			}
		}, 100);
	},[imageChanged]);

    return (
      <Fragment>
        <div className="product-large-image-wrapper product-large-img" id="sw1">
          {product.discount || product.new ? (
            <div className="product-img-badges">
              {product.discount ? (
                <span className="pink">-{product.discount}%</span>
              ) : (
                ''
              )}
              {product.new ? <span className="blue">New</span> : ''}
            </div>
          ) : (
            ''
          )}
				{isReplace ? <Swiper {...gallerySwiperParams}>
					{product.MediaContent &&
						product.MediaContent.map((single, key) => {
							let findlogoinfo = ''
							let singlelogoDetails = {}
							let prImage = single.url
							if(single?.isNewLogo == "1" && !!single?.processedImage) {
								prImage = single.processedImage
							}
				let getImage = product?.WebstoreProductPartArray.filter((val) => val.color == single.color && val.isSwapImage == true,)
				if (getImage.length > 0) {
					prImage = getImage[0].image
				}
							if (singleSelectedDecorations != "" && singleSelectedDecorations != null && singleSelectedDecorations != "null" && singleSelectedDecorations != undefined && getSettingStatus('showDecoration')) {
								const itemsOnTop = product?.LogoInfo.filter(item => item.color !== selectedColor);
								const sortedItems = product?.LogoInfo.filter(item => item.color === selectedColor).sort();
								product.LogoInfo = [...itemsOnTop, ...sortedItems]

								for (let i = 0; i < product?.LogoInfo.length; i++) {
									const logoInfoData = product?.LogoInfo[i]
									if (logoInfoData.color === selectedColor) {
										if (logoInfoData.image === selectedImage && logoInfoData.location == singleSelectedDecorations.location) {
											findlogoinfo = logoInfoData
											break;
										} else if (logoInfoData.location == singleSelectedDecorations.location) {
											findlogoinfo = logoInfoData
											break;
										} else if (logoInfoData.image === selectedImage) {
											let extra_logo = logoInfoData.extra_logo.filter(val => val.location == singleSelectedDecorations.location)

											if (extra_logo.length > 0) {

												findlogoinfo = extra_logo
												findlogoinfo[0].image = logoInfoData.image
												findlogoinfo[0].color = logoInfoData.color
												findlogoinfo[0].ih = logoInfoData.ih
												findlogoinfo[0].iw = logoInfoData.iw
												findlogoinfo[0].location = extra_logo[0].location
												findlogoinfo[0].h = extra_logo[0].h

												findlogoinfo[0].w = extra_logo[0].w
												findlogoinfo[0].x = extra_logo[0].x
												findlogoinfo[0].y = extra_logo[0].y
												if (logoInfoData?.isNewLogo == "1" && !!logoInfoData?.processedImage) {
													findlogoinfo[0].processedImageWidth = logoInfoData?.processedImageWidth
													findlogoinfo[0].processedImageHeight = logoInfoData?.processedImageHeight
													findlogoinfo[0].isNewLogo = logoInfoData?.isNewLogo
													findlogoinfo[0].processedImage = logoInfoData?.processedImage
												}
												break;
											}
										}
									}
								}
							}

							if (findlogoinfo && Object.keys(findlogoinfo).length > 0) {
							 if(!!findlogoinfo && findlogoinfo.length > 0) {
								singlelogoDetails = findlogoinfo[0];
							 } else {
								singlelogoDetails = findlogoinfo;
							 }
							}

							let imgWidth = singlelogoDetails?.iw
							let imgHeight = singlelogoDetails?.ih
							let singleX = singlelogoDetails?.x
							let singleY = singlelogoDetails?.y
							let singleW = singlelogoDetails?.w
							let singleH = singlelogoDetails?.h
							if(singlelogoDetails?.isNewLogo == "1" && !!singlelogoDetails?.processedImage) {
								imgWidth = singlelogoDetails?.processedImageWidth
								imgHeight = singlelogoDetails?.processedImageHeight
								singleX = singlelogoDetails?.newX
								singleY = singlelogoDetails?.newY
								singleW = singlelogoDetails?.newW
								singleH = singlelogoDetails?.newH
								prImage = single?.processedImage

							}

							if(imgWidth > 900 || imgHeight > 1100){
								var ratio = Math.min(800 / imgWidth, 800 / imgHeight);
						
								imgWidth = imgWidth * ratio
								imgHeight = imgHeight * ratio
							}

							let reducePer = getReducePer(imgHeight, imageH)


                {/* imgWidth = (imgWidth * reducePer) / 100
                imgHeight = (imgHeight * reducePer) / 100*/}

                //artwork box left top position set set
                let positionSetLeft = (imageW * singleX) / imgWidth
                let positionSetTop = (imageH * singleY) / imgHeight
				const imageJustifyProduct = getImageJustification(getSingleImage)
                //artwork box width/height set
                let positionSetBoxWidth = (imageW * singleW) / imgWidth
                let positionSetBoxHeight = (imageH * singleH) / imgHeight
				let mainCls = 'single-image lightGal'
				let zmImg = ''
				if((getImage && getImage.length > 0 && !!prImage && productImageExt(prImage)) || productImageExt(process.env.PUBLIC_URL + prImage))
				{
					mainCls = 'single-image lightGal product-img-bg'
					zmImg = 'product-img-bg'
				}
                return (
                  <div key={key}>
                    <div>
                      <div className={mainCls}>
                        <div className="containerimg" style={{maxWidth:'451px', margin: "0 auto"}}>
                          <LightgalleryProvider onAfterOpen={() => photoZoom(zmImg,  singlelogoDetails)}>
                            <LightgalleryItem
                              group="any"
							  src={getImage && getImage.length > 0 ? productImageUrl(prImage) : productImageUrl(
								process.env.PUBLIC_URL + prImage,
							)}
                            >
                              <img
                               src={getImage && getImage.length > 0 ? productImageUrl(prImage) : productImageUrl(
								process.env.PUBLIC_URL + prImage,
							)}
                                className="img-fluid"
                                alt=""
                              />

														{getSettingStatus('showDecoration') && singlelogoDetails && Object.keys(singlelogoDetails).length > 0 && selectedDecorations != "" && selectedColor == selectedThumbImgColor && selectedImage === single.url && selectedDecorations != null && artworkListById?.length > 0 && selectedDecorations.length > 0 && selectedDecorations.map((li, key) => {
															let to = ""
															let lef = ""
															let wid = ""
															let hei = ""
															if (li.location == singlelogoDetails.location && li.location === singleSelectedDecorations.location) {
																to = singlelogoDetails.y;
																lef = singlelogoDetails.x;
																wid = singlelogoDetails.w;
																hei = singlelogoDetails.h;
																if(singlelogoDetails?.isNewLogo == "1" && !!singlelogoDetails?.processedImage) {
																	lef = singlelogoDetails?.newX
																	to = singlelogoDetails?.newY
																	wid = singlelogoDetails?.newW
																	hei = singlelogoDetails?.newH
																}
															} else {
																singlelogoDetails.extra_logo && singlelogoDetails.extra_logo.map(el => {
																	if (li.location == el.location && li.location === singleSelectedDecorations.location) {
																		to = el.y;
																		lef = el.x;
																		wid = el.w;
																		hei = el.h;
																		if(singlelogoDetails?.isNewLogo == "1" && !!singlelogoDetails?.processedImage) {
																			to = el?.newY
																			lef = el?.newX
																			wid = el?.newW
																			hei = el?.newH
																		}
																	}
																})
															}
															to = ((Number(to) * reducePer) / 100).toString();
															lef = ((Number(lef) * reducePer) / 100).toString();
															wid = ((Number(wid) * reducePer) / 100).toString();
															hei = ((Number(hei) * reducePer) / 100).toString();

															let artwork_selected = null
															if (li?.artworkID !== '' && li?.artworkID !== undefined) {

																const idToSearch = singleSelectedDecorations ? singleSelectedDecorations.artworkID : li.artworkID
																artwork_selected = artworkListById?.find(
																	(artworkArray) => artworkArray.id === idToSearch,
																)
															}

															let rowVariation = null
															if (artwork_selected?.design?.variation?.length > 0) {
																rowVariation = artwork_selected.design.variation.filter(
																	(variationArray) =>
																		variationArray.design_variation_unique_id ==
																		li.variationUniqID,
																)[0]
																if (artwork_selected?.design?.variation?.itemImageThumbnail != "" && artwork_selected?.design?.variation?.itemImageThumbnail != undefined) {
																	rowVariation = artwork_selected?.image
																}
															}
														if(rowVariation) {
															return (
																<div
																	className="overlayimg"
																	key={key}
																	style={{
																		transitionDelay: 'none !important',
																		top: positionSetTop + 'px',
																		left: positionSetLeft + 'px',
																		display: 'flex',
																		alignItems: 'flex-start',
																		width: '100%',
                    													height: '100%',
																	}}
																>
																	{((!!rowVariation && !!rowVariation?.itemImageThumbnail && !!rowVariation?.itemImageThumbnail?.[0]) || (!!artwork_selected?.thumbnail)) && <img className="img-fluid" src={
																		!!rowVariation?.itemImageThumbnail && !!rowVariation.itemImageThumbnail?.[0]
																			? productImageUrl(
																				rowVariation.itemImageThumbnail[0],
																			)
																			: (!!artwork_selected?.thumbnail ? productImageUrl(artwork_selected?.thumbnail) : '')
																	}
																		alt="thumbnail"
																		style={{
																			top: positionSetTop + 'px',
																			left: positionSetLeft + 'px',
																			objectPosition: imageJustifyProduct,
																			width: '100%',
																			height: '100%',
																			maxWidth: positionSetBoxWidth + 'px',
																			maxHeight: positionSetBoxHeight + 'px',
																		}}
																	/>}
																</div>
															)
														}
														})}
														<span style={{ display: 'none' }}>
															{single.color}
														</span>
													</LightgalleryItem>
												</LightgalleryProvider>
											</div>
										</div>
									</div>
								</div>
							)
						})}
				</Swiper> :
					<Swiper {...gallerySwiperParams}>
						{product?.WebstoreProductPartArray &&
							product?.WebstoreProductPartArray?.filter(
								(ele, ind) => ind === product?.WebstoreProductPartArray.findIndex(elem => elem.color === ele.color)).map((single, key) => {
									let getImage = product?.WebstoreProductPartArray.filter((val) => val.color == single.color && val.isSwapImage == true,)
									let mainCls = 'single-image'
									let zmImg = ''
									if((getImage && getImage.length > 0 && !!getImage[0]?.image && productImageExt(getImage[0]?.image)) || productImageExt(process.env.PUBLIC_URL + single.url))
									{
										mainCls = 'single-image product-img-bg'
										zmImg = 'product-img-bg'
									}
									return (
										<div key={key}>
											<div>
												<div className={mainCls}>
													<div className="containerimg" style={{maxWidth:'451px', margin: "0 auto"}}>
														<LightgalleryProvider onAfterOpen={() => photoZoom(zmImg)}>
															<LightgalleryItem
																group="any"
																src={getImage && getImage.length > 0 ? productImageUrl(getImage[0].image) : productImageUrl(
																	process.env.PUBLIC_URL + single.url,
																)}
															>
																<img
																	src={getImage && getImage.length > 0 ? productImageUrl(getImage[0].image) : productImageUrl(
																		process.env.PUBLIC_URL + single.url,
																	)} className="img-fluid"
																	alt=""
																/>
																<span style={{ display: 'none' }}>
																	{single.color}
																</span>
															</LightgalleryItem>
														</LightgalleryProvider>
													</div>
												</div>
											</div>
										</div>
									)
								})}
					</Swiper>}
        </div>
        <div className="product-small-image-wrapper mt-15">
				{isReplace ?
					<Swiper {...thumbnailSwiperParams}>
						{product.MediaContent &&
							product.MediaContent.map((single, key) => {
								let getImage = product?.WebstoreProductPartArray.filter((val) => val.color == single.color && val.isSwapImage == true,)
								let mainCls = 'single-image lightGal'
								if((getImage && getImage.length > 0 && !!getImage[0]?.image && productImageExt(getImage[0]?.image)) || productImageExt(process.env.PUBLIC_URL + single.url))
								{
								  mainCls = 'single-image lightGal product-img-bg'
								}
								return (
									<div key={key}>
										<div className={mainCls}>
											<img
												src={getImage && getImage.length > 0 ? productImageUrl(getImage[0].image) : productImageUrl(
													process.env.PUBLIC_URL + single.url,
												)}
												className="img-fluid"
												alt=""
											/>
										</div>
									</div>
								)
							})}
					</Swiper> :

					<Swiper {...thumbnailSwiperParams}>
						{product?.WebstoreProductPartArray &&
							product?.WebstoreProductPartArray?.filter(
								(ele, ind) => ind === product?.WebstoreProductPartArray.findIndex(elem => elem.color === ele.color)).map((single, key) => {
									let getImage = product?.WebstoreProductPartArray.filter((val) => val.color == single.color && val.isSwapImage == true,)
									let mediaImage = product?.MediaContent?.filter(
										(mediaArray) => !mediaArray.hidden && mediaArray.color == single.color)
									let mainCls = 'single-image'
									if(((getImage && getImage.length > 0 && !!getImage[0]?.image && productImageExt(getImage[0]?.image)) || (!!single?.image && productImageExt(single.image))) || productImageExt(process.env.PUBLIC_URL + mediaImage[0]?.url))
									{
										mainCls = 'single-image product-img-bg'
									}
									return (
										<div key={key}>
											<div className={mainCls}>
												<img
													src={getImage && getImage.length > 0 ? productImageUrl(getImage[0].image) : single.image ? productImageUrl(
														process.env.PUBLIC_URL + single.image,
													) : productImageUrl(
														process.env.PUBLIC_URL + mediaImage[0]?.url,
													)}
													className="img-fluid"
													alt=""
												/>
											</div>
										</div>
									)
								})}
					</Swiper>
				}
        </div>
      </Fragment>
    )
};

ProductImageGallery.propTypes = {
  product: PropTypes.object
};

export default ProductImageGallery;
