import React from 'react'
import useAddressFormValues from './fulfillment/useAddressFormValues'
import PassiveRow from './PassiveRow'
import { Address, AddressContact } from '../../models/Address'
import { useAppSelector } from '../../redux/hooks'
import { selectDebug } from '../../redux/selectors/checkoutSelectors'
import { AddressSource } from '../../models/FulfillmentQuote'

interface AddressInfoProps {
  formPrefix?: string
  hideAddress?: boolean
}

export default function AddressInfo({
  formPrefix,
  hideAddress,
}: AddressInfoProps) {
  const debug = useAppSelector(selectDebug)
  const [address, contact, addressSource] = useAddressFormValues<[Address, AddressContact, AddressSource]>({
    formPrefix,
    names: ['address', 'contact', 'addressSource'],
  })

  return (
    <>
      {!hideAddress && address?.locationName && (
        <PassiveRow field="Location Name" value={address.locationName} />
      )}
      <PassiveRow
        field="Contact"
        values={[
          contact ? `${contact.firstName} ${contact.lastName}` : undefined,
          contact?.email,
          contact?.phone,
        ]}
      />
      {!hideAddress && address && (
        <PassiveRow
          field="Address"
          values={[
            contact?.company,
            address?.street2 ? `${address.street1}, ${address.street2}` : address?.street1,
            address ? `${address.city}, ${address.state} ${address.postalCode} ${address.country}` : undefined,
          ]}
        />
      )}
      {debug && (
        <PassiveRow field="Address Source" value={addressSource} />
      )}
    </>
  )
}
