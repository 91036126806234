
import * as productsAPIUtil from "../../../util/products/productsAPIUtil";
import { fetchImageSize, getImageJustification } from "../../../wrappers/product/util/getDimensions";
import { useState } from 'react';
export const useProductArtworkState = (props) => {
    const {artworksFromState, getSettingStatus, setLoading} = props;
    const [overlayImgWidth, setOverlayImgWidth] = useState(0);
    const [overlayImgHeight, setOverlayImgHeight] = useState(0);
    const [overlayImgTop, setOverlayImgTop] = useState(0);
    const [overlayImgLeft, setOverlayImgLeft] = useState(0);
    const getDecoPrice = async (artWorkID) => {
        let data = null;
        try {
            let artwork_selected = artworksFromState?.filter(
                (artorkArray) => artorkArray.id === artWorkID,
            )[0]
            if (artwork_selected?.design) {
                let decodata = {
                    offset: '0',
                    limit: '500',
                    order: 'name',
                    orient: 'desc',
                    source: 'aether',
                    term: {
                        id: '',
                        name: '',
                        vendorId: artwork_selected?.design?.decoVendorId,
                        vendorName: artwork_selected?.design?.decoVendorName,
                        decoratorType: artwork_selected?.design?.designType,
                        customerName: artwork_selected?.design?.customerName,
                        decoratorSubType: '',
                        decorationIdentifier: '',
                        stitchesStart: '',
                        stitchesUpto: '',
                        setupCharge: '',
                        qunatityStart: '',
                        quantityUpto: '',
                        price: '',
                        salePrice: '',
                        setupChargeSalePrice: '',
                        decorationDetail: '',
                    },
                }
                setLoading(true)
                await productsAPIUtil.getDecorationPrice(decodata).then(result => {
                    if (result?.data?.data) {
                        data = result.data.data;
                    }
                }).catch(error => {
                    console.log("error", error)
                }).finally(() => setLoading(false))
            }
        } catch (error) {
            setLoading(false)
            console.log('error', error)
        }
        return data;
    }

    const filterArtworks = (artworks) => {
        const artworkMap = new Map();

        artworks.forEach(artwork => {
            const location = artwork.location;
            if (!artworkMap.has(location) || (artworkMap.get(location).isRequiredForStore === "0" && artwork.isRequiredForStore === "1")) {
                artworkMap.set(location, artwork);
            }
        });

        const filteredArtworks = Array.from(artworkMap.values());

        return filteredArtworks.sort((a, b) => {
            if (a.isRequiredForStore === '1' && b.isRequiredForStore !== '1') {
                return 1; // Move a to the end
            } else if (a.isRequiredForStore !== '1' && b.isRequiredForStore === '1') {
                return -1; // Move b to the end
            } else {
                return 0; // Keep original order
            }
        });
    }

    const selectDecoration = async( color, isHatsLayout = false ) => {
        let tempDecoArray = []
        const decorationPricing = {}
        for( let val of artworksFromState ) {
            const artWorkId = val.id;
            const productToDecorationMapping = val.productToDecorationMapping || []
            for( let decoMap of productToDecorationMapping ) {
                if( decoMap.isRequiredForStore === '1' || ( isHatsLayout && decoMap.autoAttach === '1' ) ) {
                    if( !decorationPricing[artWorkId] ) {
                        const decoPrices = await getDecoPrice(val.id);
                        const artwork = artworksFromState.find(( artwork ) => artwork.id === artWorkId);
                        decorationPricing[artWorkId] = decoPrices.filter(( p ) => artwork?.design?.designType === p.decoratorType);
                    }
                    const locationId = decoMap.location
                    const locationName = decoMap.locationName
                    decoMap.mapping.forEach(( map ) => {
                        if( map.color === color ) {
                            const variationId = map.variationId
                            const decoData = {
                                "artworkID": artWorkId,
                                "location": locationName,
                                "locationId": locationId,
                                "variationUniqID": variationId,
                                "isRequiredForStore": decoMap.isRequiredForStore,
                                decoPricedetails: decorationPricing[artWorkId],
                                "autoAttach": decoMap.isRequiredForStore === "1" && isHatsLayout ? '0' : decoMap.autoAttach,
                                "supplierDeco": decoMap.supplierDeco
                            }
                            tempDecoArray.push(decoData)
                        }
                    })
                }
            }
        }
        if( isHatsLayout === true ) {
            tempDecoArray = filterArtworks(tempDecoArray)
        }
        return tempDecoArray
    }
    let getSingleImage = {};
    const photoZoom = async (zmImg = '', singlelogoDetails = {}, key = '', decokey) => {
        const node = document.querySelectorAll(".single-image-sticky")[key]
        let zoomPictureNode = document.getElementsByClassName("lg-img-wrap")
        if (!!zoomPictureNode && !!zoomPictureNode?.[0] && zmImg !== '') {
            zoomPictureNode[0].classList.add(zmImg)
        }

        if (node) {
            const clone = node.cloneNode(true);
            zoomPictureNode[0].classList.add('lg-img-zoom')
            if (zoomPictureNode) {
                zoomPictureNode[0].appendChild(clone);
            }

            var fourChildNode = zoomPictureNode[0].querySelector('.containerimg');
            if (!!fourChildNode) {
                fourChildNode.style.maxWidth = 'unset'
                fourChildNode.style.width = 'unset'
                fourChildNode.style.margin = 'unset'
            }
            let fourChildNode0 = zoomPictureNode[0].querySelector('.containerimg .react_lightgallery_item');
            let fourChildNode1 = zoomPictureNode[0].querySelector('.containerimg .react_lightgallery_item .img-fluid');
            console.log('fourChildNode1: ', fourChildNode1?.width);
            if (!!fourChildNode1 && fourChildNode1?.width > 2000) {
                fourChildNode1.style.height = 'auto'
                fourChildNode0.style.display = 'flex'
                fourChildNode0.style.alignItems = 'center'
                fourChildNode0.style.justifyContent = 'center'
            }
        }

        getSingleImage = singlelogoDetails
        let getOuterBOx = document.querySelector('.lg-img-wrap .react_lightgallery_item')
        if (!!getOuterBOx && !!getSingleImage) {
            let imgWidthZoom = parseFloat(getSingleImage?.iw) || 0;
            let imgHeightZoom = parseFloat(getSingleImage?.ih) || 0;
            let singleZoomX = parseFloat(getSingleImage?.x) || 0;
            let singleZoomY = parseFloat(getSingleImage?.y) || 0;
            let singleZoomW = parseFloat(getSingleImage?.w) || 0;
            let singleZoomH = parseFloat(getSingleImage?.h) || 0;

            if (getSingleImage?.isNewLogo === "1" && !!getSingleImage?.processedImage) {
                imgWidthZoom = getSingleImage?.processedImageWidth
                imgHeightZoom = getSingleImage?.processedImageHeight
                singleZoomX = getSingleImage?.newX
                singleZoomY = getSingleImage?.newY
                singleZoomW = getSingleImage?.newW
                singleZoomH = getSingleImage?.newH
            }
            const { width, height } = await fetchImageSize(getSingleImage.image);
            const imageJustify = getImageJustification(getSingleImage);
            let maxValue = Number.MIN_VALUE;
            let ratio = 1;
            let newX = getSingleImage.x;
            let newY= getSingleImage.y;
            let newW = getSingleImage.w;
            let newH = getSingleImage.h;
      
            let newIw = width, newIh = height;
            if(height > 800 || width > 800) {
      
              maxValue = Math.max(width, height);
              if(maxValue === height) {
                ratio = 800/height
                newIh = 800;
                newIw = ratio*width;
              }else{
                ratio = 800/width;
                newIw = 800;
                newIh = height*ratio;
              }
            }
            if(ratio !== 1){
              newW = (getSingleImage.w*100)/newIw;
              newX = (getSingleImage.x*100)/newIw;
              newY = (getSingleImage.y*100)/newIh;
              newH = (getSingleImage.h*100)/newIh;
            } else{
              newW = (getSingleImage.w*100)/width;
              newX = (getSingleImage.x*100)/width;
              newY = (getSingleImage.y*100)/height;
              newH = (getSingleImage.h*100)/height;
            }

            let overlayImag = document.querySelectorAll(`.lg-img-wrap .decokey${decokey}`)
            const css = '.overlayimg:hover{  transform: scale(1.5); }';
            const style = document.createElement('style');
            if (style.sheet) {
                style.sheet.cssText = css;
            } else {
                style.appendChild(document.createTextNode(css));
            }
            for (let i = 0; i < overlayImag.length; i++) {
                overlayImag[i].style.left = newX + "%"
                overlayImag[i].style.top = newY + "%"
                overlayImag[i].style.width = newW + "%"
                overlayImag[i].style.height = newH + "%"
                overlayImag[i].style.transition = 'transform 0.3s';
                setOverlayImgTop(newX)
                setOverlayImgLeft(newX)
                setOverlayImgWidth(newW );
                setOverlayImgHeight(newH);
                overlayImag[i].appendChild(style)
            }

            let overlayImag2 = document.querySelectorAll(`.lg-img-wrap .decokey${decokey} .img-fluid`)
            for (let i = 0; i < overlayImag2.length; i++) {
                overlayImag2[i].style.removeProperty('max-width');
                overlayImag2[i].style.removeProperty('max-height');
                overlayImag2[i].style.left = overlayImgLeft;
                overlayImag2[i].style.top = overlayImgTop;
                overlayImag2[i].style.width = '100%';
                overlayImag2[i].style.height = '100%';
                overlayImag2[i].style.objectFit = 'contain';
                overlayImag2[i].style.objectPosition = imageJustify
            }
        }
        let getOuterBOxCheck2 = document.querySelector('.lg-img-wrap .lightGal')

        if (!getOuterBOxCheck2) {
            return
        }
        let zoomInBtn = document.getElementById('lg-zoom-in');
        let zoomOutBtn = document.getElementById('lg-zoom-out');

        let scaleItem = 1

        zoomInBtn.addEventListener('click', function (event) {
            getOuterBOxCheck2.style.transform = `scale(${scaleItem + 0.5}) translateX(-50%)`
            scaleItem = scaleItem + 0.5
        });
        zoomOutBtn.addEventListener('click', function (event) {
            getOuterBOxCheck2.style.transform = `scale(${scaleItem - 0.5}) translateX(-50%)`
            scaleItem = scaleItem - 0.5
        })
    }

    const decorationRequiredForStoreEvaluation = (decorations) => {
        const emptyDecorations = decorations.filter(decoration => !!!decoration.artworkID);
        if(emptyDecorations.length > 0) {
            const artworkDetails = emptyDecorations.map(decoration => {
                for( let artwork of artworksFromState ) {
                    const  productToDecorationMapping = artwork.productToDecorationMapping || []
                    for ( let decoMap of productToDecorationMapping ) {
                        if (decoMap.location === decoration.locationId) {
                            return {
                                ...decoration,
                                requiredForStore: decoMap.isRequiredForStore === '1'
                            }
                        }
                    }
                }
            })
            return artworkDetails.filter(decoration => decoration?.requiredForStore).length > 0;
        }
        return false
    }

    const autoAttachDecoration = (locationId) => {
        for( let artwork of artworksFromState ) {
            const  productToDecorationMapping = artwork.productToDecorationMapping || []
            for ( let decoMap of productToDecorationMapping ) {
                if (decoMap.location === locationId) {
                    return decoMap.isRequiredForStore === '1';
                }
            }
        }
        return false
    }

    return {
        getDecoPrice,
        selectDecoration,
        photoZoom,
        decorationRequiredForStoreEvaluation,
        autoAttachDecoration
    }
}
