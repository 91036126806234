import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'

interface LoginRequiredDialogProps {
  open: boolean
}

export default function LoginRequiredDialog({
  open,
}: LoginRequiredDialogProps) {
  return (
    <Dialog open={open}>
      <DialogTitle>Account Required</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          An account is required to checkout. Please log in or create an account
          to continue.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button href="/cart">Back to Cart</Button>
        <Button href="/login" variant="contained">
          Log In
        </Button>
      </DialogActions>
    </Dialog>
  )
}
