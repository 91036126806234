import { FieldValues, Path, UseFormReturn, useWatch } from "react-hook-form-mui"

interface UserFormValueProps<T extends FieldValues> {
  name: Path<T>,
  formContext: UseFormReturn<T, any, undefined>
}

export default function useFormValue<T extends FieldValues, K>({ name, formContext }: UserFormValueProps<T>): K {
  const { getValues, control } = formContext;
  const settingValue = useWatch({ name: name, control })
  return settingValue ?? getValues(name);
}