import React, { useEffect, useMemo } from 'react'
import { Alert, Button, Stack, Tooltip, Typography } from '@mui/material'
import { useAppSelector } from '../../../redux/hooks'
import {
  selectBudgetIds,
  selectDebug,
  selectIsOtherPayments,
  selectParams,
  selectPaymentRequests,
} from '../../../redux/selectors/checkoutSelectors'
import { Controller, useFormContext } from 'react-hook-form-mui'
import OptionCard from '../OptionCard'
import { GroupOutlined, LocalAtm, PersonOutline } from '@mui/icons-material'
import { BudgetType, UserBudget } from '../../../models/NewBudget'
import { useGetActiveBudgetsQuery } from '../../../redux/api/budgetApi'
import { getError } from '../../../helpers/checkout'
import { PaymentMethodFormProps } from './PaymentMethodForms'
import { AetherPaymentRequest } from '../../../models/Payment'
import CheckoutTextField from '../CheckoutTextField'
import useCurrencyDisplay from '../useCurrencyDisplay'

const isBudgetDisabled = (
  budget: UserBudget,
  isOtherPayments: boolean,
  methodBalanceDue: number,
) => {
  if (
    (budget.balance === 0 && !budget.allowOverbudget) ||
    (!budget.allowMultiplePayments &&
      !budget.allowOverbudget &&
      budget.balance < methodBalanceDue)
  ) {
    return 'Insufficient balance'
  } else if (isOtherPayments && !budget.allowMultiplePayments) {
    return 'This budget cannot be combined with other payments'
  }
}

export default function BudgetForm({
  settings,
  index,
  methodBalanceDue,
}: PaymentMethodFormProps) {
  const debug = useAppSelector(selectDebug)
  const { format } = useCurrencyDisplay()
  const params = useAppSelector(selectParams)
  const isOtherPayments = useAppSelector((state) =>
    selectIsOtherPayments(state, params, index),
  )
  const budgetIds = useAppSelector((state) =>
    selectBudgetIds(state, params, index),
  )

  const { setValue } = useFormContext()

  const { data: budgets, isLoading, error } = useGetActiveBudgetsQuery()

  const handleSelect =
    (budget: UserBudget | null, onChange: (...event: any[]) => void) => () => {
      onChange(budget?._id ?? null)
      if (budget && !budget.allowOverbudget) {
        setValue('maximumAmount', budget?.balance)
      } else {
        setValue('maximumAmount', undefined)
      }
    }

  const effectiveBudgets =
    budgets?.filter((b) => !budgetIds.includes(b._id)) ?? []

  return (
    <>
      {error && (
        <Alert severity="error">Error loading budgets: {getError(error)}</Alert>
      )}
      {!isLoading && effectiveBudgets.length === 0 && (
        <Alert severity="info">No budgets found</Alert>
      )}
      {!isLoading && (effectiveBudgets.length ?? 0) > 0 && (
        <Typography variant="h6">Select Budget</Typography>
      )}
      <Controller<AetherPaymentRequest, 'budgetId'>
        name={'budgetId'}
        rules={{ required: true }}
        render={({ field }) => {
          const budget = effectiveBudgets.find((b) => b._id === field.value)
          return (
            <Stack gap={2}>
              {effectiveBudgets?.map((budget, idx) => (
                <OptionCard
                  key={idx}
                  disabled={
                    !!isBudgetDisabled(
                      budget,
                      isOtherPayments,
                      methodBalanceDue,
                    )
                  }
                  tooltip={isBudgetDisabled(
                    budget,
                    isOtherPayments,
                    methodBalanceDue,
                  )}
                  label={budget.plan.name}
                  secondaryLabel={
                    <Stack direction={'row'} spacing={2}>
                      <Typography>{format(budget.balance)}</Typography>
                      {budget.type === BudgetType.USER && (
                        <Tooltip title={'User Budget'}>
                          <PersonOutline />
                        </Tooltip>
                      )}
                      {budget.type === BudgetType.USER_GROUP && (
                        <Tooltip title={'Group Budget (Shared)'}>
                          <GroupOutlined />
                        </Tooltip>
                      )}
                    </Stack>
                  }
                  onClick={handleSelect(budget, field.onChange)}
                  selected={field.value === budget._id}
                  iconComponent={<LocalAtm />}
                />
              ))}
              {effectiveBudgets.length > 0 && settings.poFieldEnabled && (
                <CheckoutTextField name={'poNumber'} label={'PO Number'} />
              )}
              {budget &&
                budget.balance < methodBalanceDue &&
                budget.allowOverbudget && (
                  <Alert severity="warning">
                    Insufficient funds in the selected budget. Continuing will
                    result in a negative balance.
                  </Alert>
                )}
            </Stack>
          )
        }}
      />
    </>
  )
}
