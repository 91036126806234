import { AgcNotificationRequest } from '../../models/AgcRequest'
import { aetherApi } from './aetherApi'

const advanceGiftCardApi = aetherApi.injectEndpoints({
  endpoints: (builder) => ({
    sendNotifications: builder.mutation<void, AgcNotificationRequest>({
      query: (params) => ({
        url: `api/v1/advance-gift-card/send-notifications`,
        body: params,
        method: 'POST',
      }),
    }),
  }),
})

export const { useSendNotificationsMutation } = advanceGiftCardApi
