import axios from 'axios';
import {
	X_TENANT_ID,
	CART_URL
} from '../../common/ActionUrl';
import React, { useEffect, useState, useContext } from "react";
const publicIp = require("react-public-ip");

const requestOptions = {
	headers: { 'X-TENANT-ID': X_TENANT_ID, },
}

export const updateCart = async (data, publicIpLoaded) => {
	let user = localStorage.getItem('user')
		? JSON.parse(localStorage.getItem('user'))
		: ''
	const cartSession = localStorage.getItem('-') || '';
	let userId = user?.userId ? user?.userId : null;
	const ipv4 = !!publicIpLoaded ? publicIpLoaded : await publicIp.v4();
	let res = {
		"cartItems": data,
		"userId": userId ? userId : cartSession,
		"ipAddress": ipv4
	}
	return axios.post(`${CART_URL}`, res, requestOptions);
};

export const getCartById = async( user, publicIpLoaded ) => {
	const userId = user?.userId ? user?.userId : null;
	const cartSession = localStorage.getItem('-') || '';
	const ipv4 = !!publicIpLoaded ? publicIpLoaded : await publicIp.v4();

	const param = userId ? userId : cartSession;
	if( !param ) return {
		cartItems: []
	};
	const cart = await axios.get(`${CART_URL}/${param}/${ipv4}`, requestOptions);
	if( cart?.data?.length > 0 ) {
		return {
			_id: cart?.data[0]?._id,
			cartItems: cart?.data[0]?.cartItems
		};
	}
};

export const getCartList = () => {
	return axios.get(CART_URL, requestOptions);
};


export const updateUserIdCart = async (user, publicIpLoaded) => {
	const ipAddress = !!publicIpLoaded ? publicIpLoaded : await publicIp.v4();
	const userId = user?.userId ? user?.userId : null;
	const cartId = localStorage.getItem('-');
	if( cartId && userId ) {
		let req = {
			userId,
			cartId,
			ipAddress
		}
		return axios.post(`${CART_URL}/update-userid`, req, requestOptions);
	}
};