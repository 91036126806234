import React, { useMemo } from "react"
import { useFormContext, useWatch } from "react-hook-form-mui"
import { Address, AddressContact } from "../../../models/Address"
import { AddressSource, FulfillmentQuoteMethod } from "../../../models/FulfillmentQuote"

interface AddressFormValues {
  address: Address,
  addressSource: AddressSource,
  savedAddressId: string,
  method: FulfillmentQuoteMethod,
  contact: AddressContact
}

export default function useAddressFormValues<T>({ formPrefix, names }: { formPrefix?: string, names: string[] }): T {
  const effectivePrefix = useMemo(() => {
    if (formPrefix !== undefined) {
      return `${formPrefix}.`
    } else {
      return ''
    }
  }, [formPrefix])

  const effectiveNames = useMemo(() => names.map(name => `${effectivePrefix}${name}`), [names]);

  const { getValues } = useFormContext()

  const [] = useWatch({ name: effectiveNames });
  const values = getValues(effectiveNames);

  return values as T;
}