import { useCallback, useEffect, useMemo, useState } from 'react';
import { RegionDefinition, RestrictAddressMode } from '../../models/CheckoutSession';
import {
  useLazyGetSavedAddressesQuery,
  useLazyGetShippingLocationQuery,
} from '../../redux/api/coShippingLocationApi';
import { useFormContext, useFormState } from 'react-hook-form-mui';
import { getError, getFirstValue } from '../../helpers/checkout';
import { SavedAddress, AddressContact, SavedAddressSource, Address } from '../../models/Address';
import { AddressSource } from '../../models/FulfillmentQuote';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectContactInfo, selectParams } from '../../redux/selectors/checkoutSelectors';
import _ from 'lodash';
import { aetherApi } from '../../redux/api/aetherApi';

interface AddressSelectionProps {
  contactOnly?: boolean;
  restrictAddressMode?: RestrictAddressMode;
  restrictAddressId?: string;
  lockName?: boolean;
  lockEmail?: boolean;
  requirePhone?: boolean;
  requireCompany?: boolean;
  restrictedCountries?: string[];
  regionDefinitions?: RegionDefinition[];
}

interface AddressSelectionContext {
  loadingAddresses: boolean;
  loadingRestrictedAddress: boolean;
  addresses: SavedAddress[] | null;
  restrictedAddress: SavedAddress | null;

  // Is undefined if not yet known (loading)
  // Is false if it is known there are no saved addresses
  hasSavedAddresses: boolean | undefined;
  error: string | undefined;
  isValid: boolean;
  onSelectSavedAddress: (savedAddress: SavedAddress) => void;
  onSubmit: () => void;
}

export default function useAddressSelection({
  contactOnly,
  restrictAddressMode,
  restrictAddressId,
  lockName,
  lockEmail,
  requirePhone,
  requireCompany,
  restrictedCountries,
  regionDefinitions,
}: AddressSelectionProps): AddressSelectionContext {
  const params = useAppSelector(selectParams);
  const contactInfo = useAppSelector((state) => selectContactInfo(state, params));
  const [restrictedAddress, setRestrictedAddress] = useState<SavedAddress | null>(null);

  const dispatch = useAppDispatch();

  const [
    getSavedAddresses,
    {
      data: addresses,
      isFetching: loadingAddresses,
      isUninitialized: uninitAddr,
      error: errorAddresses,
    },
  ] = useLazyGetSavedAddressesQuery();
  const [
    getShippingLocation,
    { isFetching: loadingRestrictedAddress, error: errorRestrictedAddress },
  ] = useLazyGetShippingLocationQuery();

  const { setValue, reset, trigger, getValues } = useFormContext();

  const formState = useFormState();

  const isValidSelection = useCallback(
    (address?: Address, contact?: AddressContact, savedAddressId?: string, saveAddress?: boolean, saveAddressName?: string) => {
      if (
        restrictAddressMode === RestrictAddressMode.SPECIFIC_SHIPPING_LOCATION &&
        savedAddressId !== restrictAddressId
      ) {
        return false;
      }

      if (requirePhone && !contact?.phone) {
        return false;
      }

      if (
        restrictAddressMode !== RestrictAddressMode.SPECIFIC_SHIPPING_LOCATION &&
        requireCompany &&
        !contact?.company
      ) {
        return false;
      }

      if (!contactOnly) {
        if (
          !address ||
          !address.street1 ||
          !address.city ||
          !address.state ||
          !address.postalCode ||
          !address.country
        ) {
          return false;
        }
      }

      if (!contact || !contact.firstName || !contact.lastName || !contact.email) {
        return false;
      }

      if (saveAddress && !saveAddressName) {
        return false;
      }

      return true;
    },
    [restrictAddressMode, restrictAddressId, requirePhone, requireCompany, contactOnly],
  );

  const [isValid, setIsValid] = useState(
    isValidSelection(...getValues([`address`, `contact`, `savedAddressId`, 'saveAddress', 'saveAddressName'])),
  );

  const handleSelectSavedAddress = useCallback(
    async (savedAddress: SavedAddress, submit: boolean) => {
      const contact = getValues(`contact`);
      let fallbackPhone = '';
      let fallbackCompany = '';
      let currentSavedAddress = null;
      // Persist phone and company if they are different from the saved address
      let currSavedAddressId = getValues(`savedAddressId`);
      if (currSavedAddressId) {
        currentSavedAddress = addresses?.find((a) => a.id === currSavedAddressId);
        const currPhone = getValues(`contact.phone`);
        const currCompany = getValues(`contact.company`);
        if (currentSavedAddress && currentSavedAddress.phone !== currPhone) {
          fallbackPhone = currPhone ?? '';
        }
        if (currentSavedAddress && currentSavedAddress.company !== currCompany) {
          fallbackCompany = currCompany ?? '';
        }
      }
      const updatedContact: AddressContact = {
        firstName: getFirstValue([
          savedAddress?.firstName,
          contact?.firstName,
          contactInfo?.firstName,
        ]),
        lastName: getFirstValue([savedAddress?.lastName, contact?.lastName, contactInfo?.lastName]),
        email: getFirstValue([savedAddress?.email, contact?.email, contactInfo?.email]),
        phone: getFirstValue([savedAddress?.phone, fallbackPhone]),
        company: getFirstValue([savedAddress?.company, fallbackCompany]),
      };
      reset({}, { keepValues: true });
      if (lockName) {
        updatedContact.firstName = contactInfo?.firstName;
        updatedContact.lastName = contactInfo?.lastName;
      }
      if (lockEmail) {
        updatedContact.email = contactInfo?.email;
      }
      setValue(`savedAddressId`, savedAddress.id);
      setValue(`address`, savedAddress);
      setValue(`contact`, updatedContact);
      setValue(
        `addressSource`,
        savedAddress.source === SavedAddressSource.SHIPPING_LOCATION
          ? AddressSource.SHIPPING_LOCATION
          : AddressSource.USER_ADDRESS,
      );
      if (submit) {
        await trigger([`address`, `contact`]);
        setIsValid(isValidSelection(savedAddress, updatedContact, savedAddress.id, ...getValues(['saveAddress', 'saveAddressName'])));
      }
    },
    [addresses, contactInfo?.email, contactInfo?.firstName, contactInfo?.lastName, getValues, isValidSelection, lockEmail, lockName, reset, setValue, trigger],
  );

  const onSelectSavedAddress = (savedAddress: SavedAddress) => {
    handleSelectSavedAddress(savedAddress, false);
  };

  useEffect(() => {
    if (contactOnly) return;
    const fetchRestrictedAddress = async () => {
      try {
        const address = await getShippingLocation(restrictAddressId!, true).unwrap();
        setRestrictedAddress(address);
        handleSelectSavedAddress(address, true);
      } catch (error) {}
    };

    const fetchSavedAddresses = async () => {
      try {
        await getSavedAddresses(
          {
            includeUserAddresses: restrictAddressMode !== RestrictAddressMode.SHIPPING_LOCATIONS,
          },
          true,
        ).unwrap();
      } catch (error) {
        console.error(error);
      }
    };

    if (
      restrictAddressId &&
      restrictAddressMode === RestrictAddressMode.SPECIFIC_SHIPPING_LOCATION
    ) {
      fetchRestrictedAddress();
    } else {
      fetchSavedAddresses();
    }
  }, [restrictAddressMode, restrictAddressId, contactOnly, getValues, getShippingLocation, handleSelectSavedAddress, getSavedAddresses]);

  
  const filteredAddresses = useMemo(() => {
    return addresses?.filter((a) => {
      const countryCheck = restrictedCountries === undefined || restrictedCountries.includes(a.country);
      const rd = regionDefinitions?.find((rd) => rd.countryCode === a.country);
      const regionCheck = rd?.regions === undefined || rd?.regions?.includes(a.state);
      return countryCheck && regionCheck;
    });
  }, [addresses, regionDefinitions, restrictedCountries]);

  useEffect(() => {
    if (filteredAddresses === undefined) {
      return;
    }
    // Auto select default address
    if (Object.keys(getValues(`address`) ?? {}).length === 0) {
      const defaultAddress = filteredAddresses?.find((a) => a.isDefault);
      if (defaultAddress) {
        handleSelectSavedAddress(defaultAddress, true);
      }
    } else if (restrictAddressMode !== RestrictAddressMode.SPECIFIC_SHIPPING_LOCATION) {
      // If the saved address is no longer valid, reset the form
      const savedAddressId = getValues(`savedAddressId`);
      if (savedAddressId) {
        const address = filteredAddresses?.find((a) => a.id === savedAddressId);
        if (!address) {
          setValue(`savedAddressId`, undefined);
          setValue(`addressSource`, undefined);
          setValue('address', undefined);
          setIsValid(false);
        }
      }
    }
  }, [filteredAddresses, getValues, handleSelectSavedAddress, restrictAddressMode, setValue]);

  const onSubmit = async () => {
    await trigger([`address`, `contact`, 'saveAddressName']);
    setIsValid(isValidSelection(...getValues([`address`, `contact`, `savedAddressId`, 'saveAddress', 'saveAddressName'])));
    dispatch(aetherApi.util.invalidateTags(['FulfillmentQuote']));
  };

  const addressDirty = useMemo(() => _.get(formState.dirtyFields, 'address'), [formState]);

  useEffect(() => {
    if (addressDirty) {
      setValue(`savedAddressId`, undefined);
      setValue(`address.locationName`, undefined);
      setValue(`addressSource`, AddressSource.USER_SUPPLIED);
    }
  }, [addressDirty, setValue])

  const hasSavedAddresses = useMemo(() => {
    if (contactOnly || restrictAddressMode === RestrictAddressMode.SPECIFIC_SHIPPING_LOCATION) {
      return false;
    }
    if (uninitAddr || loadingAddresses) {
      return undefined;
    }
    return (filteredAddresses?.length ?? 0) > 0;
  }, [contactOnly, restrictAddressMode, uninitAddr, loadingAddresses, filteredAddresses?.length]);

  return {
    loadingAddresses: loadingAddresses,
    loadingRestrictedAddress: loadingRestrictedAddress,
    restrictedAddress,
    hasSavedAddresses,
    addresses: filteredAddresses ?? null,
    error: getError(errorAddresses) ?? getError(errorRestrictedAddress),
    isValid,
    onSelectSavedAddress,
    onSubmit,
  };
}
