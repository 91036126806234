export enum BudgetType {
  USER = 'user',
  USER_GROUP = 'userGroup',
}

export interface UserBudget {
  _id: string
  plan: {
    id: string
    name: string
  }
  type: BudgetType
  ownerName: string
  balance: number
  allowOverbudget: boolean
  allowMultiplePayments: boolean
}

export interface BudgetHistoryFilters {
  description?: string
  types: BudgetHistoryEventType[]
  eventTypes: BalanceUpdateType[]
}

export interface BudgetHistoryUser {
  id: string
  name: string
  email: string
  username: string
}

export interface BudgetHistory {
  _id: string
  budgetIds: string[]
  planWide: boolean
  planId: string
  user?: BudgetHistoryUser
  order?: {
    id: string
    no: string
  }
  type: BalanceUpdateType
  eventType: BudgetHistoryEventType
  resetAmount?: number
  adjustmentAmount?: number
  description: string
  timestamp: string
}

export enum BalanceUpdateType {
  ADJUSTMENT = 'adjustment',
  RESET = 'reset',
}

export enum BudgetHistoryEventType {
  CREATION = 'creation',
  AUTO_ADJUSTMENT = 'autoAdjustment',
  AUTO_RESET = 'autoReset',
  MANUAL_ADJUSTMENT = 'manualAdjustment',
  MANUAL_RESET = 'manualReset',
  PAYMENT = 'payment',
  REFUND = 'refund',
}

export const BUDGET_HISTORY_EVENT_TYPE_MAP = {
  [BudgetHistoryEventType.CREATION]: 'Creation',
  [BudgetHistoryEventType.AUTO_ADJUSTMENT]: 'Auto Adjustment',
  [BudgetHistoryEventType.AUTO_RESET]: 'Auto Reset',
  [BudgetHistoryEventType.MANUAL_ADJUSTMENT]: 'Manual Adjustment',
  [BudgetHistoryEventType.MANUAL_RESET]: 'Manual Reset',
  [BudgetHistoryEventType.PAYMENT]: 'Payment',
  [BudgetHistoryEventType.REFUND]: 'Refund',
}
