import React, { useEffect, useMemo } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import {
  selectContactInfo,
  selectFulfillmentRegionRestrictions,
  selectParams,
  selectSettings,
} from '../../../redux/selectors/checkoutSelectors';
import { FulfillmentMethodType } from '../../../models/FulfillmentMethod';
import AddressSelector from '../AddressSelector';
import FulfillmentQuoteContent from './FulfillmentQuoteContent';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { Fulfillment } from '../../../models/Order';

interface ShippingMethodContentProps {
  index: number;
  onSubmit: (data: Fulfillment) => Promise<void>;
  error?: string;
  submitLoading: boolean;
  fulfillment?: Fulfillment;
}

export default function ShippingMethodContent({
  index,
  onSubmit,
  error,
  submitLoading,
  fulfillment,
}: ShippingMethodContentProps) {
  const params = useAppSelector(selectParams);
  const settings = useAppSelector(selectSettings);
  const contactInfo = useAppSelector((state) => selectContactInfo(state, params));
  const { countries, regionDefinitions } = useAppSelector(selectFulfillmentRegionRestrictions);

  const formContext = useForm<Fulfillment>({
    defaultValues: useMemo(() => {
      if (fulfillment) {
        return fulfillment;
      } else {
        return { contact: contactInfo };
      }
    }, [fulfillment, contactInfo]),
  });

  const { reset } = formContext;

  useEffect(() => {
    if (fulfillment) {
      return reset(fulfillment);
    } else {
      return reset({ contact: contactInfo });
    }
  }, [contactInfo, formContext, fulfillment, reset]);

  const { handleSubmit } = formContext;

  return (
    <FormContainer formContext={formContext} handleSubmit={handleSubmit(onSubmit)}>
      <AddressSelector
        label="Shipping"
        requirePhone={settings.isFulfillmentPhoneRequired}
        requireCompany={settings.isFulfillmentCompanyRequired}
        companyRename={
          settings.isFulfillmentCompanyRenameEnabled
            ? settings.fulfillmentCompanyRenameText
            : undefined
        }
        restrictAddressMode={settings.restrictShippingAddressMode}
        restrictAddressId={settings.restrictShippingAddressId}
        lockName={settings.isFulfillmentNameLocked}
        lockEmail={settings.isFulfillmentEmailLocked}
        countries={countries}
        regionDefinitions={regionDefinitions}
      >
        <FulfillmentQuoteContent
          index={index}
          label="Shipping"
          type={FulfillmentMethodType.SHIPPING}
          error={error}
          submitLoading={submitLoading}
        />
      </AddressSelector>
    </FormContainer>
  );
}
