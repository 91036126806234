import React, { useEffect, useMemo } from 'react';
import { CheckoutCostCenter, CostCenter } from '../../../models/CostCenter';
import { AutocompleteElement, useFormContext } from 'react-hook-form-mui';
import { AutocompleteInputChangeReason, ListItemText, Stack } from '@mui/material';
import _ from 'lodash';
import CheckoutTextField from '../CheckoutTextField';
import useFormValue from '../../../common/useFormValue';
import { CustomerDetailsForm } from '../../../models/CustomerDetailsForm';
import { useDebounceCallback } from 'usehooks-ts';

interface CostCenterFieldProps {
  label: string;
  required: boolean;
  allowCustom: boolean;
  onQueryChange: (search: string) => void;
  queryOptions: CostCenter[];
  userOptions: CostCenter[];
  isLoadingQuery: boolean;
  isSecondary: boolean;
  defaultId?: string;
  isTertiary: boolean;
}

export default function CostCenterField({
  label,
  required,
  allowCustom,
  onQueryChange,
  queryOptions,
  userOptions,
  isLoadingQuery,
  isSecondary,
  defaultId,
  isTertiary,
}: CostCenterFieldProps) {
  const { reset, getValues, setValue } = useFormContext<CustomerDetailsForm>();

  const name = isTertiary ? 'tertiaryCostCenter' : isSecondary ? 'secondaryCostCenter' : 'primaryCostCenter';

  const onInputChange = useDebounceCallback(async (text) => {
    onQueryChange(text);
  }, 500);

  const options = useMemo(
    () => [
      ...(allowCustom
        ? [
            {
              costName: '',
              code: '',
              _id: undefined,
            },
          ]
        : []),
      ..._.uniqBy([...userOptions, ...queryOptions], '_id'),
    ],
    [allowCustom, userOptions, queryOptions],
  );

  const handleChange = (
    _: React.SyntheticEvent,
    newValue: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    if (reason === 'input' || reason === 'clear' || reason === 'blur') {
      onInputChange(newValue);
    }
  };

  const formContext = useFormContext<CustomerDetailsForm>();

  const costCenter = useFormValue<CustomerDetailsForm, CheckoutCostCenter>({
    name,
    formContext,
  });

  useEffect(() => {
    if (defaultId) {
      const defaultCostCenter = options.find((option) => option._id === defaultId);
      if (defaultCostCenter && getValues(name) === undefined) {
        reset((pv) => ({
          ...pv,
          [name]: defaultCostCenter,
        }));
        return;
      }
    }
    if (getValues(name) === undefined && options.length === 1) {
      setValue(name, options[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack direction={'column'} spacing={2}>
      <AutocompleteElement
        name={name}
        label={label}
        required={required}
        rules={{
          required: required ? `${label} is required` : undefined,
        }}
        autocompleteProps={{
          size: 'small',
          getOptionLabel: (option) =>
            typeof option === 'string' ? option : !option._id ? 'Custom Entry' : option.costName,
          fullWidth: true,
          onInputChange: handleChange,
          isOptionEqualToValue: (option, value) => value && option?._id === value?._id,
          filterOptions: (options, state) =>
            options.filter(
              (option) =>
                option?.costName?.toLowerCase()?.includes(state.inputValue.toLowerCase()) ||
                option?.code?.toLowerCase()?.includes(state.inputValue.toLowerCase()) ||
                !option._id,
            ),
          renderOption: (props, option: string | CostCenter | CheckoutCostCenter) =>
            typeof option === 'string' ? (
              option
            ) : (
              <li {...props}>
                <ListItemText
                  primary={!option._id ? 'Custom entry' : option.costName}
                  secondary={option._id && option.code}
                />
              </li>
            ),
        }}
        options={options}
        loading={isLoadingQuery}
      />
      {costCenter && !costCenter?._id && (
        <>
          <CheckoutTextField
            name={`${name}Name`}
            label="Name"
            rules={{ required: 'Name is required' }}
          />
          <CheckoutTextField
            name={`${name}Code`}
            label="Code"
            rules={{ required: 'Code is required' }}
          />
        </>
      )}
    </Stack>
  );
}
