import React, { useContext, useState } from 'react'
import {
  Button,
  Card,
  CircularProgress,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from '@mui/material'
import { useGetActiveBudgetsQuery } from '../../redux/api/budgetApi'
import { useAppSelector } from '../../redux/hooks'
import { styled } from '@material-ui/core'
import { newTheme } from '../../components/theme/theme'
import { AuthContext } from '../../common/Auth'
import { useThemeSettings } from '../../layouts/state/useThemeSettings'
import { Group, Person } from '@mui/icons-material'
import BudgetHistoryDialog from './BudgetHistoryDialog'
import { formatCurrency } from '../../helpers/formattingHelpers'
import MainBreadCrumb from '../../wrappers/breadcrumb/MainBreadCrumb'

const StyledTable = styled(Table)({
  '& .MuiTableRow-root:last-child': {
    '& td': {
      borderBottom: 0,
    },
  },
})

export default function BudgetPage() {
  const { paymentMethodsSettings } = useContext(AuthContext)

  const currency = useAppSelector((state) => state.currencyData)
  const { settingData } = useContext(AuthContext)
  const { themedefaultColor, themedefaultTextColor, defaultFont } = useThemeSettings({
    settingData,
  })
  const [openHistoryId, setOpenHistoryId] = useState<string | null>(null)

  const handleOpenHistory = (id: string) => () => {
    setOpenHistoryId(id)
  }

  const handleCloseHistory = () => {
    setOpenHistoryId(null)
  }

  const {
    data: budgets,
    isLoading,
    error,
    isSuccess,
  } = useGetActiveBudgetsQuery()

  const budgetName =
    (paymentMethodsSettings as any[])?.find(
      (s) => s.settingsName === 'budgetSettings',
    )?.values?.[0]?.budgetNameChange ?? 'Budgets'

  return (
    <ThemeProvider
      theme={newTheme({ themedefaultColor, themedefaultTextColor, fontFamily: defaultFont })}
    >
      <MainBreadCrumb />
      {openHistoryId && (
        <BudgetHistoryDialog
          key={openHistoryId}
          id={openHistoryId}
          open={!!openHistoryId}
          onClose={handleCloseHistory}
        />
      )}
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          {budgetName}
        </Typography>
        {isSuccess && budgets?.length === 0 && (
          <Typography variant="body1">No {budgetName} found.</Typography>
        )}
        {isLoading && <CircularProgress />}
        {isSuccess && budgets?.length > 0 && (
          <Card variant="outlined">
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Balance</TableCell>
                  <TableCell align="right">History</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {budgets.map((budget) => (
                  <TableRow>
                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        {budget.type === 'user' ? <Person /> : <Group />}
                        <Typography variant="body1">
                          {budget.type === 'user' ? 'User' : 'Group'}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {budget.plan.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body1">
                        {formatCurrency(
                          currency.currencySymbol,
                          budget.balance,
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="outlined"
                        onClick={handleOpenHistory(budget._id)}
                      >
                        View History
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </Card>
        )}
      </Container>
    </ThemeProvider>
  )
}
