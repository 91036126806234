import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  Input,
  Pagination,
  Stack,
  Typography,
} from '@mui/material';
import { useAppSelector } from '../../../redux/hooks';
import {
  selectGiftCardItems,
  selectOrder,
  selectParams,
} from '../../../redux/selectors/checkoutSelectors';
import GiftCardRow from './GiftCardRow';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { AgcFullDetail, AgcNotificationRequest } from '../../../models/AgcRequest';
import Papa from 'papaparse';
import _ from 'lodash';
import { useSendNotificationsMutation } from '../../../redux/api/advanceGiftCardApi';
import { _useToasts } from '../../../common/_useToasts';
import { CloudDownload, CloudUpload } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

interface GiftCardDialogProps {
  open: boolean;
  onHold: boolean;
  onClose: () => void;
}

const CSV_COLUMNS = [
  {
    id: 'productName',
    label: 'Product Name',
  },
  {
    id: 'itemId',
    label: 'Item ID',
  },
  {
    id: 'quantityIndex',
    label: 'Quantity Index',
  },
  {
    id: 'amount',
    label: 'Amount',
  },
  {
    id: 'code',
    label: 'Code',
  },
  {
    id: 'email',
    label: 'Email',
  },
];

const PAGE_MAX = 5;

export default function GiftCardDialog({ open, onHold, onClose }: GiftCardDialogProps) {
  const { _addToast } = _useToasts();
  const params = useAppSelector(selectParams);
  const order = useAppSelector((state) => selectOrder(state, params));
  const giftCardItems = useAppSelector((state) => selectGiftCardItems(state, params));

  const [page, setPage] = useState<number>(1);

  const [sendNotifications, { isLoading }] = useSendNotificationsMutation();

  const onSubmit = async (data: AgcNotificationRequest) => {
    try {
      await sendNotifications(data).unwrap();
      _addToast(onHold ? 'Gift cards saved successfully' : 'Gift cards sent successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
      onClose();
    } catch (error) {
      console.error(error);
      _addToast('Failed to send gift cards', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const rows: AgcFullDetail[] = useMemo(
    () =>
      giftCardItems.flatMap((i) =>
        [...Array(i.quantity)].map((_, index) => ({
          itemId: i.id,
          quantityIndex: index,
          email: order?.shipping?.storeShippingContactEmail ?? '',
          imageUrl: i.rows[0]?.image ?? i.quoteCustomImage[0],
          productName: i.product.name,
          amount: i.aetherData.displayPrice,
          code: i.aetherData.giftCards?.[index]?.code?.replace(/(.{4})(?!$)/g, '$1-') ?? 'N/A',
        })),
      ),
    [giftCardItems, order],
  );

  const pageCount = Math.ceil(rows.length / PAGE_MAX);

  const formContext = useForm<AgcNotificationRequest>({
    defaultValues: useMemo(
      () => ({
        orderId: order?.id,
        notificationDetails: rows,
      }),
      [order, rows],
    ),
  });

  const { reset, handleSubmit } = formContext;

  useEffect(() => {
    reset({
      orderId: order?.id,
      notificationDetails: rows,
    });
  }, [formContext, order, reset, rows]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] ?? null;
    const reader = new FileReader();
    if (file) {
      reader.onload = (e) => {
        const content = e.target?.result;
        const text = content as string;
        const parseResults = Papa.parse<Record<string, any>>(text, {
          header: true,
          skipEmptyLines: 'greedy',
          transformHeader: (header) => CSV_COLUMNS.find((c) => c.label === header)?.id || header,
          transform: (value, header) => {
            if (header === 'amount') {
              return Number(value);
            }
            if (header === 'quantityIndex') {
              return Number(value);
            }
            return value;
          },
        });
        const updatedRows = _.cloneDeep(rows);
        for (const row of parseResults.data) {
          const index = updatedRows.findIndex(
            (r) => r.itemId === row.itemId && r.quantityIndex === row.quantityIndex - 1,
          );
          if (index >= 0) {
            updatedRows[index].email = row.email;
          }
        }
        reset({
          orderId: order?.id,
          notificationDetails: updatedRows,
        });
      };
      reader.readAsText(file);
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  const csvString = useMemo(() => {
    const getCsv = () => {
      const formattedRows: Record<string, any>[] = [];
      for (const row of rows) {
        const data: Record<string, any> = {
          productName: row.productName,
          itemId: row.itemId,
          quantityIndex: row.quantityIndex + 1,
          amount: row.amount,
          code: row.code,
          email: row.email,
        };
        formattedRows.push(data);
      }
      const headerRow = Papa.unparse({
        fields: CSV_COLUMNS.map((c) => c.label),
        data: [],
      });
      const mainRows = Papa.unparse<Record<string, any>>(formattedRows, {
        header: false,
        columns: CSV_COLUMNS.map((c) => c.id),
      });
      return headerRow + mainRows;
    };
    return `data:text/csv;charset=utf-8,${encodeURIComponent(getCsv())}`;
  }, [rows]);

  return (
    <FormContainer formContext={formContext} handleSubmit={handleSubmit(onSubmit)}>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
        <DialogTitle display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          Send Gift Cards
          <Stack direction={'row'} spacing={2}>
            <Button
              startIcon={<CloudDownload />}
              color="primary"
              variant="outlined"
              type="submit"
              download={'notification-template.csv'}
              href={csvString}
            >
              Download CSV Template
            </Button>
            <Button
              startIcon={<CloudUpload />}
              component="label"
              role={undefined}
              tabIndex={-1}
              variant="outlined"
            >
              Upload CSV Template
              <Input
                type="file"
                hidden
                onChange={handleFileUpload}
                value={''}
                inputProps={{ accept: '.csv' }}
              />
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <Stack spacing={2} justifyContent={'center'}>
            {onHold && (
              <Alert severity="info">
                This order needs to be approved. Gift cards will not be sent or usable until the
                order is approved.
              </Alert>
            )}
            <Grid2 container spacing={4}>
              <Grid2 size={3}>
                <Typography variant="body1">Product</Typography>
              </Grid2>
              <Grid2 size={1}>
                <Typography variant="body1" align={'right'}>
                  Index
                </Typography>
              </Grid2>
              <Grid2 size={2}>
                <Typography variant="body1" align={'right'}>
                  Amount
                </Typography>
              </Grid2>
              <Grid2 size={2}>
                <Typography variant="body1">Code</Typography>
              </Grid2>
              <Grid2 size={4}>
                <Typography variant="body1">Email</Typography>
              </Grid2>
            </Grid2>
            {rows.slice((page - 1) * PAGE_MAX, (page - 1) * PAGE_MAX + PAGE_MAX).map((row) => (
              <GiftCardRow key={row.itemId + row.quantityIndex} item={row} index={row.quantityIndex} />
            ))}
            <Stack direction={'row'} justifyContent={'center'}>
              <Pagination
                page={page}
                onChange={handlePageChange}
                count={pageCount}
                color="primary"
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} size="large">
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            size="large"
            onClick={handleSubmit(onSubmit)}
          >
            Send
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </FormContainer>
  );
}
