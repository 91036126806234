import React, { useState, useEffect, useContext } from 'react'
import { Button } from '@material-ui/core'
import { AuthContext } from '../../../common/Auth'
import { USERTYPES } from '../../../constant/userType'
import { filter } from 'lodash'
const DecorationSelectbox = ({
  itemsRows,
  handleCheckbox,
  locID,
  handleCheckboxDefault,
  clearDecoSelection,
  parentKey,
  decoExist,
  tempDecorationLocations,
  artworkListById,
  setAddnewart,
  setAddnewartId,
  allowCustomDecoration,
  searchTextArtwork,
  searchArtworkName,
  isSearchArtwork,
  productAllowOneDecoration,
  productSettingsAllowOneDecoration,
  productRenameLocationNameLabel,
  productSettingsRenameLocationNameLabel,
  productRenameLocationNamePrompt,
  productSettingsRenameLocationNamePrompt,
  productSettingsShowLocationNameLabel,
  productShowLocationNameLabel,
  artworkSearchPlaceholderText,
  displayDrop,
  allowToUpCustArtwork,
  showDecoration
}) => {
  const [remainDecoLocation, setRemainDecoLocation] = useState(
    tempDecorationLocations,
  )

  const [tempVal, setTempVal] = useState(0)
  const { userType } = useContext(AuthContext)
  const [allowOneDec, setallowOneDec] = useState("0")
  const [checkLocationVal, setcheckLocationVal] = useState('')
  const [locationLabel, setLocationLabel] = useState('')
  const [locationPrompt, setLocationPrompt] = useState('')
  const [showLocationLabel, setShowLocationLabel] = useState(true)

  useEffect(() => {
    let newLocation = []
    tempDecorationLocations &&
      tempDecorationLocations.map((val, index) => {
        let artworkSelected = null
        let checkLocation =
          decoExist && decoExist.find((d) => d.locationId == val.id && d.variationUniqID && d.variationUniqID != "" && d.variationUniqID != undefined && allowOneDec !== "2")
        if (checkLocation == undefined || checkLocation == '') {
          newLocation.push(val)
        }
      })
    setRemainDecoLocation(newLocation)

    setTempVal(tempVal + 1)
  }, [decoExist, tempDecorationLocations, itemsRows])

  useEffect(() => {
      if(locID !== '') {
        let checkLocationVala = decoExist && decoExist.find((d) => d.locationId == locID && d.variationUniqID && d.variationUniqID != "" && d.variationUniqID != undefined)
        if (checkLocationVala !== undefined || checkLocationVala !== '') {
          setcheckLocationVal(checkLocationVala)
        }
        setTempVal(tempVal + 1)
      } else {
        setcheckLocationVal('')
        setTempVal(tempVal + 1)
      }
  }, [decoExist, tempDecorationLocations, itemsRows, locID])

  useEffect(() => {
    if (remainDecoLocation?.length === 1) {
      if (decoExist?.length > 0 && remainDecoLocation?.[0]?.id) {
        let findIfexist =
            decoExist &&
            decoExist.filter(
                (decorationArray) =>
                    decorationArray.locationId === remainDecoLocation?.[0]?.id,
            )
        if (findIfexist?.length > 0) {
        } else {
          if (remainDecoLocation?.length === 1) {
            let locationCount = artworkListById.filter(
                (val) =>
                    val.artworkLocation === remainDecoLocation?.[0]?.locationName,
            )

            if (locationCount > 1) {
            } else {
              handleCheckboxDefault(remainDecoLocation?.[0]?.id, parentKey)
            }
          }
        }
      } else {
        handleCheckboxDefault(remainDecoLocation?.[0]?.id, parentKey)
      }
    }
  }, [remainDecoLocation]);



  useEffect(() => {
    if(!!productRenameLocationNamePrompt) {
      setLocationPrompt(productRenameLocationNamePrompt)
    } else if(!!productSettingsRenameLocationNamePrompt) {
      setLocationPrompt(productSettingsRenameLocationNamePrompt)
    } else {
      setLocationPrompt('Select Location')
    }
  },[productSettingsRenameLocationNamePrompt, productRenameLocationNamePrompt])

  useEffect(() => {
    if(productShowLocationNameLabel === false || productShowLocationNameLabel === true) {
      setShowLocationLabel(productShowLocationNameLabel)
    } else if(productSettingsShowLocationNameLabel === false || productSettingsShowLocationNameLabel === true) {
      setShowLocationLabel(productSettingsShowLocationNameLabel)
    }
  },[productShowLocationNameLabel, productSettingsShowLocationNameLabel])

  useEffect(() => {
    if(!!productRenameLocationNameLabel) {
      setLocationLabel(productRenameLocationNameLabel)
    } else if(!!productSettingsRenameLocationNameLabel) {
      setLocationLabel(productSettingsRenameLocationNameLabel)
    } else {
      setLocationLabel('Select Location')
    }
  },[productSettingsRenameLocationNameLabel, productRenameLocationNameLabel])

  useEffect(() => {
    if(!!productAllowOneDecoration && productAllowOneDecoration !== " ")
    {
        setallowOneDec(productAllowOneDecoration)
    }
    else if (!!productSettingsAllowOneDecoration && productSettingsAllowOneDecoration !== " ")
    {
        setallowOneDec(productSettingsAllowOneDecoration)
    }
  },[productAllowOneDecoration, productSettingsAllowOneDecoration])

  useEffect(() => {
    if(tempDecorationLocations.length > 1) {
      displayDrop(parentKey)
    }
  },[tempDecorationLocations])

  return (
    <div>
    {(!(!!checkLocationVal && allowOneDec === "1")) && (
     <>
      {(remainDecoLocation.length > 0 || tempDecorationLocations?.length === 1) && (
        <>
          <div>
            {showLocationLabel && remainDecoLocation.length > 0 && <label className='ml-2'>
              <b>{`${locationLabel}:`}</b>
            </label>}
            {locID != '' && (
              <Button
                variant="outlined"
                onClick={() => {
                  clearDecoSelection()
                }}
                className="float-right"
              >
                Clear selection
              </Button>
            )}
            {
              showDecoration ? <>
                {(remainDecoLocation?.length > 1 || (decoExist?.[0]?.artworkID && remainDecoLocation?.length > 1))  ?
                    <select
                        className={showLocationLabel ? "ml-2" : ''}
                        style={{ width: 'auto' }}
                        onChange={(e) => {
                          handleCheckbox(e, parentKey, '', allowOneDec)
                        }}
                        value={locID}
                    >
                      <option value="">{locationPrompt}</option>
                      {remainDecoLocation &&
                          remainDecoLocation?.map((locationArray, key) => {
                            return (
                                <option key={'lta' + key} value={`${locationArray.id}-${parentKey}`}>
                                  {locationArray.locationName}
                                </option>
                            )
                          })}
                    </select> :
                    ( <>
                      { showLocationLabel && remainDecoLocation?.length > 0 && <label className="ml-2">
                        { remainDecoLocation?.[0]?.locationName }
                      </label> }
                    </> )
                }</> : <></>
            }
          </div>
        </>
      )}
     </>
    )}
      <div className='row clear mt-20'>
        {(userType && (userType === USERTYPES.ADMIN || userType === USERTYPES.USER) || allowToUpCustArtwork === "2") && allowCustomDecoration && (
          <div className="col-md-12 AddNewart mt-2">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setAddnewart && setAddnewart(true);
                setAddnewartId(parentKey);
              }}
            >
              Add New Art
            </Button>
          </div>
        )}
			  {( remainDecoLocation.length > 0 && !!locID && isSearchArtwork) &&
				  <div className="col-md-6 mt-2">
					  <input
						  type="text"
						  className="inputStyle"
						  value={searchTextArtwork}
						  name="searchTextArtwork"
						  placeholder={(artworkSearchPlaceholderText === undefined ? "Search Artwork…" : (!!artworkSearchPlaceholderText ? artworkSearchPlaceholderText : ''))}
						  onChange={(e) => searchArtworkName(e)}
					  />
				  </div>
			  }
      </div>
    </div>
  )
}
export default DecorationSelectbox
