import { Button, Stack, Typography } from '@mui/material'
import React, { useCallback, useContext, useEffect } from 'react'
import { PointsPaymentSettings } from '../../../models/PaymentMethodSettings'
import { AuthContext } from '../../../common/Auth'
import { TextFieldElement, useFormContext, useWatch } from 'react-hook-form-mui'
import { formatNumber, getPoints } from '../../../helpers/formattingHelpers'

export interface PointsPaymentMethodFormProps {
  settings: PointsPaymentSettings
  index: number | null
  methodBalanceDue: number
}

export default function PointsForm({
  settings,
  methodBalanceDue,
}: PointsPaymentMethodFormProps) {
  const { userPoints } = useContext(AuthContext)

  const { setValue, getValues, trigger } = useFormContext()

  const points = useWatch({ name: 'points' })

  useEffect(() => {
    trigger('points')
    setValue('maximumAmount', points * (settings.pointsValue ?? 1))
  }, [points, setValue, settings, trigger])

  const pointsBalanceDue = getPoints(
    methodBalanceDue ?? 0,
    settings.pointsValue ?? 1,
    settings.showDecimals ?? false,
  )

  const handleUseAll = useCallback(() => {
    const maxPoints = settings.maxPointsPerOrder ?? Infinity
    const amount = Math.min(
      pointsBalanceDue,
      settings.showDecimals
        ? Number(userPoints.points)
        : Math.floor(Number(userPoints.points)),
      settings.showDecimals ? maxPoints : Math.floor(maxPoints),
    )
    setValue('points', amount, { shouldValidate: true })
  }, [pointsBalanceDue, setValue, settings, userPoints])

  useEffect(() => {
    if (getValues('points') === 0) {
      handleUseAll()
    }
  }, [handleUseAll, getValues])

  return (
    <Stack direction={'column'} spacing={2}>
      <Typography variant="h6">Redeem {settings.pointsLabelPlural}</Typography>
      {Number(userPoints.points) === 0 && (
        <Typography variant={'body1'}>
          You do not have any {settings.pointsLabelPlural} to redeem
        </Typography>
      )}
      {Number(userPoints.points) > 0 && (
        <Typography variant={'body1'}>
          Available {settings.pointsLabelPlural}:{' '}
          {formatNumber(
            settings.showDecimals
              ? Number(userPoints.points)
              : Math.floor(Number(userPoints.points)) ?? 0,
          )}
        </Typography>
      )}
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        <TextFieldElement
          name={'points'}
          label={'Points'}
          size="small"
          autoComplete="off"
          type={settings.showDecimals ? 'number' : 'text'}
          rules={{
            required: true,
            min: 0,
            validate: {
              maxPoints: (value) =>
                value > (settings.maxPointsPerOrder ?? Infinity)
                  ? `Maximum of ${settings.maxPointsPerOrder ?? Infinity} ${
                      settings.pointsLabelPlural
                    } per order`
                  : undefined,
              max: (value) =>
                value > Number(userPoints.points)
                  ? `Insufficient ${settings.pointsLabelPlural}`
                  : undefined,
              overPayment: (value) =>
                value > pointsBalanceDue ? `Over payment` : undefined,
            },
          }}
          transform={{
            input: (value) => value,
            output: (event) => {
              return settings.showDecimals
                ? Number(event?.target.value)
                : Number(event?.target.value?.replaceAll(/\D/g, '')) ?? 0
            },
          }}
        />
        <Button variant="outlined" onClick={handleUseAll}>
          Use All Available
        </Button>
      </Stack>
    </Stack>
  )
}
