import { useMemo } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { selectCartItems } from '../../../redux/selectors/ cartSelectors';

export interface CartRow {
  size: string;
  color: string;
  quantity: number;
  price: string;
  cost: string;
  product: {
    sku: string;
  };
  image: string;
}

export interface CartItem {
  currentOrder: CartRow[][];
  product: {
    id: string;
  };
}

interface CartQuantityProps {
  productId: string;
  multiSkuEnabled: boolean;
}

export default function useCartQuantity({
  productId,
  multiSkuEnabled,
}: CartQuantityProps) {
  const cartItems: CartItem[] = useAppSelector(selectCartItems);

  const total = useMemo(() => {
    if (multiSkuEnabled) {
      let filteredItems: CartRow[] = [];
  
      cartItems.forEach((cartItem) => {
        if (cartItem.product.id === productId) {
          cartItem.currentOrder.forEach((order) => {
            filteredItems.push(...order);
          });
        }
      });
  
      return filteredItems.reduce((acc, row) => acc + Number(row.quantity ?? 0), 0);
    } else {
      return 0;
    }
  }, [cartItems, productId, multiSkuEnabled]);

  return total;
}
