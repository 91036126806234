import React from 'react'
import { CircularProgress, Stack, styled, Typography } from '@mui/material'

const WholePageContainer = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  zIndex: 9999,
  backdropFilter: 'blur(2px)',
})

export default function WholePageSpinner({ text, warningText }: { text?: string, warningText?: string }) {
  return (
    <WholePageContainer>
      <Stack direction={'column'} spacing={2} alignItems={'center'}>
        <CircularProgress size={'5rem'} thickness={5} />
        {text && <Typography textAlign={'center'} variant="h6">{text}</Typography>}
        {warningText && <Typography textAlign={'center'} variant="body1">{warningText}</Typography>}
      </Stack>
    </WholePageContainer>
  )
}
