import React, { useState } from 'react';
import { Alert, Grid2, Skeleton } from '@mui/material';
import { CostCenterSettings } from '../../../models/CheckoutSession';
import { useContext } from 'react';
import { AuthContext } from '../../../common/Auth';
import { useGetUserCostCentersQuery } from '../../../redux/api/userApi';
import { useSearchCostCentersQuery } from '../../../redux/api/costCenterApi';
import { skipToken } from '@reduxjs/toolkit/query';
import _ from 'lodash';
import CostCenterField from './CostCenterField';
import { useFormContext } from 'react-hook-form';
import { getError } from '../../../helpers/checkout';

export default function CostCenterSection({ settings }: { settings: CostCenterSettings }) {
  const { getValues } = useFormContext();

  const [primaryQuery, setPrimaryQuery] = useState<string>(
    getValues('primaryCostCenter')?.costName || '',
  );

  const [secondaryQuery, setSecondaryQuery] = useState<string>(
    getValues('secondaryCostCenter')?.costName || '',
  );

  const [tertiaryQuery, setTertiaryQuery] = useState<string>(
    getValues('tertiaryCostCenter')?.costName || '',
  );

  const { user } = useContext(AuthContext);
  const {
    data: userCostCenterRes,
    isLoading: isLoadingUser,
    isSuccess: userSuccess,
    error: userError,
  } = useGetUserCostCentersQuery(user ? user.id : skipToken, {
    skip: !user,
  });

  const userPrimaryEmpty = !userCostCenterRes?.primary || userCostCenterRes?.primary.length === 0;
  const userSecondaryEmpty =
    !userCostCenterRes?.secondary || userCostCenterRes?.secondary.length === 0;
  const userTertiaryEmpty =
    !userCostCenterRes?.tertiary || userCostCenterRes?.tertiary.length === 0;


  const skipPrimary =
    !settings.primaryEnabled ||
    (user && !settings.showAllPrimaryEnabled && !userPrimaryEmpty) ||
    isLoadingUser;
  const skipSecondary =
    !settings.secondaryEnabled ||
    (user && !settings.showAllSecondaryEnabled && !userSecondaryEmpty) ||
    isLoadingUser;
  const skipTertiary =
    !settings.tertiaryEnabled ||
    (user && !settings.showAllTertiaryEnabled && !userTertiaryEmpty) ||
    isLoadingUser;

  const {
    data: primaryQueryCostCenters,
    isFetching: isLoadingPrimary,
    error: primaryQueryError,
  } = useSearchCostCentersQuery(
    {
      query: primaryQuery,
      isPrimary: true,
      limit: 10,
    },
    {
      skip: skipPrimary,
    },
  );
  const {
    data: secondaryQueryCostCenters,
    isFetching: isLoadingSecondary,
    error: secondaryQueryError,
  } = useSearchCostCentersQuery(
    {
      query: secondaryQuery,
      isSecondary: true,
      limit: 10,
    },
    {
      skip: skipSecondary,
    },
  );

  const {
    data: tertiaryQueryCostCenters,
    isFetching: isLoadingTertiary,
    error: tertiaryQueryError,
  } = useSearchCostCentersQuery(
    {
      query: tertiaryQuery,
      isTertiary: true,
      limit: 10,
    },
    {
      skip: skipTertiary,
    },
  );

  const userReady = userSuccess || !user || userError;
  const primaryReady =
    (primaryQueryCostCenters !== undefined || skipPrimary || primaryQueryError) && userReady;
  const secondaryReady =
    (secondaryQueryCostCenters !== undefined || skipSecondary || secondaryQueryError) && userReady;
  const tertiaryReady =
    (tertiaryQueryCostCenters !== undefined || skipTertiary || tertiaryQueryError) && userReady;

  return (
    <>
      {userError && (
        <Alert severity="error">Error loading user cost centers: {getError(userError)}</Alert>
      )}
      {primaryQueryError && (
        <Alert severity="error">
          Error searching primary cost centers: {getError(primaryQueryError)}
        </Alert>
      )}
      {secondaryQueryError && (
        <Alert severity="error">
          Error searching secondary cost centers: {getError(secondaryQueryError)}
        </Alert>
      )}
      <Grid2 container spacing={2}>
        {settings.primaryEnabled && (
          <Grid2 size={{ xs: 12, md: 6 }}>
            {primaryReady && (
              <CostCenterField
                label={settings.primaryLabelText}
                required={settings.primaryRequired}
                allowCustom={settings.customPrimaryEnabled}
                onQueryChange={setPrimaryQuery}
                queryOptions={primaryQueryCostCenters ?? []}
                userOptions={userCostCenterRes?.primary ?? []}
                isLoadingQuery={isLoadingPrimary}
                isSecondary={false}
                isTertiary={false}
                defaultId={userCostCenterRes?.primaryDefaultId}
              />
            )}
            {!primaryReady && <Skeleton height={40} />}
          </Grid2>
        )}
        {settings.secondaryEnabled && (
          <Grid2 size={{ xs: 12, md: 6 }}>
            {secondaryReady && (
              <CostCenterField
                label={settings.secondaryLabelText}
                allowCustom={settings.customSecondaryEnabled}
                required={settings.secondaryRequired}
                onQueryChange={setSecondaryQuery}
                queryOptions={secondaryQueryCostCenters ?? []}
                userOptions={userCostCenterRes?.secondary ?? []}
                isLoadingQuery={isLoadingSecondary}
                isSecondary={true}
                isTertiary={false}
                defaultId={userCostCenterRes?.secondaryDefaultId}
              />
            )}
            {!secondaryReady && <Skeleton height={40} />}
          </Grid2>
        )}

        {settings.tertiaryEnabled && (
          <Grid2 size={{ xs: 12, md: 6 }}>
            {tertiaryReady && (
              <CostCenterField
                label={settings.tertiaryLabelText}
                allowCustom={settings.customTertiaryEnabled}
                required={settings.tertiaryRequired}
                onQueryChange={setTertiaryQuery}
                queryOptions={tertiaryQueryCostCenters ?? []}
                userOptions={userCostCenterRes?.tertiary ?? []}
                isLoadingQuery={isLoadingTertiary}
                isTertiary={true}
                isSecondary={false}
                defaultId={userCostCenterRes?.tertiaryDefaultId}
              />
            )}
            {!tertiaryReady && <Skeleton height={40} />}
          </Grid2>
        )}
      </Grid2>
    </>
  );
}
