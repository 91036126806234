import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { _useToasts } from "../../common/_useToasts";
import { getDiscountPrice, getFirstPrice, getShowProductPrice, getminQuantity } from "../../helpers/product";
import Rating from "./sub-components/ProductRating";
import { useHistory } from "react-router-dom";
import ProductModal from "./ProductModal";
import { AuthContext } from "../../common/Auth"
import * as productsAPIUtil from "../../util/products/productsAPIUtil"
import { getDefaultSize, getDefaultColor } from "../../redux/actions/cartActions"
import AddnewArtDialogue from "../artwork/AddnewArtDialogue"
import { productImageExt, productImageUrl, getErrorMessageForQuickBookPopup, productPath } from "../../util/helper";
import {fetchArtworksById} from "../../redux/actions/productActions";
import { useThemeSettingsData } from "../../layouts/state/useThemeSettingsData";
import { useThemeSettings } from "../../layouts/state/useThemeSettings";
import ProductPrices from "./sub-components/ProductPrices";
import { useProductPriceState } from "./state/useProductPriceState";

const RelatedProductGridSingle = ({
  product,
  currency,
  addToCart,
  addToWishlist,
  addToCompare,
  cartItem,
  wishlistItem,
  compareItem,
  sliderClassName,
  spaceBottomClass,
  toggle,
  colClass,
  setToggle
}) => {
  const [productStock, setProductStock] = useState(null);
  const [showAddnewart, setAddnewart] = useState(false);
  const [newArtData, setNewArtData] = useState(false);
  const [productDstInventory, setProductDstInventory] = useState(null);
  const [productInventory, setProductInventory] = useState(null);
  const [isMoq,setIsMoq]=useState(false);
  const [isSkuMoq,setIsSkuMoq]=useState(false);
  const [partPrice,setPartPrice]=useState([]);
  const [minQty,setMinQty]=useState("");
  const [productId, setProductId] = useState(null)
  const [RequiredDecorationForCheckOutPro, setRequiredDecorationForCheckOutPro] = useState(false)
  const [modalShow, setModalShow] = useState(false);
  const [IsAllowOutOfStockOrder, setIsAllowOutOfStockOrder] = useState(false)
  const { _addToast } = _useToasts();
  const [productLayout, setProductLayout] = useState('')
  const {storeDetails,themeSettingsData, isDirectToCart, userGroupDiscount, settingData, pointValue, usePointsAsCurrency } = useContext(AuthContext)
  const [decimalsToShow, setDecimalsToShow] = useState(2);
	const {
		isAllowoutofStockOrder,
		isStock,
		productSettings,
		generalProductLayout,
    SettingsRequiredDecorationForCheckOut,
	} = useThemeSettingsData({ themeSettingsData });
	const {
		showAddToCartOnRelatedProd,
    showQuickViewRelatedProduct,
    showWishListForRelatedProduct,
	} = useThemeSettings({ settingData })
	
  const {
    productPrice,
    showSellPrice,
    productDiscount,
    discountedPrice,
    finalProductDiscPrice,
    discountType,
    finalProductPrice,
    finalDiscountedPrice,
    offerPrice
  } = useProductPriceState({product, disIndProductPrice, currency})

  const disIndProductPrice = getShowProductPrice(product)
  const history = useHistory();

  useEffect(() => {
		if(!!productSettings?.decimalsToShow) {
			setDecimalsToShow(Number(productSettings?.decimalsToShow));
		}
	}, [productSettings])

  useEffect(() => {
	setPartPrice(product?.WebstoreProductPartArray[0]?.partPrice?.PartPriceArray?.PartPrice)
	setIsMoq(product?.isProductMoq);	
	setIsSkuMoq(product?.isProductskuMoq)
    let componentMounted = true;
    const fetchData = async () => {
      let result = await (productsAPIUtil.fetchProductInventory({ "productId": product.id, "color": "No Color", "live": 1 }))
      let dstresult = await productsAPIUtil.fetchDstProductInventory({ "productId": product.id })
      if (componentMounted) {
        if (dstresult?.data?.data) {
          setProductDstInventory(dstresult.data.data)
        }
        if (result.data) {
          setProductInventory(result.data)
        }

      }
    };
    fetchData();
	setProductId(product?.id)
    return () => {
      componentMounted = false;
    }
  }, [product])
  useEffect(() => {
	if (partPrice && partPrice.length > 0) {
		let minQty =getminQuantity(partPrice)
		setMinQty(minQty)
	}
}, [partPrice])
  const getStock = (sizeDefault, sizcolorDefault) => {

    let inventoryArray = null
    let dstInventoryArray = null;

    if (productInventory) {
      //supplierStock
     // if (productInventory.productId === product.productId) {
        inventoryArray = productInventory?.PartInventoryArray?.PartInventory
     // }
    }
    if (productDstInventory) {
      dstInventoryArray = productDstInventory
    }

    let stock = 0
    if ((product?.poType == 'DropShip' || product?.poType == "Stock") && (isStock == "supplierStock" || isStock == "both")) {
      inventoryArray && inventoryArray.map(stockArray => {
        if (sizcolorDefault === stockArray.partColor && stockArray.labelSize === sizeDefault) {
          if (stockArray?.InventoryLocationArray?.InventoryLocation?.length) {
            stockArray.InventoryLocationArray.InventoryLocation.map(inventoryArray => {
              if (inventoryArray?.inventoryLocationQuantity?.Quantity?.value) {
                stock = Number(stock) + Number(inventoryArray.inventoryLocationQuantity.Quantity.value)
              }
            })
          }
        }
      })
    }


    let dstStock = 0;
    //distributorStock or both
    //distributor inventory
    if (product?.poType == "Stock" && (isStock == "distributorStock" || isStock == "both")) {
      dstInventoryArray && dstInventoryArray.map(dstStockArray => {
        if (dstStockArray.productId === product.productId) {
          if (sizcolorDefault === dstStockArray.color && dstStockArray.size === sizeDefault) {
            dstStock = Number(dstStockArray.quantity)
          }
        }
      })
    }
    return Number(stock) + Number(dstStock)
  }
  const checkStock = () => {
    let sizeDefault = getDefaultSize(product);
    let sizcolorDefault = getDefaultColor(product);
    return getStock(sizeDefault, sizcolorDefault)
  };
  let primaryImage = ""
  let secondaryImage = ""
  if (product && product.MediaContent) {
    product.MediaContent.map(mediaArray => {
      if (mediaArray.primary) {
        primaryImage = mediaArray.url;
      } else if (mediaArray.secondary) {
        secondaryImage = mediaArray.url;
      }
    })
    if (primaryImage == "" && product.MediaContent.length > 0) {
      primaryImage = product.MediaContent[0].url
    }
    if (secondaryImage == "" && product.MediaContent.length >= 1 && product.MediaContent[1]) {
      secondaryImage = product.MediaContent[1].url
    }
  }



  useEffect(() => {
    if(product?.RequiredDecorationForCheckOutPro === true || product?.RequiredDecorationForCheckOutPro === false)
    {
        if(product?.RequiredDecorationForCheckOutPro === true)
            setRequiredDecorationForCheckOutPro(true)
        else
            setRequiredDecorationForCheckOutPro(false)
    }
    else if (SettingsRequiredDecorationForCheckOut === true || SettingsRequiredDecorationForCheckOut === false)
    {
        if(SettingsRequiredDecorationForCheckOut === true)
            setRequiredDecorationForCheckOutPro(true)
        else
            setRequiredDecorationForCheckOutPro(false)
    }
  },[SettingsRequiredDecorationForCheckOut])

  useEffect(() => {
		if(product?.AllowOutofStock === true || product?.AllowOutofStock === false) {
			setIsAllowOutOfStockOrder(product?.AllowOutofStock)
		} else {
		  setIsAllowOutOfStockOrder(isAllowoutofStockOrder)
		}
	  },[product, isAllowoutofStockOrder])

  useEffect(() => {
    if (!!product?.productLayout ) {
      setProductLayout(product.productLayout);
    } else {
      setProductLayout(generalProductLayout)
    }
  },[product, generalProductLayout])

  const handleCancel = () => {
    setAddnewart(false)
    setToggle(!toggle)
  }
  const handleAddnewArt = (addedNewartdata) => {
    let artwork = addedNewartdata.newArtData;
	fetchArtworksById({
        account_id: storeDetails.accountId,
        product_id: product.id,
      })
    setAddnewart(false)
    if (artwork.id) {
      if (artwork.design && artwork.design.variation) {
        if (artwork.design.variation.length == 1) {
          let variation = (artwork.design.variation[0])
          let tempDecoArray = {
            artworkID: artwork.id,
            variation: variation
          }
          setNewArtData(tempDecoArray)
        }
      }
    }
  }

  const goToCart = () => {
    localStorage.setItem("redirectUrl", window.location.pathname)
    if (isDirectToCart) {
      history.push("/cart");
    }
  }
  let mainCls = 'product-img text-center'
  if(productImageExt(process.env.PUBLIC_URL + primaryImage))
  {
	  mainCls = 'product-img text-center product-img-bg'
  }
  if(secondaryImage !== "" && productImageExt(process.env.PUBLIC_URL + secondaryImage))
  {
  	mainCls = 'product-img text-center product-img-bg'
  }
  return (
    <Fragment>
      <div
        className={`${colClass} ${sliderClassName ? sliderClassName : ""
          }`}
      >
        <div
          className={`antera-product-preview-container product-wrap ${spaceBottomClass ? spaceBottomClass : ""}`}
        >
          <div className={mainCls}>
            <Link to={process.env.PUBLIC_URL + "/product/" + productPath(product)}>
              <img
                style={{ maxHeight: '300px', width: "auto", maxWidth: '300px' }}
                className="antera-product-preview-primary-image default-img"
                src={productImageUrl(process.env.PUBLIC_URL + primaryImage)}
                alt=""
              />
              {secondaryImage != "" ? (
                <img
                  style={{ maxHeight: '300px', width: "auto", maxWidth: '300px' }}
                  className="antera-product-preview-secondary-image hover-img"
                  src={productImageUrl(process.env.PUBLIC_URL + secondaryImage)}
                  alt=""
                />
              ) : (
                ""
              )}
            </Link>
            {product.discount || product.new ? (
              <div className="product-img-badges">
                {product.discount ? (
                  <span className="antera-product-preview-discount-text pink">-{product.discount}%</span>
                ) : (
                  ""
                )}
                {product.new ? <span className="antera-product-preview-new-text blue">New</span> : ""}
              </div>
            ) : (
              ""
            )}

            <div className="antera-product-preview-action-bar product-action">
              {showWishListForRelatedProduct && <div className="antera-product-preview-action-button-container pro-same-action pro-wishlist">
                <button
                  className={wishlistItem !== undefined ? "active" : ""}
                  disabled={wishlistItem !== undefined}
                  title={
                    wishlistItem !== undefined
                      ? "Added to wishlist"
                      : "Add to wishlist"
                  }
                  onClick={() => addToWishlist(product, _addToast)}
                >
                  <i className="pe-7s-like" />
                </button>
              </div>
             }
                {(showAddToCartOnRelatedProd !== false) && <>
                  <div className="antera-product-preview-action-button-container pro-same-action pro-cart">
                {product.affiliateLink ? (
                  <a
                    href={product.affiliateLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {" "}
                    Buy now{" "}
                  </a>
                ) : product.variation && product.variation.length >= 1 ? (
                  <Link to={`${process.env.PUBLIC_URL}/product/${product.id}`}>
                    Select Option
                  </Link>
                ) : (checkStock() || IsAllowOutOfStockOrder) ? (
                  <Link
                    to={{
                      state: { redirectUrl: window.location.pathname }
                    }}
                    onClick={(e) => {
                      if(productLayout === "product-printable" || product.isKitEnabled) {
                       history.push("/product/"+product.id)
                      } else {
                      let resVal = getErrorMessageForQuickBookPopup(product, RequiredDecorationForCheckOutPro);
                      if(resVal.type === true) {
                        addToCart(product, _addToast,isMoq&&minQty)
                        goToCart()
                      } else {
                        _addToast(resVal.message, {
                          appearance: "error",
                          autoDismiss: true
                        })
                        setModalShow(true)
                        e.preventDefault();
                      }
                    }
                    }
                    }
                    className={
                      cartItem !== undefined && cartItem.quantity > 0
                        ? "active"
                        : ""
                    }
                    disabled={cartItem !== undefined && cartItem.quantity > 0}
                    title={
                      cartItem !== undefined ? "Added to cart" : "Add to cart"
                    }
                  >
                    {" "}
                    <i className="pe-7s-cart"></i>{" "}
                    {cartItem !== undefined && cartItem.quantity > 0
                      ? "Added"
                      : "Add to cart"}
                  </Link>
                ) : (
                  <button disabled className="active">
                    Out of Stock
                  </button>
                )}
              </div></>}
              {showQuickViewRelatedProduct && <div className="antera-product-preview-action-button-container pro-same-action pro-quickview">
                <button onClick={() => {
                    if(productLayout === "product-printable" || product.isKitEnabled) {
                      history.push("/product/"+product.id)
                    } else {
                      setModalShow(true)
                    }
								  }} title="Quick View">
                  <i className="pe-7s-look" />
                </button>
              </div>
              }
            </div>
          </div>
          <div className="product-content text-center">
            <h3 className="antera-product-preview-product-name-text">
              <Link to={process.env.PUBLIC_URL + "/product/" + productPath(product)}>
                {product.productName}
              </Link>
            </h3>
            {product.rating && product.rating > 0 ? (
              <div className="antera-product-preview-product-rating-container product-rating">
                <Rating ratingValue={product.rating} />
              </div>
            ) : (
              ""
            )}
            <ProductPrices
                discountedprice={discountedPrice}
                finaldiscountedprice={finalDiscountedPrice}
                finalproductprice={finalProductPrice}
                currency={currency}
                offerPrice={offerPrice}
                showSellPrice={showSellPrice}
                discountType={discountType}
                userGroupDiscount={userGroupDiscount}
                disIndProductPrice={disIndProductPrice}
                usePointsAsCurrency={usePointsAsCurrency}
                product={product}
            />
          </div>
        </div>
      </div>
      {/* product modal */}
      {(modalShow) && <ProductModal
        show={showAddnewart ? false : modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        setAddnewart={setAddnewart}
        newArtData={newArtData}
        setNewArtData={setNewArtData}
        currency={currency}
        discountedprice={discountedPrice}
        finalproductprice={finalProductPrice}
        finaldiscountedprice={finalDiscountedPrice}
        cartitem={cartItem}
        wishlistitem={wishlistItem}
        compareitem={compareItem}
        addtocart={addToCart}
        addtowishlist={addToWishlist}
        addtocompare={addToCompare}
        _addToast={_addToast}
        isRelatedProduct={1}
      />}
      {showAddnewart && <AddnewArtDialogue
	  	productId={productId}
        open={showAddnewart}
        show={showAddnewart}
        title="Add New Artwork"
        onHide={() => handleCancel()}
        handleSubmit={handleAddnewArt}
        cancel={() => handleCancel()}
        catalogueItem={product}
      />}
    </Fragment>
  );
};

RelatedProductGridSingle.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItem: PropTypes.object,
  compareItem: PropTypes.object,
  currency: PropTypes.object,
  product: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  wishlistItem: PropTypes.object
};

export default RelatedProductGridSingle;
