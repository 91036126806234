import React, { useContext } from 'react'
import { PaymentMethodFormProps } from './PaymentMethodForms'
import {
  Alert,
  Skeleton,
  Stack,
} from '@mui/material'
import { useGetCardConnectAccountsQuery } from '../../../redux/api/paymentApi'
import { AuthContext } from '../../../common/Auth'
import CardConnectAccountList from './CardConnectAccountList'
import { Typography } from '@material-ui/core'
import CardConnectTokenForm from './CardConnectTokenForm'
import { getError } from '../../../helpers/checkout'
import { TextFieldElement } from 'react-hook-form-mui'

export default function CardConnectForm({ settings }: PaymentMethodFormProps) {
  const { user } = useContext(AuthContext)

  const {
    data: accounts,
    isLoading: accountsLoading,
    error: accountsError,
  } = useGetCardConnectAccountsQuery(undefined, { skip: !user })

  return (
    <>
      {settings.testModeEnabled && (
        <Alert severity="error">Test mode enabled</Alert>
      )}
      {accountsError && (
        <Alert severity="error">
          Error loading saved cards: {getError(accountsError)}
        </Alert>
      )}
      {user && accounts && accounts?.length !== 0 && (
        <>
          <Typography variant="h6">Saved Cards</Typography>
          <CardConnectAccountList
            name="ccAccountId"
            testModeEnabled={settings.testModeEnabled ?? false}
            accounts={accounts}
          />
        </>
      )}
      {accountsLoading && (
        <Stack direction={'column'} spacing={2}>
          <Skeleton variant="rounded" width={'100%'} height={'74px'} />
          <Skeleton variant="rounded" width={'100%'} height={'74px'} />
          <TextFieldElement name="ccAccountId" hidden />
        </Stack>
      )}
      {(!user || (!accountsLoading && (accounts?.length ?? 0)) === 0) && (
        <CardConnectTokenForm
          testModeEnabled={settings.testModeEnabled ?? false}
        />
      )}
    </>
  )
}
