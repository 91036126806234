interface ChargePrice {
    xMinQty: number;
    salePrice: number;
    price: number;
}

interface ChargePriceArray {
    ChargePrice: ChargePrice[];
}

interface Charge {
    chargeCode: string;
    chargeName: string;
    chargeId: string;
    matchOrderQty: string;
    ChargePriceArray: ChargePriceArray;
}


interface DecorationMapping {
    charges: Charge[];
    location: string;
    preDecorated: string;
}

interface AdditionalCharge {
    totalSetupCharge: number;
    totalCostSetupCharge: number;
    setupChargeName: string;
    chargeCode: string;
    chargeId: string;
}

interface Artwork {
    id: string;
    productToDecorationMapping?: DecorationMapping[];
    multipleProductsToDecorationMapping?: { [key: number]: DecorationMapping[] };
    design?: { stitchCount: string };
}

interface DecorationPriceDetail {
    stitchesStart: number;
    stitchesUpto: number;
    qunatityStart: number;
    quantityUpto: number;
    salePrice: number;
    price: number;
}

interface Decoration {
    locationId: string;
    decoPricedetails: DecorationPriceDetail[];
    artworkID: string;
}

interface UseProductDecoChargeProps {
    artworksFromState: Artwork[];
}

export const useProductDecoCharge = ({artworksFromState}: UseProductDecoChargeProps) => {

    const getDecoPriceDetails = (
        decoration: Decoration,
        selectedArtwork: Artwork | undefined,
        quantity: number | string,
        productId?: number | undefined
    ) => {
        let priceAccum = 0;
        let salePriceAccum = 0;
        const decorationPrices: DecorationPriceDetail[] = [];
        let productDecoMapping = null;
        if (productId) {
            productDecoMapping = selectedArtwork?.multipleProductsToDecorationMapping?.[productId]?.find(x => x.location === decoration.locationId);
        } else {
            productDecoMapping = selectedArtwork?.productToDecorationMapping?.find(x => x.location === decoration.locationId);
        }
        if (productDecoMapping?.preDecorated !== '1') {
            let decorationPriceDetail = null;
            decoration.decoPricedetails.forEach((decorationPrice) => {
                let sCount = selectedArtwork?.design?.stitchCount;
                if (sCount === '' || !Number(sCount)) {
                    sCount = '0';
                }
                if (
                    Number(sCount) >= decorationPrice.stitchesStart &&
                    Number(sCount) <= decorationPrice.stitchesUpto
                ) {
                    if (
                        Number(quantity !== '' ? quantity : 1) >= decorationPrice.qunatityStart &&
                        Number(quantity !== '' ? quantity : 1) <= decorationPrice.quantityUpto
                    ) {
                        salePriceAccum = Number(decorationPrice.salePrice);
                        priceAccum = Number(decorationPrice.price);
                        decorationPriceDetail = decorationPrice;
                    }
                }
            });
            if (decorationPriceDetail) {
                decorationPrices.push(decorationPriceDetail)
            }
        }
        return {
            salePriceAccum: Number(salePriceAccum),
            decorationPrices,
            priceAccum: Number(priceAccum)};
    };

    const getDecoPriceByQuantity = (decorationsFromProduct: Decoration[], quantity: number, productId: number | undefined): number => {
        let decorationSalePrice = 0;
        decorationsFromProduct &&
        decorationsFromProduct.forEach((decoration) => {
            if (decoration?.decoPricedetails?.length > 0) {
                let selectedArtwork = artworksFromState.find((artwork) => artwork.id === decoration.artworkID);
                const decorationPrice = getDecoPriceDetails(decoration, selectedArtwork, quantity, productId);
                decorationSalePrice += decorationPrice.salePriceAccum
            }
        });
        return Number(decorationSalePrice);
    };

    const getDecoChargesByQuantity = (decorationsFromProduct: Decoration[], quantity: number, productId: number | undefined): DecorationPriceDetail[] => {
        let decorationPrices: DecorationPriceDetail[] = [];
        decorationsFromProduct &&
        decorationsFromProduct.forEach((decoration) => {
            if (decoration?.decoPricedetails?.length > 0) {
                let selectedArtwork = artworksFromState.find((artwork) => artwork.id === decoration.artworkID);
                const decorationPrice = getDecoPriceDetails(decoration, selectedArtwork, quantity, productId);
                decorationPrices = decorationPrices.concat(decorationPrice.decorationPrices);
            }
        });
        return decorationPrices;
    };


    const getDecoCostByQuantity = (decorationsFromProduct: Decoration[], quantity: number, productId: number | undefined): number => {
        let decorationSalePrice = 0;
        decorationsFromProduct &&
        decorationsFromProduct.forEach((decoration) => {
            if (decoration?.decoPricedetails?.length > 0) {
                let selectedArtwork = artworksFromState.find((artwork) => artwork.id === decoration.artworkID);
                const decorationPrice = getDecoPriceDetails(decoration, selectedArtwork, quantity, productId);
                decorationSalePrice += decorationPrice.priceAccum
            }
        });
        return Number(decorationSalePrice);
    };

    const getAdditionalDecorationCharge = (
        artwork: Artwork,
        quantity: number,
        location: string,
        productId: number | undefined
    ): { totalAdditionalCharges: number; additionalCharges: AdditionalCharge[] } => {
        let totalAdditionalCharges = 0;
        const additionalCharges: AdditionalCharge[] = [];
        let productToDecorationMapping;
        if (productId) {
            productToDecorationMapping = artwork.multipleProductsToDecorationMapping?.[productId]?.filter(x => x.location === location && x.preDecorated !== '1');
        } else {
            productToDecorationMapping = artwork.productToDecorationMapping?.filter(x => x.location === location && x.preDecorated !== '1');
        }
        if (productToDecorationMapping && productToDecorationMapping.length) {
            productToDecorationMapping.forEach(productMap => {
                productMap.charges.forEach(charge => {
                    if (additionalCharges.findIndex(x => x.chargeCode === charge.chargeCode) === -1) {
                        let price = 0;
                        let cost = 0;
                        charge.ChargePriceArray.ChargePrice.forEach(x => {
                            if (x.xMinQty <= quantity) {
                                price = x.salePrice;
                                cost = x.price;
                            }
                        });
                        const chargePrice = charge.matchOrderQty === "1" ? Number(price) * Number(quantity) : Number(price);
                        const chargeCost = charge.matchOrderQty === "1" ? Number(cost) * Number(quantity) : Number(cost);
                        additionalCharges.push({
                            totalSetupCharge: chargePrice,
                            totalCostSetupCharge: chargeCost,
                            setupChargeName: charge.chargeName,
                            chargeCode: charge.chargeCode,
                            chargeId: charge.chargeId
                        });
                        totalAdditionalCharges = Number(totalAdditionalCharges) + chargePrice;
                    }
                });
            });
        }
        return {
            totalAdditionalCharges,
            additionalCharges
        };
    };


    return {
        getDecoPriceByQuantity,
        getDecoChargesByQuantity,
        getDecoCostByQuantity,
        getAdditionalDecorationCharge
    }
}