import {useDispatch, useSelector} from "react-redux";
import {useCallback, useContext, useEffect, useState} from "react";
import {
    fetchProducts,
    isApiCount,
    isLoading, receiveProducts
} from "../../../redux/actions/productActions";

import {
    getCategoryList, getColorList, fetchingCategories, fetchingColors, receiveCategories, receiveSizes
} from "../../../redux/actions/shopActions";

import { defaultTo, get } from "lodash";
import {AuthContext} from "../../../common/Auth";
import React from "react";
import { useHistory } from "react-router-dom";
import { useThemeSettingsData } from "../../../layouts/state/useThemeSettingsData";
import { useCategoryFormatState } from "./useCategoryFormatState";
import { ShopViewsObject } from "../../../util/shop.views";
let apiCount = 0;

export const UseShopMainProductsState = (props) => {
    const {
        location,
        themeLayout,
        match,
        showSideBar
    } = props;



    const qSearch = window.location.search;
    const getParams = new URLSearchParams(qSearch);
    const getParamData = getParams.get('q');
    
    const [selectedColors, setSelectedColors] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [tags, setTags] = useState([]);
    const {tabName, categoriesList, user, settingData, categoriesByUserGroup, themeSettingsData, tagsList} = useContext(AuthContext);
    const [layout, setLayout] = useState("grid three-column");
    const [sortType, setSortType] = useState("");
    const [useInternal, setUseInternal] = useState(false);
    const [sortValue, setSortValue] = useState("");
    const [filterSortType, setFilterSortType] = useState('productName');
    const [sortingOrderFilter, setSortingOrderFilter] = useState(false);
    const [flagFromDropdown, setFlagFromDropdown] = useState('');
    const [filterSortValue, setFilterSortValue] = useState('asc');
    const [offset, setOffset] = useState(1);
    const [pageLimitData, setPageLimitData] = useState("");
    const [rawStructure, setRawStructure] = useState({
        colors: [],
        sizes: [],
        categories: [],
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [generalDescription, setGeneralDescription] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [newColor, setNewColor] = useState([])
    const {pathname} = location;
    const [breadCrumbProps, setBreadCrumbProps] = useState({})
    const [sortSizeSetting, setSortSizeSetting] = useState([]);
    const [categoryNameURL, setCategoryNameURL] = useState(null);
    const [viewSizesOptions, setViewSizesOptions] = useState([]);
    const [categoryBuilding, setCategoryBuilding] = useState(true);
    const dispatch = useDispatch();
    const history = useHistory()
    const { shopLayout, productSettings, productSorting } = useThemeSettingsData({
        themeSettingsData
    })
    const { structuredData } = useCategoryFormatState({
        ...rawStructure,
    })
    const [categoriesUrl, setCategoriesUrl] = useState([])

    useEffect(() => {
        return () => {
            setSelectedCategories([])
            setRawStructure({
                colors: [],
                sizes: [],
                categories: [],
            })
        };
    }, []);

    useEffect(() => {
        if(!!getParamData){
            setSearchQuery(getParamData)
        }
    }, [getParamData])

    useEffect(async () => {
        const getCategoryNameURL = match?.params?.category
        const prevCat = localStorage.getItem('currentCat')
        if ( typeof getCategoryNameURL !== 'undefined' && prevCat !== getCategoryNameURL ) {
            localStorage.setItem('currentCat', getCategoryNameURL)
            setCategoryNameURL(getCategoryNameURL)
        }
    }, [match])

    useEffect(() => {
        if(shopLayout) {
            setLayout(shopLayout)
        }
    }, [shopLayout])

    useEffect(() => {
        if(productSorting && structuredData.length > 0) {
            const paramsURL = new URLSearchParams(window.location.search);
            if(paramsURL.get('cat')) {
                let selectedCategoriesId = paramsURL.get('cat');
                let tempSelectedCategories = [];
                if(selectedCategoriesId){
                    let arrayCategoryId = selectedCategoriesId.split('_');
                    arrayCategoryId.forEach(rowCategoryId => {
                        let categoryByParam = categoriesList.filter(x => x._id === rowCategoryId)?.[0];
                        if (categoryByParam) {
                            tempSelectedCategories.push({category: categoryByParam})
                        }
                    });
                }
                if(tempSelectedCategories.length > 0) {
                    setSelectedCategories(tempSelectedCategories);
                }
            }
        }
    }, [productSorting, structuredData])

    const colorsStore = useSelector((state) =>
        defaultTo(  state.productData.productColorsSelected, [])
    );
    const tagsStore = useSelector((state) =>
        defaultTo( state.productData.productTagsSelected, [])
    );

    const categoriesStore = useSelector((state) =>
        defaultTo(state.productData.productCategoriesSelected, [])
    );

    const sizesStore = useSelector((state) =>
        defaultTo(state.productData.productSizesSelected, [])
    );

    const productQueryStore = useSelector((state) =>
        defaultTo(state.productData.productQuery, '')
    );

    const products = useSelector((state) =>
        state.productData.frontProducts &&
        state.productData.frontProducts.products
            ? state.productData.frontProducts.products
            : []
    );

    const pageLimit = useSelector((state) =>
        state.productData.frontProducts &&
        state.productData.frontProducts.meta &&
        state.productData.frontProducts.meta.per_page
            ? state.productData.frontProducts.meta.per_page
            : 18
    );

    const total_count = useSelector((state) =>
        state.productData.frontProducts &&
        state.productData.frontProducts.meta &&
        state.productData.frontProducts.meta.total_count
            ? state.productData.frontProducts.meta.total_count
            : "0"
    );
    const loading = useSelector((state) => state.productData.loading);
    const loadingCategories = useSelector((state) => state.shopData.fetchingCategories);
    const loadingColors = useSelector((state) => state.shopData.fetchingColors);
    const page = useSelector((state) => state.productData.frontProducts &&
        state.productData.frontProducts.meta &&
        state.productData.frontProducts.meta.page
        ? state.productData.frontProducts.meta.page
        : "0"
    );


    const categoriesShop = useSelector((state) =>
        defaultTo(state.shopData.categories, [])
    );

    const sizesShop = useSelector((state) =>
        defaultTo(state.shopData.sizes, [])
    );

    const tagsShop = useSelector((state) =>
        defaultTo(state.shopData.tags, [])
    );

    const colorsShop = useSelector((state) =>
        defaultTo(state.shopData.colors, [])
    );


    const setQueryParams = (categories) => {
        const rawCategories = categories || selectedCategories
        const params = new URLSearchParams()
        if(selectedTags?.length > 0) {{
            params.append("tags", selectedTags.map(x => x._id).join('_'))
        }}
        if(selectedSizes?.length > 0) {
            params.append("sizes",selectedSizes.map(x => x).join('_'))
        }
        if(rawCategories?.length) {
            params.append("cat", rawCategories.map(x => x.category._id).join('_'))
        }
        if(selectedColors?.length > 0) {
            params.append("colors", selectedColors.map(x=> Object.keys(x)[0]).join('_'))
        }
        if(searchQuery) {
            params.append("q", searchQuery)
        }
        history.push({search: params.toString()})
    }

    useEffect(() => {
        if(categoriesShop.length) {
            dispatch(fetchingCategories(false))
            setRawStructure({
                sizes: sizesShop,
                categories: categoriesShop,
                colors: colorsShop
            })

        }
    }, [categoriesShop, sizesShop])

    useEffect(() => {
        if(tagsStore.length !== selectedTags.length || (selectedTags.length === 1 && selectedTags !== tagsStore)) {
            setSelectedTags(tagsStore);
        }
    }, [tagsStore])

    useEffect(() => {
        if(compareSizes(sizesStore, selectedSizes))  {
            setSelectedSizes(sizesStore);
        }
    }, [sizesStore])

    useEffect(() => {

        if(compareColors(colorsStore, selectedColors))  {
            setSelectedColors(colorsStore);
        }
    }, [colorsStore])

    const compareColors = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return true;
        }
        const ids1 = arr1.map(x => Object.keys(x)?.[0])
        const ids2 = arr2.map(x => Object.keys(x)?.[0])
        const result = []
        ids1.forEach(x => {
            const index = ids2.findIndex(i => i === x)
            if(index >= 0) {
                result.push(x)
            }
        })
        return result.length !== ids2.length
    }

    const compareSizes = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return true;
        }
        const result = []
        arr1.forEach(x => {
            const index = arr2.findIndex(i => i === x)
            if(index >= 0) {
                result.push(x)
            }
        })
        return result.length !== arr2.length
    }

    useEffect(() => {
        if(((selectedCategories.length !== 1 || categoriesStore.length > 0) && !sameSelectedCategories(selectedCategories, categoriesStore)))  {
            setSelectedCategories(categoriesStore);
        }
    }, [categoriesStore])

    useEffect(() => {
        if(productQueryStore !== searchQuery )  {
            setSearchQuery(productQueryStore);
        }
    }, [productQueryStore])

    useEffect(() => {
        if(selectedTags.length !== tagsStore.length ||
            selectedColors.length !== colorsStore.length ||
            selectedSizes.length !== sizesStore.length ) {
            setTimeout(() => {
                setQueryParams()
            })
        }
    }, [selectedTags, selectedColors, selectedSizes]);


    useEffect(() => {
        if( searchQuery !== productQueryStore) {
            setQueryParams()
        }
    }, [searchQuery])

    useEffect(() => {
        if(tagsList?.length) {
            setTags(tagsList)
        }
    }, [tagsList])

    useEffect(() => {
        setTimeout(() => {
           setCategoryBuilding(false)
       }, 10)
    }, [selectedCategories])


    const sameSelectedCategories = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return false;
        }
        const ids1 = arr1.map(x => x.category._id)
        const ids2 = arr2.map(x => x.category._id)
        const result = []
        ids1.forEach(x => {
            const index = ids2.findIndex(i => i === x)
            if(index >= 0) {
                result.push(x)
            }
        })
        return result.length === ids2.length
    }

    const deleteSelectedColor = (colorName) => {
        setSelectedColors(
            selectedColors.filter((color) => Object.keys(color)[0] !== colorName)
        );
    };

    const onSelectColor = (colorObject, isChecked) => {
        if (isChecked) {
            setSelectedColors((oldArray) => [...oldArray, colorObject]);
        } else {
            const colorName = Object.keys(colorObject)[0];
            deleteSelectedColor(colorName);
        }
    };

    const showAllProduct = (event) => {
        //Remove selected data
        setSelectedCategories([]);
        setSelectedTags([]);
        setSelectedColors([]);
        setSelectedSizes([]);
        setSearchQuery('')
    };

    const selectParamsValues = useCallback(
        (categoriesList, tagName) => {
            let foundVal = false;
            if (structuredData.length > 0) {
                if(categoriesList.length > 0) {
                    if(categoriesStore.length <= 1) {
                        setFlagFromDropdown('')
                        setSelectedCategories([categoriesList[categoriesList.length - 1]]);
                    }
                    foundVal = true;
                } else {
                    setSelectedCategories([]);
                }
            }
            const findTag = (tag) =>
                tag?.tagName?.toLowerCase() === tagName?.toLowerCase();
            if (!selectedTags.find(findTag)) {
                const tagFound = tags.find(findTag);
                if (tagFound) {
                    setSelectedTags((oldArray) => [...oldArray, tagFound]);
                    foundVal = true;
                }
            }
        },
        [structuredData, selectedCategories, tags, selectedTags]
    );

    useEffect(() => {
        if (categoriesList.length && !loadingCategories  && themeLayout) {
            getCategories().then()
        }
    }, [categoriesList, categoriesByUserGroup, categoryNameURL, themeLayout]);


    useEffect(() => {
        if(!structuredData.length  || categoryBuilding) {
            return
        }
        const rawSelectedColors = []
        selectedColors.map((color) => {
            let childColor = Object.values(color)[0]
            let child = childColor.reduce((prev, current) => {
                return prev.concat(current);
            }, []);
            rawSelectedColors.push(...child)
        })
        const data = {
            categories: selectedCategories,
            tags: selectedTags,
            colors: rawSelectedColors,
            sizes: selectedSizes,
            query: searchQuery,
            userGroups: user.userGroups,
        };

        /* check categories from user group categoriesList
           Considerations:
             - user object must have usergroup field
             - selected categories must be empty to send categories from user group context
         */
        apiCount = Number(apiCount) + 1;
        if (categoriesByUserGroup?.length > 0) {
            let categories = categoriesList.length > 0 ? categoriesList : [];
            if (categoriesByUserGroup.length > 0) {
                let tempCategories = [];
                categories.forEach(category => {
                    categoriesByUserGroup.forEach(categoryUserGroup => {
                        if (category._id === categoryUserGroup) {
                            tempCategories.push(category);
                        }
                    })
                })
                categories = tempCategories;
            }
            data.categories = filterCategories(categories, true);
            callProducts(data, apiCount);
        } else {
            data.categories = filterCategories(categoriesList, false);
            callProducts(data, apiCount);

        }

    }, [
        selectedCategories,
        selectedTags,
        selectedColors,
        selectedSizes,
        searchQuery,
        currentPage,
        filterSortValue,
        filterSortType,
        pageLimitData,
        categoriesByUserGroup,
        categoryBuilding
    ]);

    useEffect(() => {
        setCurrentPage(1)
    }, [
        selectedCategories,
        selectedTags,
        selectedColors,
        selectedSizes,
        searchQuery
    ])

    useEffect(() => {
        viewSizesOptionsMap()
    }, [selectedCategories, rawStructure, match])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPage]);


    useEffect(() => {
        if(!structuredData.length) {
            return;
        }
        setFlagFromDropdown('')
        const params = new URLSearchParams(window.location.search);
        const allCategoryLevels = [];
        for(let key in match?.params) {
            allCategoryLevels.push(match?.params?.[key]);
        }
        const categoryNameURL = match?.params?.category
        setCategoryNameURL(categoryNameURL)
        let categoriesList = [];
        for(let i=0; i<allCategoryLevels.length; i++) {
            let category = getCategoryFromList(structuredData, allCategoryLevels[i], categoriesList)
            if(category) {
                categoriesList.push(category);
            }
        }
        const currentCatList = JSON.parse(localStorage.getItem('catTree') ?? '[]')
        if(currentCatList.length) {
            const catData = []
            currentCatList.forEach(x => {
                let category = getCategoryFromList(structuredData, x, catData)
                if(category) {
                    catData.push(category);
                }
            })
            if(sameSelectedCategories(catData, categoriesList)) {
                return;
            }
        }
        const tagName = params.get("tag");
        selectParamsValues(categoriesList, tagName);
        settCatTree(categoriesList)

    }, [
        structuredData.length,
        tags.length,
        match
    ]);

    const getCategoryFromList = (categories, categoryName, categoriesList) => {
        let categoryToReturn = null;
        let parent = categoriesList.length > 0 ? categoriesList[categoriesList.length - 1].category._id : '';
        categories.forEach(category => {
            if(categoryToReturn === null &&
                categoryName.replace(/ /g, "-").toLowerCase() === category.category.categoryName?.replace(/ /g, "-").toLowerCase() &&
                category.category.parent_id === parent) {
                categoryToReturn = category;
            }
            if(categoryToReturn === null && category.children.length > 0) {
                categoryToReturn = getCategoryFromList(category.children, categoryName, categoriesList);
            }
        })
        return categoryToReturn
    }


    useEffect(() => {
        if(!showSideBar) {
            return
        }
        if ( colorsShop.length ) {
            setNewColor(colorsShop)
        } else if ( !loadingColors ) {
            dispatch(fetchingColors(true))
            dispatch(getColorList())
        }
    }, [colorsShop, showSideBar])

    useEffect(() => {
        if(!showSideBar) {
            return
        }
        if(!sizesShop.length) {
            let showSize = true;
            let showCategories = false;
            let body = {categoryIds: [], showCategories, showSize};
            dispatch(getCategoryList(body))
        }
    }, [sizesShop, showSideBar])



    useEffect(() => {
        if (settingData && settingData.length > 0) {
            const settings = settingData.find(x => x.page === "General");
            if (settings) {
                setGeneralDescription(settings.values?.generalCategoryDescription ? settings.values.generalCategoryDescription : "")
            }
            let SortSizeSetting = settingData.find(x => x.settingsName === "SortSizeSetting");
            if (SortSizeSetting) {
                let data = [];
                if(SortSizeSetting.values?.length > 0) {
                    data = SortSizeSetting.values
                } else {
                    if(Object.keys(SortSizeSetting.values).length > 0) {
                        Object.keys(SortSizeSetting.values).forEach(x => {
                            data.push({value: x, label: SortSizeSetting.values[x]})
                        })
                    }
                }
                setSortSizeSetting(data ? data : [])
            }
        }
    }, [settingData])

    const settCatTree = (categoriesFromUrl) => {
        localStorage.removeItem('catTree')
        if(categoriesFromUrl.length > 0) {
            setCategoriesUrl(categoriesFromUrl)
            localStorage.setItem('catTree', JSON.stringify(categoriesFromUrl.map(x => x.category.categoryName)))
        } else {
            setCategoriesUrl([])
        }
    }

    const getLayout = (layout) => {
        setLayout(layout);
    };

    const filterCategories = (categoryList, userGroups) => {
        let tempSelectedCategories = [];
        let categoryId = [];
        let childCategoryId = null;
        const params = new URLSearchParams(window.location.search);
        let selectedCategoriesId = params.get('cat');
        if(selectedCategoriesId){
            let arrayCategoryId = selectedCategoriesId.split('_');
            arrayCategoryId.forEach(rowCategoryId => {
                let categoryByParam = (categoryList ? categoryList : []).filter(x => x._id === rowCategoryId)?.[0];
                if (categoryByParam) {
                    categoryId.push(categoryByParam._id);
                    tempSelectedCategories.push(categoryByParam)
                }
            });
        }
        // check if selectedCategories has items
        if (selectedCategories.length > 0) {
            selectedCategories.forEach(selectedCategory => {
                if (categoryId.findIndex(x => x === selectedCategory.category._id) === -1) {
                    if (selectedCategory.category._id !== childCategoryId) {
                        tempSelectedCategories.push(selectedCategory.category);
                    }
                }
            })
        }
        if (userGroups && tempSelectedCategories.length === 0) {
            tempSelectedCategories = categoryList;
        }
        //setSelectedCategories(tempSelectedCategories);
        return tempSelectedCategories;
    }

    const getSortParams = (sortType, sortValue) => {
        setSortType(sortType);
        setSortValue(sortValue);
    };

    const getFilterSortParams = (sortType, sortValue) => {
        const values = sortValue.split('_');
        setFlagFromDropdown(sortType)
        if (values.length > 1) {
            sortingOrderFilter && sortType === '' ? setFilterSortType('productCategorySorting.productSorting') : setFilterSortType(values[0]);
            sortingOrderFilter && sortType === '' ? setFilterSortValue('asc') : setFilterSortValue(values[1]);
        }
        if(sortType === 'filterSort') {
            setSortingOrderFilter(false);
        }
    };

    const pageLimit1 = (value) => {
        setPageLimitData(value)
    }

    const getCategories = useCallback(async () => {
        if (categoriesList.length > 0) {
             const idsCategoriesContext = [];
            let categoriesToRead = categoriesByUserGroup.length > 0 ? categoriesByUserGroup :
                (localStorage.getItem("categoriesByUserGroup")
                    ? JSON.parse(localStorage.getItem("categoriesByUserGroup")) : []);
            if (categoriesToRead.length > 0) {
                categoriesToRead.forEach(categoryUserGroup => {
                    categoriesList.forEach(category => {
                        if (categoryUserGroup === category._id) {
                            idsCategoriesContext.push(category._id)
                        }
                    })
                })
            }
            let mappedCategories = categoriesList
            if(idsCategoriesContext.length) {
                mappedCategories = []
                 categoriesList.forEach(x => {
                     if(idsCategoriesContext.includes(x._id)) {
                         mappedCategories.push(x)
                     }
                })
            }
            dispatch(fetchingCategories(true));
            dispatch(
                receiveProducts(
                    [],
                    undefined,
                    undefined,
                    0,
                ),
            )
            dispatch(receiveCategories(mappedCategories.map(category => {
                return { category }
            })))
        }
    }, [categoriesList, categoriesByUserGroup]);

    const fixSortTypeAndValue = (data) => {
        let filterSortTypeMethod = '';
        let filterSortValueMethod = '';
        if(flagFromDropdown === '') {
            if(data?.categories?.length === 1) {
                let settingsCategory = productSorting?.[data.categories[0]._id]
                if(settingsCategory?.mainSort) {
                    setSortingOrderFilter(true)
                    filterSortTypeMethod = 'productCategorySorting.productSorting'
                    filterSortValueMethod = 'asc'
                } else if(settingsCategory?.secondarySort) {
                    setSortingOrderFilter(false)
                    let filters = settingsCategory.selectedSorting.value.split('_');
                    filterSortTypeMethod = filters[0];
                    filterSortValueMethod = filters[1];
                } else {
                    if(productSorting?.selectedSorting?.value){
                        let filters = productSorting.selectedSorting.value.split('_');
                        filterSortTypeMethod = filters[0];
                        filterSortValueMethod = filters[1];
                    }else{
                        filterSortTypeMethod = 'productName'
                        filterSortValueMethod = 'asc'
                    }
                    setSortingOrderFilter(false)
                }
            } else {
                if(productSorting?.selectedSorting?.value) {
                    let filters = productSorting.selectedSorting.value.split('_');
                    filterSortTypeMethod = filters[0];
                    filterSortValueMethod = filters[1];
                    setSortingOrderFilter(false)
                } else {
                    filterSortTypeMethod = 'productName'
                    filterSortValueMethod = 'asc'
                    setSortingOrderFilter(false)
                }
            }
        }
        return { filterSortTypeMethod, filterSortValueMethod }
    }


    const callProducts = (data, apiCount, sortType) => {
        dispatch(isLoading(true));
        const { filterSortTypeMethod, filterSortValueMethod } = fixSortTypeAndValue(data)
        setTimeout(() => {
            if (pageLimitData) {
                dispatch(fetchProducts(
                    pageLimitData,
                    Number(currentPage) - 1,
                    flagFromDropdown === '' ? filterSortTypeMethod : filterSortType,
                    flagFromDropdown === '' ? filterSortValueMethod : filterSortValue,
                    data,
                    apiCount
                ));
            } else {
                dispatch(fetchProducts(
                    pageLimit,
                    Number(currentPage) - 1,
                    flagFromDropdown === '' ? filterSortTypeMethod : filterSortType,
                    flagFromDropdown === '' ? filterSortValueMethod : filterSortValue,
                    data,
                    apiCount
                ));
            }
        }, 500)
    }

    const onSelectCategory = (relationObject, isChecked) => {
        const {category} = relationObject;
        if (isChecked) {
            const selectCategories = [relationObject]
            let array = categoriesSelected(selectCategories);
            setUseInternal(false)
            if(array.length > 0) {
                setSelectedCategories((oldArray) => [...oldArray, ...array]);
                setQueryParams( [...selectedCategories, ...array])
            }
        } else {
            setUseInternal(true)
            let ids = getIdsChildren(relationObject?.children);
            if (relationObject?.category?.parent_id) {
                ids.push(relationObject.category.parent_id)
            }
            ids.push(category._id)
            deleteSelectedCat(ids);
        }
    };

    const categoriesSelected = (categories) => {
        let array = [];
        categories.length > 0 && categories.map((val) => {
            let catData = selectedCategories.filter((item) => item.category._id === val.category._id)
            if (!catData.length) {
                array.push(val);
            }
            if (val.children.length > 0) {
                array = array.concat(categoriesSelected(val.children));
            }
        })
        return array;
    }

    const getIdsChildren = (categories) => {
        let ids = [];
        categories.forEach(category => {
            ids.push(category.category._id);
            ids = ids.concat(getIdsChildren(category.children));
        });
        return ids;
    }

    const onSelectSize = (sizeValue, isChecked) => {
        if (isChecked) {
            setSelectedSizes((oldArray) => [...oldArray, sizeValue]);
        } else {
            deleteSelectedSize(sizeValue);
        }
    };

    const onSelectTag = (tag, isChecked) => {
        if (isChecked) {
            setSelectedTags((oldArray) => [...oldArray, tag]);
        } else {
            deleteSelectedTag(tag._id);
        }
    };

    const onHandleInput = (values) => {
        setSearchQuery(values);
        if(values === "") {
            const params = new URLSearchParams()
            params.delete("q")
            history.push({search: params.toString()})
        }
    };

    const deleteSelectedTag = (tagId) => {
        setSelectedTags(selectedTags.filter((item) => item._id !== tagId));
    };


    const deleteSelectedSize = (sizeName) => {
        setSelectedSizes(selectedSizes.filter((item) => item !== sizeName));
    };

    const deleteSelectedCat = (catIds) => {
        let tempCat = selectedCategories
        catIds.map((id) => {
            tempCat = tempCat.filter((item) => id !== item.category._id)
        })
        setSelectedCategories(tempCat)
        setQueryParams(tempCat)
        
        if(categoryNameURL && tempCat && tempCat.length === 0){
            history.push('/products')
        }
    };

    const viewColorsOptions = () => {
        if (selectedCategories.length > 0) {
            // basically only show the colors of the selected categeory
            return selectedCategories.reduce(
                (prev, current) => prev.concat(current.colors),
                []
            );
        }

        return rawStructure.colors;
    };

    const viewSizesOptionsMap = () => {
        /*
        * TODO filter by selected category
        * */
        setViewSizesOptions(rawStructure.sizes.map(x => x.size));
    };

    return {
        breadCrumbProps,
        currentPage,
        products,
        pageLimit,
        total_count,
        layout,
        loading,
        page,
        selectedColors,
        deleteSelectedColor,
        onSelectColor,
        showAllProduct,
        getLayout,
        setOffset,
        setCurrentPage,
        getFilterSortParams,
        pageLimit1,
        sideBarSettings: productSettings,
        sortSizeSetting,
        tabName,
        getSortParams,
        onHandleInput,
        searchQuery,
        onSelectCategory,
        structuredData,
        newColor,
        viewSizesOptions,
        selectedCategories,
        onSelectTag,
        tags,
        selectedTags,
        selectedSizes,
        onSelectSize,
        deleteSelectedCat,
        deleteSelectedTag,
        deleteSelectedSize,
        generalDescription,
        themeLayout,
        rawStructure,
        sortingOrderFilter,
        filterSortType,
    }
}
