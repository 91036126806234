import React, { useEffect } from 'react'
import CheckoutTextField from '../CheckoutTextField'
import CheckoutDropdown from '../CheckoutDropdown'
import { CheckoutQuestionForm } from '../../../models/CheckoutQuestion'
import { QuestionType } from '../../../models/Question'
import { Stack } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form-mui'

interface CheckoutQuestionFieldProps {
  field: CheckoutQuestionForm
  index: number
}

export default function CheckoutQuestionField({ field, index }: CheckoutQuestionFieldProps) {
  const { setValue } = useFormContext()

  const choiceValue: string = useWatch({ name: `questions.${index}.choiceValue` })

  const displayMultipleFormTextField =
    field.type === QuestionType.MULTIPLE_FORM && field.choices?.find((c) => c.value === choiceValue)?.additionalField

  useEffect(() => {
    if (
      field.type === QuestionType.MULTIPLE_FORM &&
      !field.choices?.find((c) => c.value === choiceValue)?.additionalField
    ) {
      setValue(`questions.${index}.value`, '')
    }
  }, [field, choiceValue, setValue, index])

  return (
    <>
      <Stack direction={'column'}>
        {[QuestionType.MULTIPLE, QuestionType.MULTIPLE_FORM].includes(field.type) && (
          <CheckoutDropdown
            key={field.questionId + 'dropdown'}
            name={`questions.${index}.choiceValue`}
            label={field.question}
            options={field.choices?.map((c) => ({ id: c.value, label: c.value })) ?? []}
            rules={{ required: field.isRequired }}
          />
        )}
        {(field.type === QuestionType.FREE || displayMultipleFormTextField) && (
          <CheckoutTextField
            key={field.questionId + 'textfield'}
            name={`questions.${index}.value`}
            label={displayMultipleFormTextField ? '' : field.question}
            rules={{ required: field.isRequired }}
          />
        )}
      </Stack>
    </>
  )
}
