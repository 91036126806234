import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import Decimal from 'decimal.js';
import {connect, useDispatch, useSelector} from "react-redux";
import { getProductCartQuantity, showCurrencycodeSymbol, getStock, getPersColor, getPersFont, getPersLocation, getPersNote,getminQuantity, roundedPrice, getPriceByColorSizeQty, getMoqNote, getShowMoqNote } from "../../helpers/product";
import { addToCart } from "../../redux/actions/cartActions";
import { addToWishlist } from "../../redux/actions/wishlistActions";
import { addToCompare } from "../../redux/actions/compareActions";
import { formatPriceFixedDecimalPlaces } from "../../common/PriceFormat";
import {
	fetchDecorationLocations,
	fetchArtworksById,
	receiveDecorationLocations
} from "../../redux/actions/productActions";
import Rating from "./sub-components/ProductRating";
import { AuthContext } from "../../common/Auth"
import AddDecoration from "./sub-components/AddDecoration"
import Button from "@material-ui/core/Button/Button";
import { Table } from "react-bootstrap";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import * as productsAPIUtil from "../../util/products/productsAPIUtil"
import * as advanceAPIUtil from "../../util/advance/advanceAPIUtil"
import AddnewArtDialogue from "../../components/artwork/AddnewArtDialogue"
import { useHistory } from "react-router-dom";
import { animateScroll } from 'react-scroll'
import Loader from "../../common/Loader";
import { USERTYPES } from '../../constant/userType'
import axios from "axios";
import { X_TENANT_ID } from "../../common/ActionUrl";
import { replaceOrigin } from "../../util/helper";
import SharedModal from "../../wrappers/modal/SharedModal";
import { useThemeSettingsData } from "../../layouts/state/useThemeSettingsData";
import { useThemeSettings } from "../../layouts/state/useThemeSettings";
import PriceTable from "../../wrappers/product/productPrice/PriceTable";
import { useProductConfigState } from "../../pages/shop-product/state/useProductConfigState";
import Select from 'react-select'
import { useProductPriceToShow } from "./state/useProductPriceToShow";
import { useProductArtworkState } from "./state/useProductArtworkState";
import ProductPriceCharges from "./sub-components/ProductPriceCharges";
import { Typography } from "@material-ui/core";
import { addOnChargesPersBody } from "../../common/AdvanceBodies";
import ProductPriceUni from "./sub-components/ProductPriceUni";
import { useProductDecoCharge } from "./state/useProductDecoCharge";
import useCartQuantity from "../../pages/shop-product/state/useCartQuantity";

const ProductDescriptionInfoSlider = ({
	product,
	discountedPrice,
	currency,
	finalDiscountedPrice,
	finalProductPrice,
	cartItems,
	wishlistItem,
	compareItem,
	_addToast,
	addToCart,
	addToWishlist,
	addToCompare,
	fetchDecorationLocations,
	setToggle,
	toggle,
	setSelectedDecorations,
	fetchArtworksById,
	decorationLocations,
	setSelectedColor,
	selectedColor,
	artworkListById,
	artworkList,
	showSell,
	showSellPrice,
	offerPrice,
	discountType,
	finalProductDiscPrice,
	setSingleSelectedDecorations,
	setSelectedImage,
	selectedImage,
	setSwipeimageFlag,
	singleSelectedDecorations,
	tempfun,
	isReplace,
	setIsReplace,
	setTempValueCheck,
	tempValueCheck,
	showPrice,
}) => {

	// merge all artwork
	artworkListById = artworkListById?.concat(artworkList?.filter(al => artworkListById?.every(ali => ali?.id != al?.id)));

	const { user, settingData, storeDetails, themeSettingsData, isDirectToCart, userGroupDiscount, showAddToCartOnProd, decoTypeList,userType, ipV4, usePointsAsCurrency, pointValue } = useContext(AuthContext)
	const [temp, setTemp] = useState(0);
	const [productDstInventory, setProductDstInventory] = useState(null);
	const [isMoq,setIsMoq]=useState(false);
	const [isPriceBreak, setIsPriceBreak] = useState(false)
	const [isSkuMoq,setIsSkuMoq]=useState(false);
	const [isRestrictQty,setIsRestrictQty]=useState(false)
	const [partPrice,setPartPrice]=useState([]);
	const [minQty,setMinQty]=useState("1");
	const [minAllQty,setMinAllQty]=useState([])
	const [prodId,setProdId]=useState(null)
	const [productId, setProductId] = useState(null)
	const [searchTextArtwork,setTextSearchArtwork]=useState('')
	const [isSearchArtwork,setIsSearchArtwork]=useState(false)
	const [personalizationCost, setPersonalizationCost] = useState({
		matchOrderQty: 0,
		price: 0,
		setupPrice: 0,
		chargeId: ''
	})
	const [finalPrice, setFinalPriceProduct] = useState(Number(0))
	const [customDecorationFee, setCustomDecorationFee] = useState(false)
	const [allowCustomDecoration, setAllowCustomDecoration] = useState(false)
	const [customDecorationPerUnitPrice, setCustomDecorationPerUnitPrice] = useState(null)
	const [customDecorationSetupFee, setCustomDecorationSetupFee] = useState(null)
	const [personalizationPerUnitPrice, setPersonalizationPerUnitPrice] = useState(null)
	const [personalizationSetupFee, setPersonalizationSetupFee] = useState(null)
	const [allowOneDec, setallowOneDec] = useState('')
	const [RequiredDecorationForCheckOutPro, setRequiredDecorationForCheckOutPro] = useState(false)
	const [IsAllowOutOfStockOrder, setIsAllowOutOfStockOrder] = useState(false)
	const [moqNote, setMoqNote] = useState('Minimum order quantity for this item is [MOQ]');
	const [showMoqNote, setShowMoqNote] = useState(false);
	const [updatePriceTable, setUpdatePriceTable] = useState([])
	const [isAdded, setIsAdded] = useState(false)
	const [decorationListOrder, setDecorationListOrder] = useState(false);
	const [preventAutoAttach, setPreventAutoAttach] = useState(false)
	const [showPersonalizationCostInCart, setShowPersonalizationCostInCart] = useState(false);
	const [priceTables, setPriceTable] = useState([])
	const [personalizationDropDown, setPersonalizationDropDown] = useState(false)
	const [personalizationRequired, setPersonalizationRequired] = useState(false)
	const [requiredLinesPersonalization, setRequiredLinesPersonalization] = useState('')
	const [dropDownValues, setDropDownValues] = useState([])
	const [appliedFirstDeco, setAppliedFirstDeco] = useState(false)
	const [defaultSizeForAll, setDefaultSizeForAll] = useState([])
	const [storeArtwork, setStoreArtwork] = useState([])
	const [decorationLocation, setDecorationLocations] = useState([]);
	const [uniqueLocation, setUniqueLocation] = useState('');
	const [showDecoPopup, setShowDecoPopup] = useState(false);
	const [indexPopup, setIndexPopup] = useState([])
	const [mainArrayKeyPopup, setMainArrayKeyPopup] = useState([])
	const [itemsRowsPopup, setItemsRowsPopup] = useState([])
	const [popupText, setPopupText] = useState('');
	const [artworkLocations, setArtworkLocations] = useState([]);
	const [showColor, setShowColor] = useState(false);
	const [showSize, setShowSize] = useState(false);
	const [loadingArtworks, setLoadingartworks] = useState(false);
	const [showDecorationCostInCart, setShowDecorationCostInCart] = useState(false);
	const [showSetupChargeInCart, setShowSetupChargeInCart] = useState(false);
	const [qtyDropDown, setQtyDropDown] = useState(false)
	const [qtyValues, setQtyValues] = useState([])
	const [colorLabel, setColorLabel] = useState('Color')
	const [sizeLabel, setSizeLabel] = useState('Size')
	const [decimalsToShow, setDecimalsToShow] = useState(2);
	const [isStockFor, setIsStockFor] = useState(null)
	const [quantityToShow, setQuantityToShow] = useState("1")
	const [selectedProductColor, setSelectedProductColor] = useState("");
	const [selectedProductLocation, setSelectedProductLocation] = useState("");
	const [selectedProductArtwork, setSelectedProductArtwork] = useState("");
	const [selectedProductDecorations, setSelectedProductDecorations] = useState([]);
	const [locationLabel, setLocationLabel] = useState('')
	const [showLocationLabel, setShowLocationLabel] = useState(true)
	const [locationPrompt, setLocationPrompt] = useState('')
	const [personalizationArray, setPersonalizationArray] = useState(null);
	const [addDecorations, setAddDecorations] = useState(false);
	const [selectedProductSize, setSelectedProductSize] = useState();
	const [showAddnewart, setAddnewart] = useState(false);
	const [productStock, setProductStock] = useState(
		product.variation ? product.variation[0].size[0].stock : product.stock
	);
	const [quantityCount, setQuantityCount] = useState(1);
	const [isFirstTime, setIsFirstTime] = useState(true)
	const [hideDecorationName, setHideDecorationName] = useState(null)
	const [discountedBasePrice, setDiscountedBasePrice] = useState(null)
	const [discountedTotalPrice, setDiscountedTotalPrice] = useState(null)
	const [hideLocationName, setHideLocationName] = useState(null)
	let productPrice = product.price ? product.price : 0
  	let productDiscount = product.discount ? product.discount : 0
	const productCartQty = getProductCartQuantity(
		cartItems,
		product,
		selectedProductColor,
		selectedProductSize
	);
	const {
		productSettings,
		inventorySettings,
		isStock,
	} = useThemeSettingsData({themeSettingsData})
	
	const {
		priceDisplay,
		itempriceDisplay,
		showCompareToggle,
		showWishListToggle,
		artworkSetting,
		sortSizeSetting,
		itemNumber
	} = useThemeSettings({settingData})
	
	const {

		getPointsFromValue,
		getPointsValue,
		getPriceToShow
	} = useProductPriceToShow({ productSettings, product , decimalsToShow})

	const dispatch = useDispatch()


  const cartQuantity = useCartQuantity({
    productId: product.id,
    multiSkuEnabled: isPriceBreak,
    selectedProductColor,
    selectedProductSize,
  });


	/*useEffect(() => {
	setFinalPriceProduct(Number(finalProductPrice))
  }, [finalProductPrice])*/

	useEffect(() => {
		if (sortSizeSetting?.length) {
			setDefaultSizeForAll(sortSizeSetting.map(item => item.value.toLowerCase()));
		}
	}, [sortSizeSetting])

  let locationName = null
	const history = useHistory();
	let itemFromCart = null;
	//let tempitemFromCart = cartItems?.filter(item => item.id === product.id)
	//if (tempitemFromCart?.[0]) {
	//	itemFromCart = tempitemFromCart[0]
	//}
	let groups = [];
	let colors = [];
	let priceTable = [];
	let colors_final = [];
	let colors_ids = [];
	let colors_hex = [];
	let colors_idsColor = [];
	let priceTableTemp = []
	product?.ProductPartArray?.ProductPart && product.ProductPartArray.ProductPart.forEach(part => {
		let priceOption = (part);
		const size = part.ApparelSize.labelSize;
		if (!groups[size]) {
			groups[size] = [];
		}
		groups[size].push(priceOption);

		const color = part.ColorArray.Color.colorName;
		colors_ids[part.partPrice.partId] = color;
		colors_idsColor[color] = part.partPrice.partId;
		colors_hex[color] = part.ColorArray.Color.hex;

		if (!colors[color]) {
			colors[color] = [];
		}
	});
	for (let size in groups) {
		priceTableTemp.push({size: size, priceOptions: groups[size]})
	}

	priceTableTemp = priceTableTemp.sort((a,b) => {
		return defaultSizeForAll.indexOf(a.size.toLowerCase()) - defaultSizeForAll.indexOf(b.size.toLowerCase());
	})
	let priceTableTemp1 = []
	let priceTableTemp2 = []

	priceTableTemp.map((a) => {
		if(defaultSizeForAll.indexOf(a.size.toLowerCase()) !== -1) {
			priceTableTemp1.push(a)
		} else {
			priceTableTemp2.push(a)
		}
	})

	if(priceTableTemp2.length > 0) {
		priceTableTemp1 = priceTableTemp1.concat(priceTableTemp2)
	}

	priceTableTemp1.map(res => {
		priceTable.push(res)
	})
	for (let color in colors) {
		colors_final.push(color);
	}
	
	const numericSort = (a, b) => {
		const aNumeric = parseInt(a);
		const bNumeric = parseInt(b);
		return aNumeric - bNumeric;
	};
	colors_final = colors_final.sort(numericSort);

	useEffect(() => {
		if(!!product?.relabelColor) {
			setColorLabel(product.relabelColor)
		}
		if(!!product?.relabelSize) {
			setSizeLabel(product.relabelSize)
		}
	},[product])

  const resetQuantity = useCallback(() => {
    if (qtyDropDown) return;
    setQuantityCount(1)
    setQuantityToShow("1")
  }, [qtyDropDown])
	
	useEffect(() => {
		if (quantityToShow === "") {
			resetQuantity()
		} else {
			const formattedValue = quantityToShow.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
			setQuantityToShow(formattedValue);
		}
	}, [quantityCount, quantityToShow]);

	useEffect(() => {
        if (product) {
            if (product.WebstoreProductPartArray?.length) {
                const partPriceArray = product.WebstoreProductPartArray.find(x =>
                    x.active &&
                    (selectedColor && selectedColor !== colorLabel ? x.color === selectedColor : x.color === colors_final[0]) &&
                    (selectedProductSize && selectedProductSize !== sizeLabel ? x.size === selectedProductSize : true)
                );
                if (partPriceArray) {
                    const priceTable = partPriceArray.partPrice?.PartPriceArray?.PartPrice ?? []
                    setPriceTable(priceTable)
                    setQtyValues(priceTable.map(x => x.minQuantity))
                }
            } else if (product?.ProductPartArray?.ProductPart && product?.ProductPartArray?.ProductPart.length > 0) {
				const defaultColor = colors_final?.[0] || '';
				const selectedColorToCompare = selectedColor || defaultColor;
				const selectedSizeToCompare = selectedProductSize || '';
				const part = product.ProductPartArray.ProductPart.find(part => {
					const colors = part?.ColorArray?.Color?.colorName ?? part.color;
					const size = part?.ApparelSize?.labelSize ?? part.size;
					return selectedColorToCompare.toLowerCase() === colors.toLowerCase() &&
						(selectedSizeToCompare === '' || selectedSizeToCompare === size);
				});
				if (part) {
					const priceTable = part.partPrice?.PartPriceArray?.PartPrice ?? [];
					setPriceTable(priceTable);
					setQtyValues(priceTable.map(x => x.minQuantity));
				}
            }
            setQtyDropDown(!!product.qtyDropDown)
        }
    }, [product, selectedColor, selectedProductSize])
	
	const colorStyles = {
		container: (styles) => ({...styles, textAlign: 'left',
		}),
		input: (styles) => ({...styles,  position: "absolute",
		  top: "0",
		  width: "100%",
		  height: "40px",
		  left: "0",
		  padding: "0",
		  margin: "0",
		  display: "block",
		  '& > input' : {
			position: "absolute",
			top: "-4px",
			height: "40px",
			left: "0",
			paddingLeft: "10px !important",
		  },
		}),
		singleValue : (styles) => ({...styles,
		  top:'3px',
		  position: "relative",
		}),
		placeholder : (styles) => ({...styles,
		  top:'3px',
		  position: "relative",
		}),
		control: (styles) => ({ ...styles, backgroundColor: 'transparent',
		  border: '1px solid #ebebeb',
		  color: '#333',
		  fontSize: '14px',
		  position: "relative",
		  borderRadius:0,
		  height:"38px",
		  textAlign:"left !important",
		  '& > div' : {
			height: "100% !important",
			display: "block !important",
			overflow: "unset !important",
		  },
		}),
	};

	useEffect(() => {
		if(product?.personalizationDropDown === true || product?.personalizationDropDown === false) {
		  setPersonalizationDropDown(product?.personalizationDropDown)
		  setRequiredLinesPersonalization(product?.requiredLinesPersonalization)
		  setDropDownValues(product?.dropDownValues)
		} else if(productSettings?.personalizationDropDown === true || productSettings?.personalizationDropDown === false) {
		  setPersonalizationDropDown(productSettings?.personalizationDropDown)
		  setRequiredLinesPersonalization(productSettings?.requiredLinesPersonalization)
		  setDropDownValues(productSettings?.dropDownValues)
		}
		if (product?.personalizationRequired === true || product?.personalizationRequired === false) {
			setPersonalizationRequired(product?.personalizationRequired)
		} else if (productSettings?.personalizationRequired === true || productSettings?.personalizationRequired === false) {
			setPersonalizationRequired(productSettings?.personalizationRequired)
		}
		if (product?.hideDecorationName === true || product?.hideDecorationName === false) {
			setHideDecorationName(product?.hideDecorationName)
		} else {
			setHideDecorationName(!!productSettings?.hideDecorationName ? productSettings.hideDecorationName : false)
		}
		if (product?.hideLocationName === true || product?.hideLocationName === false) {
			setHideLocationName(product?.hideLocationName)
		} else {
			setHideLocationName(!!productSettings?.hideLocationName ? productSettings.hideLocationName : false)
		}
	},[product, productSettings])

	useEffect(() => {
		if (personalizationRequired) {
			addPersonalization();
		}
	}, [personalizationRequired])

	const artworksListState = useSelector((state) => state.productData.artworkList);
	const artworksProductState = useSelector((state) => state.productData.artworkListById);
	const artworksFromState = [...artworksListState, ...artworksProductState]
	const productStockState = useSelector((state) => state.productData.productStock);

	const {
		checkMinQty,
		orderAction,
		getDecorationCost,
		getSettingStatus,
		getCartItemTotal,
		getLocations,
	} = useProductConfigState({
		cartItems,
		product,
		artworksFromState,
		artworksProductState,
		allowCustomDecoration,
		selectedProductColor,
		selectedProductDecorations,
		selectedProductLocation,
		artworkSetting,
	})


	useEffect(() => {
		if(product?.allowCustomDecoration === true || product?.allowCustomDecoration === false) {
		  setAllowCustomDecoration(product?.allowCustomDecoration)
		} else {
		  setAllowCustomDecoration(!!productSettings?.allowCustomDecoration ? productSettings.allowCustomDecoration : false)
		}
	},[productSettings, product])


	const {
		selectDecoration, getDecoPrice
	} = useProductArtworkState({
		artworksFromState,
		getSettingStatus,
		setLoading: (loading) => setLoading(loading)
	})
	const {
		getAdditionalDecorationCharge
	} = useProductDecoCharge({ artworksFromState: artworksFromState })


	useEffect( ()=>{
		if ( artworksFromState?.length && selectedProductColor && !appliedFirstDeco) {
			setTimeout( async ()=>{
				setAppliedFirstDeco(true)
				await applyFirstColor(colors_final.length === 1 ? colors_final[0] : selectedProductColor)
			}, 1)
		}

	}, [ artworksProductState])

	const applyFirstColor = async (color)=>{
		let getImage = product?.WebstoreProductPartArray.filter((val)=>val.color === color && val.isSwapImage === true,)
		let tempDecoArray = []
		if ( getImage.length === 0 ) {
			tempDecoArray = await selectDecoration(color)
			setIsReplace(true)
		} else {
			setIsReplace(false)
		}
		if ( tempDecoArray.length ) {
			setSingleSelectedDecorations(tempDecoArray[tempDecoArray.length - 1])
			setSelectedProductDecorations(tempDecoArray)
			setTemp(temp + 1)
		}

	}


	useEffect(() => {
		if(productStockState) {
			setProductDstInventory(productStockState.dstInventory)
			setProductInventory(productStockState.inventory)
		}
	}, [productStockState])

	if (!selectedProductLocation && decorationLocation?.length === 1) {
		let locationCount = artworksFromState.filter(
			(val) =>
			val.artworkLocation == decorationLocation?.[0]?.locationName,
		)

		if (locationCount > 1) {
		} else {
			setSelectedProductLocation(decorationLocation?.[0]?.id);
			setUniqueLocation(decorationLocation?.[0]?.locationName);
		}
	}
	const getStoreArtwork = () => {
		const requestOptions = {
			headers: {
				'Content-Type': 'application/json',
				'X-TENANT-ID': X_TENANT_ID,
			},
		}
		axios
			.get('/api/v1/artworkdata/', requestOptions)
			.then((res) => {
				if (res.data.error) {
					_addToast(res.data.message, {
						appearance: 'error',
						autoDismiss: true,
					})
				} else {
					setStoreArtwork(res.data)
				}
			})
			.catch((err) => {
				console.log('err', err)
			})
	}

	useEffect(()=>{
		if ( product?.preventAutoAttach === true || product?.preventAutoAttach === false ) {
		  setPreventAutoAttach(product?.preventAutoAttach)
		} else {
		  setPreventAutoAttach(!!productSettings?.preventAutoAttach ? productSettings.preventAutoAttach : false)
		}
	}, [product, productSettings])

	useEffect(() => {
		if(!!!product.isStockFor || product.isStockFor === 'globalsetting') {
			setIsStockFor(isStock)
		} else {
			setIsStockFor(product.isStockFor)
		}
	}, [product, isStock])

	useEffect(() => {
		getStoreArtwork()
		getArtworkLocations()
	}, [])

	const getArtworkLocations = () => {
		productsAPIUtil.getEnabledArtworkLocations(product._id).then(response => {
			setArtworkLocations(response.data);
		}).catch(error => {
			console.log('error', error);
			_addToast('Error while loading data!', {
				appearance: "error",
				autoDismiss: true
			});
		})
	  }

	const searchArtworkName = (e) => {
		setTextSearchArtwork(e.target.value)
	}

	useEffect(()=> {
		getPriceByColorSizeQty(product, quantityCount + cartQuantity,
			selectedProductColor,
			selectedProductSize,
			userGroupDiscount).then(res=>{
			setFinalPriceProduct((new Decimal(Number(res.price)).toDP(decimalsToShow)).toNumber());
				setDiscountedBasePrice((new Decimal(Number(res?.basePrice)).toDP(decimalsToShow)).toNumber())
          		setDiscountedTotalPrice((new Decimal(Number(res?.price)).toDP(decimalsToShow)).toNumber())
			})
	},[isPriceBreak, selectedProductSize, selectedProductColor, product, quantityCount, userGroupDiscount, cartQuantity])
	

	useEffect(() => {
		if(product?.RequiredDecorationForCheckOutPro === true || product?.RequiredDecorationForCheckOutPro === false) {
			setRequiredDecorationForCheckOutPro(product?.RequiredDecorationForCheckOutPro )
        }
        else {
            setRequiredDecorationForCheckOutPro(productSettings?.RequiredDecorationForCheckOut)
        }
		if (product?.showColorProd === true || product?.showColorProd === false) {
            setShowColor(product?.showColorProd)
        } else {
            setShowColor(productSettings?.showColor)
        }
        if (product?.showSizeProd === true || product?.showSizeProd === false) {
            setShowSize(product?.showSizeProd)
        } else {
			setShowSize(productSettings?.showSize)
        }
		if(!!product?.renameLocationNameLabel) {
			setLocationLabel(product?.renameLocationNameLabel)
		} else if(!!productSettings?.renameLocationNameLabel) {
			setLocationLabel(productSettings?.renameLocationNameLabel)
		} else {
			setLocationLabel('Select Location')
		}
		if(product?.showDecorationLocationLabel === false || product?.showDecorationLocationLabel === true) {
			setShowLocationLabel(product?.showDecorationLocationLabel)
		} else {
			setShowLocationLabel(productSettings?.showDecorationLocationLabel)
		}
		if(!!product?.renameLocationNamePrompt) {
			setLocationPrompt(product?.renameLocationNamePrompt)
		} else if(!!productSettings?.renameLocationNamePrompt) {
			setLocationPrompt(productSettings?.renameLocationNamePrompt)
		} else {
			setLocationPrompt('Select Location')
		}
		if (product?.showDecorationCostInCart === true || product?.showDecorationCostInCart === false) {
			setShowDecorationCostInCart(product?.showDecorationCostInCart)
		} else {
			setShowDecorationCostInCart(productSettings?.showDecorationCostInCart)
		}
		if (product?.showSetupChargeInCart === true || product?.showSetupChargeInCart === false) {
			setShowSetupChargeInCart(product?.showSetupChargeInCart)
		} else {
			setShowSetupChargeInCart(productSettings?.showSetupChargeInCart)
		}
		if(!!product?.AllowOneDecorationLimitPro && product?.AllowOneDecorationLimitPro !== " ") {
			setallowOneDec(product?.AllowOneDecorationLimitPro)
		} else {
			setallowOneDec(productSettings?.AllowOneDecorationLimit === " " ? "0" : productSettings?.AllowOneDecorationLimit)
		}
		if(product?.AllowOutofStock === true || product?.AllowOutofStock === false) {
			setIsAllowOutOfStockOrder(product?.AllowOutofStock)
		} else {
		  setIsAllowOutOfStockOrder(inventorySettings?.isAllowoutofStockOrder)
		}
		if(product && productSettings && minQty) {
			setMoqNote(getMoqNote(productSettings, product, minQty));
			setShowMoqNote(getShowMoqNote(productSettings, product));
		}
		if(product?.showPersonalizationCostInCart === true || product?.showPersonalizationCostInCart === false) {
			setShowPersonalizationCostInCart(product?.showPersonalizationCostInCart)
		} else {
			setShowPersonalizationCostInCart(productSettings?.showPersonalizationCostInCart)
		}
		if(!!product?.decimalsToShow) {
			setDecimalsToShow(Number(product?.decimalsToShow))
		} else if(!!productSettings?.decimalsToShow) {
			setDecimalsToShow(Number(productSettings?.decimalsToShow))
		} else {
			setDecimalsToShow(2);
		}
	},[product, productSettings, inventorySettings, minQty])

	useEffect(() => {
		if (selectedProductDecorations) {
			setSelectedDecorations(selectedProductDecorations)
			setToggle(!toggle)
		}
	}, [selectedProductDecorations.length])

	useEffect(() => {
		getAlertMessageProductDecoration()
	}, [])

	useEffect(() => {
		verifyShowSize();
      	verifyShowColor();
	}, [showSize, showColor])

	const verifyShowSize = () => {
		if(priceTable.length === 1) {
		  setSelectedProductSize(priceTable[0].size);
		}
		if(!showSize && priceTable.length > 1) {
			setShowSize(true);
		}
	}
	const verifyShowColor = () => {
		if(colors_final.length === 1) {
		  setSelectedColor(colors_final[0]);
		  setSelectedProductColor(colors_final[0]);
		  setAddDecorations(true)
		}
		if(!showColor && colors_final.length > 1) {
			setShowColor(true);
		}
	}

	useEffect(() => {
        verifyShowSize()
        verifyShowColor()
    }, [showColor, showSize])

	const goToCart = () => {
		if(colors_final.length === 1){
            setTempValueCheck(0)
			setAddDecorations(true)
            colorselectbox()
        } else {
            setSelectedProductColor('');
			setAddDecorations(false)
        }
        if(priceTable.length === 1) {
            setSelectedProductSize('')
            setTempValueCheckSize(0)
			setAddDecorations(true)
            sizeselectbox()
			if (priceTable.length === 1 && !showSize) {
				setSelectedProductSize(priceTable[0].size);
			}
        } else {
            setSelectedProductSize('')
			setAddDecorations(false)
        }
    resetQuantity()
		setSelectedProductDecorations([]);
		if (personalizationRequired) {
			addPersonalization(true);
		} else {
			setPersonalizationArray(null)
			setPersonalizationCost({
				matchOrderQty: 0,
				price: 0,
				setupPrice:0,
				chargeId: ''
			})
		}
		setSelectedProductLocation('')
    setUniqueLocation('')
		localStorage.setItem("redirectUrl", window.location.pathname)
		if (isDirectToCart) {
			history.push("/cart");
		}
	}

	useEffect(() => {
		if (storeDetails?.accountId) {
			setLoadingartworks(true)
			fetchArtworksById({
				account_id: storeDetails.accountId,
				product_id: product.id,
				userId: user?.userId || undefined,
				categories: product.ProductCategoryArray|| [],
				restrict: true,
			}).finally(() => {
				setLoadingartworks(false)
			})
		}
	}, [storeDetails])

	useEffect(() => {
		const locations = getLocations();
		if (locations?.locationIds?.length > 0 && isFirstTime) {
			setIsFirstTime(true)
			fetchDecorationLocations(locations.locationIds)
		}  else if(locations?.locationIds?.length === 0 && isFirstTime) {
			dispatch(
				receiveDecorationLocations({
						data: []
					}
				),
			)
			setIsFirstTime(true)
		}
	}, [product, artworksProductState, selectedProductColor])

	useEffect(() => {
		if (personalizationArray) {
			addPersonalization()
		}
	}, [quantityCount])

	const fetchPersonalizationCost = async () => {
		setLoading(true)
		try {
		  let result = await advanceAPIUtil.customAdvanceCall(addOnChargesPersBody)
		  if (result?.data?.data) {
			let personalizationData = result?.data?.data.filter(
			  (val) => val.itemCode === 'PERS',
			)
			if (personalizationData) {
			  setPersonalizationCost((preVal) => ({
				...preVal,
				['matchOrderQty']: personalizationSetupFee?1:0,
                ['price']:personalizationPerUnitPrice ? personalizationPerUnitPrice: 0,
			    ['setupPrice']:personalizationSetupFee ? personalizationSetupFee:0,
				['chargeId']: personalizationPerUnitPrice ? '' : ''
			  }))
			  setLoading(false)
			}
		  }
		} catch (error) {
		  console.log('error', error)
		}
	  }
	useEffect(() => {
		if (selectedColor) {
			setSelectedProductColor(selectedColor)
		}
	}, [selectedColor])
	const [loading, setLoading] = useState(false);
	const handleCancel = () => {
		setAddnewart(false)
	}
	const handleAddnewArt = (addedNewartdata) => {
		let artwork = addedNewartdata.newArtData;
		fetchArtworksById({
			account_id: storeDetails.accountId,
			product_id: product.id,
			userId: user?.userId || undefined,
			categories: product.ProductCategoryArray|| [],
			restrict: true,
		  })
		setAddnewart(false)
		if (artwork.id) {
			setSelectedProductArtwork(artwork.id)
			if (artwork?.design?.variation) {
				if (artwork.design.variation.length == 1) {
					let variation = (artwork.design.variation[0])
					let tempDecoArray = selectedProductDecorations ? selectedProductDecorations : [];
					let locationArray = decorationLocations?.filter(
						(locationArray) => locationArray?.locationName === artwork?.artworkLocation,
					)
					let decoObj = {
						artworkID: artwork.id,
						location: locationArray?.[0].locationName,
						locationId: locationArray?.[0]?.id,
						variationUniqID: variation.design_variation_unique_id,
					}
					let getDecoOfLocation = tempDecoArray.filter(val => val.locationId === locationArray?.[0]?.id)

					if (getDecoOfLocation.length === 0) {
						tempDecoArray.push(decoObj)
						setSingleSelectedDecorations(decoObj)
						setSelectedProductDecorations(tempDecoArray)
					}
					else if (getDecoOfLocation.length === 1) {
						let replaceTempDecoArray = tempDecoArray.filter(val => val.locationId !== locationArray?.[0]?.id)
						tempDecoArray = replaceTempDecoArray
						tempDecoArray.push(decoObj)
						setSingleSelectedDecorations(decoObj)
						setSelectedProductDecorations(tempDecoArray)
						setSelectedDecorations(tempDecoArray)
					}
					setSelectedProductLocation("")
          setUniqueLocation("")
					setSelectedProductArtwork("")
					setAppliedFirstDeco(true)
				}
			}
		}
	}
	const addPersonalization = (clear = false) => {
		if (!personalizationCost.price) {
			fetchPersonalizationCost()
		  }
		let persQty = Number(quantityCount.toString().replace(/,/g, ''));
		let persRows = [];
		if (Number(persQty) > 0) {
			let tempMainrows = !!personalizationArray && personalizationArray.filter(
				(row) => Number(row.sequance) === 1,
			)
			let itemQty = Number(persQty)
			let pushedIds = []
			for (let index = 0; index < itemQty; index++) {
				if (tempMainrows[index]) {
					let tempPersRow = personalizationArray.filter(
						(row) => row.rowQtyNo === tempMainrows[index].rowQtyNo,
					)
					if (pushedIds.indexOf(tempPersRow[0].rowQtyNo) === -1) {
					  pushedIds.push(tempPersRow[0].rowQtyNo)
					  tempPersRow.map((tempRow) => {
						persRows.push({
						  ...tempRow,
						  itemSize: selectedProductSize,
						  itemColor: selectedProductColor,
						  rowId: 1,
						})
					  })
					}
				} else {
					if(!!personalizationDropDown && !!requiredLinesPersonalization) {
					  !!dropDownValues && dropDownValues.length > 0 && dropDownValues.map((resData, keysData) => {
						persRows.push({
						  color: getPersColor(product, productSettings),
						  location: getPersLocation(product, productSettings),
						  font: getPersFont(product, productSettings),
						  notes: getPersNote(product, productSettings),
						  displayText: '',
						  itemCode: product.itemCode,
						  itemColor: selectedProductColor,
						  itemId: product.id,
						  itemNo: product.productId,
						  itemSize: selectedProductSize,
						  orderId: '',
						  rowId: 1,
						  rowQtyId: '',
						  rowQtyNo: index + 1,
						  sequance: 1 + keysData,
						  lineName: resData.personalizationDropDownRename,
						  isDrp: resData.isPersonalizationDropDown,
						  drpValue: resData.personalizationDropDownValue,
						  isEnabled: personalizationDropDown,
						  requiredLine: requiredLinesPersonalization,
						})
					  })
				   } else {
					persRows.push({
						color: getPersColor(product, productSettings),
						location: getPersLocation(product, productSettings),
						font: getPersFont(product, productSettings),
						notes: getPersNote(product, productSettings),
						displayText: "",
						itemCode: product.itemCode,
						itemColor: selectedProductColor,
						itemId: product.id,
						itemNo: product.productId,
						itemSize: selectedProductSize,
						orderId: "",
						rowId: 1,
						rowQtyId: "",
						rowQtyNo: (index + 1),
						sequance: 1
					})
				  }
			    }
			}
      if (clear) {
        persRows = persRows.map((row) => ({...row, displayText: ''}))
      }
			setPersonalizationArray(persRows)
		}
	}
	const getPesonalizationTable = () => {
		let rowQtyNo = null;
		let rowId = null;
		return <Table className="personalizationTabel"  >
			<thead>
				<tr>
					<td>Item</td>
					<td>{sizeLabel}</td>
					<td>Item {colorLabel}</td>
					<td>Line</td>
					<td>Text</td>
					<td></td>
				</tr>
			</thead>
			<tbody>
				{personalizationArray.map((personalization, key) => {
					let flag = true;
					if (Number(personalization.sequance) !== 1) {
						flag = false;
					}
					rowQtyNo = personalization.rowQtyNo;
					rowId = personalization.rowId;
					const objOptions = []
					if(!!personalization?.isDrp) {
					!!personalization?.drpValue && personalization?.drpValue.length > 0 && personalization.drpValue.map(op => {
						objOptions.push({ value: op, label: op })
					})
					}
					return <tr key={key}>
						<td>{flag && "Item " + personalization.rowQtyNo}</td>
						<td>{flag && personalization.itemSize}</td>
						<td>{flag && personalization.itemColor}</td>
						<td>{!!personalization?.lineName ? personalization?.lineName : 'Line ' + personalization.sequance}</td>
						<td>
							{!(!!personalization?.isDrp) ? (
								<input onChange={(e) => { changePersonalizatinArray(e, "displayText", key) }} value={personalization.displayText} />
							) : (
								<div className="selectDropDown">
									<Select
									noOptionsMessage={() => "No matches for this search"}
                  value={objOptions.find(op => op.value === personalization.displayText) ?? ''}
                  options={objOptions}
									styles={colorStyles}
									components={{
										DropdownIndicator: () => null,
										IndicatorSeparator: () => null,
									}}
									name="isDrpValuePer"
									onChange={(e) =>
										changePersonalizatinArray(e, "displayText", key, 1)
									}
									/>
								</div>
								)
							}
						</td>
						{(!(!!personalization?.isDrp) && !(!!personalization?.isEnabled) && !(!!personalization?.requiredLine)) ? (
						<td>
							{flag && <AddIcon onClick={() => { AddPersonalizationRow(personalization, key) }} />}
							{!flag && <DeleteIcon onClick={() => { DeletePersonalizationRow(personalization, key) }} />}
						</td>
						) :  ''}
					</tr>
				})}
			</tbody>
		</Table>
	}
	const DeletePersonalization = () => {
		setPersonalizationArray(null)
		setPersonalizationCost((preVal) => ({
			...preVal,
			['matchOrderQty']: 0,
			['price']: 0,
			['setupPrice']: 0,
			['chargeId'] : ''
		  }))
	}
	const DeletePersonalizationRow = (personalization, key) => {
		if (personalizationArray) {
			let tempArray = personalizationArray.filter((persArray, index) => {
				if (key === index) {
					return false
				}
				return persArray
			})
			setPersonalizationArray(tempArray)
		}
	}
	const AddPersonalizationRow = (personalization, key) => {
		if (personalizationArray) {
			let tempArray = []
			personalizationArray.map((persArray, index) => {
				tempArray.push(persArray)
				if (key === index) {
					tempArray.push({
						...persArray,
						color: getPersColor(product, productSettings),
						location: getPersLocation(product, productSettings),
						font: getPersFont(product, productSettings),
						notes: getPersNote(product, productSettings),
						displayText: "",
						sequance: (Number(persArray.sequance) + 1).toString()
					})
				}
			})
			setPersonalizationArray(tempArray)
		}
	}

	const changePersonalizatinArray = (e, type, key, isDrp = 0) => {
		if (personalizationArray) {
			let tempArray = personalizationArray.filter((persArray, index) => {
				if (key === index) {
					if (type == "Font") {
						persArray.font = e.target.value
					} else if (type == "Color") {
						persArray.color = e.target.value
					} else if (type == "Location") {
						persArray.location = e.target.value
					} else if (type == "Note") {
						persArray.notes = e.target.value
					} else if (type == "displayText") {
						persArray.displayText =  isDrp === 1 ? e.value : e.target.value
					}
				}
				return persArray
			})
			setPersonalizationArray(tempArray)
		}
	}
	const selectcolorBox = (selectcolorBox, key) => {
		return <select onChange={(e) => { changePersonalizatinArray(e, "Color", key) }} value={selectcolorBox.color}>
			<option value="0">Select</option>
			<option value="Black">Black</option>
			<option value="White">White</option>
		</select>
	}
	const selectfontBox = (selectfontBox, key) => {
		return <select onChange={(e) => { changePersonalizatinArray(e, "Font", key) }} value={selectfontBox.font}>
			<option value="0">Select</option>
			<option value="Script">Script</option>
			<option value="Block Upper Lower">Block Upper Lower</option>
			<option value="Block Upper">Block Upper</option>
			<option value="Italic Upper Lower">Italic Upper Lower</option>
			<option value="Italic Upper">Italic Upper</option>
			<option value="Drax Standard Font">Drax Standard Font</option>
		</select>
	}
	const changeLocation = (e) => {
		setLoading(true)
		setSelectedProductLocation(e.currentTarget.value)
    setUniqueLocation(e.currentTarget.options[e.currentTarget.selectedIndex].text)
		setIsAdded(false)

		let locationId = e.currentTarget.value
		let variationId = []

		if (selectedProductDecorations.length === 1 && allowOneDec === "2") {
			setSingleSelectedDecorations(null)
			setSelectedProductDecorations([])
			setSelectedDecorations(null)
		}

		if (artworksFromState && artworksFromState.length) {
			artworksFromState.map((paramval, key) => {
				paramval.productToDecorationMapping.map((parentVal) => {
					parentVal.mapping.map((val1)=> {
						if (val1.color == selectedProductColor && locationId === parentVal?.location) {
						if(!variationId.includes(val1.variationId)) {
							variationId.push(val1.variationId)
						}
						}
					})
				})
			})
			/*artworkListById.map((val, key) => {
				if (locationId == val?.productToDecorationMapping[0]?.location && (val?.productToDecorationMapping[0]?.isRequiredForStore === "1" || !(variationId.length > 1))) {
					locationId = val?.productToDecorationMapping[0]?.location
					val.productToDecorationMapping[0].mapping.map((mapVal, mapkey) => {
						if (mapVal.color === selectedProductColor) {

							setSelectedProductLocation(locationId)
							setSelectedProductArtwork(val.id)

							if (val.design.variation.length == 1) {
								addDecoration(val.design.variation[0], val.id, locationId, selectedProductColor)
							} else {
								let getVariation = ""
								val.design.variation.map((val, index) => {
									if (val.design_variation_color.includes(selectedProductColor)) {
										getVariation = index;
									}
								})
								addDecoration(val.design.variation[Number(getVariation)], val.id, locationId, selectedProductColor)
							}
						}
					})
				}
			})*/
		}
		setLoading(false)
	}

	const getDecoHtml = () => {
		const locations = getLocations();
		let decoExistLocations = locations.decoExistLocations;
		let yFilter = locations.yFilter
		let locationName = locations.locationName;
		let filteredX = decorationLocations.filter(itemX => yFilter.includes(itemX.locationName));
		let totalAdditionalCharges = 0
		const additionalCharges = []
		let tempDecorationLocations = filteredX
		if (decoExistLocations?.length > 0 && tempDecorationLocations?.length > 0) {
			if(decorationLocation.length !== tempDecorationLocations.length) {
				setDecorationLocations(tempDecorationLocations)
			}
			tempDecorationLocations = tempDecorationLocations.filter((f) => {
				if (decoExistLocations.indexOf(f.locationName) !== -1) {
					if(product.restrictAfterFirstChoice && artworksProductState.length > 0) {
						let numberChoice = 0;
						let selectedArtworks = selectedProductDecorations.filter((row) => row.artworkID) || []
						numberChoice = selectedArtworks.length === tempDecorationLocations.length
						  ? selectedArtworks.length - 1
						  : selectedArtworks.length;
						let arrayArtworks = artworkLocations.filter(x => x.numberChoice === numberChoice && !x.isEnable) ?? [];
						let artworksToReadFiltered = [];
						let arrayArtworksTemp = [];
						artworksFromState.forEach(x => {
							let index = arrayArtworks.findIndex(z => z.artworkId === x.id);
							if(index === -1) {
							  arrayArtworksTemp.push(x);
							}
						})
						arrayArtworksTemp.forEach(x => {
						  let insert = x.productToDecorationMapping.filter(y =>  y.locationName === f.locationName).length;
						  if(insert > 0) {
							artworksToReadFiltered.push(x);
						  }
						});
						return artworksToReadFiltered.length > 0;
					  } else {
						return true;
					  }
				}
				return false
			})
		}

		let newLocation = []
		if (selectedProductDecorations.length > 0 || tempDecorationLocations.length > 0) {
			tempDecorationLocations &&
				tempDecorationLocations.map((val, index) => {
					let artworkSelected = null
					let checkLocation =
						selectedProductDecorations && selectedProductDecorations.find((d) => d.locationId === val.id && d.variationUniqID && d.variationUniqID !== "" && d.variationUniqID !== undefined)

					if (checkLocation === undefined || checkLocation === '') {
						newLocation.push(val)
					}
				})
		}

		if (selectedProductDecorations.length > 0 && getSettingStatus('showDecoration')) {
			selectedProductDecorations.map((decoration, index) => {
				let artwork = null
				if (artworksFromState?.length) {
					artwork = artworksFromState.find(
						(artworkArray) => (artworkArray.id === decoration.artworkID && artworkArray.artworkLocation === decoration.location),
					)

					if (!!!artwork && artworksProductState?.length) {
						artwork = artworksProductState.find((artworkArray) =>
							artworkArray?.productToDecorationMapping?.some(
								(res) => res.locationName === decoration.location && artworkArray.id === decoration.artworkID
							)
						);
					}
					if (artwork) {
						const additionalChargeData = getAdditionalDecorationCharge (artwork, quantityCount, decoration.locationId)
						if( additionalChargeData ) {
							totalAdditionalCharges += additionalChargeData.totalAdditionalCharges;
							additionalCharges.push(...additionalChargeData.additionalCharges);
						}
					}
				}
			})
		}

		let toatlPrice = 0
		let totalSetupDecoCharges = 0
		let totalDecoCharges = 0
		let totalSetupCharge = 0
		let setupChargeName = 'Setup Charge'
        let setupCharges = []
		if(showSetupChargeInCart) {
			if(product?.ChargeArray?.Charge?.length > 0) {
				product.ChargeArray.Charge.forEach(charge => {
					if(setupCharges.findIndex(x => x.chargeCode === charge.chargeCode) === -1) {
						const chargeByQuantity = charge?.ChargePriceArray?.ChargePrice?.filter(x => Number(x.xMinQty) <= quantityCount)
						if(chargeByQuantity?.length > 0) {
							let totalSetupChargeUnit = Number(chargeByQuantity?.[chargeByQuantity.length - 1]?.salePrice ?? 0) * Number(charge?.matchOrderQty === "1" ? quantityCount : 1);
							if(totalSetupChargeUnit > 0) {
								setupChargeName = charge?.chargeName ?? 'Setup Charge';
								totalSetupCharge = Number(totalSetupCharge) + totalSetupChargeUnit
								toatlPrice = Number(toatlPrice) + Number(totalSetupCharge)
								setupCharges.push({totalSetupCharge: totalSetupChargeUnit, setupChargeName, chargeCode: charge.chargeCode})
							}
						}
					}
				})
			}

			additionalCharges.forEach(additionalCharge => {
				if (additionalCharge?.totalSetupCharge) {
					setupCharges.push(additionalCharge)
				}
			})

			totalSetupCharge = totalSetupCharge + totalAdditionalCharges
    	}
		let valueForDecorationCost = getDecorationCost(selectedProductDecorations, quantityCount, customDecorationFee, showSetupChargeInCart, customDecorationPerUnitPrice, toatlPrice, customDecorationSetupFee, allowCustomDecoration)
		toatlPrice = valueForDecorationCost.toatlPrice;
		totalDecoCharges = valueForDecorationCost.totalDecoCharges;
		totalSetupDecoCharges = valueForDecorationCost.totalSetupDecoCharges;
		let rPrice = (Number(finalDiscountedPrice) > 0 ? Number(finalDiscountedPrice) : Number(finalPrice))
		let mainPersonalizationCost = 0
		if(getSettingStatus('showPersonalization') && !showPersonalizationCostInCart && personalizationCost && !!personalizationCost?.price) {
			let personalizationCost2 = (quantityCount > 0 ? formatPriceFixedDecimalPlaces(personalizationCost?.price, decimalsToShow) : 0)
		  if (personalizationCost.matchOrderQty == '1' && !showPersonalizationCostInCart && product?.doNotCalculateAtOrder == "1" && isRestrictQty) {
				mainPersonalizationCost = Number(personalizationCost2) * Number(quantityCount)
		  } else {
			rPrice = Number(rPrice) + Number(personalizationCost2)
		  }
		}
		let calPriceIsPrice = Number(rPrice) * Number(quantityCount)
		if (product?.doNotCalculateAtOrder == "1" && Number(quantityCount) > 0 && product?.isRestrictQty) {
			calPriceIsPrice = Number(rPrice) + mainPersonalizationCost
		}
		toatlPrice = (calPriceIsPrice + Number(showSetupChargeInCart ? totalSetupDecoCharges : 0) + Number(totalDecoCharges) + Number(totalSetupCharge))

		if (personalizationCost?.price !== '' && personalizationCost?.price !== 'undefined' && personalizationCost?.price !== undefined) {
		  if (personalizationCost.matchOrderQty == '0') {
			toatlPrice = Number(toatlPrice) + Number(personalizationCost.price)+Number(showSetupChargeInCart ? personalizationCost.setupPrice : 0)
		  } else if (personalizationCost.matchOrderQty == '1' && showPersonalizationCostInCart) {
			toatlPrice =
			  Number(toatlPrice) +
			  Number(personalizationCost.price) * Number(quantityCount) + (Number(showSetupChargeInCart ? personalizationCost.setupPrice : 0))
		  } else if (personalizationCost.matchOrderQty == '1' && showSetupChargeInCart) {
			toatlPrice = Number(toatlPrice) + (Number(personalizationCost.setupPrice))
		  }
		}
		if((newLocation.length === 1) && !!(newLocation?.[0]?.locationName) && (allowOneDec === "0" || selectedProductDecorations.length === 0)) {
			if (uniqueLocation === '' || newLocation[0].locationName !== uniqueLocation) {
				setSelectedProductLocation(newLocation[0].id);
				setUniqueLocation(newLocation[0].locationName);
			}
		}
		return (
	   <div className="mt-3 mb-3">
		   {
			   // TODO: why not using DecorationSelectBox
			   (getSettingStatus('showDecoration') && getSettingStatus('showSelectArtwork', true)) ? <>
				   {(isReplace) && (!(selectedProductDecorations.length === 1 && allowOneDec === "1")) && (
					   <>
						   {loadingArtworks && <p className={"ml-3"}>Loading ...</p>}
						   {(newLocation.length > 0 || tempDecorationLocations?.length === 1) && (
							   <>
								   {showLocationLabel && newLocation.length > 0 && <label>
									   <b>{`${locationLabel}:`}</b> {' '}
								   </label>}
								   {newLocation.length > 1 || (selectedProductDecorations.length > 0 && newLocation.length > 1) ? (
									   <select
										   className={showLocationLabel ? "ml-2" : ''}
										   style={{ width: 'auto' }}
										   onChange={(e) => {
											   changeLocation(e)
										   }}
										   value={selectedProductLocation}
									   >
										   <option value="">{locationPrompt}</option>
										   {newLocation?.map((locationArray, key) => {
											   return (
												   <option key={key} value={locationArray.id}>
													   {locationArray.locationName}
												   </option>
											   )
										   })}
									   </select>
								   ) :  showLocationLabel && <label className="mr-2">{ uniqueLocation }</label>
								   }
							   </>
						   )}
					   </>
				   )}
			   </>:<></>
		   }
		   {
			   showPrice !== false &&  <ProductPriceCharges
				   product={product}
				   decimalsToShow={decimalsToShow}
				   personalizationCost={personalizationCost}
				   showPersonalizationCostInCart={showPersonalizationCostInCart}
				   showSetupChargeInCart={showSetupChargeInCart}
				   quantityCount={quantityCount}
				   showDecorationCostInCart={showDecorationCostInCart}
				   totalDecoCharges={totalDecoCharges}
				   totalSetupDecoCharges={totalSetupDecoCharges}
				   usePointsAsCurrency={usePointsAsCurrency}
				   totalSetupCharge={totalSetupCharge}
				   setupCharges={setupCharges}
			   />
		   }
			{showPrice !== false && toatlPrice > 0 && <>
			  <br />
			  <label>
					<b>Total:</b>{ usePointsAsCurrency ? getPointsValue(toatlPrice) : <>
						<p>{`${showCurrencycodeSymbol(currency)}${productSettings?.priceRoundUp ? roundedPrice(Number(toatlPrice), decimalsToShow) : 
							formatPriceFixedDecimalPlaces(toatlPrice, decimalsToShow)}`}</p>
						</>
					}
			  </label>
			</>}
			{
				<div className="row clear mt-20">
					{selectedProductLocation !== '' && isSearchArtwork &&

					  <div className="col-md-6">
						  <input
							  type="text"
							  className="inputStyle"
							  value={searchTextArtwork}
							  name="searchTextArtwork"
							  placeholder={(product?.artworkSearchPlaceholderText === undefined ? "Search Artwork…" : (!!product?.artworkSearchPlaceholderText ? product.artworkSearchPlaceholderText : ''))}
							  onChange={(e) => searchArtworkName(e)}
						  />
					  </div>
				   }
				</div>
			}
			{getSettingStatus('showDecoration') && getSettingStatus('showSelectArtwork', true) && selectedProductLocation !== '' && (
			  <AddDecoration
				setSelectedProductArtwork={setSelectedProductArtwork}
				selectedProductArtwork={selectedProductArtwork}
				addDecoration={addDecoration}
				setAddnewart={setAddnewart}
				selectedProductLocation={selectedProductLocation}
				selectedColor={selectedColor}
				selectedProductColor={selectedProductColor}
				setLoading={setLoading}
				locationName={locationName}
				searchTextArtwork={searchTextArtwork}
				artworkSetting={artworkSetting}
				storeArtwork={storeArtwork}
				decorationLocations={decorationLocations}
				allowCustomDecoration={allowCustomDecoration}
				selectedDecorationsList={selectedProductDecorations}
				tempDecorationLocations={tempDecorationLocations}
				product={product}
				artworkLocations={artworkLocations}
				allowOneDec={allowOneDec}
				isAdded={isAdded}
				setIsAdded={setIsAdded}
				decorationListOrder = {decorationListOrder}
			  />
			)}
		  </div>)
	  }
	useEffect(() => {
		if (singleSelectedDecorations) {
			let getIndex = product.LogoInfo.filter(
				(val) =>
					val.color === selectedColor &&
					val.location === singleSelectedDecorations.location,
			)

			if (getIndex.length > 0) {
				animateScroll.scrollToTop()
				setSelectedImage(getIndex[0]?.image)
			} else {

				let getIndexOfExtra_logo = []
				product.LogoInfo.map(val => {
					if (val.color == selectedColor) {

						if (val.extra_logo.filter(e => e.location == singleSelectedDecorations.location).length > 0) {
							getIndexOfExtra_logo.push(val)
						}
					}
				})
				if (getIndexOfExtra_logo.length) {

					animateScroll.scrollToTop()
					setSelectedImage(getIndexOfExtra_logo[0]?.image)
				} else {
					let getImgColorIndex = product.LogoInfo.filter(
						(val) =>
							val.color === selectedColor
					)

					if (getImgColorIndex.length > 0) {
						animateScroll.scrollToTop()
						setSelectedImage(getImgColorIndex[0]?.image)
					}
				}
			}
		}
	}, [singleSelectedDecorations])

	useEffect(() => {
		if(product && !!product?.decorationListOrder) {
		  setDecorationListOrder(product?.decorationListOrder)
		}
	}, [product])

	const changeColor = async (e)=>{
		setLoading(true)
		let color = e && e.target && e.target.value && e.target.value ? e.target.value : e
		setSelectedProductColor(color);
		if (priceTable.length > 1 && showSize) {
			setSelectedProductSize('')
			setTempValueCheckSize(tempValueCheckSize + 1)
		}
		if ( setSelectedColor ) {
			setSelectedColor(color)
		}
		setAddDecorations(true)
		setSelectedProductDecorations([])
		setSingleSelectedDecorations(null)

		let getImage = product?.WebstoreProductPartArray.filter((val)=>val.color == color && val.isSwapImage == true,)
		if ( getImage.length > 0 ) {
			setSelectedImage(getImage[0]?.image)
		} else {
			let getIndex = product.MediaContent.filter(
				(val)=>val.color == color && val.primary,
			)
			if ( getIndex.length ) {
				setSelectedImage(getIndex[0].url)
			} else {
				let secondarygetIndex = product.MediaContent.filter(
					(val)=>val.color == color,
				)
				setSelectedImage(secondarygetIndex[0]?.url)

			}
		}

		let tempDecoArray = [];
		if ( getImage.length === 0 ) {
			tempDecoArray = await selectDecoration(color)
			setIsReplace(true)
		} else {
			setIsReplace(false)
		}
		setLoading(false)

		setSingleSelectedDecorations(tempDecoArray[tempDecoArray.length - 1])
		setSelectedProductDecorations(tempDecoArray)
		if (!!personalizationArray) {
			let tempPersRow = [...personalizationArray]
			let blnktest = !!tempPersRow ? tempPersRow.filter(x => !!!x.itemColor || x.itemColor != color) : []
			if(blnktest?.length) {
			  tempPersRow = tempPersRow.map((tempRow) => {
				return {
				  ...tempRow,
				  itemColor: color
				}
			  });
			  setPersonalizationArray(tempPersRow);
			}
		}
		setTemp(temp + 1)
	}

	useEffect(() => {
		if (selectedProductColor) {
			let grp = []
			let updatePriceTable1 = []
			if (product?.WebstoreProductPartArray && product?.WebstoreProductPartArray.length > 0) {
				product?.WebstoreProductPartArray && product.WebstoreProductPartArray.forEach((part) => {
					if (selectedProductColor === part?.ColorArray?.Color?.colorName) {
						let priceOption = part
						const size = part.ApparelSize.labelSize
						if (!grp[size]) {
							grp[size] = []
						}
						grp[size].push(priceOption)
					}

				})
			} else if (product?.ProductPartArray?.ProductPart && product?.ProductPartArray?.ProductPart.length > 0) {
				product?.ProductPartArray?.ProductPart &&
					product.ProductPartArray.ProductPart.forEach((part) => {
						if (selectedProductColor === part?.ColorArray?.Color?.colorName) {
							let priceOption = part
							const size = part.ApparelSize.labelSize
							if (!grp[size]) {
								grp[size] = []
							}
							grp[size].push(priceOption)
						}

					})
			}
			for (let size in grp) {
				updatePriceTable1.push({ size: size, priceOptions: grp[size] })
			}
			updatePriceTable1 = updatePriceTable1.sort((a, b) => {
                let aValue = defaultSizeForAll.indexOf(a.size.toLowerCase());
                let bValue = defaultSizeForAll.indexOf(b.size.toLowerCase());
                if (aValue === -1) aValue = Infinity;
                if (bValue === -1) bValue = Infinity;
                return aValue - bValue;
            })
			setUpdatePriceTable(updatePriceTable1)

		}
	}, [selectedProductColor])

	useEffect(() => {
        if(colors_final.length === 1 && tempValueCheck === 0) {
            changeColor(colors_final[0])
            setTempValueCheck(tempValueCheck+1)
        }
    },[colors_final, setTempValueCheck, tempValueCheck])

	const colorselectbox = () => {
		let colors_final_temp = colors_final;
		return <select onChange={(e) => { changeColor(e) }
		} value={selectedProductColor}>
			<option key={"colorkey"}>Select {colorLabel}</option>
			{colors_final_temp.map((color, key) => {
				return <option key={key} value={color}>{color}</option>
			})}</select>
	}
	const [tempValueCheckSize, setTempValueCheckSize] = useState(0)
	const sizeselectbox = () => {
		if((!(!!selectedProductColor) || selectedProductColor == `Select ${colorLabel}` || showColor === false) && priceTable.length === 1 && tempValueCheckSize === 0) {
            setSelectedProductSize(priceTable[0].size)
            setTempValueCheckSize(tempValueCheckSize+1)
        }
		return <select onChange={(e) => { 
				setSelectedProductSize(e.target.value) 
				if (!!personalizationArray) {
					let tempPersRow = [...personalizationArray]
					let blnktest = !!tempPersRow ? tempPersRow.filter(x => !!!x.itemSize || x.itemSize != e.target.value) : []
					if (blnktest?.length) {
						tempPersRow = tempPersRow.map((tempRow) => {
							return {
								...tempRow,
								itemSize: e.target.value
							}
						});
						setPersonalizationArray(tempPersRow);
					}
				}
			}} value={selectedProductSize}>
			<option key={"colorkey"}>Select {sizeLabel}</option>
			{(selectedProductColor == undefined || selectedProductColor == "" || selectedProductColor == `Select ${colorLabel}`) && priceTable.map((tableArray, key) => {
				return <option key={key} value={tableArray.size}>{tableArray.size}</option>
			})}
			{(selectedProductColor !== undefined  && selectedProductColor !== "" && selectedProductColor !== `Select ${colorLabel}`) && updatePriceTable.map((tableArray, key) => {
				return <option key={key} value={tableArray.size}>{tableArray.size}</option>
			})}
			</select>
	}

	const addDecoration = async (variation, arkworkId, selectedProductLocationId, color) => {

		let dPrice = await getDecoPrice(arkworkId)
		setTemp(temp + 1)
		let tempDecoArray = selectedProductDecorations ? selectedProductDecorations : [];
		let locationArray = decorationLocations?.filter((locationArray) => locationArray.id === selectedProductLocationId)

		let artwork_selected = artworksFromState.filter(val => val.id === arkworkId)[0]
		let dataselec = null
		if(artwork_selected?.productToDecorationMapping?.length) {
		  dataselec = artwork_selected?.productToDecorationMapping.find(res => res.locationName === locationArray[0].locationName)
		}
		let decoPricedetails = []
		if (dPrice?.length > 0) {
		  dPrice.map((priceArray) => {
			if (artwork_selected?.design?.designType === priceArray.decoratorType) {
			  decoPricedetails.push(priceArray)
			}
		  })
		}
		let decoData = {
		  artworkID: arkworkId,
		  location: locationArray[0].locationName,
		  locationId: locationArray[0].id,
		  variationUniqID: variation.design_variation_unique_id,
		  decoPricedetails: decoPricedetails,
		  supplierDeco: dataselec?.supplierDeco,
		  isRequiredForStore: dataselec?.isRequiredForStore,
		}
		let getDecoOfLocation = tempDecoArray.filter(val => val.locationId === locationArray[0].id)

		if (getDecoOfLocation.length === 0) {
		  tempDecoArray.push(decoData)
		  setSingleSelectedDecorations(decoData)
		  setSelectedProductDecorations(tempDecoArray)
		}
		else if (getDecoOfLocation.length === 1) {
		  let replaceTempDecoArray = tempDecoArray.filter(val => val.locationId !== locationArray[0].id)
		  tempDecoArray = replaceTempDecoArray
		  tempDecoArray.push(decoData)
		  setSingleSelectedDecorations(decoData)
		  setSelectedProductDecorations(tempDecoArray)
		  setSelectedDecorations(tempDecoArray)
		}


		let getIndex = product.LogoInfo.filter(
		  (val) =>
			val.color === color &&
			val.location === locationArray[0].locationName,
		)

		if (getIndex.length > 0) {
		  setSelectedImage(getIndex[0]?.image)
		} else {

		  let getIndexOfExtra_logo = []
		  product.LogoInfo.map(val => {
			if (val.color === color) {

			  if (val.extra_logo.filter(e => e.location === locationArray[0].locationName).length > 0) {
				getIndexOfExtra_logo.push(val)
			  }
			}
		  })

		  if (getIndexOfExtra_logo.length) {

			setSelectedImage(getIndexOfExtra_logo[0]?.image)
		  } else {
			let getImgColorIndex = product.LogoInfo.filter(
			  (val) =>
				val.color === color
			)

			if (getImgColorIndex.length > 0) {
			  setSelectedImage(getImgColorIndex[0]?.image)
			}
		  }
		}

		animateScroll.scrollToTop()
		// setSelectedProductLocation('')
		// setSelectedProductArtwork('')
		setUniqueLocation('')
	  }

	useEffect(() => {
		let inventoryArray = null;
		let stock = null;
		if (productInventory) {
			if (productInventory.productId === product.productId) {
				inventoryArray = productInventory?.PartInventoryArray?.PartInventory
			}
		}
		inventoryArray && inventoryArray.map(stockArray => {
			if (selectedProductColor === stockArray.partColor && stockArray.labelSize === selectedProductSize) {
				if (stockArray?.InventoryLocationArray?.InventoryLocation.length) {
					stockArray.InventoryLocationArray.InventoryLocation.map(inventoryArray => {
						if (inventoryArray?.inventoryLocationQuantity?.Quantity) {
							stock = Number(stock) + Number(inventoryArray.inventoryLocationQuantity.Quantity.value)
						}
					})
				}
			}
		})
		setProductStock(stock)
	}, [selectedProductSize, selectedProductColor, productInventory])
	const [productInventory, setProductInventory] = useState(null);
	useEffect(() => {
		setPartPrice(product?.WebstoreProductPartArray[0]?.partPrice?.PartPriceArray?.PartPrice
			?? product?.ProductPartArray?.ProductPart?.[0]?.partPrice?.PartPriceArray?.PartPrice)
		setIsMoq(product?.isProductMoq);
		setIsPriceBreak(product?.doNotCalculateAtOrder == "1" ? false : product?.isPriceBreak)
		setIsSkuMoq(product?.isProductskuMoq)
		setIsRestrictQty(product?.isRestrictQty)
		setProdId(product?.productId)
		setIsSearchArtwork(product?.searchArtwork)
		setProductId(product.id)
		setCustomDecorationFee(product?.customDecorationFee)
		setCustomDecorationPerUnitPrice(product?.customDecorationPerUnitPrice)
		setPersonalizationPerUnitPrice(product?.personalizationPerUnitPrice)
		setPersonalizationSetupFee(product?.personalizationSetupFee)
		setCustomDecorationSetupFee(product?.customDecorationSetupFee)
		let componentMounted = true;
		return () => {
			componentMounted = false;
		}
	}, [product])
	useEffect(() => {
		if (partPrice && partPrice.length > 0) {
			let allQty=[];
			let minQty =getminQuantity(partPrice)
			setMinQty(minQty)
			allQty=partPrice.map((item)=>{
				return Number(item.minQuantity)
			})
			setMinAllQty(allQty)
		}
	}, [partPrice])
	const getInventrySettingStatus = (key) => {
		if (product) {
		  if (product[key] == false || product[key] == true) {
			return product[key]
		  }
		}
		if (inventorySettings) {
		  if (inventorySettings[key] == false || inventorySettings[key] == true) {
			return inventorySettings[key]
		  }
		}
		return false
	}
	
	const isSizeValid = () => {
		return !!selectedProductSize && selectedProductSize !== `Select ${sizeLabel}`;
	}

	const isColorValid = () => {
		return !!selectedProductColor && selectedProductColor !== `Select ${colorLabel}`;
	}

	const isShowInventoryEnabled = getInventrySettingStatus('showInventory');

	const getProductStock = () => {
		return getStock(selectedProductSize, selectedProductColor, productInventory, product, productDstInventory, isStockFor)
	}
	
	const handleChange = (num) => {
		const value = num.toString().replace(/\D/g, '')
		if (value === "") {
			setQuantityToShow("");
		} else {
			setQuantityToShow(value);
		}
		setQuantityCount(Number(value) || 1)
	}

	const onClickArtDesign = (vari, locationName, locationId) => {
		setTemp(temp + 1);
		let getArt = artworksFromState.filter(
		  (t) =>
			t.design.variation.filter(
			  (y) => y.design_variation_unique_id == vari.design_variation_unique_id
			).length > 0
		);
		if (getArt.length > 0) {
		  let locationData = getArt[0].productToDecorationMapping.filter(
			(val) => val.locationName === locationName
		  );
		  if(locationData.length === 0)
        	locationData = decorationLocations?.filter((locationArray) => locationArray.locationName === locationName)

		  let locationId = !!locationData?.[0].location ? locationData[0].location : locationData[0].id
		  setSelectedProductLocation(locationId);
      setUniqueLocation(locationName);
		  if (locationData.length > 0) {
			let decoData = {
			  artworkID: getArt[0].id,
			  location: locationData[0].locationName,
			  locationId: locationId,
			  variationUniqID: vari.design_variation_unique_id,
			};
			setSingleSelectedDecorations(decoData);

			let getIndex = product.LogoInfo.filter(
			  (val) =>
				val.color === selectedColor &&
				val.location === locationData[0].locationName
			);

			if (getIndex.length > 0) {
			  setSelectedImage(getIndex[0]?.image);
			} else {
			  const getIndex = product.LogoInfo.filter(
				(element) =>
				  element.color === selectedColor &&
				  element.extra_logo.some(
					(subElement) =>
					  subElement.location === locationData[0].locationName
				  )
			  );

			  if (getIndex.length > 0) {
				setSelectedImage(getIndex[0]?.image);
			  } else {
				const getIndex = product.LogoInfo.filter(
				  (val) => val.color === selectedColor
				);

				if (getIndex.length > 0) {
				  setSelectedImage(getIndex[0]?.image);
				}
			  }
			}
			tempfun();
		  }
		}
		setTemp(temp + 0);
	};

	const removeDeco = (mainArraykey, itemsRows, index) => {
		if(index !== '' && index !== null) {
			let tempDecoArray = selectedProductDecorations;
			tempDecoArray = tempDecoArray.filter((decoArray, keyIndex) => keyIndex !== index,);
			setSelectedProductDecorations(tempDecoArray);
		} else {
			setSelectedProductDecorations([]);
		}
	}

	const showPopupText = (mainArraykey, itemsRows, index) => {
		if(product.restrictAfterFirstChoice) {
		  setIndexPopup(index)
		  setMainArrayKeyPopup(mainArraykey)
		  setItemsRowsPopup(itemsRows)
		  setShowDecoPopup(true);
		} else {
		  removeDeco(mainArraykey, itemsRows, index);
		}
	  }

	const getAlertMessageProductDecoration = () => {
		if(product._id) {
			productsAPIUtil.getProductDecorationPopup(product._id).then(response => {
			if(response?.data?.alertMessage) {
				setPopupText(response?.data?.alertMessage);
			}
			}).catch(error => {
			console.log('error', error);
			_addToast('Error while loading data!', {
					appearance: "error",
					autoDismiss: true
				});
			})
		}
	}

	const getAdditionalPrice = () => {
		let personalization = (getSettingStatus('showPersonalization') && !showPersonalizationCostInCart && personalizationCost && !!personalizationCost?.price && quantityCount > 0) ? Number(personalizationCost.price) : 0
		let valueForDecorationCost = getDecorationCost(selectedProductDecorations, quantityCount, customDecorationFee, showSetupChargeInCart, customDecorationPerUnitPrice, 0, customDecorationSetupFee, allowCustomDecoration)
		let decoration = (!showDecorationCostInCart ? valueForDecorationCost.totalDecoCharges : 0) / quantityCount;
		return personalization + decoration;
	  }

	return (
    <div className="product-details-content pro-details-slider-content">
			{loading && <Loader />}
      <h2>{product.productName}</h2>
      <p>
        <span className="boldcolor">Item Number:</span>{' '}
        {itemNumber ? product[itemNumber] : product['productId']}
      </p>
      {showPrice === false ? (
        ''
      ) : (
			<>
				{showPrice !== false &&
					<ProductPriceUni
						productPrice={ productPrice }
						usePointsAsCurrency={ usePointsAsCurrency }
						pointValue={ pointValue }
						showSellPrice={ showSellPrice }
						showSell={ showSell }
						finalProductDiscPrice={ finalPrice }
						discountType={ discountType }
						finalDiscountedPrice={ finalDiscountedPrice }
						productDiscount={ productDiscount }
						finalProductPrice={ discountedBasePrice }
						currency={ currency }
						mainClass={ "product-details-price" }
						decimalsToShow={decimalsToShow}
					/>
				}
			</>
      )}
      {product?.rating > 0 ? (
        <div className="pro-details-rating-wrap justify-content-center">
          <div className="pro-details-rating mr-0">
            <Rating ratingValue={product.rating} />
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="pro-details-list">
        <p>{product.shortDescription}</p>
      </div>
      <div className="pro-details-size-color">
				{(showColor) && (
          <div className="pro-details-color-wrap">
            <span>{colorLabel}</span>
            <div className="pro-details-color-content">{colorselectbox()}</div>
          </div>
        )}
        {showSize && (
          <div className="pro-details-size">
            <span>{sizeLabel}</span>
            <div className="pro-details-size-content">{sizeselectbox()}</div>
          </div>
        )}
      </div>
      {selectedProductDecorations?.length > 0 && getSettingStatus('showDecoration') && (
        <div className="mt-2">
          <span>
            <b>Selected Decorations</b>
          </span>
          <div className="col-md-12" style={{ display: 'flex' }}>
            <div className="row pl-2">
              {selectedProductDecorations &&
								selectedProductDecorations.map((decoitem, index) => {

					let artworkSelected = null
					if (decoitem.artworkID && artworksFromState?.length) {
						artworkSelected = artworksFromState.filter(
							(artorkArray) => artorkArray.id === decoitem.artworkID,
                    )[0]
                  }
                  let variations = null
                  if (!artworkSelected) {
                    return ''
                  } else {
                    variations = artworkSelected.design.variation.filter(
                      (variationArray) =>
                        variationArray.design_variation_unique_id ==
                        decoitem.variationUniqID,
                    )

					  let label = decoTypeList.filter(
						  (val) => val.code == artworkSelected?.design?.designType,
					  )
					  if (variations)
                      return variations.map((vari, indexi) => {

                        return (
                          <div
                            className="decorows inlinefexDecorationDiv"
                            key={'idx' + indexi}
								onClick={() => {
									onClickArtDesign(vari, decoitem.location, label[0]?.name)
								}}
                          >
                            <img
                              className="default-img1"
                              style={{ maxHeight: '60px', maxWidth: '60px' }}
                              src={
                                vari?.itemImageThumbnail?.[0] != ''
                                  ? replaceOrigin(vari.itemImageThumbnail)
                                  : ''
                              }

                            />
                            <div>
                              <div className="col-md-12 text-left">
								{!!!hideDecorationName && <p>
									<b>{artworkSelected?.identity}</b>
								</p>}
                                {!!!hideLocationName && <span style={{whiteSpace:"nowrap"}}>{decoitem.location}</span>}
								{(!decoitem?.isRequiredForStore || decoitem?.isRequiredForStore == "0") &&
									<DeleteIcon
										className="float-right"
										onClick={() => {
											showPopupText([], [], index)
										}}
									/>
								}
                              </div>
                            </div>
                          </div>
                        )
                      })
                  }
                })}
            </div>
          </div>
        </div>
      )}
      {product.variation ? (
        <div className="pro-details-size-color justify-content-center">
          <div className="pro-details-color-wrap">
            <span>{colorLabel}</span>
            <div className="pro-details-color-content">
              {product.variation.map((single, key) => {
                return (
                  <label
                    className={`pro-details-color-content--single ${single.color}`}
                    key={key}
                  >
                    <input
                      type="radio"
                      value={single.color}
                      name="product-color"
                      checked={
                        single.color === selectedProductColor ? 'checked' : ''
                      }
                      onChange={() => {
                        setSelectedProductColor(single.color)
                        setSelectedProductSize(single.size[0].name)
                        setProductStock(single.size[0].stock)
                        resetQuantity()
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                )
              })}
            </div>
          </div>
          <div className="pro-details-size">
            <span>{sizeLabel}</span>
            <div className="pro-details-size-content">
              {product?.variation?.map((single) => {
                return single.color === selectedProductColor
                  ? single.size.map((singleSize, key) => {
                      return (
                        <label
                          className={`pro-details-size-content--single`}
                          key={key}
                        >
                          <input
                            type="radio"
                            value={singleSize.name}
                            checked={
                              singleSize.name === selectedProductSize
                            }
                            onChange={() => {
                              setSelectedProductSize(singleSize.name)
                              setProductStock(singleSize.stock)
                              resetQuantity()
                            }}
                          />
                          <span className="size-name">{singleSize.name}</span>
                        </label>
                      )
                    })
                  : ''
              })}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      {addDecorations && getDecoHtml()}
	  {addDecorations && (userType && (userType === USERTYPES.ADMIN || userType === USERTYPES.USER) || productSettings?.allowToUpCustArtwork === "2") && getSettingStatus('showDecoration') && allowCustomDecoration ? <div>
          <div className="col-md AddNewart ml-3 mt-2">
              <Button variant="outlined" color="primary" onClick={() => { setAddnewart && setAddnewart(true) }}>Add New Art</Button>
          </div>
      </div> : ''}
      {!personalizationArray && getSettingStatus('showPersonalization') && (
        <Button
          variant="contained"
          className="mt-2 mb-2"
          onClick={() => {
            addPersonalization()
          }}
        >
          Add { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' }
        </Button>
      )}
	  {personalizationArray && !personalizationRequired && (
        <Button
          variant="contained"
          className="mt-2 mb-2"
          onClick={() => {
            DeletePersonalization()
          }}
        >
          Delete { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' }
        </Button>
      )}
		  {personalizationArray &&
        <Typography variant="h6" className="mt-2 mb-2">
          { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' }
        </Typography>
      }
      {personalizationArray && (
        <div style={!!personalizationDropDown && !!requiredLinesPersonalization && !!dropDownValues && dropDownValues.length > 0 && dropDownValues.filter((res) => !!res?.isPersonalizationDropDown).length > 0 ? {overflowX: 'unset'} : {}} className="mt-2">
          {getPesonalizationTable()}
        </div>
      )}
	  {(isSkuMoq || isMoq) && showMoqNote && (
		<p style={{color: 'red'}}>{moqNote}</p>
	  )}
	  {(product && priceTables.length > 0 && !!product?.displayPriceGrid) &&
                                        <PriceTable
                                            priceTable={priceTables}
                                            product={product}
                                            currency={currency}
											decimalsToShow={decimalsToShow}
											doNotCalculateAtOrder={product?.doNotCalculateAtOrder}
											userGroupDiscount={userGroupDiscount}
											showSellPrice={showSellPrice}
											showSell={showSell}
											discountType={discountType}
											offerPrice={offerPrice}
                                        />}
      {product.affiliateLink ? (
        <div className="pro-details-quality justify-content-center">
          <div className="pro-details-cart ml-0">
            <a
              href={product.affiliateLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              Buy Now
            </a>
          </div>
        </div>
      ) : (
        <>
          {isShowInventoryEnabled && isSizeValid() && isColorValid() && (
            <div className="justify-content-center mt-3">
              <label>
                {inventorySettings?.['inventoryLabel']
                  ? inventorySettings['inventoryLabel']
                  : 'Available'}{' '}
                {getProductStock()}
              </label>
            </div>
          )}
          <div className="pro-details-quality justify-content-center">
            <div className="cart-plus-minus">
			{ qtyDropDown ? <select name="qty-drop-down" id="qty-drop-down"
				className="cart-plus-minus-box cart-plus-minus"
				style={ {
					borderWidth: '1px',
					padding: '5px',
					textAlign: "center"
				} }
				onChange={ (e) => handleChange(e.target.value) }
			>
				<option value="0">QTY</option>
				{
					qtyValues.map((o, index)=>{
						return (
							<option value={ o.toString() }>{ o }</option>
						)
					})
				}
			</select> :<>
              <button
				onClick={() => {
					let currentCount = Number(quantityCount.toString().replace(/,/g, ''));
					let num = currentCount > 1 ? currentCount - 1 : 0;

					setQuantityCount(num);
					setQuantityToShow(num > 0 ? num.toLocaleString() : ""); // Update the display value
				}}
                className="dec qtybutton"
              >
                -
              </button>
              <input
                className="cart-plus-minus-box"
                type="text"
                value={quantityToShow}
                onChange={ (e) => handleChange(e.target.value)}
                maxLength={6}
              />
              <button
                onClick={() => {
                  if (IsAllowOutOfStockOrder) {
	                  handleChange(quantityCount + 1)
                  } else {
                    let num1 = quantityCount < getProductStock() - productCartQty ? quantityCount + 1 : quantityCount
	                  handleChange(num1)
                  }
                }}
                className="inc qtybutton"
              >
                +
              </button>
			  </>}
            </div>
			  {((showAddToCartOnProd != false && getSettingStatus('showAddToCart') != false) || getSettingStatus('showAddToCart') != false) != false && (
              <div className="pro-details-cart">
                {getProductStock() > 0 || IsAllowOutOfStockOrder || !selectedProductSize || selectedProductSize === `Select ${sizeLabel}`
					|| !selectedProductColor || selectedProductColor === `Select ${colorLabel}` ? (
                  <Link
                    to={{
                      state: { redirectUrl: window.location.pathname },
                    }}
                    onClick={() => {
						/*if (product?.showProductPrice !== false) {*/
						if (!quantityToShow) {
							orderAction("Please enter quantity", "error");
							return;
						}
						if (checkMinQty(prodId, quantityCount, isRestrictQty, minAllQty, isMoq, isSkuMoq, minQty, {color: selectedProductColor, size: selectedProductSize}, product?.doNotCalculateAtOrder)) {
								let blnktest = []
								if (!!personalizationArray) {
									blnktest = !!personalizationArray ? personalizationArray.filter(x => x.displayText === '') : []
								}
								if (showSize) {
									if (!selectedProductSize || selectedProductSize === `Select ${sizeLabel}`) {
										orderAction(`You must select a ${sizeLabel}`, "error");
										return;
									}
								}
								if (showColor) {
									if (!selectedProductColor || selectedProductColor === `Select ${colorLabel}`) {
										orderAction(`You must select a ${colorLabel}`, "error");
										return;
									}
								}
								if((RequiredDecorationForCheckOutPro === true) && !((!!selectedProductDecorations && selectedProductDecorations.length > 0))) {
									orderAction('Please choose a decoration to add this product to your cart', "error")
									return;
								}
								if (blnktest.length > 0) {
									orderAction('You have added personalization to this product, but you haven’t added your text. Please add your text or delete Personalization to add this product to your cart.', "error")
									return;
								}
								const quantityFromCart = getCartItemTotal(prodId, selectedProductColor, selectedProductSize);
								const stock = getProductStock();
								if(!IsAllowOutOfStockOrder && (stock - quantityFromCart < quantityCount)) {
                  resetQuantity()
									orderAction("Stock is not enough to add to cart", "error");
									return;
								}
								addToCart(
									product,
									_addToast,
									Number(quantityCount.toString().replace(/,/g, '')),
									selectedProductColor,
									selectedProductSize,
									selectedProductDecorations,
									personalizationArray,
									(!!personalizationArray && personalizationArray.length > 0 && getSettingStatus('showPersonalization') == true) ? personalizationCost : {},
									userGroupDiscount,
									isPriceBreak,
									true,
									RequiredDecorationForCheckOutPro,
									ipV4
								)
								goToCart()
							}
                    }}
                    disabled={productCartQty >= getProductStock()}
                  >
                    {' '}
                    Add To Cart{' '}
                  </Link>
                ) : (
                  <button disabled>Out of Stock</button>
                )}
              </div>
            )}

            {showWishListToggle && (
              <div className="pro-details-wishlist ml-10">
                <button
                  className={wishlistItem !== undefined ? 'active' : ''}
                  disabled={wishlistItem !== undefined}
                  title={
                    wishlistItem !== undefined
                      ? 'Added to wishlist'
                      : 'Add to wishlist'
                  }
                  onClick={() => addToWishlist(product, _addToast)}
                >
                  <i className="pe-7s-like" />
                </button>
              </div>
            )}
            {showCompareToggle && (
              <div className="pro-details-compare">
                <button
                  className={compareItem !== undefined ? 'active' : ''}
                  disabled={compareItem !== undefined}
                  title={
                    compareItem !== undefined
                      ? 'Added to compare'
                      : 'Add to compare'
                  }
                  onClick={() => addToCompare(product, _addToast)}
                >
                  <i className="pe-7s-shuffle" />
                </button>
              </div>
            )}
          </div>
        </>
      )}
      {product.category ? (
        <div className="pro-details-meta justify-content-center">
          <span>Categories :</span>
          <ul>
            {product.category.map((single, key) => {
              return (
                <li key={key}>
                  <Link to={process.env.PUBLIC_URL + '/shop-grid-standard'}>
                    {single}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      ) : (
        ''
      )}
      {product.tag ? (
        <div className="pro-details-meta justify-content-center">
          <span>Tags :</span>
          <ul>
            {product.tag.map((single, key) => {
              return (
                <li key={key}>
                  <Link to={process.env.PUBLIC_URL + '/shop-grid-standard'}>
                    {single}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      ) : (
        ''
      )}

      {showAddnewart && (
        <AddnewArtDialogue
		  productId={productId}
          open={showAddnewart}
          show={showAddnewart}
          title="Add New Artwork"
          onHide={() => handleCancel()}
          handleSubmit={handleAddnewArt}
          cancel={() => handleCancel()}
          catalogueItem={product}
        />
      )}

	  {showDecoPopup && (
        <SharedModal
          indexPopup={indexPopup}
          mainArrayKeyPopup={mainArrayKeyPopup}
          itemsRowsPopup={itemsRowsPopup}
          popupText={popupText}
          removeDeco={removeDeco}
          setShowDecoPopup={setShowDecoPopup}
          showDecoPopup={showDecoPopup}
        />
      )}
    </div>
  )
};

ProductDescriptionInfoSlider.propTypes = {
	addToCart: PropTypes.func,
	addToCompare: PropTypes.func,
	addToWishlist: PropTypes.func,
	_addToast: PropTypes.func,
	cartItems: PropTypes.array,
	compareItem: PropTypes.object,
	currency: PropTypes.object,
	discountedPrice: PropTypes.number,
	finalDiscountedPrice: PropTypes.number,
	finalProductPrice: PropTypes.number,
	product: PropTypes.object,
	wishlistItem: PropTypes.object
};

const mapDispatchToProps = dispatch => {
	return {
		addToCart: (
			item,
			_addToast,
			quantityCount,
			selectedProductColor,
			selectedProductSize,
			selectedProductDecorations,
			personalizationArray,
			personalizationCost,
			userGroupDiscount,
			isPriceBreak,
			needValidation,
			RequiredDecorationForCheckOutPro,
			ipV4,
		) => {
			dispatch(
				addToCart(
					item,
					_addToast,
					quantityCount,
					selectedProductColor,
					selectedProductSize,
					selectedProductDecorations,
					personalizationArray,
					personalizationCost,
					userGroupDiscount,
					isPriceBreak,
					true,
					RequiredDecorationForCheckOutPro,
					ipV4,
				)
			);
		},
		addToWishlist: (item, _addToast) => {
			dispatch(addToWishlist(item, _addToast));
		},
		addToCompare: (item, _addToast) => {
			dispatch(addToCompare(item, _addToast));
		},
		fetchDecorationLocations: (data) => dispatch(fetchDecorationLocations(data)),
		fetchArtworksById: (data) => dispatch(fetchArtworksById(data)),
	};
};
const mapStateToProps = state => {
	return {
		decorationLocations: state.productData.decorationLocations,
		artworkListById: state.productData.artworkListById,
		artworkList: state.productData.artworkList,
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductDescriptionInfoSlider);
