import { FileMetadata } from './File';
import { Order } from './Order';

export enum CheckoutStep {
  CUSTOMER_DETAILS = 'CustomerDetails',
  FULFILLMENT = 'Fulfillment',
  PAYMENT = 'Payment',
}

export enum CheckoutStatus {
  PENDING_DETAILS = 'PendingDetails',
  PENDING_FULFILLMENT_METHOD = 'PendingFulfillmentMethod',
  PENDING_PAYMENT = 'PendingPayment',
  PENDING_SUBMISSION = 'PendingSubmission',
  COMPLETED = 'Completed',
}

export interface CheckoutSession {
  _id: string;
  userId: string;
  cartId: string;
  status: CheckoutStatus;
  order: Order;
  confirmationHtml?: string;
  skipCustomerDetails?: boolean;
  files?: FileMetadata[];
}

export enum RestrictAddressMode {
  NONE = 'none',
  SHIPPING_LOCATIONS = 'shippingLocations',
  SPECIFIC_SHIPPING_LOCATION = 'specificShippingLocation',
}

export interface RegionDefinition {
  countryCode: string;
  regions?: string[];
}

export interface CheckoutSettings {
  fulfillmentRenameEnabled?: boolean;
  fulfillmentRenameText?: string;
  showInHandsDateField?: boolean;
  ihdRenameEnabled?: boolean;
  ihdRenameText?: string;
  ihdRequired?: boolean;
  showFulfillmentEstimate?: boolean;
  instructionsEnabled?: boolean;
  instructionsText?: string;
  deliveryInstructionsEnabled?: boolean;
  deliveryInstructionsText?: string;
  paymentInstructionsEnabled?: boolean;
  paymentInstructionsText?: string;
  orderNoteEnabled?: boolean;
  documentUploadEnabled?: boolean;
  documentUploadRelabel?: string,
  isFulfillmentPhoneRequired?: boolean;
  isFulfillmentCompanyRequired?: boolean;
  isFulfillmentCompanyRenameEnabled?: boolean;
  fulfillmentCompanyRenameText?: string;
  isBillingPhoneRequired?: boolean;
  isBillingCompanyRequired?: boolean;
  isBillingCompanyRenameEnabled?: boolean;
  billingCompanyRenameText?: string;
  restrictShippingAddressMode?: RestrictAddressMode;
  restrictShippingAddressId?: string;
  restrictBillingAddressMode?: RestrictAddressMode;
  restrictBillingAddressId?: string;
  isBillingAddressHidden?: boolean;
  isFulfillmentNameLocked?: boolean;
  isFulfillmentEmailLocked?: boolean;
  isBillingNameLocked?: boolean;
  isBillingEmailLocked?: boolean;
  isAddressCarryoverEnabled?: boolean;
  isShippingEstimatorEnabled?: boolean;
  costCenterEnabled?: boolean;
  costCenterSettings?: CostCenterSettings;
  handlingFeeEnabled?: boolean;
  handlingFeeRenameEnabled?: boolean;
  handlingFeeRenameText?: string;
  fulfillmentCountries?: string[];
  fulfillmentRegionDefinitions?: RegionDefinition[];
  billingCountries?: string[];
  billingRegionDefinitions?: RegionDefinition[];
  isCouponEnabled?: boolean;
  couponLabelText?: string;
}

export interface ConfirmationPageSettings {
  headerText: string;
  showOrderNumber: boolean;
  showTransactionId: boolean;
  showOrderDetails: boolean;
  showOrderItems: boolean;
  html: string;
  showFulfillmentEstimate: boolean;
  showOrderApprovalStatus: boolean;
  holdApprovalStatusText: string;
  noHoldApprovalStatusText: string;
  showPaymentMethodHtml: boolean;
  showPayments: boolean;
}

export interface CostCenterSettings {
  sectionHeaderText: string;
  primaryEnabled: boolean;
  secondaryEnabled: boolean;
  tertiaryEnabled: boolean;
  primaryRequired: boolean;
  secondaryRequired: boolean;
  tertiaryRequired: boolean;
  primaryRenameEnabled: boolean;
  secondaryRenameEnabled: boolean;
  tertiaryRenameEnabled: boolean;
  primaryRenameText: string;
  secondaryRenameText: string;
  tertiaryRenameText: string;
  customPrimaryEnabled: boolean;
  customSecondaryEnabled: boolean;
  customTertiaryEnabled: boolean;
  showAllPrimaryEnabled: boolean;
  showAllSecondaryEnabled: boolean;
  showAllTertiaryEnabled: boolean;
  primaryLabelText: string;
  secondaryLabelText: string;
  tertiaryLabelText: string;
}

export interface DocumentUploadRelabel {
  documentUploadRelabel: string;
}
