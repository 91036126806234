import { Voucher } from '../../models/Voucher'
import { aetherApi } from './aetherApi'

export const budgetApi = aetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getActiveVouchers: builder.query<Voucher[], void>({
      query: () => `api/v1/vouchers/user-active`,
      providesTags: ['Vouchers'],
    }),
  }),
})

export const {
  useGetActiveVouchersQuery,
} = budgetApi
