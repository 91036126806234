export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const DELETE_FROM_WISHLIST = "DELETE_FROM_WISHLIST";
export const DELETE_ALL_FROM_WISHLIST = "DELETE_ALL_FROM_WISHLIST";
export const WISHLIST_DATA="WISHLIST_DATA";

// add to wishlist
export const addToWishlist = (item, _addToast) => {
  return dispatch => {
    if (_addToast) {
      _addToast("Added To Wishlist", {
        appearance: "success",
        autoDismiss: true
      });
    }
    dispatch({ type: ADD_TO_WISHLIST, payload: item });
  };
};

// delete from wishlist
export const deleteFromWishlist = (item, _addToast) => {
  return dispatch => {
    if (_addToast) {
      _addToast("Removed From Wishlist", {
        appearance: "error",
        autoDismiss: true
      });
    }
    dispatch({ type: DELETE_FROM_WISHLIST, payload: item });
  };
};

//delete all from wishlist
export const deleteAllFromWishlist = _addToast => {
  return dispatch => {
    if (_addToast) {
      _addToast("Removed All From Wishlist", {
        appearance: "error",
        autoDismiss: true
      });
    }
    dispatch({ type: DELETE_ALL_FROM_WISHLIST });
  };
};
export const wishlistItemData = (data) => {
	return dispatch => {
	  dispatch({ 
		  type:WISHLIST_DATA,
			payload: data 
		});
	};
};