import React, { useContext, useEffect, useState } from "react";
import uuid from "uuid/v4";
import { useDispatch, useSelector } from "react-redux";
import { selectKitEditingIndex, selectKitItems, selectKitItemsLength } from "../../redux/selectors/ cartSelectors";
import { completeKit, editKitCartItem } from "../../redux/actions/cartActions";
import { Box, Button, Card, CardContent, Dialog, DialogContent, DialogTitle, Grid, IconButton, Step, StepButton, StepContent, StepLabel, Stepper, Typography, makeStyles } from "@material-ui/core";
import ProductModalContent from "./ProductModalContent";
import { getGlobalShowProductPrice, numberWithCommas2, roundedPrice } from '../../helpers/product'
import { getCartItemTotal, getHtmlTextElement, getMainImageUrls, productImageUrl } from "../../util/helper";
import { AuthContext } from "../../common/Auth";
import { isProductDataLoading } from "../../redux/selectors/productSelectors";
import { useThemeSettingsData } from "../../layouts/state/useThemeSettingsData";
import KitItemLine from "./KitItemLine";
import { fetchArtworksById, fetchProductStock, isLoading } from "../../redux/actions/productActions";
import * as productsAPIUtil from "../../util/products/productsAPIUtil";
import { getKitProducts } from '../../util/products/productsAPIUtil';
import Loader from "../../common/Loader";
import { Close } from "@material-ui/icons";
import { useThemeSettings } from '../../layouts/state/useThemeSettings'
import { useProductPriceToShow } from "./state/useProductPriceToShow";


const KitStepper = ({
    kitProduct,
    _addToast,
    show,
    currency,
    onClose,
    decimalsToShow,
    kitId = uuid(),
}) => {
    const { usePointsAsCurrency, themeSettingsData, storeDetails, user, decoTypeList, settingData } = useContext(AuthContext);
    const {
        productSettings,
    } = useThemeSettingsData({ themeSettingsData })
    const {
      getPointsValue
    } = useProductPriceToShow({ productSettings })

    const {
        itempriceDisplay,
        priceDisplay,
    } = useThemeSettings({ settingData });

    const dispatch = useDispatch();
    const [kitProducts, setKitProducts] = useState([]);
    const [kitProductsData, setKitProductsData] = useState([]);
    const [productIndex, setProductIndex] = useState(0);
    const [kitPrice, setKitPrice] = useState(0);
    const [kitProductPrice, setKitProductPrice] = useState(0);
    const [kitProductQuantity, setKitProductQuantity] = useState(1);
    const [loading, setLoading] = useState(false);
    const [kitArtworkList, setKitArtworkList] = useState([]);
    const [artworkIdsInCart, setArtworkIdsInCart] = useState([]);
    const [productIdsInCart, setProductIdsInCart] = useState([]);
    const kitCartItems = useSelector(state => selectKitItems(state, kitId));
    const kitCartItemsLength = useSelector(state => selectKitItemsLength(state, kitId));
    const kitEditingIndex = useSelector(state => selectKitEditingIndex(state, kitId));
    const productLoading = useSelector(isProductDataLoading);

    const kitProductShowPrice = getGlobalShowProductPrice(kitProduct, itempriceDisplay, priceDisplay, user);
    const hideTotal = !kitProductShowPrice && kitProduct.kitPriceMode !== 'separate'

    useEffect(() => {
        const getProducts = async () => {
            try {
                setLoading(true);
                const result = await getKitProducts(kitProduct.id);
                setKitProducts(result.data);
            } catch (error) {
                console.error(error);
                _addToast("Error fetching kit products", { appearance: 'error', autoDismiss: true });
                onClose();
            } finally {
                setLoading(false);
            }
        }
        getProducts();
    }, [kitProduct])

    // Check pending kit items in cart to determine next product to select
    useEffect(() => {
        setProductIndex((kitEditingIndex !== -1 ? kitEditingIndex : kitCartItemsLength) - 1);
    }, [kitCartItemsLength, kitEditingIndex])

    useEffect(() => {
        let updatedData = [];
        for (let productConfig of kitProduct.kitProducts) {
            let product = kitProducts.find(p => p.id === productConfig.productId);
            if (product) {
                let data = {
                    product: product,
                    kitConfig: {
                        changeQuantity: kitProduct.kitChangeQuantity ?? false,
                        startingQuantity: (productConfig.startingQuantity ?? 1) * kitProductQuantity,
                        priceMode: kitProduct.kitPriceMode,
                        kitId: kitId,
                    }
                }
                updatedData.push(data);
            }
        }
        setKitProductsData(updatedData);
    }, [kitProduct, kitProducts, kitProductQuantity]);

    useEffect(() => {
        const display = !kitProduct.isKitEnabled || kitProduct.kitPriceMode !== 'rollup';
        const totalKitPrice = kitCartItems.reduce((acc, item) => acc + Number(getCartItemTotal(item, kitArtworkList, productSettings, 0, display, user, itempriceDisplay, priceDisplay ).toFixed(decimalsToShow)), 0);
        setKitPrice(totalKitPrice);
        if (kitCartItems.length > 0) {
            setKitProductPrice(getCartItemTotal(kitCartItems[0], kitArtworkList, productSettings, 0, display, user, itempriceDisplay, priceDisplay));
        }
    }, [kitArtworkList, productSettings, kitCartItems, itempriceDisplay, priceDisplay, user]);

    useEffect(() => {
        setKitProductQuantity(kitCartItems?.[0]?.currentOrder?.[0]?.[0]?.quantity ?? 1);
    }, [kitCartItems]);

    useEffect(() => {
        const artworkSet = new Set(kitCartItems.map((item) => item.currentOrder ?? []).flat()
            .map((rowArray) => rowArray)
            .map((row) => row).flat()
            .map((row) => row.decorations ?? []).flat()
            .map((decoration) => decoration.artworkID));
        const updatedArtworkIdsInCart = [...artworkSet].sort();
        // Only update if the decorations in cart have changed to prevent unnecessary API calls
        setArtworkIdsInCart(
            (pv) => pv.length === updatedArtworkIdsInCart.length
                && pv.every((v, i) => v === updatedArtworkIdsInCart[i])
                ? pv : updatedArtworkIdsInCart);
        setProductIdsInCart(
            (pv) => pv.length === kitCartItems.length
                && pv.every((v, i) => v === kitCartItems[i].product.id)
                ? pv : kitCartItems.map(item => item.product.id));
    }, [kitCartItems, storeDetails]);

    useEffect(() => {
        if (artworkIdsInCart.length === 0) return;
        const getKitArtwork = async () => {
            try {
                const result = await productsAPIUtil.fetchArtworksById({
                    account_id: storeDetails.accountId,
                    ids: artworkIdsInCart,
                    products: productIdsInCart,
                });
                setKitArtworkList(result.data.artworkList.data);
            } catch (error) {
                console.log(error);
            }
        };
        getKitArtwork();
    }, [artworkIdsInCart, productIdsInCart]);

    useEffect(() => {
        if (!kitProductsData[productIndex]) return;
        const { product } = kitProductsData[productIndex];
        if (product && storeDetails && storeDetails.accountId) {
            dispatch(isLoading(true));
            dispatch(
                fetchArtworksById({
                    account_id: storeDetails.accountId,
                    product_id: product.id,
                    userId: user?.userId || undefined,
                    categories: product.ProductCategoryArray|| [],
                    restrict: true,
                })
            );
        }
    }, [storeDetails, productIndex, kitProductsData]);

    const handleOnClose = () => {
        dispatch(isLoading(true));
        dispatch(
            fetchArtworksById({
                account_id: storeDetails.accountId,
                product_id: kitProduct.id,
                userId: user?.userId || undefined,
                categories: kitProduct.ProductCategoryArray|| [],
                restrict: true,
            })
        );
        dispatch(
          fetchProductStock(kitProduct.id)
        )
        onClose();
    };

    const handleComplete = () => {
        dispatch(completeKit(kitId, _addToast));
        handleOnClose();
    }

    const handleStepSelect = (productId) => {
        dispatch(editKitCartItem(kitId, productId));
    }

    let primaryImage = productImageUrl(process.env.PUBLIC_URL + getMainImageUrls(kitProduct).primaryImage);

    const useStyles = makeStyles((theme) => ({
        dialogPaper: {
            height: '100%',
        },
        showTopKitCart: {
            transition: "max-height 1s",
            maxHeight: "100%",
        },
        hideTopKitCart: {
            transition: "max-height 1s, visibility 1s",
            maxHeight: "0px",
            visibility: "hidden",
        },
        stepperLabel: {
            "& .MuiStepLabel-label": {
                fontSize: "1.15rem",
            },
            "& .MuiStepIcon-root": {
                width: "1.15em",
                height: "1.15em",
                color: "var(--themedefaultColor)"
            },
            "& .MuiStepLabel-iconContainer": {
                paddingRight: theme.spacing(2)
            }
        }
    }));
    let classes = useStyles();

    return (
        <Dialog open={show} onClose={handleOnClose} fullWidth maxWidth="lg" scroll="paper" classes={{ paper: classes.dialogPaper }}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1} >
                        Kit Builder
                    </Box>
                    <Box>
                        <IconButton onClick={handleOnClose} size="small">
                            <Close />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                {(productLoading || loading) && <Loader />}
                {!loading &&
                    <>
                        <Card variant={"outlined"} className={productIndex === kitProductsData.length ? classes.hideTopKitCart : classes.showTopKitCart}>
                            <CardContent>
                                <Grid container spacing={8}>
                                    {!!primaryImage && (
                                        <Grid item>
                                            <img
                                                style={{ maxHeight: '96px', width: "auto", maxWidth: '96px' }}
                                                className="img-fluid"
                                                src={primaryImage}
                                                alt={kitProduct.productName}
                                                loading="lazy"
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs>
                                        <Typography variant="h6">{kitProduct.productName}</Typography>
                                        {!kitProduct.kitChangeQuantity && (<Typography variant="body1">Quantity: {kitProductQuantity}</Typography>)}
                                        <Typography variant="body1">{getHtmlTextElement(kitProduct.description)}</Typography>
                                    </Grid>
                                    {kitProductShowPrice && kitProduct.kitPriceMode === 'kit' && (
                                        <Grid item container xs={3} justifyContent="center" alignItems="center">
                                            {
                                                usePointsAsCurrency ?
                                                    getPointsValue(kitProductPrice)
                                                    : <Typography variant={"h6"}>
                                                        {`${currency?.currencySymbol}
                                                    ${productSettings?.priceRoundUp
                                                                ? roundedPrice(kitProductPrice, decimalsToShow)
                                                                : numberWithCommas2(kitProductPrice, decimalsToShow)}
                                                        `}
                                                    </Typography>
                                            }
                                        </Grid>
                                    )}
                                    {kitProduct.kitPriceMode !== 'kit' && !hideTotal && (
                                        <Grid item container xs={3} justifyContent="center" alignItems="center">
                                            {
                                                usePointsAsCurrency ?
                                                    getPointsValue(kitPrice)
                                                    : <Typography variant={"h6"}>
                                                        {`${currency?.currencySymbol}
                                                    ${productSettings?.priceRoundUp
                                                                ? roundedPrice(kitPrice, decimalsToShow)
                                                                : numberWithCommas2(kitPrice, decimalsToShow)}
                                                        `}
                                                    </Typography>
                                            }
                                        </Grid>
                                    )}
                                </Grid>
                            </CardContent>
                        </Card>
                        <Stepper activeStep={productIndex} nonLinear orientation="vertical">
                            {kitProductsData.map(({
                                product,
                                kitConfig
                            }, index) => (
                                <Step key={product.productId}>
                                    <StepButton onClick={() => handleStepSelect(product.id)} completed={productIndex > index}>
                                        <StepLabel className={classes.stepperLabel}>{product.productName}</StepLabel>
                                    </StepButton>
                                    <StepContent TransitionProps={{ unmountOnExit: false }}>
                                        {
                                            <ProductModalContent
                                                product={product}
                                                kitConfig={kitConfig}
                                                hideImage={index !== productIndex}
                                            />
                                        }
                                    </StepContent>
                                </Step>
                            ))}
                            <Step>
                                <StepLabel className={classes.stepperLabel}>Review Kit</StepLabel>
                                <StepContent>
                                    <Card variant={"outlined"}>
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <KitItemLine
                                                        cartItem={kitCartItems[0]}
                                                        row={kitCartItems[0]?.currentOrder?.[0]?.[0]}
                                                        usePointsAsCurrency={usePointsAsCurrency}
                                                        productSettings={productSettings}
                                                        currency={currency}
                                                        decimalsToShow={decimalsToShow}
                                                        artworkList={kitArtworkList}
                                                        decoTypeList={decoTypeList}
                                                        isKitProduct
                                                        user = {user}
                                                        itemPriceDisplay = {itempriceDisplay}
                                                        priceDisplay = {priceDisplay}
                                                    />
                                                </Grid>
                                                {kitCartItems.map((cartItem, keyOne) => {
                                                    return cartItem.currentOrder.map((rowArray, keyTwo) => {
                                                        return rowArray.map((row, innerKey, keyThree) => {
                                                            if (cartItem.product.isKitEnabled)
                                                                return null;
                                                            return (
                                                                <Grid key={`${keyOne}${keyTwo}${keyThree}`} item xs={12}>
                                                                    <Card variant="outlined">
                                                                        <CardContent>
                                                                            <KitItemLine
                                                                                cartItem={cartItem}
                                                                                row={row}
                                                                                usePointsAsCurrency={usePointsAsCurrency}
                                                                                productSettings={productSettings}
                                                                                currency={currency}
                                                                                decimalsToShow={
                                                                                    cartItem.product.decimalsToShow
                                                                                        ? cartItem.product.decimalsToShow
                                                                                        : productSettings.decimalsToShow}
                                                                                decoTypeList={decoTypeList}
                                                                                artworkList={kitArtworkList}
                                                                                user = {user}
                                                                                itemPriceDisplay = {itempriceDisplay}
                                                                                priceDisplay = {priceDisplay}
                                                                            />
                                                                        </CardContent>
                                                                    </Card>
                                                                </Grid>
                                                            )
                                                        })
                                                    })
                                                })}
                                                {!hideTotal && (
                                                  <Grid item container xs={12} justifyContent="center">
                                                      {
                                                          usePointsAsCurrency ?
                                                              getPointsValue(kitPrice)
                                                              : <Typography variant={"h6"} style={{ fontWeight: 400 }}>
                                                                  Kit Subtotal: {`${currency?.currencySymbol}
                                                              ${productSettings?.priceRoundUp
                                                                          ? roundedPrice(kitPrice, decimalsToShow)
                                                                          : numberWithCommas2(kitPrice, decimalsToShow)}
                                                                  `}
                                                              </Typography>
                                                      }
                                                  </Grid>
                                                )}
                                                <Grid item container xs={12} justifyContent="center">
                                                    <Button variant={"contained"} onClick={handleComplete}>Add Kit to Cart</Button>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </StepContent>
                            </Step>
                        </Stepper>
                    </>
                }
            </DialogContent>
        </Dialog>
    );
}

export default KitStepper;