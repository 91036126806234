import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'

interface LoginOptionalDialogProps {
  open: boolean
  onClose: () => void
}

export default function LoginOptionalDialog({
  open,
  onClose,
}: LoginOptionalDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Redirect to Login</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          To create an account or login prior to checkout please select "Login"
          below.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button href="/login">Log In</Button>
        <Button onClick={onClose}>Continue as Guest</Button>
      </DialogActions>
    </Dialog>
  )
}
