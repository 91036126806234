import Decimal from 'decimal.js'
import { formatCurrency, formatPoints } from '../../helpers/formattingHelpers'
import { PointsPaymentSettings } from '../../models/PaymentMethodSettings'
import { PaymentMethodType } from '../../models/PaymentMethodType'
import { useAppSelector } from '../../redux/hooks'
import {
  selectCurrency,
  selectPaidMethodsUsed,
  selectParams,
  selectSinglePaymentSettings,
} from '../../redux/selectors/checkoutSelectors'

export default function useCurrencyDisplay() {
  const currency = useAppSelector(selectCurrency)
  const pointsSettings: PointsPaymentSettings | undefined = useAppSelector(
    (state) => selectSinglePaymentSettings(state, PaymentMethodType.POINTS),
  )
  const params = useAppSelector(selectParams)
  const paidMethodsUsed = useAppSelector((state) =>
    selectPaidMethodsUsed(state, params),
  )

  const format = (amount: number | Decimal, decimals: number = 2) => {
    if (
      !paidMethodsUsed &&
      pointsSettings &&
      pointsSettings.usePointsAsCurrency
    ) {
      return formatPoints(
        amount,
        pointsSettings?.pointsValue ?? 1,
        pointsSettings?.showDecimals ?? false,
        pointsSettings?.pointsLabelPlural ?? 'Points',
        pointsSettings?.pointsLabelSingular ?? 'Points',
      )
    } else {
      return formatCurrency(currency.currencySymbol, amount, false, decimals)
    }
  }

  return {
    format,
  }
}
