import React, { useContext } from "react";
import { numberWithCommas2 } from "../../../helpers/product";
import { AuthContext } from "../../../common/Auth";
import { useProductPriceToShow } from "../../../components/product/state/useProductPriceToShow";
import { formatPriceFixedDecimalPlaces } from "../../../common/PriceFormat";
const PriceTable = (props) => {
    const {
        usePointsAsCurrency } = useContext(AuthContext)
    const {
        getPointsValue
    } = useProductPriceToShow({})

    const priceTable = props.priceTable || [];
    const isModel = !!props?.isModel;
    const currency = props.currency;
    const decimalsToShow = props.decimalsToShow;
    const doNotCalculateAtOrder = props.doNotCalculateAtOrder;
    const islayout = props?.islayout ?? 0;
    const userGroupDiscount = props.userGroupDiscount;
    const showSellPrice = props.showSellPrice
    const showSell = props.showSell
    const offerPrice = props.offerPrice
    const discountType = props.discountType
    let layout = props?.layout
      
    return (
        <div className={!!!isModel ? (layout == "condense" ? "ml-1" : (layout === "tab" ? "" : "ml-3")) : ""} style={{ marginRight: islayout ? "15px" : '' }}>
            <div className={!!isModel ? "sizediv product-price-tbl mb-1 my-2" : "row sizediv product-price-tbl mb-1 my-2"} style={{ overflowX: 'auto', margin: 0 }}>
                {islayout ? <div className="col-lg sizeContainer">
                    <label className="inputQty"><b>Quantity</b></label>
                    {showSellPrice ? <label className=" bggray lblbggray" title="Price" /> : ""}
                    <label className="bggray"><b>Price</b></label>
                </div>:''}
                {priceTable.map((row, keyP) => {
                    let Price = row.salePrice
                    if (Number(userGroupDiscount > 0) && Number(Price) > 0) {
                        Price =
                            Number(Price) -
                            (Number(Price) * Number(userGroupDiscount)) / 100
                        if (Price < 0) {
                            Price = 0
                        }
                    }
                    row.discountedPrice = Price
                    if (discountType == '1' && showSell && Number(row.discountedPrice) > 0) {
                        if (offerPrice > 0) {
                            row.discountedPrice = Number(row.discountedPrice) - Number(offerPrice)
                            if(!showSellPrice)
                                Price = Number(Price) - Number(offerPrice)
                            if (row.discountedPrice < 0) {
                                row.discountedPrice = 0
                                if(!showSellPrice)
                                    Price = 0
                            }
                        }
                    } else if (discountType == '0' && showSell && Number(row.discountedPrice) > 0) {
                        row.discountedPrice =
                            Number(row.discountedPrice) -
                            (Number(row.discountedPrice) * Number(offerPrice)) / 100
                        if(!showSellPrice)
                            Price = Number(Price) - (Number(Price) * Number(offerPrice)) / 100
                        if (row.discountedPrice < 0) {
                            row.discountedPrice = 0
                            if(!showSellPrice)
                                Price = 0
                        }
                    }
                    Price = formatPriceFixedDecimalPlaces(Price, decimalsToShow);

                    if(islayout) {
                        return <div className="col-lg sizeContainer" key={'p-t' + keyP}>
                            <label className="inputQty"> {row.minQuantity} </label>
                              <label className={`${showSellPrice ? 'breakable-price' : ''} bggray`} title="Price">
                            {
                                doNotCalculateAtOrder == "1" ? (
                                    <>
                                    {usePointsAsCurrency
                                        ? getPointsValue(Price * row.minQuantity)
                                        : currency.currencySymbol + numberWithCommas2(Price * row.minQuantity, decimalsToShow)}
                                    </>
                                ) : (
                                    <>
                                    {usePointsAsCurrency
                                        ? getPointsValue(Price)
                                        : currency.currencySymbol + numberWithCommas2(Price, decimalsToShow)}
                                    </>
                                )
                            }
                            </label>
                            {showSellPrice ? <label className=" bggray" title="Price"> {
                                doNotCalculateAtOrder == "1" ? (
                                    <>
                                    {usePointsAsCurrency
                                        ? getPointsValue(row.discountedPrice * row.minQuantity)
                                        : currency.currencySymbol + numberWithCommas2(row.discountedPrice * row.minQuantity, decimalsToShow)}
                                    </>
                                ) : (
                                    <>
                                    {usePointsAsCurrency
                                        ? getPointsValue(row.discountedPrice)
                                        : currency.currencySymbol + numberWithCommas2(row.discountedPrice, decimalsToShow)}
                                    </>
                                )
                            }
                            </label> : ''}
                        </div>
                    } else {
                    return <div className="col-lg sizeContainer" key={'p-t' + keyP}>
                        <label className={`${showSellPrice && showSell ? 'breakable-price' : ''} bggray`} title="Price">
                            {/* {
                                usePointsAsCurrency ? getPointsValue(row.salePrice) : currency.currencySymbol + numberWithCommas2(row.salePrice,
                                    decimalsToShow )
                            } */}
                                {/* {console.log('doNotCalculateAtOrder: ', doNotCalculateAtOrder)} */}
                            {
                                // doNotCalculateAtOrder == "1" ? (
                                //     <>
                                //     {usePointsAsCurrency
                                //         ? getPointsValue(row.salePrice * row.minQuantity)
                                //         : currency.currencySymbol + numberWithCommas2(row.salePrice * row.minQuantity, decimalsToShow)}
                                //     </>
                                // ) : (
                                    <>
                                    {usePointsAsCurrency
                                        ? getPointsValue(Price)
                                        : currency.currencySymbol + numberWithCommas2(Price, decimalsToShow)}
                                    </>
                                // )
                            }
                        </label>
                        {showSellPrice && showSell ? <label className=" bggray" title="Price"> {
                            <>
                                {usePointsAsCurrency
                                    ? getPointsValue(row.discountedPrice)
                                    : currency.currencySymbol + numberWithCommas2(row.discountedPrice, decimalsToShow)}
                            </>
                        }
                        </label> : ''}

                        <label className="inputQty"> {row.minQuantity} </label>
                    </div>
                }}) } 
            </div>
        </div>
    )
}

export default PriceTable;
