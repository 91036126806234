import PassiveRow from '../PassiveRow'
import React from 'react'
import { useGetActiveBudgetsQuery } from '../../../redux/api/budgetApi'

export default function PaymentSummaryBudgetInfo({
  budgetId,
}: {
  budgetId: string
}) {
  const { data: budgets } = useGetActiveBudgetsQuery()

  const budget = budgets?.find((b) => b._id === budgetId)

  if (budget) {
    return (
      <>
        <PassiveRow field={'Budget Name'} value={budget.plan.name} />
      </>
    )
  }
  return (<></>)
}
