import { getImageSize } from 'react-image-size';

export async function fetchImageSize(url) {
    try {
        const dimensions = await getImageSize(url);
        return dimensions || { width: 0, height: 0 };
    } catch (error) {
        console.error(error);
        return { width: 0, height: 0 };
    }
}

export function getImageJustification(getSingleImage) {
    let imageJustify = '';
    if (getSingleImage !== null) {

        if (getSingleImage.justify === 'R') {
            imageJustify = 'right';
        } else if (getSingleImage.justify === 'L') {
            imageJustify = 'left';
        } else {
            imageJustify = 'center';
        }
    }
    return imageJustify;
}