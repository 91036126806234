import { SavedAddress } from '../../models/Address'
import { aetherApi } from './aetherApi'

const coShippingLocationApi = aetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getSavedAddresses: builder.query<
      SavedAddress[],
      { includeUserAddresses: boolean }
    >({
      query: (params) => ({
        url: `api/v1/co-shipping-location/saved-addresses/all`,
        method: 'GET',
        params,
      }),
      providesTags: ['SavedAddresses'],
    }),
    getShippingLocation: builder.query<SavedAddress, string>({
      query: (id) => `api/v1/co-shipping-location/shipping-location/${id}`,
    }),
  }),
})

export const {
  useGetSavedAddressesQuery,
  useGetShippingLocationQuery,
  useLazyGetSavedAddressesQuery,
  useLazyGetShippingLocationQuery,
} = coShippingLocationApi
