import axios from 'axios';
import * as advanceAPIUtil from '../../util/advance/advanceAPIUtil';
import {
    X_TENANT_ID,
    UPLOAD_CUSTOM_ARTWORK,
    PAYMENTS_ORDER,
    PUNCH_OUT_ORDER,
    ADVANCE_ORDER,
    PAYMENT_SETTING_URL
} from '../../common/ActionUrl';
import { orderListBody } from '../../common/AdvanceBodies';
const requestOptions = {
    headers: { 'X-TENANT-ID': X_TENANT_ID },
}
export const fetchOrders = (limit = 50, offset = 0, order = "", orient = "asc", filters = {}) => {
    const body = {
        source: 'cp',
        limit: limit,
        offset: offset,
        order: order,
        orient: orient,
        'term[orderType]': ['Order', 'CreditMemo', 'StoreOrder'],
    }
    for (let key in filters) {
        if (filters[key] && Array.isArray(filters[key])) {
            if (key == 'paymentStatus' && filters[key] == 0) {
                body[`term[${key}][]`] = 'All'
            } else if (key === 'accountId') {
                body[`termIn[${key}][]`] = filters[key]
            } else {
                filters[key].forEach(el => {
                    body[`term[${key}][]`] = el
                });
            }
        } else if (filters[key] && (key === 'contact_id' || key === 'account_id' || key === 'source' || key === 'cp_user_id')) {
            body[`${key}`]=filters[key]
        } else if (!Array.isArray(filters[key])) {
            if(key === 'orderSource')
                body[`termIn[${key}][]`]=filters[key]
            else 
                body[`term[${key}]`] = filters[key]
        }
    }
    const orderParams = {...orderListBody}
    orderParams.data = body
    return advanceAPIUtil.customAdvanceCall(orderParams)
}

export const getPaymentSettings = async () => {
    return await axios.get(PAYMENT_SETTING_URL, requestOptions)
}

export const fetchOrdersByUser = (approver, groups, limit = 50, offset = 0, order = "", orient = "asc") => {
    const params = {
        limit,
        offset,
        order,
        orient,
        approver,
        groups
    }

    let url = `/api/v1/hold-orders`;
    return axios.get(url, {...requestOptions, params});
}

export const updateStatusHold = ( params) => {
    return axios.post('/api/v1/order-hold/status', params)
}

export const fetchAllStates = (data) => {
    return axios.post(`api/v1/advance/orders/global-state-list-by-country`, data)
}

export const fetchQuotes = (limit = 50, offset = 0, order = "", orient = "asc", filters = {}) => {
    const body = {
        limit: limit,
        offset: offset,
        order: order,
        orient: orient,
        'term[orderType]': ['Quote'],
    }
    for (let key in filters) {
        if (filters[key] && Array.isArray(filters[key])) {
            filters[key].forEach(el => {
                body[`term[${key}][]`] = el
            });
        } else if (filters[key] && (key === 'contact_id' || key === 'account_id' || key === 'source' || key === 'cp_user_id')) {
            body[`${key}`]=filters[key]
        } else if (filters[key] && filters[key].length && !Array.isArray(filters[key])) {
          if(key === 'orderSource')
            body[`termIn[${key}][]`]=filters[key]
          else 
            body[`term[${key}]`] = filters[key]
        }
    }
    const orderParams = {...orderListBody}
    orderParams.data = body
    return advanceAPIUtil.customAdvanceCall(orderParams)
}
export const uploadArtwork = (data) => {
    let URL = window.advanceApiurl;
    if (window.advanceApiurl == undefined || window.advanceApiurl == "undefined") {
        URL = ""
    }
    let url = URL + `/content/file-upload`;
    const formData = new FormData()
    formData.append('File', data.File, data.File.name)
    return axios.post(UPLOAD_CUSTOM_ARTWORK, formData);
};

export const fetchOrderPaymentsAether = (quoteId) => {
    return axios.get(PAYMENTS_ORDER + quoteId, requestOptions);
};

export const deleteOrderItem = (orderId, itemId, userId = null, details = null) => {
    return axios.delete(`${ADVANCE_ORDER}/${orderId}/items/${itemId}`, {
        params: userId ? {
            userId,
            details
        } : undefined
    });
}

export const creatOrderItem = (orderId, item, userId) => {
    const params = {
        userId
    }
    return axios.post(`${ADVANCE_ORDER}/${orderId}/items/`, item, { params });
}


export const punchOutOrderCheckout = (requestDataParams) => {
    return axios.post(PUNCH_OUT_ORDER,requestDataParams, requestOptions);
};


export const fetchOrderOnHoldPendingByOrderId = (orderId) => {
    let url = `/api/v1/hold-orders/order/${orderId}`;
    return axios.get(url, requestOptions);
}
