import React, { useCallback, useEffect } from 'react'
import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Grid2, Stack, Typography } from '@mui/material'
import { useSendOtpMutation } from '../../../redux/api/checkoutApi'
import { useValidateOtpMutation } from '../../../redux/api/otpApi'
import { useAppSelector } from '../../../redux/hooks'
import {
  selectEmail,
  selectParams,
  selectSessionId,
} from '../../../redux/selectors/checkoutSelectors'
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui'
import CheckoutTextField from '../CheckoutTextField'
import { LoadingButton } from '@mui/lab'
import { OtpRequest, OtpValidationResult } from '../../../models/Otp'
import { _useToasts } from '../../../common/_useToasts'
import { getError } from '../../../helpers/checkout'

interface OtpDialogProps {
  open: boolean,
  onClose: () => void
  onContinue: (otpReq: OtpRequest) => void
}

export default function OtpDialog({ open, onClose, onContinue }: OtpDialogProps) {
  const params = useAppSelector(selectParams)
  const sessionId = useAppSelector((state) => selectSessionId(state, params))
  const email = useAppSelector((state) => selectEmail(state, params));
  const { _addToast } = _useToasts()
  const [sendOtp, { isLoading: sendOtpLoading, error: sendOtpError, isSuccess: sendOtpSuccess, data: sendOtpResponse }] = useSendOtpMutation()

  const [validateOtp, { isLoading: validateOtpLoading, error: validateOtpError }] = useValidateOtpMutation()

  const handleSendOtp = useCallback((toast?: boolean) => async () => {
    if (!sessionId || !params) return
    try {
      await sendOtp({ sessionId, userId: params.userId })
      if (toast) {
        _addToast('OTP resent successfully', { appearance: 'success', autoDismiss: true })
      }
    } catch (error) {
      console.error(error);
    }
  }, [sessionId, params, sendOtp, _addToast])

  const handleValidateOtp = useCallback(async (data) => {
    if (!sessionId || !sendOtpResponse) return
    try {
      const otpReq = { id: sendOtpResponse.id, otp: data.otp };
      await validateOtp(otpReq).unwrap();
      onContinue(otpReq)
    } catch (error) {
      console.error(error);
    }
  }, [sendOtpResponse, sessionId, validateOtp, onContinue])

  const formContext = useForm();

  const { handleSubmit, setValue } = formContext;

  useEffect(() => {
    if (open) {
      setValue('otp', '');
      handleSendOtp()();
    }
  }, [open, handleSendOtp, setValue])

  return (
    <FormContainer formContext={formContext}>
      <Dialog open={open}>
        <DialogTitle>One-Time Passcode (OTP)</DialogTitle>
        <DialogContent>
          {sendOtpLoading && (
            <Stack direction={'row'} justifyContent={'center'}>
              <CircularProgress />
            </Stack>
          )}
          {sendOtpSuccess && (
            <Stack direction={'column'} spacing={2}>
              <Typography variant='body1'>A One-Time Passcode has been sent to {email}.</Typography>
              <Typography variant='body1'>Please enter the One-Time Passcode below.</Typography>
              <Grid2 container spacing={2}>
                <Grid2 size={{xs: 12, md: 9}}>
                  <TextFieldElement name='otp' label='One-Time Passcode' required fullWidth />
                </Grid2>
                <Grid2 container size={{xs: 12, md: 3}} alignItems={'center'}>  
                  <Button onClick={handleSendOtp(true)} variant='text'>Resend OTP</Button>
                </Grid2>
              </Grid2>
              {validateOtpError && (
                <Alert severity='error'>{getError(validateOtpError)}</Alert>
              )}
            </Stack>
          )}
          {sendOtpError && (
            <Alert severity='error'>{getError(sendOtpError)}</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton variant='contained' onClick={handleSubmit(handleValidateOtp)} loading={validateOtpLoading} size='large'>Submit</LoadingButton>
        </DialogActions>
      </Dialog>
    </FormContainer>
  )
}
