import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { CardConnectAccount } from "../../../models/CardConnectAccount";
import { _useToasts } from "../../../common/_useToasts";
import { useDeleteCardConnectAccountMutation } from "../../../redux/api/paymentApi";
import { LoadingButton } from "@mui/lab";

interface DeleteCCAccountDialogProps {
  account: CardConnectAccount | null
  onClose: () => void
}

export default function DeleteCCAccountDialog({ account, onClose }: DeleteCCAccountDialogProps) {
  const [deleteAccount, { isLoading: deleteLoading }] = useDeleteCardConnectAccountMutation()

  const { _addToast } = _useToasts()

  const handleDeleteDialogDelete = async () => {
    if (!account) return;
    try {
      await deleteAccount(account?.acctid).unwrap()
      _addToast("Saved card deleted successfully", { appearance: 'success', autoDismiss: true });
      onClose()
    } catch (e) {
      _addToast("Error deleting saved card", { appearance: 'error', autoDismiss: true });
      console.error(e)
    }
  }

  return (
    <Dialog
      open={!!account}
      onClose={onClose}
    >
      <DialogTitle>
        {"Delete Saved Card"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this saved card?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton onClick={handleDeleteDialogDelete} loading={deleteLoading} autoFocus>Delete</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
