import React from 'react'
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Skeleton,
  Typography,
} from '@mui/material'

interface ListSkeletonProps {
  quantity: number
  primaryText?: boolean
  secondaryText?: boolean
  secondaryAction?: boolean
}

export default function ListSkeleton({
  quantity,
  primaryText,
  secondaryText,
  secondaryAction,
}: ListSkeletonProps) {
  return (
    <List disablePadding>
      {Array.from({ length: quantity }, Number.call, (i: number) => i + 1).map(
        (_, idx) => (
          <React.Fragment key={idx}>
            <ListItem
              secondaryAction={
                secondaryAction ? (
                  <Typography variant="body1">
                    <Skeleton variant="text" width={50} />
                  </Typography>
                ) : undefined
              }
              disablePadding
            >
              <ListItemButton dense>
                <ListItemIcon>
                  <Radio disabled />
                </ListItemIcon>
                <ListItemText
                  primary={
                    primaryText ? (
                      <Skeleton variant="text" width={100} />
                    ) : undefined
                  }
                  secondary={
                    secondaryText ? (
                      <Skeleton variant="text" width={200} />
                    ) : undefined
                  }
                />
              </ListItemButton>
            </ListItem>
            <Divider />
          </React.Fragment>
        ),
      )}
    </List>
  )
}
