import { Alert, Skeleton, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import {
  selectDebug,
  selectParams,
  selectVoucherCodes,
} from '../../../redux/selectors/checkoutSelectors';
import { AuthContext } from '../../../common/Auth';
import { useGetActiveVouchersQuery } from '../../../redux/api/voucherApi';
import { AutocompleteElement, useFormContext } from 'react-hook-form-mui';
import { VoucherPaymentSettings } from '../../../models/PaymentMethodSettings';
import useCurrencyDisplay from '../useCurrencyDisplay';
import { getError } from '../../../helpers/checkout';

export interface VoucherPaymentMethodFormProps {
  settings: VoucherPaymentSettings;
  index: number | null;
  methodBalanceDue: number;
}

export default function VoucherForm({ settings, index }: VoucherPaymentMethodFormProps) {
  const debug = useAppSelector(selectDebug);
  const { user } = useContext(AuthContext);
  const params = useAppSelector(selectParams);
  const voucherCodes = useAppSelector((state) => selectVoucherCodes(state, params, index));
  const { format } = useCurrencyDisplay();

  const { setValue } = useFormContext();

  const {
    data: vouchers,
    isLoading,
    error,
  } = useGetActiveVouchersQuery(undefined, { skip: !user });

  const handleVoucherChange = (value: string) => {
    setValue('voucherCode', value);
  };

  return (
    <Stack direction={'column'} spacing={2}>
      <Typography variant="h6">
        Redeem {settings.labelRenameEnabled ? settings.labelRenameText : 'Voucher'}
      </Typography>
      {debug && error ? <Alert severity="error">{getError(error)}</Alert> : null}
      {isLoading && <Skeleton height={56} />}
      {!isLoading && (
        <AutocompleteElement
          name="voucherCode"
          label={`${settings.labelRenameEnabled ? settings.labelRenameText : 'Voucher'} Code`}
          options={vouchers ?? []}
          autocompleteProps={{
            freeSolo: settings.showActiveVouchers,
            getOptionLabel: (option) =>
              typeof option === 'string' ? option : `${option.code} (${format(option.amount)})`,
            getOptionKey: (option) => (typeof option === 'string' ? option : option.code),
            onInputChange(_, value, reason) {
              if (reason === 'input' && settings.showActiveVouchers) {
                handleVoucherChange(value);
              }
            },
          }}
          rules={{
            required: true,
            validate: { used: (value) => !voucherCodes.includes(value) || 'Code already used' },
          }}
          transform={{
            input: (value) => (typeof value === 'string' ? value : value?.code),
            output: (_, value) => (typeof value === 'string' ? value : value?.code ?? null),
          }}
        />
      )}
    </Stack>
  );
}
