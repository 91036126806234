import { createSelector } from 'reselect'
import { RootState } from '../hooks'
import { checkoutApi } from '../api/checkoutApi'
import {
  CheckoutSettings,
  CheckoutStatus,
  CheckoutStep,
} from '../../models/CheckoutSession'
import { GetOrCreateSessionParams } from '../api/checkoutApi'
import Decimal from 'decimal.js'
import {
  AetherPaymentRequest,
  ItemPaymentDetails,
  OrderPayment,
} from '../../models/Payment'
import { AccessType } from '../../models/AccessType'
import { PaymentMethodType } from '../../models/PaymentMethodType'
import { paymentApi } from '../api/paymentApi'
import { budgetApi } from '../api/budgetApi'
import {
  PaymentMethodSettings,
  PointsPaymentSettings,
} from '../../models/PaymentMethodSettings'
import { AetherItemType } from '../../models/OrderItem'
import _ from 'lodash'
import { UserBudget } from '../../models/NewBudget'

export const selectParams = (state: RootState) => state.checkout.sessionParams

export const selectHeaderOffset = (state: RootState) =>
  state.checkout.headerOffset

export const selectSession = createSelector(
  [
    (state: RootState) => state,
    (_, params: GetOrCreateSessionParams | null) => params,
  ],
  (state, params) =>
    params != null
      ? checkoutApi.endpoints.getOrCreateCheckoutSession.select(params)(state)
          .data ?? null
      : null,
)

export const selectSettings = (state: RootState): CheckoutSettings =>
  checkoutApi.endpoints.getCheckoutSettings.select()(state).data ?? {}

export const selectPaymentSettings = (
  state: RootState,
): PaymentMethodSettings[] =>
  paymentApi.endpoints.getPaymentSettings.select()(state).data ?? []

export const selectUserBudgets = (state: RootState): UserBudget[] | undefined =>
  budgetApi.endpoints.getActiveBudgets.select()(state).data

export const selectPaymentMethods = createSelector(
  [selectPaymentSettings],
  (paymentSettings) =>
    paymentSettings
      .filter((settings) => settings.enabled)
      .map((settings) => settings.methodType),
)

export const selectOrder = createSelector(
  selectSession,
  (session) => session?.order,
)

export const selectOrderNo = createSelector(
  selectSession,
  (session) => session?.order?.no,
)

export const selectConfirmationHtml = createSelector(
  selectSession,
  (session) => session?.confirmationHtml,
)

export const selectAetherOrderData = createSelector(
  selectOrder,
  (order) => order?.aetherData ?? null,
)

export const selectOrderDecimalsToShow = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => aetherOrderData?.decimalsToShow ?? 2,
)

export const selectSubtotal = createSelector(
  selectOrder,
  (order) => order?.aetherData?.displaySubtotal ?? 0,
)

export const selectTaxAmount = createSelector(
  selectOrder,
  (order) => order?.aetherData?.displayTax ?? 0,
)

export const selectTaxRate = createSelector(
  selectOrder,
  (order) => order?.tax.rate,
)

export const selectShippingAmount = createSelector(
  selectOrder,
  (order) => order?.aetherData?.displayShipping ?? 0,
)

export const selectHandlingFee = createSelector(
  selectOrder,
  (order) => order?.aetherData?.displayHandlingFee ?? 0,
)

export const selectTotal = createSelector(
  selectOrder,
  (order) => order?.aetherData?.displayTotal ?? 0,
)

export const selectConvenienceFee = createSelector(
  selectAetherOrderData,
  (aetherData) => aetherData?.displayConvenienceFee ?? 0,
)

export const selectDiscount = createSelector(
  selectAetherOrderData,
  (aetherData) => aetherData?.discount ?? 0,
)

export const selectItems = createSelector(
  selectSession,
  (session) => session?.order.items ?? null,
)

export const selectOrderTotals = createSelector(
  [
    selectSubtotal,
    selectShippingAmount,
    selectHandlingFee,
    selectTaxAmount,
    selectTotal,
    selectConvenienceFee,
    selectDiscount,
  ],
  (subtotal, shipping, handlingFee, tax, total, convenienceFee, discount) => ({
    subtotal,
    shipping,
    handlingFee,
    tax,
    total,
    convenienceFee,
    discount,
  }),
)

export const selectStatus = createSelector(
  selectSession,
  (session) => session?.status,
)

export const selectOrderPayments = createSelector(
  selectAetherOrderData,
  (aetherData) => aetherData?.payments ?? [],
)

export const selectTransactionIds = createSelector(
  selectOrderPayments,
  (payments) =>
    payments.map((payment) => payment.transactionId).filter((i) => i),
)

export const selectPaymentRequests = (state: RootState) =>
  state.checkout.paymentRequests

export const selectIsOtherPayments = createSelector(
  [
    selectOrderPayments,
    selectPaymentRequests,
    (_, __, index: number | null) => index,
  ],
  (orderPayments, requests, index) =>
    [...orderPayments, ...requests.filter((_, i) => i !== index)].length > 0,
)

export const selectSkipCustomerDetails = createSelector(
  selectSession,
  (session) => session?.skipCustomerDetails ?? false,
)

export const selectActiveStep = createSelector(
  [selectStatus, (state: RootState) => state.checkout.editingStep],
  (status, editingStep) => {
    if (editingStep) {
      return editingStep
    } else {
      switch (status) {
        case CheckoutStatus.PENDING_DETAILS:
          return CheckoutStep.CUSTOMER_DETAILS
        case CheckoutStatus.PENDING_FULFILLMENT_METHOD:
          return CheckoutStep.FULFILLMENT
        case CheckoutStatus.PENDING_PAYMENT:
          return CheckoutStep.PAYMENT
        case CheckoutStatus.PENDING_SUBMISSION:
          return CheckoutStep.PAYMENT
        default:
          return 'CustomerDetails'
      }
    }
  },
)

export const selectQuestions = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => aetherOrderData?.questions ?? null,
)

export const selectCostCenters = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => ({
    primaryCostCenter: aetherOrderData?.primaryCostCenter,
    secondaryCostCenter: aetherOrderData?.secondaryCostCenter,
    tertiaryCostCenter: aetherOrderData?.tertiaryCostCenter
  }),
)

export const selectFirstName = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => aetherOrderData?.firstName ?? '',
)

export const selectLastName = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => aetherOrderData?.lastName ?? '',
)

export const selectEmail = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => aetherOrderData?.email ?? '',
)

export const selectContactInfo = createSelector(
  [selectFirstName, selectLastName, selectEmail],
  (firstName, lastName, email) => ({ firstName, lastName, email }),
)

export const selectInHandsDate = createSelector(
  [selectOrder],
  (order) => order?.inhandDate,
)

export const selectOrderNote = createSelector(
  [selectAetherOrderData],
  (data) => data?.orderNote,
)

export const selectSessionId = createSelector(
  selectSession,
  (session) => session?._id,
)

export const selectFulfillments = createSelector(
  selectOrder,
  (order) => order?.aetherData?.fulfillments ?? [],
)

export const selectCoupon = createSelector(
  selectAetherOrderData,
  (aetherData) => aetherData?.coupon ?? null,
)

export const selectBalanceDue = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => aetherOrderData?.displayBalanceDue ?? 0,
)

export const selectPayments = createSelector(
  selectAetherOrderData,
  (aetherOrderData) => aetherOrderData?.payments ?? [],
)

export const selectCurrency = createSelector(
  (state: RootState) => state,
  (state) => state.currencyData,
)

export const selectCartId = (state: RootState) => state.cartData.id
export const selectIsCartEmpty = (state: RootState) =>
  (state.cartData?.cartItems?.length ?? 0) === 0

export const selectDebug = (state: RootState) => state.checkout.debug

export const selectGiftCardCodes = createSelector(
  [
    selectPaymentRequests,
    selectOrderPayments,
    (_, __, index: number | null) => index,
  ],
  (requests: AetherPaymentRequest[], payments: OrderPayment[], index) => {
    const codes = []
    for (const request of [
      ...requests.filter((_, idx) => idx !== index),
      ...payments,
    ]) {
      if (request.giftCardCode) {
        if (request.giftCardId) {
          codes.push(request.giftCardCode.replace(/-/g, ''))
        } else {
          codes.push(request.giftCardCode)
        }
      }
    }
    return codes
  },
)

export const selectGiftCardIds = createSelector(
  [
    selectPaymentRequests,
    selectOrderPayments,
    (_, __, index: number | null) => index,
  ],
  (requests: AetherPaymentRequest[], payments: OrderPayment[], index) => {
    const ids = []
    for (const request of [
      ...requests.filter((_, idx) => idx !== index),
      ...payments,
    ]) {
      if (request.giftCardId) {
        ids.push(request.giftCardId)
      }
    }
    return ids
  },
)

export const selectVoucherCodes = createSelector(
  [
    selectPaymentRequests,
    selectOrderPayments,
    (_, __, index: number | null) => index,
  ],
  (requests: AetherPaymentRequest[], payments: OrderPayment[], index) => {
    const codes: string[] = []
    for (const request of [
      ...requests.filter((_, idx) => idx !== index),
      ...payments,
    ]) {
      if (request.voucherCode) {
        codes.push(request.voucherCode)
      }
    }
    return codes
  },
)

export const selectBudgetIds = createSelector(
  [
    selectPaymentRequests,
    selectOrderPayments,
    (_, __, index: number | null) => index,
  ],
  (requests: AetherPaymentRequest[], payments: OrderPayment[], index) => {
    const codes = []
    for (const request of [
      ...requests.filter((_, idx) => idx !== index),
      ...payments,
    ]) {
      if (request.budgetId) {
        codes.push(request.budgetId)
      }
    }
    return codes
  },
)

export const selectWorkingItemPaymentDetails = createSelector(
  [selectPaymentRequests, selectItems, (_, __, index: number | null) => index],
  (requests, items, index) => {
    let details: ItemPaymentDetails[] = []
    for (const item of items ?? []) {
      const restriction = item.aetherData.paymentRestriction
      details.push({
        restriction,
        itemId: item.id,
        itemType: item.aetherData.aetherItemType,
        applied: item.aetherData.paymentApplied,
        total: new Decimal(item.aetherData.displayTotalPrice)
          .plus(item.aetherData.displayTax)
          .minus(item.aetherData.discount)
          .toNumber(),
      })
    }
    const filteredRequests = requests.filter((_, i) => i !== index)
    for (const request of filteredRequests) {
      let left = new Decimal(request.itemAmount)
      for (const itemId of request.itemIds) {
        const itemDetail = details.find((r) => r.itemId === itemId);
        if (itemDetail) {
          const itemRemaining = new Decimal(itemDetail.total).minus(itemDetail.applied);
          if (itemRemaining.greaterThan(0)) {
            const applied = left.greaterThan(itemRemaining)
              ? itemRemaining.toNumber()
              : left.toNumber();
            left = left.minus(applied);
            itemDetail.applied = new Decimal(itemDetail.applied).plus(applied).toNumber();
          }
        }
      }
    }
    return details
  },
)

export const selectWorkingConvenienceFee = createSelector(
  selectPaymentRequests,
  (requests) =>
    requests.reduce(
      (acc, request) => ({
        fee: new Decimal(request.convenienceFee ?? 0).plus(acc.fee),
        tax: new Decimal(request.convenienceFeeTax ?? 0).plus(acc.tax),
      }),
      { fee: new Decimal(0), tax: new Decimal(0) },
    ),
)

export const selectRemainingBalanceDue = createSelector(
  [
    selectBalanceDue,
    selectPaymentRequests,
    (_, __, index: number | null) => index,
  ],
  (balanceDue, requests, index) =>
    new Decimal(balanceDue)
      .minus(
        requests
          .filter((_, i) => i !== index)
          .reduce(
            (acc, request) =>
              acc
                .plus(request.amount)
                .minus(request.convenienceFee ?? 0)
                .minus(request.convenienceFeeTax ?? 0),
            new Decimal(0),
          ),
      )
      .toNumber(),
)

export const selectRoundingAdjustment = createSelector(
  selectAetherOrderData,
  (aetherData) => aetherData?.roundingAdjustment ?? 0,
);

export const selectWorkingRoundingAdjustment = createSelector(
  [selectPaymentRequests, (_, index: number | null) => index],
  (requests, index) =>
    requests
      .filter((_, idx) => !index || index >= idx)
      .reduce((acc, req) => new Decimal(req.roundingAdjustment).plus(acc), new Decimal(0)),
);

export const selectMethodBalanceDueMap = createSelector(
  [
    selectWorkingItemPaymentDetails,
    selectPaymentMethods,
    selectPaymentSettings,
  ],
  (
    workingItemPaymentDetails,
    methodTypes,
    paymentSettings,
  ) => {
    let methodDataMap: Record<
      string,
      {
        amount: Decimal
        items: { itemId: string; amount: number }[]
        restrictedItemIds: string[]
      }
    > = {}
    for (const methodType of methodTypes) {
      methodDataMap[methodType] = {
        amount: new Decimal(0),
        items: [],
        restrictedItemIds: [],
      }
    }
    for (const details of workingItemPaymentDetails) {
      const restriction = details.restriction
      for (const methodType of methodTypes) {
        const settings = paymentSettings.find(
          (settings) => settings.methodType === methodType,
        )
        const methodAllowed =
          (details.itemType === AetherItemType.PRODUCT && settings?.applyToProduct) ||
          ([AetherItemType.SHIPPING, AetherItemType.HANDLING_FEE].includes(details.itemType) &&
            settings?.applyToShipping);
        const productAllowed =
          !restriction ||
          (restriction.type === AccessType.ALLOW_LIST &&
            restriction.methodTypes.includes(methodType)) ||
          (restriction.type === AccessType.BLOCK_LIST &&
            !restriction.methodTypes.includes(methodType))
        if (methodAllowed && productAllowed) {
          const amount = new Decimal(details.total).minus(details.applied)
          methodDataMap[methodType].amount =
            methodDataMap[methodType].amount.plus(amount)
          methodDataMap[methodType].items.push({
            itemId: details.itemId,
            amount: amount.toNumber(),
          })
        } else {
          if (details.total > 0) {
            methodDataMap[methodType].restrictedItemIds.push(details.itemId)
          }
        }
      }
    }
    return methodDataMap
  },
)

export const selectAllowedPaymentMethods = createSelector(
  [
    selectPaymentSettings,
    selectPaymentRequests,
    selectOrderPayments,
    (_, __, index: number | null) => index,
  ],
  (paymentSettings, paymentRequests, orderPayments, index) => {
    const existingPayments = paymentRequests.filter((_, i) => i !== index)
    const allowedMethods = paymentSettings.filter(
      (settings) => settings.enabled,
    )
    const pointsSettings = paymentSettings.find(
      (s) => s.methodType === PaymentMethodType.POINTS,
    ) as PointsPaymentSettings
    if (
      pointsSettings?.enabled &&
      pointsSettings?.requirePointsFirst &&
      existingPayments.length === 0 &&
      orderPayments.length === 0
    ) {
      return [pointsSettings]
    }
    const types = new Set(
      [...existingPayments, ...orderPayments].map((p) => p.methodType),
    )
    return allowedMethods.filter(
      (m) => m.allowMultiple || !types.has(m.methodType),
    )
  },
)

export const selectLockChanges = createSelector(
  [selectOrderPayments, selectStatus],
  (payments, status) =>
    payments.length > 0 ||
    status === undefined ||
    status === CheckoutStatus.COMPLETED,
)

export const selectMethodPaymentData = createSelector(
  [
    selectMethodBalanceDueMap,
    (_, __, ___, methodType: PaymentMethodType) => methodType,
  ],
  (methodBalanceDueMap, methodType) => methodBalanceDueMap[methodType],
)

export const selectPaymentMethodType = (state: RootState) =>
  state.checkout.selectedPaymentMethodType

export const selectCurrentPaymentMethodTypes = createSelector(
  [selectOrderPayments, selectPaymentRequests, selectPaymentMethodType],
  (orderPayments, requests, selectedPaymentMethodType) => {
    return [
      ...orderPayments.map((p) => p.methodType),
      ...requests.map((p) => p.methodType),
      ...(selectedPaymentMethodType ? [selectedPaymentMethodType] : []),
    ]
  },
)

export const selectPaidMethods = createSelector(
  selectPaymentSettings,
  (settings) =>
    settings?.filter((s) => s.paidMethod).map((s) => s.methodType) ?? [],
)

export const selectPaidMethodsUsed = createSelector(
  [selectCurrentPaymentMethodTypes, selectPaidMethods],
  (currentPaymentMethodTypes, paidMethods) => {
    return _.intersection(currentPaymentMethodTypes, paidMethods).length > 0
  },
)

export const selectSinglePaymentSettings = createSelector(
  [selectPaymentSettings, (_, paymentMethodType) => paymentMethodType],
  (settings, paymentMethodType) =>
    settings?.find((s) => s.methodType === paymentMethodType),
)

export const selectOrderFiles = createSelector(
  [selectAetherOrderData],
  (aetherData) => aetherData?.files ?? [],
)

export const selectGiftCardItems = createSelector(
  selectItems,
  (items) =>
    items?.filter((item) => item.aetherData.isGiftCard) ?? [],
)

export const selectFulfillmentCountries = createSelector(
  selectSettings,
  (settings) => settings.fulfillmentCountries,
)

export const selectBillingCountries = createSelector(
  selectSettings,
  (settings) => settings.billingCountries,
)

export const selectFulfillmentRegionDefinitions = createSelector(
  selectSettings,
  (settings) => settings.fulfillmentRegionDefinitions,
)

export const selectBillingRegionDefinitions = createSelector(
  selectSettings,
  (settings) => settings.billingRegionDefinitions,
)

export const selectFulfillmentRegionRestrictions = createSelector(
  [selectFulfillmentCountries, selectFulfillmentRegionDefinitions],
  (countries, regionDefinitions) => ({  countries, regionDefinitions }),
)

export const selectBillingRegionRestrictions = createSelector(
  [selectBillingCountries, selectBillingRegionDefinitions],
  (countries, regionDefinitions) => ({  countries, regionDefinitions }),
)