import { Card, CardContent, Stack, Typography } from '@mui/material'
import React, { forwardRef } from 'react'
import { useAppSelector } from '../../../redux/hooks'
import {
  selectActiveStep,
  selectParams,
  selectPayments,
} from '../../../redux/selectors/checkoutSelectors'
import { CheckoutStep } from '../../../models/CheckoutSession'
import PaymentActiveContent from './PaymentActiveContent'
import PaymentPassiveContent from './PaymentPassiveContext'

export default forwardRef<HTMLDivElement>(function PaymentCard(_, ref) {
  const params = useAppSelector(selectParams)
  const activeStep = useAppSelector((state) => selectActiveStep(state, params))
  const payments = useAppSelector((state) => selectPayments(state, params));

  return (
    <div ref={ref}>
      <Card variant="outlined">
        <CardContent>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="h5" gutterBottom>
              {"Payment"}
            </Typography>
          </Stack>
          <Stack direction={'column'} spacing={2}>
            {payments.length > 0 && (
              <PaymentPassiveContent />
            )}
            {activeStep === CheckoutStep.PAYMENT && (
              <PaymentActiveContent />
            )}
          </Stack>
        </CardContent>
      </Card>
    </div>
  )
})
