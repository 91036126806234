import { Cart } from '../../models/Cart';
import { aetherApi } from './aetherApi';

export const cartApi = aetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getSavedCart: builder.query<Cart, string>({
      query: (id) => `api/v1/cart/${id}`,
    }),
  }),
});

export const { useGetSavedCartQuery, useLazyGetSavedCartQuery } = cartApi;
