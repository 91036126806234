import React, { useEffect, useState } from 'react';
import { OrderItem } from '../../models/OrderItem';
import { Box, Card, Collapse, IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ItemListItem } from './ItemListItem';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface KitItemCardProps {
  parentItem: OrderItem;
  childItems: OrderItem[];
  index: number;
}

export default function KitItemCard({ parentItem, childItems, index }: KitItemCardProps) {
  const theme = useTheme();
  const xsDisplay = useMediaQuery(theme.breakpoints.only('xs'));

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(!xsDisplay);
  }, [xsDisplay]);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <Card sx={{ backgroundColor: grey[50], m: 1 }}>
      <Stack direction={'row'} alignItems={'center'}>
        <IconButton onClick={handleToggle}>{open ? <ExpandLess /> : <ExpandMore />}</IconButton>
        <Box flexGrow={1}>
          <ItemListItem item={parentItem} data-testid={'listItem' + index} />
        </Box>
      </Stack>
      <Collapse in={open}>
        {(childItems ?? []).map((childItem, childIdx) => (
          <ItemListItem
            key={childIdx}
            item={childItem}
            data-testid={'listItem' + index + '-' + childIdx}
          />
        ))}
      </Collapse>
    </Card>
  );
}
