import React, { useEffect, useMemo } from 'react'
import {
  Alert,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import PaymentMethodCard from './PaymentMethodCard'
import {
  PaymentMethodType,
} from '../../../models/PaymentMethodType'
import { AetherPaymentRequest } from '../../../models/Payment'
import { useGetPaymentSettingsQuery } from '../../../redux/api/paymentApi'
import {
  getError,
  getValidContact,
  getValidFulfillmentsForBilling,
} from '../../../helpers/checkout'

import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
  selectAllowedPaymentMethods,
  selectBillingRegionRestrictions,
  selectContactInfo,
  selectFulfillments,
  selectOrderPayments,
  selectParams,
  selectPaymentMethodType,
  selectPaymentRequests,
  selectSettings,
} from '../../../redux/selectors/checkoutSelectors'
import _ from 'lodash'
import { PaymentMethodSettings } from '../../../models/PaymentMethodSettings'
import { RestrictAddressMode } from '../../../models/CheckoutSession'
import { setSelectedPaymentMethodType } from '../../../redux/reducers/checkoutReducer'

interface PaymentRequestContentProps {
  index: number | null
  onEdit: () => void
  errorMessage?: {
    method: PaymentMethodType
    message: string
  }
  onSubmit: ({
    index,
    data,
  }: {
    index: number | null
    data: AetherPaymentRequest
  }) => void
}

export default function PaymentRequestContent({
  index,
  onEdit,
  errorMessage,
  onSubmit,
}: PaymentRequestContentProps) {
  const settings = useAppSelector(selectSettings)
  const params = useAppSelector(selectParams)
  const requests = useAppSelector(selectPaymentRequests)
  const selectedPaymentMethodType = useAppSelector(selectPaymentMethodType)
  const orderPayments = useAppSelector((state) =>
    selectOrderPayments(state, params),
  )
  const contactInfo = useAppSelector((state) =>
    selectContactInfo(state, params),
  )
  const allowedPaymentMethods: PaymentMethodSettings[] = useAppSelector(
    (state) => selectAllowedPaymentMethods(state, params, index),
  )
  const fulfillments = useAppSelector((state) =>
    selectFulfillments(state, params),
  )

  const { countries, regionDefinitions } = useAppSelector(selectBillingRegionRestrictions)

  const { isLoading: settingsLoading, error: settingsError } =
    useGetPaymentSettingsQuery()

  const dispatch = useAppDispatch()

  const defaultPaymentRequest: Partial<AetherPaymentRequest> = useMemo(() => {
    // First use the last payment on the order if exists
    const payments = [...(orderPayments ?? 0), ...(requests ?? [])]
    const primaryBilling = payments[payments.length - 1]
    if (primaryBilling) {
      return _.cloneDeep({
        address: primaryBilling.address,
        contact: getValidContact(
          primaryBilling.contact,
          contactInfo,
          settings.isBillingNameLocked,
          settings.isBillingEmailLocked,
        ),
        addressSource: primaryBilling.addressSource,
        savedAddressId: primaryBilling.savedAddressId ?? null,
      })
    }

    if (settings.isAddressCarryoverEnabled ?? true) {
      // Then use the last fulfillment address if it is a shipping address
      const validFulfillments = getValidFulfillmentsForBilling(
        fulfillments,
        settings.restrictBillingAddressMode ?? RestrictAddressMode.NONE,
        settings.restrictBillingAddressId,
        countries,
        regionDefinitions,
      )
      const primaryFulfillment = validFulfillments[validFulfillments.length - 1]
      if (primaryFulfillment) {
        return _.cloneDeep({
          address: primaryFulfillment.address,
          contact: getValidContact(
            primaryFulfillment.contact,
            contactInfo,
            settings.isBillingNameLocked,
            settings.isBillingEmailLocked,
          ),
          addressSource: primaryFulfillment.addressSource,
          savedAddressId: primaryFulfillment.savedAddressId ?? null,
        })
      }

      // Use last fulfillment contact
      const primaryContactFulfillment = fulfillments[fulfillments.length - 1]
      if (primaryContactFulfillment) {
        return _.cloneDeep({
          contact: getValidContact(
            primaryContactFulfillment.contact,
            contactInfo,
            settings.isBillingNameLocked,
            settings.isBillingEmailLocked,
          ),
        })
      }
    }
    return {
      contact: { ...contactInfo },
    }
  }, [
    contactInfo,
    fulfillments,
    orderPayments,
    requests,
    settings,
  ])

  const request: AetherPaymentRequest | null =
    typeof index === 'number' ? requests[index] : null

  useEffect(() => {
    if (request?.methodType) {
      dispatch(setSelectedPaymentMethodType(request.methodType))
    }
  }, [dispatch, request])

  const handleClick = (type: PaymentMethodType) => () => {
    dispatch(setSelectedPaymentMethodType(type))
  }

  const handleSubmit = (data: AetherPaymentRequest) => {
    dispatch(setSelectedPaymentMethodType(null))
    onSubmit({ index, data })
  }

  return (
    <Stack direction={'column'} spacing={2}>
      <Typography variant={'h6'}>Select Payment Method</Typography>
      <Stack direction={'column'} spacing={2}>
        {settingsLoading && (
          <>
            <Skeleton variant="rounded" width={'100%'} height={'74px'} />
            <Skeleton variant="rounded" width={'100%'} height={'74px'} />
          </>
        )}
        {allowedPaymentMethods?.map((settings) => (
          <PaymentMethodCard
            key={settings.methodType}
            settings={settings}
            onSelect={handleClick(settings.methodType)}
            selected={selectedPaymentMethodType === settings.methodType}
            request={
              request?.methodType === settings.methodType
                ? request
                : defaultPaymentRequest
            }
            index={index}
            errorMessage={
              selectedPaymentMethodType === errorMessage?.method
                ? errorMessage.message
                : undefined
            }
            onSubmit={handleSubmit}
          />
        ))}
      </Stack>
      {settingsError && (
        <Alert severity="error">
          Error loading payment methods: {getError(settingsError)}
        </Alert>
      )}
    </Stack>
  )
}
