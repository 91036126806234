import { CostCenter, SearchCostCenterRequest } from '../../models/CostCenter'
import { aetherApi } from './aetherApi'

const costCenterApi = aetherApi.injectEndpoints({
  endpoints: (builder) => ({
    searchCostCenters: builder.query<CostCenter[], SearchCostCenterRequest>({
      query: (params) => ({
        url: `api/v1/cost-centers/basic/search`,
        params
      }),
    }),
    getAllCostCenters: builder.query<CostCenter[], void>({
      query: () => ({
        url: 'cost-code',
        params: { allCostCenter: true, query: '' },
      }),
    }),
  }),
})

export const {
  useGetAllCostCentersQuery,
  useSearchCostCentersQuery,
  useLazySearchCostCentersQuery
} = costCenterApi
