import PropTypes from "prop-types";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import Swiper from "react-id-swiper";
import useElementSize from "../../util/custom-hooks/useElementSize";
import { productImageExt, productImageUrl } from "../../util/helper";
import { AuthContext } from '../../common/Auth'
import { useProductConfigState } from "../../pages/shop-product/state/useProductConfigState";
import { fetchImageSize, getImageJustification } from "../../wrappers/product/util/getDimensions";
const ProductImageGalleryHats = ({ product,
    selectedImage,
    selectedColor,
    selectedDecorations,
    isSearchArtwork,
    singleSelectedDecorations,
    artworkListById,
    setSelectedImage,
    selectedThumbImgColor,
    setSelectedThumbImgColor,
    isReplace,
    sliderListingData,
    isSmall = false,
    swiperKey,
}) => {
    const {
        settingData
    } = useContext(AuthContext)

    const {
        getSettingStatus,
    } = useProductConfigState({ product })

    const [imgW, imgH] = useElementSize(
        '.swiper-slide-active .containerimg img',
    )
    const [imgSmallW, imgSmallH] = useElementSize(
        '.product-small-image-wrapper .swiper-slide .single-image .main-small',
    )

    const [imageW, setImageW] = useState(imgW)
    const [imageH, setImageH] = useState(imgH)

    const [imageSmallW, setImageSmallW] = useState(imgSmallW)
    const [imageSmallH, setImageSmallH] = useState(imgSmallH)

    const [gallerySwiper, getGallerySwiper] = useState(null)
    const [thumbnailSwiper, getThumbnailSwiper] = useState(null)
    const [DefaultProductBackgroundColor, setDefaultProductBackgroundColor] = useState()
    const [imageChanged, setImageChanged] = useState(0)
    const [heightVal, setHeightVal] = useState('140')
    const [overlayImgWidth, setOverlayImgWidth] = useState(0);
    const [overlayImgHeight, setOverlayImgHeight] = useState(0);
    const [overlayImgTop, setOverlayImgTop] = useState(0);
    const [overlayImgLeft, setOverlayImgLeft] = useState(0);
    useEffect(() => {
        if (settingData?.length > 0) {
            settingData.map((i) => {
                if (i.page === 'General') {
                    if (!!i?.values?.DefaultProductBackgroundColor) {
                        setDefaultProductBackgroundColor(i?.values?.DefaultProductBackgroundColor);
                    }
                }
            })
        }
    }, [settingData])

    useEffect(() => {
        setTimeout(() => {
            const element = document.querySelectorAll('.swiper-slide-active .containerimg img');
            setImageW(element[0]?.offsetWidth)
            setImageH(element[0]?.offsetHeight)

            const elementSmall = document.querySelectorAll('.product-small-image-wrapper .swiper-slide .single-image .main-small');
            if (elementSmall[0]?.offsetWidth >= 190) {
                setHeightVal('90')
            } else {
                setHeightVal('140')
            }
            setImageSmallW(elementSmall[0]?.offsetWidth)
            setImageSmallH(elementSmall[0]?.offsetHeight)

        }, 1000);
    }, [selectedDecorations]);

    useEffect(() => {
        setTimeout(() => {
            const element = document.querySelectorAll('.swiper-slide-active .containerimg img');
            setImageW(element[0]?.offsetWidth)
            setImageH(element[0]?.offsetHeight)

            const elementSmall = document.querySelectorAll('.product-small-image-wrapper .swiper-slide .single-image .main-small');
            if (elementSmall[0]?.offsetWidth >= 190) {
                setHeightVal('90')
            } else {
                setHeightVal('140')
            }
            setImageSmallW(elementSmall[0]?.offsetWidth)
            setImageSmallH(elementSmall[0]?.offsetHeight)
        }, 500);
    }, [imgW, imgH]);

    useEffect(() => {
        let key = 0
        if (selectedImage) {
            let temp = product?.WebstoreProductPartArray.filter((val) => val.image === selectedImage && val?.isSwapImage,)
            if (temp.length > 0) {
                if (product?.WebstoreProductPartArray) {
                    product.WebstoreProductPartArray.filter(
                        (ele, ind) => ind === product?.WebstoreProductPartArray.findIndex(elem => elem.color === ele.color)).map((single, index) => {
                            if (single.image === selectedImage) {
                                return key = index

                            }
                        })
                }
            } else {
                if (sliderListingData) {
                    sliderListingData.map((single, index) => {
                        if (single.url === selectedImage) {
                            key = index
                        }
                    })
                }
            }
            if (document.querySelectorAll('.swiper-pagination-bullet')) {
                let color_current = ''
                if (document.querySelector('#sw1')) {
                    if (
                        document.querySelector('#sw1').querySelector('.swiper-slide-active')
                    ) {
                        if (
                            document
                                .querySelector('#sw1')
                                .querySelector('.swiper-slide-active')
                                .querySelector('span')
                        ) {
                            color_current = document
                                .querySelector('#sw1')
                                .querySelector('.swiper-slide-active')
                                .querySelector('span').innerText
                        }
                    }
                }
                if (color_current != selectedImage) {
                    const frameZones = Array.from(
                        document.querySelectorAll('.swiper-pagination-bullet'),
                    )
                    let flag = true
                    frameZones &&
                        frameZones.map(async (el, k) => {
                            if (k === key && flag) {
                                flag = false
                                let opts = {
                                    view: window,
                                    bubbles: true,
                                    cancelable: true,
                                    buttons: 1,
                                }
                                el.dispatchEvent(new MouseEvent('mousedown', opts))
                                await new Promise((r) => setTimeout(r, 50))
                                el.dispatchEvent(new MouseEvent('mouseup', opts))
                                el.dispatchEvent(new MouseEvent('click', opts))
                            }
                        })
                }
            }
        }
    }, [selectedImage])

    useEffect(() => {
        if (singleSelectedDecorations) {
            let getIndex = product.LogoInfo.filter(
                (val) =>
                    val.color === selectedColor &&
                    val.location === singleSelectedDecorations.location,
            )

            if (getIndex.length > 0) {
                setSelectedImage(getIndex[0]?.image)
                setImageChanged(imageChanged + 1)
            } else {

                let getIndexOfExtra_logo = []
                product.LogoInfo.map(val => {
                    if (val.color === selectedColor) {

                        if (val.extra_logo.filter(e => e.location === singleSelectedDecorations.location).length > 0) {
                            getIndexOfExtra_logo.push(val)
                        }
                    }
                })
                if (getIndexOfExtra_logo.length) {
                    setSelectedImage(getIndexOfExtra_logo[0]?.image)
                    setImageChanged(imageChanged + 1)
                } else {
                    let getImgColorIndex = product.LogoInfo.filter(
                        (val) =>
                            val.color === selectedColor
                    )

                    if (getImgColorIndex.length > 0) {
                        setSelectedImage(getImgColorIndex[0]?.image)
                        setImageChanged(imageChanged + 1)
                    }
                }

            }
        }

    }, [singleSelectedDecorations])

    useEffect(() => {
        if (
            gallerySwiper !== null &&
            gallerySwiper.controller &&
            thumbnailSwiper !== null &&
            thumbnailSwiper.controller
        ) {
            gallerySwiper.controller.control = thumbnailSwiper
            thumbnailSwiper.controller.control = gallerySwiper
        }
    }, [gallerySwiper, thumbnailSwiper])

    // swiper slider settings
    const gallerySwiperParams = {
        getSwiper: getGallerySwiper,
        spaceBetween: 10,
        loopedSlides: 4,
        loop: false,
        effect: 'fade',
    }

    const thumbnailSwiperParams = {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        on: {
            init: () => {
                let color_current = document
                    ?.querySelector('#sw1')
                    ?.querySelector('.swiper-slide-active')
                    ?.querySelector('span').innerText
                setSelectedThumbImgColor(color_current)
            },
            slideChangeTransitionEnd: (el) => {
                if (document.querySelector('#sw1')) {
                    if (
                        document.querySelector('#sw1').querySelector('.swiper-slide-active')
                    ) {
                        if (
                            document
                                ?.querySelector('#sw1')
                                ?.querySelector('.swiper-slide-active')
                                ?.querySelector('span')
                        ) {
                            let color_current = document
                                ?.querySelector('#sw1')
                                ?.querySelector('.swiper-slide-active')
                                ?.querySelector('span').innerText
                            setSelectedThumbImgColor(color_current)
                        }
                    }
                }
            },
        },
        getSwiper: getThumbnailSwiper,
        spaceBetween: 10,
        slidesPerView:
            sliderListingData?.length >= 4
                ? 4
                : sliderListingData?.length >= 3
                    ? 3
                    : sliderListingData?.length >= 2
                        ? 2
                        : 1,
        loopedSlides: 4,
        touchRatio: 1,
        touchAngle: 90,
        threshold: 20,
        speed: 100,
        loop: false,
        slideToClickedSlide: true,
        direction: 'vertical',
        observer: true,
        observeParents: true,
        initialSlide: 0,
    }

    const getReducePer = (width, param) => {
        let reducePer = 100
        while ((Number(width) * reducePer) / 100 > param) {
            reducePer = reducePer - 1
        }
        return reducePer
    }
    let getSingleImage = {}
    const photoZoom = async (zmImg = '', singlelogoDetails = {}) => {
        const node = document.querySelector(".swiper-slide-active .single-image.lightGal");
        let zoomPictureNode = document.getElementsByClassName("lg-img-wrap")
        if (!!zoomPictureNode && !!zoomPictureNode?.[0] && !!DefaultProductBackgroundColor && zmImg !== '')
            zoomPictureNode[0].classList.add(zmImg)

        if (node) {
            const clone = node.cloneNode(true);
            zoomPictureNode[0].classList.add('lg-img-zoom')
            if (zoomPictureNode) {
                zoomPictureNode[0].appendChild(clone);
            }
            var fourChildNode = zoomPictureNode[0].querySelector('.containerimg');
            if (!!fourChildNode) {
                fourChildNode.style.maxWidth = 'unset'
                fourChildNode.style.width = 'unset'
                fourChildNode.style.margin = 'unset'
            }
            let fourChildNode0 = zoomPictureNode[0].querySelector('.containerimg .react_lightgallery_item');
            let fourChildNode1 = zoomPictureNode[0].querySelector('.containerimg .react_lightgallery_item .img-fluid');
            console.log('fourChildNode1: ', fourChildNode1?.width);
            if (!!fourChildNode1 && fourChildNode1?.width > 2000) {
                fourChildNode1.style.height = 'auto'
                fourChildNode0.style.display = 'flex'
                fourChildNode0.style.alignItems = 'center'
                fourChildNode0.style.justifyContent = 'center'
            }
        }

        getSingleImage = singlelogoDetails
        let getOuterBOx = document.querySelector('.lg-img-wrap .react_lightgallery_item')
        if (!!getOuterBOx && !!getSingleImage) {
            let imgWidthZoom = parseFloat(getSingleImage?.iw) || 0;
            let imgHeightZoom = parseFloat(getSingleImage?.ih) || 0;
            let singleZoomX = parseFloat(getSingleImage?.x) || 0;
            let singleZoomY = parseFloat(getSingleImage?.y) || 0;
            let singleZoomW = parseFloat(getSingleImage?.w) || 0;
            let singleZoomH = parseFloat(getSingleImage?.h) || 0;

            if (getSingleImage?.isNewLogo === "1" && !!getSingleImage?.processedImage) {
                imgWidthZoom = getSingleImage?.processedImageWidth
                imgHeightZoom = getSingleImage?.processedImageHeight
                singleZoomX = getSingleImage?.newX
                singleZoomY = getSingleImage?.newY
                singleZoomW = getSingleImage?.newW
                singleZoomH = getSingleImage?.newH
            }
            const { width, height } = await fetchImageSize(getSingleImage.image);
            const imageJustify = getImageJustification(getSingleImage);
            let maxValue = Number.MIN_VALUE;
            let ratio = 1;
            let newX = getSingleImage.x;
            let newY= getSingleImage.y;
            let newW = getSingleImage.w;
            let newH = getSingleImage.h;
      
            let newIw = width, newIh = height;
            if(height > 800 || width > 800) {
      
              maxValue = Math.max(width, height);
              if(maxValue === height) {
                ratio = 800/height
                newIh = 800;
                newIw = ratio*width;
              }else{
                ratio = 800/width;
                newIw = 800;
                newIh = height*ratio;
              }
            }
            if(ratio !== 1){
              newW = (getSingleImage.w*100)/newIw;
              newX = (getSingleImage.x*100)/newIw;
              newY = (getSingleImage.y*100)/newIh;
              newH = (getSingleImage.h*100)/newIh;
            } else{
              newW = (getSingleImage.w*100)/width;
              newX = (getSingleImage.x*100)/width;
              newY = (getSingleImage.y*100)/height;
              newH = (getSingleImage.h*100)/height;
            }

            let overlayImag = document.querySelectorAll(".lg-img-wrap .overlayimg")
            const css = '.overlayimg:hover{  transform: scale(1.5); }';
            const style = document.createElement('style');
            if (style.sheet) {
                style.sheet.cssText = css;
            } else {
                style.appendChild(document.createTextNode(css));
            }
            for (let i = 0; i < overlayImag.length; i++) {
                overlayImag[i].style.left = newX + "%"
                overlayImag[i].style.top = newY + "%"
                overlayImag[i].style.width = newW + "%"
                overlayImag[i].style.height = newH + "%"
                overlayImag[i].style.transition = 'transform 0.3s';
                setOverlayImgTop(newX)
                setOverlayImgLeft(newX)
                setOverlayImgWidth(newW );
                setOverlayImgHeight(newH);
                overlayImag[i].appendChild(style)
            }


            let overlayImag2 = document.querySelectorAll(".lg-img-wrap .overlayimg .img-fluid")
            for (let i = 0; i < overlayImag2.length; i++) {
                overlayImag2[i].style.removeProperty('max-width');
                overlayImag2[i].style.removeProperty('max-height');
                overlayImag2[i].style.left = overlayImgLeft;
                overlayImag2[i].style.top = overlayImgTop;
                overlayImag2[i].style.width = '100%';
                overlayImag2[i].style.height = '100%';
                overlayImag2[i].style.objectFit = 'contain';
                overlayImag2[i].style.objectPosition = imageJustify
            }
        }
        let getOuterBOxCheck2 = document.querySelector('.lg-img-wrap .lightGal')
        if (!getOuterBOxCheck2) {
            return
        }
        let zoomInBtn = document.getElementById('lg-zoom-in');
        let zoomOutBtn = document.getElementById('lg-zoom-out');

        let scaleItem = 1

        zoomInBtn.addEventListener('click', function (event) {
            getOuterBOxCheck2.style.transform = `scale(${scaleItem + 0.5}) translateX(-50%)`
            scaleItem = scaleItem + 0.5
        });
        zoomOutBtn.addEventListener('click', function (event) {
            getOuterBOxCheck2.style.transform = `scale(${scaleItem - 0.5}) translateX(-50%)`
            scaleItem = scaleItem - 0.5
        });
    }

    useEffect(() => {
        setTimeout(() => {
            const element = document.querySelectorAll('.swiper-slide-active .containerimg img');
            const elementSmall = document.querySelectorAll('.product-small-image-wrapper .swiper-slide .single-image .main-small');
            if (element.length > 0) {
                setImageW(element[0]?.offsetWidth)
                setImageH(element[0]?.offsetHeight)
                setImageSmallW(elementSmall[0]?.offsetWidth)
                setImageSmallH(elementSmall[0]?.offsetHeight)
                if (element[0]?.offsetWidth <= 451 && element[0]?.offsetWidth > 0) {
                    const element1 = document.querySelectorAll('.swiper-slide-active .containerimg');
                    if (element1.length > 0) {
                        element1[0].style.width = `${element[0]?.offsetWidth}px`
                    }
                }
            }
        }, 100);
    }, [imageChanged]);

    return (
        <Fragment>
            {!!!isSmall && <div
                className={'col-xl-12'}
            >
                <div className="product-large-image-wrapper" id={'sw1'}>
                    {product.discount || product.new ? (
                        <div className="product-img-badges">
                            {product.discount ? (
                                <span className="pink">-{product.discount}%</span>
                            ) : (
                                ''
                            )}
                            {product.new ? <span className="blue">New</span> : ''}
                        </div>
                    ) : (
                        ''
                    )}

                    {isReplace ? <Swiper {...gallerySwiperParams}>
                        {sliderListingData &&
                            sliderListingData.map((single, key) => {
                                let findlogoinfo = ''
                                let singlelogoDetails = {}
                                let prImage = single.url
                                if (single?.isNewLogo == "1" && !!single?.processedImage) {
                                    prImage = single.processedImage
                                }
                                let getImage = product?.WebstoreProductPartArray.filter((val) => val.color == single.color && val.isSwapImage == true,)
                                if (getImage.length > 0) {
                                    prImage = getImage[0].image
                                }
                                if (getSettingStatus('showDecoration') && singleSelectedDecorations != "" && singleSelectedDecorations != null && singleSelectedDecorations != "null" && singleSelectedDecorations != undefined) {
                                    for (let i = 0; i < product?.LogoInfo.length; i++) {
                                        if (product?.LogoInfo[i].color === selectedColor && single.color == selectedColor && product?.LogoInfo[i].location == singleSelectedDecorations.location) {

                                            findlogoinfo = product?.LogoInfo[i]
                                            break;
                                        } else if (product?.LogoInfo[i].color === selectedColor && single.color == selectedColor) {
                                            let extra_logo = product?.LogoInfo[i].extra_logo.filter(val => val.location == singleSelectedDecorations.location)
                                            if (extra_logo.length > 0) {

                                                findlogoinfo = extra_logo
                                                findlogoinfo[0].image = product?.LogoInfo[i].image
                                                findlogoinfo[0].color = product?.LogoInfo[i].color
                                                findlogoinfo[0].ih = product?.LogoInfo[i].ih
                                                findlogoinfo[0].iw = product?.LogoInfo[i].iw
                                                findlogoinfo[0].location = extra_logo[0].location
                                                findlogoinfo[0].h = extra_logo[0].h

                                                findlogoinfo[0].w = extra_logo[0].w
                                                findlogoinfo[0].x = extra_logo[0].x
                                                findlogoinfo[0].y = extra_logo[0].y
                                                if (product?.LogoInfo[i]?.isNewLogo == "1" && !!product?.LogoInfo[i]?.processedImage) {
                                                    findlogoinfo[0].processedImageWidth = product?.LogoInfo[i]?.processedImageWidth
                                                    findlogoinfo[0].processedImageHeight = product?.LogoInfo[i]?.processedImageHeight
                                                    findlogoinfo[0].isNewLogo = product?.LogoInfo[i]?.isNewLogo
                                                    findlogoinfo[0].processedImage = product?.LogoInfo[i]?.processedImage
                                                }
                                                break;
                                            }
                                        }
                                    }
                                }

                                if (findlogoinfo && Object.keys(findlogoinfo).length > 0) {
                                    if (!!findlogoinfo && findlogoinfo.length > 0) {
                                        singlelogoDetails = findlogoinfo[0];
                                    } else {
                                        singlelogoDetails = findlogoinfo;
                                    }
                                }

                                let imgWidth = singlelogoDetails.iw
                                let imgHeight = singlelogoDetails.ih
                                let singleX = singlelogoDetails?.x
                                let singleY = singlelogoDetails?.y
                                let singleW = singlelogoDetails?.w
                                let singleH = singlelogoDetails?.h
                                if (singlelogoDetails?.isNewLogo == "1" && !!singlelogoDetails?.processedImage) {
                                    imgWidth = singlelogoDetails?.processedImageWidth
                                    imgHeight = singlelogoDetails?.processedImageHeight
                                    singleX = singlelogoDetails?.newX
                                    singleY = singlelogoDetails?.newY
                                    singleW = singlelogoDetails?.newW
                                    singleH = singlelogoDetails?.newH
                                    prImage = singlelogoDetails?.processedImage
                                }

                                if (imgWidth > 900 || imgHeight > 1100) {
                                    var ratio = Math.min(800 / imgWidth, 800 / imgHeight);

                                    imgWidth = imgWidth * ratio
                                    imgHeight = imgHeight * ratio
                                }

                                let reducePer = getReducePer(imgHeight, imageH)
                                const imageJustifyProduct = getImageJustification(getSingleImage)
                                let positionSetLeft = (imageW * singleX) / imgWidth
                                let positionSetTop = (imageH * singleY) / imgHeight

                                let positionSetBoxWidth = (imageW * singleW) / imgWidth
                                let positionSetBoxHeight = (imageH * singleH) / imgHeight
                                
                                return (
                                    <div key={key}>
                                        <div className={`${productImageExt(process.env.PUBLIC_URL + prImage) ? 'product-img-bg' : ''} single-image lightGal`}>
                                            <div className="containerimg" style={{ maxWidth: '451px', margin: '0px auto' }}>
                                                <LightgalleryProvider onAfterOpen={() => photoZoom(productImageExt(process.env.PUBLIC_URL + prImage) ? 'product-img-bg' : '', singlelogoDetails)}>
                                                    <LightgalleryItem
                                                        group="any"
                                                        src={productImageUrl(
                                                            process.env.PUBLIC_URL + (prImage),
                                                        )}
                                                    >
                                                        <img
                                                            src={productImageUrl(
                                                                process.env.PUBLIC_URL + (prImage),
                                                            )}
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        {getSettingStatus('showDecoration') && singlelogoDetails && Object.keys(singlelogoDetails).length > 0 && selectedImage === (singlelogoDetails?.image ?? single?.url) && selectedDecorations != "" && selectedDecorations != null && artworkListById?.length > 0 && selectedDecorations.length > 0 && selectedDecorations.map((li, key) => {
                                                            let to = ""
                                                            let lef = ""
                                                            let wid = ""
                                                            let hei = ""
                                                            if (li.location == singlelogoDetails.location) {
                                                                to = singlelogoDetails.y;
                                                                lef = singlelogoDetails.x;
                                                                wid = singlelogoDetails.w;
                                                                hei = singlelogoDetails.h;
                                                                if (singlelogoDetails?.isNewLogo == "1" && !!singlelogoDetails?.processedImage) {
                                                                    lef = singlelogoDetails?.newX
                                                                    to = singlelogoDetails?.newY
                                                                    wid = singlelogoDetails?.newW
                                                                    hei = singlelogoDetails?.newH
                                                                }
                                                            } else {
                                                                singlelogoDetails.extra_logo && singlelogoDetails.extra_logo.map(el => {
                                                                    if (singlelogoDetails.location == el.location) {
                                                                        to = el.y;
                                                                        lef = el.x;
                                                                        wid = el.w;
                                                                        hei = el.h;
                                                                        if (singlelogoDetails?.isNewLogo == "1" && !!singlelogoDetails?.processedImage) {
                                                                            to = el?.newY
                                                                            lef = el?.newX
                                                                            wid = el?.newW
                                                                            hei = el?.newH
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                            to = ((Number(to) * reducePer) / 100).toString();
                                                            lef = ((Number(lef) * reducePer) / 100).toString();
                                                            wid = ((Number(wid) * reducePer) / 100).toString();
                                                            hei = ((Number(hei) * reducePer) / 100).toString();

                                                            let artwork_selected = null
                                                            if (li?.artworkID !== '' && li?.artworkID !== undefined) {
                                                                const idToSearch = singleSelectedDecorations ? singleSelectedDecorations.artworkID : li.artworkID
                                                                artwork_selected = artworkListById?.find(
                                                                    (artworkArray) => artworkArray.id === idToSearch,
                                                                )
                                                            }

                                                            let rowVariation = null
                                                            if (artwork_selected?.design?.variation?.length > 0) {
                                                                rowVariation = artwork_selected.design.variation.filter(
                                                                    (variationArray) =>
                                                                        variationArray.design_variation_unique_id ==
                                                                        li.variationUniqID,
                                                                )[0]
                                                                if (artwork_selected?.design?.variation?.itemImageThumbnail != "" && artwork_selected?.design?.variation?.itemImageThumbnail !== undefined) {
                                                                    rowVariation = artwork_selected?.image
                                                                }
                                                            }
                                                            if (!!rowVariation) {
                                                                return (
                                                                    <div
                                                                        className="overlayimg"
                                                                        key={key}
                                                                        style={{
                                                                            transitionDelay: 'none !important',
                                                                            top: positionSetTop + 'px',
                                                                            left: positionSetLeft + 'px',
                                                                            width: '100%',
                                                                            height: '100%',
                                                                        }}
                                                                    >


                                                                        {
                                                                            rowVariation && rowVariation?.itemImageThumbnail && rowVariation.itemImageThumbnail[0] ?
                                                                                <img
                                                                                    className="img-fluid"
                                                                                    src={
                                                                                        rowVariation?.itemImageThumbnail
                                                                                            ? productImageUrl(
                                                                                                rowVariation.itemImageThumbnail[0],
                                                                                            )
                                                                                            : ''
                                                                                    }


                                                                                    style={{
                                                                                        maxWidth: positionSetBoxWidth + 'px',
                                                                                        maxHeight: positionSetBoxHeight + 'px',
                                                                                        top: positionSetTop + 'px',
                                                                                        left: positionSetLeft + 'px',
                                                                                        objectPosition: imageJustifyProduct,
                                                                                        width: '100%',
                                                                                        height: '100%',
                                                                                    }}
                                                                                />
                                                                                :
                                                                                <img className="img-fluid"
                                                                                    src={rowVariation && rowVariation?.itemImageThumbnail != "" ? productImageUrl(rowVariation) : productImageUrl(rowVariation)}


                                                                                    style={{
                                                                                        maxWidth: positionSetBoxWidth + 'px',
                                                                                        maxHeight: positionSetBoxHeight + 'px',
                                                                                        top: positionSetTop + 'px',
                                                                                        left: positionSetLeft + 'px',
                                                                                        objectPosition: imageJustifyProduct,
                                                                                        width: '100%',
                                                                                        height: '100%',
                                                                                    }}
                                                                                />
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                        <span style={{ display: 'none' }}>
                                                            {single.color}
                                                        </span>
                                                    </LightgalleryItem>
                                                </LightgalleryProvider>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                    </Swiper> :
                        <Swiper {...gallerySwiperParams}>
                            {product?.WebstoreProductPartArray &&
                                product?.WebstoreProductPartArray?.filter(
                                    (ele, ind) => ind === product?.WebstoreProductPartArray.findIndex(elem => elem.color === ele.color)).map((single, key) => {
                                        let getImage = product?.WebstoreProductPartArray.filter((val) => val.color == single.color && val.isSwapImage == true,)
                                        let mainCls = 'single-image'
                                        let zmImg = ''
                                        if ((getImage && getImage.length > 0 && !!getImage[0]?.image && productImageExt(getImage[0]?.image)) || productImageExt(process.env.PUBLIC_URL + single.url)) {
                                            mainCls = 'single-image product-img-bg'
                                            zmImg = 'product-img-bg'
                                        }
                                        return (
                                            <div key={key}>
                                                <div className={mainCls}>
                                                    <div className="containerimg">
                                                        <LightgalleryProvider onAfterOpen={() => photoZoom(zmImg)}>
                                                            <LightgalleryItem
                                                                group="any"
                                                                src={getImage && getImage.length > 0 ? productImageUrl(getImage[0].image) : productImageUrl(
                                                                    process.env.PUBLIC_URL + single.url,
                                                                )}
                                                            >
                                                                <div>
                                                                    <img
                                                                        src={getImage && getImage.length > 0 ? productImageUrl(getImage[0].image) : productImageUrl(
                                                                            process.env.PUBLIC_URL + single.url,
                                                                        )}
                                                                        className="img-fluid"
                                                                        alt=""
                                                                    />
                                                                    <span style={{ display: 'none' }}>
                                                                        {single.color}
                                                                    </span>
                                                                </div>
                                                            </LightgalleryItem>
                                                        </LightgalleryProvider>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                        </Swiper>
                    }
                </div>
            </div>}
            {!!isSmall && <div
                className={`small-images m-0`}>
                <div className={`product-small-image-wrapper product-small-image-wrapper--side-thumb ${sliderListingData?.length > 3 ? 'smh-100' : ''}`}>
                    {isReplace ? <Swiper {...thumbnailSwiperParams} key={swiperKey}>
                        {sliderListingData &&
                            sliderListingData.map((single, key) => {
                                let getImage = product?.WebstoreProductPartArray.filter((val) => val.color == single.color && val.isSwapImage == true,)
                                let mainCls = 'single-image test1'
                                if ((getImage && getImage.length > 0 && !!getImage[0]?.image && productImageExt(getImage[0]?.image)) || productImageExt(process.env.PUBLIC_URL + single.url)) {
                                    mainCls = 'single-image product-img-bg test2'
                                }
                                let findlogoinfo = ''
                                let singlelogoDetails = {}
                                let prImage = single.url
                                if (single?.isNewLogo == "1" && !!single?.processedImage) {
                                    prImage = single.processedImage
                                }
                                if (getImage.length > 0) {
                                    prImage = getImage[0].image
                                }
                                if (getSettingStatus('showDecoration') && singleSelectedDecorations != "" && singleSelectedDecorations != null && singleSelectedDecorations != "null" && singleSelectedDecorations != undefined) {
                                    let ids = `${single.locationData}`
                                    let ids2 = `${singleSelectedDecorations?.locationId}-${key}`
                                    for (let i = 0; i < product?.LogoInfo.length; i++) {
                                        if (product?.LogoInfo[i].color === selectedColor && single.color == selectedColor && product?.LogoInfo[i].location == singleSelectedDecorations.location && ids == ids2) {

                                            findlogoinfo = product?.LogoInfo[i]
                                            break;
                                        } else if (product?.LogoInfo[i].color === selectedColor && single.color == selectedColor) {
                                            let extra_logo = product?.LogoInfo[i].extra_logo.filter(val => val.location == singleSelectedDecorations.location && ids == ids2)
                                            if (extra_logo.length > 0) {

                                                findlogoinfo = extra_logo
                                                findlogoinfo[0].image = product?.LogoInfo[i].image
                                                findlogoinfo[0].color = product?.LogoInfo[i].color
                                                findlogoinfo[0].ih = product?.LogoInfo[i].ih
                                                findlogoinfo[0].iw = product?.LogoInfo[i].iw
                                                findlogoinfo[0].location = extra_logo[0].location
                                                findlogoinfo[0].h = extra_logo[0].h

                                                findlogoinfo[0].w = extra_logo[0].w
                                                findlogoinfo[0].x = extra_logo[0].x
                                                findlogoinfo[0].y = extra_logo[0].y
                                                if (product?.LogoInfo[i]?.isNewLogo == "1" && !!product?.LogoInfo[i]?.processedImage) {
                                                    findlogoinfo[0].processedImageWidth = product?.LogoInfo[i]?.processedImageWidth
                                                    findlogoinfo[0].processedImageHeight = product?.LogoInfo[i]?.processedImageHeight
                                                    findlogoinfo[0].isNewLogo = product?.LogoInfo[i]?.isNewLogo
                                                    findlogoinfo[0].processedImage = product?.LogoInfo[i]?.processedImage
                                                }
                                                break;
                                            }
                                        }
                                    }
                                }

                                if (findlogoinfo && Object.keys(findlogoinfo).length > 0) {
                                    if (!!findlogoinfo && findlogoinfo.length > 0) {
                                        singlelogoDetails = findlogoinfo[0];
                                    } else {
                                        singlelogoDetails = findlogoinfo;
                                    }
                                }

                                let imgWidth = singlelogoDetails.iw
                                let imgHeight = singlelogoDetails.ih
                                let singleX = singlelogoDetails?.x
                                let singleY = singlelogoDetails?.y
                                let singleW = singlelogoDetails?.w
                                let singleH = singlelogoDetails?.h
                                if (singlelogoDetails?.isNewLogo == "1" && !!singlelogoDetails?.processedImage) {
                                    imgWidth = singlelogoDetails?.processedImageWidth
                                    imgHeight = singlelogoDetails?.processedImageHeight
                                    singleX = singlelogoDetails?.newX
                                    singleY = singlelogoDetails?.newY
                                    singleW = singlelogoDetails?.newW
                                    singleH = singlelogoDetails?.newH
                                }

                                if (imgWidth > 900 || imgHeight > 1100) {
                                    var ratio = Math.min(800 / imgWidth, 800 / imgHeight);

                                    imgWidth = imgWidth * ratio
                                    imgHeight = imgHeight * ratio
                                }


                                let reducePer = getReducePer((imgHeight / 2), imageSmallH)

                                let positionSetLeft = (imageSmallW * singleX) / imgWidth
                                let positionSetTop = (imageSmallH * singleY) / imgHeight

                                let positionSetBoxWidth = (imageSmallW * singleW) / imgWidth
                                let positionSetBoxHeight = (imageSmallH * singleH) / imgHeight
                                const imageJustifyProduct = getImageJustification(getSingleImage)
                                return (
                                    <div key={`${key}${single.color}`}>
                                        <div className={mainCls} style={{height:heightVal+'px'}}>
                                            <img
                                                src={getImage && getImage.length > 0 ? productImageUrl(getImage[0].image) : productImageUrl(
                                                    process.env.PUBLIC_URL + single.url,
                                                )}
                                                className="img-fluid main-small"
                                                alt=""
                                            />
                                            {singlelogoDetails && getSettingStatus('showDecoration') && Object.keys(singlelogoDetails).length > 0 && selectedImage === single.url && selectedDecorations != "" && selectedDecorations != null && artworkListById?.length > 0 && selectedDecorations.length > 0 && selectedDecorations.map((li, key) => {
                                                let to = ""
                                                let lef = ""
                                                let wid = ""
                                                let hei = ""
                                                if (li.location == singlelogoDetails.location) {
                                                    to = singlelogoDetails.y;
                                                    lef = singlelogoDetails.x;
                                                    wid = singlelogoDetails.w;
                                                    hei = singlelogoDetails.h;
                                                    if (singlelogoDetails?.isNewLogo == "1" && !!singlelogoDetails?.processedImage) {
                                                        lef = singlelogoDetails?.newX
                                                        to = singlelogoDetails?.newY
                                                        wid = singlelogoDetails?.newW
                                                        hei = singlelogoDetails?.newH
                                                    }
                                                } else {
                                                    singlelogoDetails.extra_logo && singlelogoDetails.extra_logo.map(el => {
                                                        if (singlelogoDetails.location == el.location) {
                                                            to = el.y;
                                                            lef = el.x;
                                                            wid = el.w;
                                                            hei = el.h;
                                                            if (singlelogoDetails?.isNewLogo == "1" && !!singlelogoDetails?.processedImage) {
                                                                to = el?.newY
                                                                lef = el?.newX
                                                                wid = el?.newW
                                                                hei = el?.newH
                                                            }
                                                        }
                                                    })
                                                }
                                                to = ((Number(to) * reducePer) / 100).toString();
                                                lef = ((Number(lef) * reducePer) / 100).toString();
                                                wid = ((Number(wid) * reducePer) / 100).toString();
                                                hei = ((Number(hei) * reducePer) / 100).toString();

                                                let artwork_selected = null
                                                if (li?.artworkID !== '' && li?.artworkID !== undefined) {

                                                    const idToSearch = singleSelectedDecorations ? singleSelectedDecorations.artworkID : li.artworkID
                                                    artwork_selected = artworkListById?.find(
                                                        (artworkArray) => artworkArray.id === idToSearch,
                                                    )
                                                }

                                                let rowVariation = null
                                                if (artwork_selected?.design?.variation?.length > 0) {
                                                    rowVariation = artwork_selected.design.variation.filter(
                                                        (variationArray) =>
                                                            variationArray.design_variation_unique_id ==
                                                            li.variationUniqID,
                                                    )[0]
                                                    if (artwork_selected?.design?.variation?.itemImageThumbnail != "" && artwork_selected?.design?.variation?.itemImageThumbnail !== undefined) {
                                                        rowVariation = artwork_selected?.image
                                                    }
                                                }
                                                if (!!rowVariation) {
                                                    return (
                                                        <div
                                                            className="overlayimg"
                                                            key={key}
                                                            style={{
                                                                transitionDelay: 'none !important',
                                                                top: positionSetTop + 'px',
                                                                left: positionSetLeft + 'px',
                                                                maxWidth: positionSetBoxWidth + 'px',
                                                                maxHeight: positionSetBoxHeight + 'px',
                                                                width: '100%',
                                                                height: '100%',
                                                            }}
                                                        >


                                                            {
                                                                rowVariation && rowVariation?.itemImageThumbnail && rowVariation.itemImageThumbnail[0] ?
                                                                    <img
                                                                        className="img-fluid"
                                                                        src={
                                                                            rowVariation?.itemImageThumbnail
                                                                                ? productImageUrl(
                                                                                    rowVariation.itemImageThumbnail[0],
                                                                                )
                                                                                : ''
                                                                        }

                                                                        style={{
                                                                            maxWidth: positionSetBoxWidth + 'px',
                                                                            maxHeight: positionSetBoxHeight + 'px',
                                                                            display: "block",
                                                                            top: positionSetTop + 'px',
                                                                            left: positionSetLeft + 'px',
                                                                            objectPosition: imageJustifyProduct,
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            
                                                                        }}
                                                                    />
                                                                    :
                                                                    <img className="img-fluid"
                                                                        src={rowVariation && rowVariation?.itemImageThumbnail != "" ? productImageUrl(rowVariation) : productImageUrl(rowVariation)}

                                                                        style={{
                                                                            maxWidth: positionSetBoxWidth + 'px',
                                                                            maxHeight: positionSetBoxHeight + 'px',
                                                                            top: positionSetTop + 'px',
                                                                            left: positionSetLeft + 'px',
                                                                            objectPosition: imageJustifyProduct,
                                                                            width: '100%',
                                                                            height: '100%',
                                                                        }}
                                                                    />
                                                            }
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                    </Swiper> :

                        <Swiper {...thumbnailSwiperParams}>
                            {product?.WebstoreProductPartArray &&
                                product?.WebstoreProductPartArray?.filter(
                                    (ele, ind) => ind === product?.WebstoreProductPartArray.findIndex(elem => elem.color === ele.color)).map((single, key) => {
                                        let getImage = product?.WebstoreProductPartArray.filter((val) => val.color == single.color && val.isSwapImage == true,)
                                        let mediaImage = sliderListingData?.filter(
                                            (mediaArray) => !mediaArray.hidden && mediaArray.color == single.color)
                                        let mainCls = 'single-image test3'
                                        if ((getImage && getImage.length > 0 && !!getImage[0]?.image && productImageExt(getImage[0]?.image)) || productImageExt(process.env.PUBLIC_URL + single.image)) {
                                            mainCls = 'single-image product-img-bg test4'
                                        } else if (productImageExt(mediaImage[0]?.url)) {
                                            mainCls = 'single-image product-img-bg test5'
                                        }
                                        return (
                                            <div key={key}>
                                                <div className={mainCls} style={{height:heightVal+'px'}}>
                                                    <img
                                                        src={getImage && getImage.length > 0 ? productImageUrl(getImage[0].image) : single.image ? productImageUrl(
                                                            process.env.PUBLIC_URL + single.image,
                                                        ) : productImageUrl(
                                                            process.env.PUBLIC_URL + mediaImage[0]?.url,
                                                        )}
                                                        className="img-fluid main-small"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}
                        </Swiper>}
                </div>
            </div>}
        </Fragment>
    )
};

ProductImageGalleryHats.propTypes = {
    product: PropTypes.object,
    thumbPosition: PropTypes.string
};

export default ProductImageGalleryHats;
