import { UserCostCenterResponse } from '../../models/User';
import { aetherApi } from './aetherApi';

const userApi = aetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserCostCenters: builder.query<UserCostCenterResponse, void>({
      query: () => `api/v1/user/cost-centers/all`,
    }),
    getUserGroupAccountId: builder.query<{ accountId: string }, string>({
      query: (userId) => `api/v1/user/${userId}/user-group-account`,
    }),
  }),
});

export const { useGetUserCostCentersQuery, useGetUserGroupAccountIdQuery } = userApi;
