import { Stack, Typography } from '@mui/material';
import React from 'react';
import CheckoutTextField from '../CheckoutTextField';
import { PaymentMethodFormProps } from './PaymentMethodForms';
import { useAppSelector } from '../../../redux/hooks';
import { selectGiftCardCodes, selectParams } from '../../../redux/selectors/checkoutSelectors';

export default function GiftCardForm({ settings, index }: PaymentMethodFormProps) {
  const params = useAppSelector(selectParams);
  const giftCardCodes = useAppSelector((state) => selectGiftCardCodes(state, params, index));

  return (
    <Stack direction={'column'} spacing={2}>
      <Typography variant={'h6'} gutterBottom>
        Redeem Gift Card
      </Typography>
      <CheckoutTextField
        name={'giftCardCode'}
        label={'Gift Card Code'}
        rules={{
          required: true,
          validate: {
            used: (value) =>
              (!giftCardCodes.includes(value) &&
                !giftCardCodes.includes(value.replace(/-/g, ''))) ||
              'Code already used',
          },
        }}
      />
    </Stack>
  );
}
