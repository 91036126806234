import Decimal from "decimal.js";

export const formatPrice = (amount, decimalPlaces = 2) => {
    amount = Number(amount);
    decimalPlaces = Number(decimalPlaces);
    return (new Decimal(amount)).toDP(decimalPlaces).toNumber();
}

export const formatPriceFixedDecimalPlaces = (amount, decimalPlaces = 2) => {
    const number = formatPrice(amount, decimalPlaces);

    return number.toFixed(decimalPlaces);
}