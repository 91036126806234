import React, { useContext, useEffect } from 'react';
import { getCartById, updateCart } from '../util/cart/cartAPIUtil';
import { getWishlistById } from '../util/wishlist/wishlistAPIUtil';
import { wishlistItemData } from '../redux/actions/wishlistActions';
import { cartItemData } from '../redux/actions/cartActions';
import { AuthContext } from './Auth';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useLazyGetSavedCartQuery } from '../redux/api/cartApi';
import _ from 'lodash';

export default function GlobalLoader({ children }) {
  const { user, ipV4, ipV4Loaded } = useContext(AuthContext);
  const dispatch = useDispatch();

  const [getSavedCart] = useLazyGetSavedCartQuery();

  const { search } = useLocation();

  useEffect(() => {
    const getCart = async () => {
      // Saved cart
      if (search) {
        const params = new URLSearchParams(search);
        const cartId = params.get('cartId');
        if (cartId) {
          try {
            const cart = await getSavedCart(cartId).unwrap();
            await updateCart(cart.cartItems, ipV4Loaded);
          } catch (error) {
            console.error('Error getting saved cart:', error);
          }
        }
      }
      const cartData = await getCartById(user, ipV4);
      if (cartData) {
        dispatch(cartItemData(cartData));
      }
    };

    const getWishlist = async () => {
      const wishlistData = await getWishlistById(ipV4);
      if (wishlistData) {
        dispatch(wishlistItemData(wishlistData));
      }
    };
    if (ipV4Loaded) {
      getCart();
      getWishlist();
    }
  }, [user, ipV4, ipV4Loaded, dispatch, search]);

  return children;
}
